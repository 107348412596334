import styles from './budgetPDF.module.css'
import { Budget, BudgetItem } from "../../../state/types"
import { BudgetPDFPage } from './budgetPDFPage'
import { useEffect, useMemo, useState } from "react"
import { DataType } from '../../../../../tables'
import { useSalesActions } from '../../../actions/salesActions'

type Props = {
    id: string
    budget: Budget
    previewMode?: boolean
}

export const BudgetPDF = (props: Props) => {
    const { id, budget, previewMode } = props

    const [budgetItems, setBudgetItems] = useState<BudgetItem[]>([])
    const [currentPage, setCurrentPage] = useState(0)

    const salesActions = useSalesActions()

    useEffect(() => {
        const onFetchBudgetItems = (budgetItemsDT: DataType[]) => {
            const budgetItems = budgetItemsDT as BudgetItem[]  
            setBudgetItems(budgetItems)
        }

        salesActions().fetchBudgetItemsByOrder(budget.id, onFetchBudgetItems)
    }, [])

    const getPagesItems = (budgetItems: BudgetItem[]) => {
        const pages: BudgetItem[][] = []
        const pushPages = (startIndex = 0) => {
            const isFirstPage = startIndex === 0
            const items = budgetItems.slice(startIndex)
            if (items.length > 0) {
                const maxPageSize = isFirstPage ? 10 : 13
                const pageSize = items.length < maxPageSize ? maxPageSize - 2 : (items.length === maxPageSize ? maxPageSize - 1 : maxPageSize)
                pages.push(items.slice(0, pageSize))
                pushPages(startIndex + pageSize)
            }
        }
        pushPages()
        
        return pages
    }

    const pagesItems: BudgetItem[][] = useMemo(() => budget ? getPagesItems(budgetItems) : [], [budgetItems])

    const previousPage = () => setCurrentPage(page => page - 1)
    const nextPage = () => setCurrentPage(page => page + 1)

    return (
        <div className={styles.container}>
            <div className={`${styles.arrow} ${styles.prevArrow}`}>
                {currentPage > 0 && <i className="bi bi-arrow-left-circle" onClick={previousPage} />}
            </div>
            <div className={styles.pdfContainer}>
                <div id={id}>
                    {pagesItems.map((budgetItems, index) => (
                        <div
                            key={index}
                            className={`${styles.pageContainer} ${!previewMode || index === currentPage ? styles.currentPage : ''}`}
                        >
                            <BudgetPDFPage
                                pageIndex={index}
                                budget={budget}
                                budgetItems={budgetItems}
                                pagesAmount={pagesItems.length}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className={`${styles.arrow} ${styles.nextArrow}`}>
                {currentPage < pagesItems.length - 1 && <i className="bi bi-arrow-right-circle" onClick={nextPage} />}
            </div>
        </div>
    )
}
