import { useState } from "react"
import { PanelWrapper } from "../../../../components/wrappers/panel-wrapper/panelWrapper"
import { Data, FieldType, FieldValue, Form, FormField, option, useGetCrumbs } from "../../../../features/ui"
import { TableName } from "../../../../tables"
import { CashFund } from "../../state/types"
import { useActions } from "./actions"
import { useAdministrationState } from "../../hooks/administrationHooks"
import { define } from "../../../../utils/typeUtils"
import { useDataTypesHooks } from "../../../../features/data-types"
import { TextArea } from "../../../../components/fields/text-area/textArea"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../features/routes"

export const TransferCashFundForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const cashFund = define(dataType) as CashFund
    const { cashFunds } = useAdministrationState()

    const [source, setSource] = useState<CashFund | undefined>(cashFund)
    const [destination, setDestination] = useState<CashFund | undefined>()
    const [concept, setConcept] = useState<string>()

    const navigate = useNavigate()
    const { formatValue } = useDataTypesHooks()

    const { submitTransfer } = useActions()

    const crumbs = useGetCrumbs('Cajas', RoutePath.CASH_FUNDS, cashFund?.name, undefined, 'Transferencia')

    const destinations = cashFunds.filter(cashFund => cashFund.id !== source?.id)

    const find = (id?: string) => cashFunds.find(cashFund => cashFund.id === id)

    const onSourceChange = (value?: FieldValue) => {
        setSource(find(value as string | undefined))
    }
    
    const onDestinationChange = (value?: FieldValue) => {
        setDestination(find(value as string | undefined))
    }

    const onConceptChange = (value?: FieldValue) => {
        setConcept(value as string | undefined)
    }

    const validateDestination = () => {
        return source?.id === destination?.id ?
        'La caja destino debe ser distinta a la caja origen' : null
    }

    const fields: FormField[] = [
        {
            name: 'sourceId',
            type: FieldType.TABLE,
            table: TableName.CASH_FUNDS,
            defaultValue: source?.id,
            label: 'Origen',
            onChange: onSourceChange
        },
        {
            name: 'sourceAmount',
            type: FieldType.TEXT,
            label: ' ',
            render: () => <>Fondos: {formatValue(source?.amount || 0, FieldType.PRICE)}</>
        },
        {
            name: 'destinationId',
            type: FieldType.TABLE,
            table: TableName.CASH_FUNDS,
            options: destinations.map(destination => option(destination.id, destination.name)),
            label: 'Destino',
            disabled: false,
            onChange: onDestinationChange,
            validate: validateDestination
        },
        {
            name: 'amount',
            type: FieldType.PRICE,
            label: 'Monto',
            max: source?.amount || 0
        },
        {
            name: 'concept',
            type: FieldType.TEXT,
            label: 'Concepto',
            render: () => <TextArea
                id="concept"
                onChange={onConceptChange}
            />
        }
    ]

    const onSubmit = (transferData: Data) => {
        submitTransfer(transferData, define(concept))
    }

    const onCancel = () => navigate(-1)

    return (
        <PanelWrapper title="Transferencia de Caja" crumbs={crumbs}>
            <Form
               formId="transfer-cash-fund-form"
               fields={fields}
               createLabel="Transferir"
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
        </PanelWrapper>
    )
}
