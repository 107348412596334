import { useDefaultDbActions } from "../../../actions/defaultDbActions"
import { useDefaultModuleActions } from "../../../actions/defaultModuleActions"
import { BaseCallback, ItemCallback, ListCallback, OptionalItemCallback } from "../../../actions/types"
import { fileSubPath, useFileActions } from "../../../features/files"
import { DynamoSystemApi } from "../../../services"
import { TextParams } from "../../../state/reducers/types"
import { DataType, TableName } from "../../../tables"
import { define, firstItem } from "../../../utils/typeUtils"
import { useSystemState, useSystemStateActions } from "../state/hooks"
import { Company, Request, User } from "../state/types"

export const useSystemActions = () => {
    const systemState = useSystemState()
    const stateCompany = systemState.company
    const stateCompanyId = stateCompany?.id

    const defaultDbActions = useDefaultDbActions()
    const defaultModuleActions = useDefaultModuleActions()
    const { setCompany, setUser, setConstrufyAdminId, updateCompanyUsers } = useSystemStateActions()
    const { saveFile, removeFile } = useFileActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const { getAll, getByParams, getByParams_async, getByIds_async, save_async, remove } = defaultDbActions(DynamoSystemApi)

        const fetchAllCompanies = async () => {
            return getAll(TableName.COMPANIES).then(response => response.dataTypes as Company[])
        }

        const fetchCompanyById = async (id: string) => {
            const companies = await getByParams_async(TableName.COMPANIES, { id }) as Company[]
            return firstItem(companies)
        }
        
        const fetchCompanyByCode = async (code: string, saveState = true) => {
            const companies = await getByParams_async(TableName.COMPANIES, { code }) as Company[]
            const company = firstItem(companies)
            saveState && company && setCompany(company)
            return company
        }

        const saveCompany = async (
            company: Company,
            logoFile?: File
        ) => {
            const updatedCompany = {
                ...company
            }
    
            if (logoFile) {
                const fileUrl = await saveFile(fileSubPath(TableName.COMPANIES, company.id), logoFile, true)
                updatedCompany.logoUrl = fileUrl
            } else if (stateCompany?.logoUrl) {
                await removeFile(stateCompany.logoUrl, true)
                delete updatedCompany.logoUrl
            }
    
            await save_async(TableName.COMPANIES, [updatedCompany])
            setCompany(updatedCompany)
        }

        const fetchUserById = async (id: string) => {
            const users = await getByParams_async(TableName.USERS, { id }) as User[]
            const user = firstItem(users)
            if (user) {
                setUser(user)
                return user
            }
        }

        const fetchUserByEmail = (email: string, callback?: OptionalItemCallback) => {
            const onFetchUsers = (usersDT: DataType[]) => {
                if (usersDT.length > 0) {
                    callback && callback(usersDT[0] as User)
                } else {
                    callback && callback()
                }
            }
            fetchUsersByEmail(email, onFetchUsers)
        }

        const fetchUsersByIds = async (ids: string[]) => {
            const users = await getByIds_async(TableName.USERS, ids) as User[]
            updateCompanyUsers(users)
            return users
        }

        const fetchUsersByEmail = (email: string, callback?: ListCallback) => {
            getByParams(TableName.USERS, { email }, callback)
        }

        const saveUser = async (user: User, saveState = true) => {
            await save_async(TableName.USERS, [user])
            saveState && setUser(user)
            return user
        }

        const removeUser = (id: string, callback?: BaseCallback) => {
            remove(TableName.USERS, [id], () => callback && callback())
        }

        const fetchAdmin = async (id: string) => {
            const dataTypes = await getByParams_async(TableName.ADMIN, { id })
            const admin = firstItem(dataTypes)
            if (admin) {
                setConstrufyAdminId(admin.id)
                return admin
            }
        }

        const fetchAllRequests = async (limit?: number, startKey?: TextParams) => {
            const { fetchAll } = defaultModuleActions(DynamoSystemApi, define(companyId))
            const response = await fetchAll(TableName.REQUESTS, limit, startKey)
            const requests = response.dataTypes as Request[]
            await fetchUsersByIds(requests.map(request => request.userId))
            return response
        }

        const fetchRequest = async (requestCompanyId: string, userId: string) => {
            const { fetchByParams_async } = defaultModuleActions(DynamoSystemApi, define(companyId))
            return fetchByParams_async(TableName.REQUESTS, { companyId: requestCompanyId, userId })
        }

        const fetchRequests = async (userId: string, saveState = true) => {
            if (saveState) {
                const { fetchMultipleByParams_async } = defaultModuleActions(DynamoSystemApi, companyId)
                return fetchMultipleByParams_async(TableName.REQUESTS, { userId })
            } else {
                const { getByParams_async } = defaultDbActions(DynamoSystemApi, companyId)
                return getByParams_async(TableName.REQUESTS, { userId })
            }
        }

        const saveRequest = (request: Request, saveState = true, callback?: ItemCallback) => {
            if (saveState) {
                const tenantModuleActions = defaultModuleActions(DynamoSystemApi, define(companyId))
                tenantModuleActions.save(TableName.REQUESTS, request, callback)
            } else {
                const tenantDbActions = defaultDbActions(DynamoSystemApi, define(companyId))
                const onSaveRequest = () => callback && callback(request)
                tenantDbActions.save(TableName.REQUESTS, [request], onSaveRequest)
            }
        }

        const removeRequest = (id: string, callback?: BaseCallback) => {
            const { remove } = defaultModuleActions(DynamoSystemApi, define(companyId))
            remove(TableName.REQUESTS, id, callback)
        }

        return {
            fetchAllCompanies,
            fetchCompanyById,
            fetchCompanyByCode,
            saveCompany,
            fetchUserById,
            fetchUserByEmail,
            fetchUsersByEmail,
            saveUser,
            removeUser,
            fetchAdmin,
            fetchAllRequests,
            fetchRequest,
            fetchRequests,
            saveRequest,
            removeRequest
        }
    }
}
