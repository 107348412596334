import { useChildrenActions } from "../../../actions/childrenActions"
import { useDefaultModuleActions } from "../../../actions/defaultModuleActions"
import { BaseCallback, ItemCallback, ListCallback, OptionalItemCallback, SubmitChildrenFn } from "../../../actions/types"
import { Data } from "../../../features/data-types"
import { DynamoUserApi } from "../../../services"
import { TextParams } from "../../../state/reducers/types"
import { DataType, TableName } from "../../../tables"
import { Currency } from "../../../tables/types/types"
import { newId } from "../../../tables/utils"
import { define } from "../../../utils/typeUtils"
import { CategorySaleYield, Settings, SettingsModule, useAdministrationActions } from "../../administration"
import { OrderDelivery, OrderStatus, useOperationsActions, useOperationsHooks } from "../../operations"
import { Lot, useProductsActions } from "../../products"
import { useStockActions } from "../../stock"
import { useSystemStateHooks } from "../../system"
import { PurchaseItem, PurchaseOrder, Supplier } from "../state/types"

export const usePurchasesActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId
    const { getPendingQuantity } = useOperationsHooks()

    const defaultModuleActions = useDefaultModuleActions()
    const administrationActions = useAdministrationActions()
    const operationsActions = useOperationsActions()
    const productsActions = useProductsActions()
    const stockActions = useStockActions()
    const { submitChildren } = useChildrenActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const { fetchAll, fetchByParams, fetchMultipleByParams_async, fetchByIds, count, save, save_async, saveMultiple, remove, removeMultiple } = defaultModuleActions(DynamoUserApi, define(companyId))
        const { saveProduct, removeProduct, saveCategory, removeCategory, saveProductPrices, fetchLot, saveLot } = productsActions(companyId)
    
        const fetchAllPurchases = (deliveryBranchId: string) => {
            return fetchMultipleByParams_async(TableName.PURCHASE_ORDERS, { deliveryBranchId })
        }

        const fetchPurchases = (ids: string[], callback?: ListCallback) => {
            fetchByIds(TableName.PURCHASE_ORDERS, ids, callback)
        }

        const fetchPurchase = (id: string, callback?: OptionalItemCallback) => {
            fetchByParams(TableName.PURCHASE_ORDERS, { id }, callback)
        }

        const countPurchases = (propName: string, propValue: string) => {
            return count(TableName.PURCHASE_ORDERS, { [propName]: propValue })
        }
        
        const savePurchase = (purchase: PurchaseOrder, callback?: ItemCallback) => {
            save(TableName.PURCHASE_ORDERS, purchase, callback)
        }

        const savePurchaseWithItems = (
            purchase: PurchaseOrder,
            purchaseItemsData: Data[],
            callback?: ItemCallback
        ) => {
            const onFinish = () => callback && callback(purchase)

            const purchaseItems = purchaseItemsData.map(purchaseItemData => ({
                ...purchaseItemData,
                companyId: define(companyId),
                id: newId(),
                purchaseId: purchase.id,
                currency: Currency.ARS
            })) as PurchaseItem[]

            const onSavePurchase = () => {
                submitChildren([], purchaseItems, savePurchaseItems as SubmitChildrenFn, undefined, onFinish)
            }

            savePurchase(purchase, onSavePurchase)
        }

        const removePurchase = (id: string, callback?: BaseCallback) => {
            const { fetchDeliveries, removeDeliveries } = operationsActions()
            
            const onFinish = () => callback && callback()

            const onRemovePurchaseItems = async () => {
                const deliveries = await fetchDeliveries(id) as OrderDelivery[]
                const deliveryIds = deliveries.map(delivery => delivery.id)
                removeDeliveries(deliveryIds, onFinish)
            }

            const onRemovePurchase = async () => {
                const purchaseItems = await fetchPurchaseItemsByOrder(id) as PurchaseItem[]
                removePurchaseItems(purchaseItems, onRemovePurchaseItems)
            }
            
            const onFetchPurchase = (purchaseDT?: DataType) => {
                const purchase = define(purchaseDT) as PurchaseOrder
                if (purchase.status !== OrderStatus.DELIVERED) {
                    remove(TableName.PURCHASE_ORDERS, id, onRemovePurchase)
                } else {
                    onFinish()
                }
            }
            
            fetchPurchase(id, onFetchPurchase)
        }

        const fetchPurchaseItemsByOrder = (purchaseId: string) => {
            return fetchMultipleByParams_async(TableName.PURCHASE_ITEMS, { purchaseId })
        }

        const countPurchaseItems = (propName: string, propValue: string) => {
            return count(TableName.PURCHASE_ITEMS, { [propName]: propValue })
        }

        const savePurchaseItems = (purchaseItems: PurchaseItem[], callback?: ListCallback) => {
            saveMultiple(TableName.PURCHASE_ITEMS, purchaseItems, callback)
        }

        const removePurchaseItems = (purchaseItems: PurchaseItem[], callback?: BaseCallback) => {
            removeMultiple(TableName.PURCHASE_ITEMS, purchaseItems.map(purchaseItem => purchaseItem.id), callback)
        }

        const savePurchaseDelivery = async (
            purchase: PurchaseOrder,
            purchaseItems: PurchaseItem[],
            delivery: OrderDelivery,
            lotData: Partial<Lot>[],
            callback?: ItemCallback
        ) => {          
            const { fetchDeliveries, saveDelivery } = operationsActions()
            const { increaseStock } = stockActions()

            const onFetchOrSaveLot = (productId: string, branchId: string, delivered: number) => (lotDT?: DataType) => {
                increaseStock(productId, branchId, delivered, define(lotDT).id)
            }

            const onSavePurchase = (savedDelivery: OrderDelivery) => (purchaseDT: DataType) => {
                savedDelivery.deliveredItems.forEach(deliveredItem => {
                    const productId = define(purchaseItems.find(purchaseItem => purchaseItem.id === deliveredItem.itemId)?.productId)
                    const lotDataItem = lotData.find(lotDataItem => lotDataItem.productId === productId)

                    if (lotDataItem?.id) {
                        fetchLot(lotDataItem.id as string, onFetchOrSaveLot(productId, savedDelivery.branchId, deliveredItem.delivered))
                    } else {
                        const lot: Lot = {
                            companyId: define(companyId),
                            id: newId(),
                            code: define(lotDataItem?.code) as string,
                            productId,
                            attributes: lotDataItem?.attributes
                        }
                        saveLot(lot).then(lotDT => onFetchOrSaveLot(productId, savedDelivery.branchId, deliveredItem.delivered)(lotDT))
                    }
                })

                callback && callback(purchaseDT)
            }
            
            const deliveries = await fetchDeliveries(purchase.id) as OrderDelivery[]
            const savedDelivery = await saveDelivery(delivery) as OrderDelivery

            let pendingItemsCount = 0
            savedDelivery.deliveredItems.forEach(deliveredItem => {
                const deliveredPurchaseItem = purchaseItems.find(purchaseItem => purchaseItem.id === deliveredItem.itemId)
                const pendingQuantity = getPendingQuantity(define(deliveredPurchaseItem) as PurchaseItem, deliveries)
                deliveredItem.delivered < pendingQuantity && pendingItemsCount++
            })
            const updatedPurchase: PurchaseOrder = {
                ...purchase,
                deliveryBranchId: savedDelivery.branchId,
                status: pendingItemsCount === 0 ? OrderStatus.DELIVERED : OrderStatus.PARTIALLY_DELIVERED
            }

            savePurchase(updatedPurchase, onSavePurchase(savedDelivery))
        }

        const fetchAllSuppliers = async (limit?: number, startKey?: TextParams) => {
            return fetchAll(TableName.SUPPLIERS, limit, startKey)
        }

        const saveSupplier = async (supplier: Supplier) => {
            return save_async(TableName.SUPPLIERS, supplier)
        }

        const saveSuppliers = (suppliers: Supplier[], callback?: ListCallback) => {
            saveMultiple(TableName.SUPPLIERS, suppliers, callback)
        }

        const removeSupplier = (id: string, callback?: BaseCallback) => {
            remove(TableName.SUPPLIERS, id, callback)
        }

        const saveSaleYield = (saleYield: number, callback?: ItemCallback) => {
            const { fetchSettings, saveSettings } = administrationActions()

            const onFetchSalesSettings = (settingsDT?: DataType) => {
                const settings = define(settingsDT) as Settings
                const updatedSettings: Settings = {
                    ...settings,
                    data: {
                        ...settings.data,
                        saleYield
                    }                
                }
                saveSettings(updatedSettings, callback)
            }

            fetchSettings(SettingsModule.SALES, onFetchSalesSettings)
        }

        const saveCategorySaleYields = (categorySaleYields: CategorySaleYield[], callback?: ItemCallback) => {
            const { fetchSettings, saveSettings } = administrationActions()

            const onFetchSalesSettings = (settingsDT?: DataType) => {
                const settings = define(settingsDT) as Settings
                const updatedSettings: Settings = {
                    ...settings,
                    data: {
                        ...settings.data,
                        categorySaleYields
                    }                
                }
                saveSettings(updatedSettings, callback)
            }

            fetchSettings(SettingsModule.SALES, onFetchSalesSettings)
        }

        return {
            fetchAllPurchases,
            fetchPurchases,
            countPurchases,
            savePurchase,
            savePurchaseWithItems,
            removePurchase,
            fetchPurchaseItemsByOrder,
            countPurchaseItems,
            savePurchaseDelivery,
            fetchAllSuppliers,
            saveSupplier,
            saveSuppliers,
            removeSupplier,
            saveSaleYield,
            saveProduct,
            removeProduct,
            saveCategory,
            removeCategory,
            saveProductPrices,
            saveCategorySaleYields
        }
    }
}
