import { useActions } from './actions'
import { useAdministrationHooks } from '../../../../administration'
import { useEffect, useState } from 'react'
import { UIPermission, useUIActions, TabsPanel, FieldType, ModalId, EditablePanel, EditableFormField } from '../../../../../features/ui'
import { TableName } from '../../../../../tables'
import { useSystemStateHooks } from '../../../../system'
import { Product } from '../../../../products'
import { NonHoardableProductsTable } from '../../hoard/non-hoardable-products-table/nonHoardableProductsTable'
import { NonHoardableProductFormModal } from '../../hoard/non-hoardable-product-form-modal/nonHoardableProductFormModal'
import { UnignoreProductModal } from '../../hoard/unignore-product-modal/unignoreProductModal'

export const SalesSettingsPanel = () => {
    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_SALES_SETTINGS)
    const { getSalesSettingsData } = useAdministrationHooks()
    const { hoardIgnoredProductIds } = getSalesSettingsData()
    
    const [ignoredProductIds, setIgnoredProductIds] = useState<string[]>(hoardIgnoredProductIds)
    const [currentProductId, setCurrentProductId] = useState<string>()
    
    const { ignoreProduct, unignoreProduct } = useActions()
    const { toggleModal } = useUIActions()
    
    useEffect(() => {
        setIgnoredProductIds(hoardIgnoredProductIds)
    }, [hoardIgnoredProductIds])

    const ignoredProductsData = ignoredProductIds.map(productId => ({ productId }))
    const filterProducts = (product: Product) => currentProductId === product.id || !ignoredProductIds.includes(product.id)

    const onAddProduct = () => {
        toggleModal(ModalId.NON_HOARDABLE_PRODUCT_FORM)
        setCurrentProductId(undefined)
    }

    const onRemoveProduct = (productId: string) => {
        setCurrentProductId(productId)
        toggleModal(ModalId.UNIGNORE_PRODUCT)
    }

    const fields: EditableFormField[] = [
        {
            name: 'nonHoardableProducts',
            type: FieldType.TABLE,
            table: TableName.PRODUCTS,
            label: 'Productos No Acopiables',
            hint: 'Agregar a la lista los productos que no deben ser acopiados.',
            render: () => (
                <NonHoardableProductsTable
                    rows={ignoredProductsData}
                    readOnly={readOnly}
                    onAdd={onAddProduct}
                    onRemoveRow={onRemoveProduct}
                />
            )
        }
    ]

    const tabs = [
        {
            id: 'hoards',
            title: 'Acopios',
            content: <EditablePanel
                fields={fields}
            />
        },
        {
            id: 'others',
            title: 'Otras',
            content: <></>
        }
    ]

    const onUnignoreProduct = (productId: string) => {
        unignoreProduct(productId)
        setCurrentProductId(undefined)
    }

    return (
        <>
            <TabsPanel
                title="Configuración"
                tabs={tabs}
                wide={false}
            />
            <NonHoardableProductFormModal
                filterProducts={filterProducts}
                onSubmit={ignoreProduct}
            />
            <UnignoreProductModal
                productId={currentProductId}
                onSubmit={onUnignoreProduct}
            />
        </>
    )
}
