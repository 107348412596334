import { ListResponse } from "../../actions/types"
import { DynamoUserApi } from "../../services"
import { AttributeMap, useDynamoActions } from "../../services/dynamo"
import { toDynamoListCallback } from "../../services/utils"
import { TableName, TablesData } from "../../tables"
import { define } from "../../utils/typeUtils"

export const useRemovedPropMigration = () => {
    const dynamoActions = useDynamoActions()

    return (
        companyIds: string[],
        table: TableName,
        propName: string
    ) => {
        companyIds.forEach(companyId => {
            const { getAll, createOrUpdateMultiple } = dynamoActions(DynamoUserApi, companyId)
            const { dbTableName } = define(TablesData.get(table))
            const onGetAll = (response: ListResponse) => {
                const updatedDataTypes = response.dataTypes.map(dataType => ({
                    ...dataType,
                    [propName]: undefined
                })) as AttributeMap[]
                createOrUpdateMultiple(dbTableName, updatedDataTypes)
            }
            getAll(dbTableName, undefined, undefined, undefined, toDynamoListCallback(onGetAll))
        })
    }
}
