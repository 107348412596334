import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import * as actionCreators from "../state/actionCreators"
import { AdministrationState } from "../state/reducer"
import { useSelector } from "react-redux"
import { State } from "../../../state"
import { define, normalize } from "../../../utils/typeUtils"
import { CashFund, Employee, Expense, PaymentType, SalesSettingsData, Settings, SettingsModule } from "../state/types"
import { useFind } from "../../../state/reducers/hooks"
import { SalaryExpenseTypeName } from "../../../tables/standards/expenseTypes"
import { DataType, TableName } from "../../../tables"
import { Supplier } from "../../purchases"
import { useFiltersHooks } from "../../../features/ui"
import { cashFundTypeLabel } from "../../../tables/types/labels"

export const useAdministrationState = (): AdministrationState => useSelector((state: State) => state.administrationReducer)

export const useAdministrationStateActions = () => bindActionCreators(actionCreators, useDispatch())

export const useAdministrationHooks = () => {
    const { settings, expenses } = useAdministrationState()
    const { search } = useFiltersHooks()
    const find = useFind()
    
    const applyPaymentTypeAdjustment = (
        price: number,
        paymentType: PaymentType
    ) => {
        return price * (1 + (paymentType.yield / 100))
    }

    const getSalesSettings = (): Settings => define(settings.find(setting => setting.module === SettingsModule.SALES))

    const getSalesSettingsData = (): SalesSettingsData => {
        const { data } = getSalesSettings()
        return data as SalesSettingsData
    }

    const getGeneralSaleYield = (categoryId?: string) => {
        const { saleYield, categorySaleYields } = getSalesSettingsData()
        const categorySaleYield = categorySaleYields.find(categorySaleYield => categorySaleYield.categoryId === categoryId)?.saleYield
        return categorySaleYield || define(saleYield)
    }

    const getTotalSaleTax = () => {
        const { saleTaxes } = getSalesSettingsData()
        return saleTaxes.reduce((sum, saleTax) => sum + saleTax.yield, 0)
    }

    const getBranchExpenses = (branchId: string) => {
        return expenses.filter(expense => expense.branchId === branchId)
    }

    const getSalaryExpenseTypeId = () => {
        return define(find(TableName.EXPENSE_TYPES, SalaryExpenseTypeName, 'name')?.id)
    }

    const getEmployeeMatchFn = (employee: Employee, words: string[]) => {
        const { name, identifier, email } = employee
        
        const values = [
            normalize(name),
            identifier ? identifier.toString() : '',
            normalize(email)
        ]

        const matchCount = words.reduce((count, word) => 
            values.some(value => value.includes(word)) ? count + 1 : count
        , 0)

        return matchCount
    }

    const searchEmployees = (employees: Employee[], text = '', showAll = false): Employee[] => {
        const matchFn = (employeeDT: DataType, words: string[]) =>
            getEmployeeMatchFn(employeeDT as Employee, words)
        const sortFn = (employeeDT_A: DataType, employeeDT_B: DataType) => 
            (employeeDT_A as Employee).name.localeCompare((employeeDT_B as Employee).name)
        
        return search(employees, matchFn, sortFn, text, showAll) as Employee[]
    }

    const getCashFundMatchFn = (cashFund: CashFund, words: string[]) => {
        const { name, type } = cashFund
        
        const values = [
            normalize(name),
            normalize(cashFundTypeLabel(type))
        ]

        const matchCount = words.reduce((count, word) => 
            values.some(value => value.includes(word)) ? count + 1 : count
        , 0)

        return matchCount
    }

    const searchCashFunds = (cashFunds: CashFund[], text = '', showAll = false): CashFund[] => {
        const matchFn = (cashFundDT: DataType, words: string[]) =>
            getCashFundMatchFn(cashFundDT as CashFund, words)
        const sortFn = (cashFundDT_A: DataType, cashFundDT_B: DataType) => 
            (cashFundDT_A as CashFund).name.localeCompare((cashFundDT_B as CashFund).name)
        
        return search(cashFunds, matchFn, sortFn, text, showAll) as CashFund[]
    }

    const getExpenseMatchFn = (expense: Expense, words: string[]) => {
        const { code, name, supplierId } = expense
        const supplier = find(TableName.SUPPLIERS, supplierId) as Supplier | undefined

        const values = [
            code.toString(),
            normalize(name),
            supplier ? normalize(supplier.name) : ''
        ]

        const matchCount = words.reduce((count, word) => 
            values.some(value => value.includes(word)) ? count + 1 : count
        , 0)

        return matchCount
    }

    const searchExpenses = (expenseList: Expense[], text = '', showAll = false): Expense[] => {
        const matchFn = (expenseDT: DataType, words: string[]) =>
            getExpenseMatchFn(expenseDT as Expense, words)
        const sortFn = (expenseDT_A: DataType, expenseDT_B: DataType) => 
            (expenseDT_B as Expense).date.localeCompare((expenseDT_A as Expense).date)
        
        return search(expenseList, matchFn, sortFn, text, showAll) as Expense[]
    }

    const getPaymentTypeMatchFn = (paymentType: PaymentType, words: string[]) => {
        const { name } = paymentType
        const values = [normalize(name)]

        const matchCount = words.reduce((count, word) => 
            values.some(value => value.includes(word)) ? count + 1 : count
        , 0)

        return matchCount
    }

    const searchPaymentTypes = (paymentTypes: PaymentType[], text = '', showAll = false): PaymentType[] => {
        const matchFn = (paymentTypeDT: DataType, words: string[]) =>
            getPaymentTypeMatchFn(paymentTypeDT as PaymentType, words)
        const sortFn = (paymentTypeDT_A: DataType, paymentTypeDT_B: DataType) => 
            (paymentTypeDT_A as PaymentType).name.localeCompare((paymentTypeDT_B as PaymentType).name)
        
        return search(paymentTypes, matchFn, sortFn, text, showAll) as PaymentType[]
    }

    return {
        applyPaymentTypeAdjustment,
        getSalesSettings,
        getSalesSettingsData,
        getGeneralSaleYield,
        getTotalSaleTax,
        getBranchExpenses,
        getSalaryExpenseTypeId,
        searchEmployees,
        searchCashFunds,
        searchExpenses,
        searchPaymentTypes
    }
}
