import { useNavigate } from "react-router-dom"
import { useDescriptorHooks } from "../../../../../features/data-types"
import { Data, showToast } from "../../../../../features/ui"
import { useWizardActions, useWizardHooks } from "../../../../../features/wizard"
import { useFind, useFindMultipleByParams } from "../../../../../state/reducers/hooks"
import { TableName } from "../../../../../tables"
import { newId } from "../../../../../tables/utils"
import { define, isDefined } from "../../../../../utils/typeUtils"
import { DeliveredItem, OrderDelivery, useOperationsHooks } from "../../../../operations"
import { Lot, Product } from "../../../../products"
import { useSalesActions } from "../../../actions/salesActions"
import { SaleOrder } from "../../../state/types"
import { useSalesUIHooks } from "../../salesUiHooks"
import { SaleItemData } from "./types"

export const useActions = () => {
    const { getPendingQuantity } = useOperationsHooks()
    const descriptorHooks = useDescriptorHooks()
    const { getStepData } = useWizardHooks()
    const { validateStock } = useSalesUIHooks()
    const navigate = useNavigate()
    const find = useFind()
    const findMultipleByParams = useFindMultipleByParams()

    const { clearData } = useWizardActions()
    const salesActions = useSalesActions()

    const findProduct = (id: string) => define(find(TableName.PRODUCTS, id) as Product)

    const findLot = (id: string) => define(find(TableName.LOTS, id)) as Lot

    const getBranchId = (wizardKey: string, sale: SaleOrder): string => {
        const infoStepData = getStepData(wizardKey, 0)
        return (infoStepData.branchId || sale.branchId) as string
    }

    const getItemsData = (
        wizardKey: string,
        sale: SaleOrder,
        saleItemsData: SaleItemData[]
    ): Data[] => {
        const deliveries = findMultipleByParams(TableName.ORDER_DELIVERIES, { orderId: sale.id }) as OrderDelivery[]

        return saleItemsData.map(saleItemData => {
            const { stepIndex, saleItem } = saleItemData
            const itemStepData = getStepData(wizardKey, stepIndex)
            const deliveredQuantity = itemStepData.deliveredQuantity as number | undefined
            const pendingQuantity = getPendingQuantity(saleItem, deliveries)
            const delivered = (isDefined(deliveredQuantity) ? deliveredQuantity : pendingQuantity) as number

            return {
                ...saleItem,
                quantity: delivered
            }
        })
    }

    const validateDelivery = async (
        wizardKey: string,
        sale: SaleOrder,
        saleItemsData: SaleItemData[]
    ) => {
        const branchId = getBranchId(wizardKey, sale)
        const itemsData = getItemsData(wizardKey, sale, saleItemsData)
        return validateStock(itemsData, branchId)
    }

    const finishDelivery = async (
        wizardKey: string,
        sale: SaleOrder,
        saleItemsData: SaleItemData[]
    ) => {
        const itemsData = getItemsData(wizardKey, sale, saleItemsData)
        const infoStepData = getStepData(wizardKey, 0)
        const companyId = sale.companyId as string
        const date = new Date(infoStepData.date || sale.deliveryDate)
        const branchId = getBranchId(wizardKey, sale)

        const saleItems = saleItemsData.map(saleItemData => saleItemData.saleItem)
        const deliveredItems: DeliveredItem[] = itemsData.map(itemData => ({
            itemId: define(itemData.id) as string,
            delivered: define(itemData.quantity) as number
        }))
        const delivery: OrderDelivery = {
            companyId,
            id: newId(),
            orderId: sale.id,
            date: date.toISOString(),
            deliveredItems,
            branchId
        }

        await salesActions().saveSaleDelivery(sale, saleItems, delivery)

        showToast(`La entrega de la Venta ${descriptorHooks.sale.code(sale)} fue registrada.`)
        clearData(wizardKey)
        navigate(-1)
    }
    
    return {
        findProduct,
        findLot,
        getBranchId,
        validateDelivery,
        finishDelivery
    }
}
