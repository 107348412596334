import { useActions } from './actions'
import { EditablePanel_OLD } from '../editable-panel/editablePanel_OLD'
import { FieldValues, FieldProps, TableRow } from '../../types'
import { useAdministrationHooks } from '../../../modules/administration'
import { Section } from '../../section/section'
import { useSystemStateHooks } from '../../../modules/system'
import { UIPermission, TabsPanel, FieldRow, FieldType } from '../../../features/ui'
import { Table_OLD as Table } from '../../table/table'

export const AdminSettingsPanel = () => {
    const { getSalesSettingsData, getTotalSaleTax } = useAdministrationHooks()
    const { valueTaxYield, saleTaxes } = getSalesSettingsData()
    const totalSaleTax = getTotalSaleTax()
    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_ADMINISTRATION_SETTINGS)
    
    const { addSaleTax, removeSaleTax } = useActions()

    const onAddSaleTax = (values: TableRow) => addSaleTax(values as FieldValues)

    const renderSaleTaxesTable = () => {
        const saleTaxesFields = [
            { name: 'name', type: FieldType.TEXT, label: 'Nombre' },
            { name: 'yield', type: FieldType.PERCENTAGE, label: 'Porcentaje' }
        ]

        return (
            <Section id='saleTaxes'>
                <Table
                    key="saleTaxes"
                    fields={saleTaxesFields}
                    identifierField='name'
                    items={saleTaxes}
                    readOnly={readOnly}
                    onSaveNewRow={onAddSaleTax}
                    onRemove={removeSaleTax}
                />
            </Section>
        )
    }

    const fields: FieldProps[] = [
        {
            name: 'valueTaxYield',
            type: FieldType.PERCENTAGE,
            label: 'IVA',
            value: valueTaxYield,
            readOnly: true
        },
        {
            name: 'saleTaxes',
            editableRowRenderer: () => {
                return <FieldRow
                    key='saleTaxes'
                    label='Impuestos de Venta'
                >
                    {renderSaleTaxesTable()}
                </FieldRow>
            }
        },
        {
            name: 'totalSaleTax',
            type: FieldType.PERCENTAGE,
            label: 'Impuesto Final de Venta',
            value: totalSaleTax,
            readOnly: true
        }
    ]

    const tabs = [
        {
            id: 'taxes',
            title: 'Impuestos',
            content: <EditablePanel_OLD fields={fields} />
        },
        {
            id: 'others',
            title: 'Otras',
            content: <></>
        }
    ]

    return (
        <TabsPanel
            title="Configuración"
            tabs={tabs}
        />
    )
}
