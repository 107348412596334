import { useChildrenActions } from "../../../actions/childrenActions"
import { useDefaultModuleActions } from "../../../actions/defaultModuleActions"
import { BaseCallback, ItemCallback, ListCallback, OptionalItemCallback, SubmitChildrenFn } from "../../../actions/types"
import { Data, areDataTypesEqual, areDataTypesListsEqual } from "../../../features/data-types"
import { DynamoUserApi } from "../../../services"
import { TextParams } from "../../../state/reducers/types"
import { DataType, TableName } from "../../../tables"
import { Currency } from "../../../tables/types/types"
import { newId } from "../../../tables/utils"
import { define } from "../../../utils/typeUtils"
import { useSystemStateHooks } from "../../system"
import { Budget, BudgetItem } from "../state/types"

export const useBudgetActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId

    const defaultModuleActions = useDefaultModuleActions()
    const { submitChildren } = useChildrenActions()

    return (paramCompanyId?: string) => {
        const companyId = define(paramCompanyId || stateCompanyId)
        const { fetchAll, fetchByParams, fetchMultipleByParams, count, save, saveMultiple, remove, removeMultiple } = defaultModuleActions(DynamoUserApi, companyId)

        const fetchAllBudgets = (limit?: number, startKey?: TextParams) => {
            return fetchAll(TableName.BUDGETS, limit, startKey)
        }

        const fetchBudget = (id: string, callback?: OptionalItemCallback) => {
            fetchByParams(TableName.BUDGETS, { id }, callback)
        }

        const countBudgets = (propName: string, propValue: string) => {
            return count(TableName.BUDGETS, { [propName]: propValue })
        }

        const saveBudget = (budget: Budget, callback?: ItemCallback) => {
            save(TableName.BUDGETS, budget, callback)
        }

        const saveBudgetWithItems = (
            budget: Budget,
            budgetItemsData: Data[],
            callback?: ItemCallback
        ) => {
            const onFinish = () => callback && callback(budget)

            const budgetItems = budgetItemsData.map(budgetItemData => {
                return (budgetItemData.id ? budgetItemData : {
                    ...budgetItemData,
                    companyId: define(companyId),
                    id: newId(),
                    budgetId: budget.id,
                    currency: Currency.ARS
                }) as BudgetItem
            })

            const onSaveBudget = (stateBudgetItemsDT: DataType[]) => () => {
                submitChildren(stateBudgetItemsDT, budgetItems, saveBudgetItems as SubmitChildrenFn, removeBudgetItems as SubmitChildrenFn, onFinish)
            }

            const onFetchBudgetItems = (stateBudgetDT?: DataType) => (stateBudgetItemsDT: DataType[]) => {
                const budgetChanged = !stateBudgetDT || !areDataTypesEqual(budget, stateBudgetDT as Budget)
                const budgetItemsChanged = !areDataTypesListsEqual(budgetItems, stateBudgetItemsDT)
                
                if (budgetChanged || budgetItemsChanged) {
                    saveBudget(budget, onSaveBudget(stateBudgetItemsDT))
                } else {
                    onFinish()
                }
            }

            const onFetchBudget = (stateBudgetDT?: DataType) => {
                fetchBudgetItemsByOrder(budget.id, onFetchBudgetItems(stateBudgetDT))
            }

            fetchBudget(budget.id, onFetchBudget)
        }

        const removeBudget = (id: string, callback?: BaseCallback) => {
            const onFinish = () => callback && callback()

            const onFetchBudgetItems = (budgetItemsDT: DataType[]) => {
                removeBudgetItems(budgetItemsDT as BudgetItem[], onFinish)
            }

            const onRemoveBudget = () => {
                fetchBudgetItemsByOrder(id, onFetchBudgetItems)
            }
            
            remove(TableName.BUDGETS, id, onRemoveBudget)
        }

        const fetchBudgetItemsByOrder = (budgetId: string, callback?: ListCallback) => {
            fetchMultipleByParams(TableName.BUDGET_ITEMS, { budgetId }, callback)
        }

        const countBudgetItems = (propName: string, propValue: string) => {
            return count(TableName.BUDGET_ITEMS, { [propName]: propValue })
        }

        const saveBudgetItems = (budgetItems: BudgetItem[], callback?: ListCallback) => {
            saveMultiple(TableName.BUDGET_ITEMS, budgetItems, callback)
        }

        const removeBudgetItems = (budgetItems: BudgetItem[], callback?: BaseCallback) => {
            removeMultiple(TableName.BUDGET_ITEMS, budgetItems.map(budgetItem => budgetItem.id), callback)
        }

        return {
            fetchAllBudgets,
            fetchBudget,
            countBudgets,
            saveBudget,
            saveBudgetWithItems,
            removeBudget,
            fetchBudgetItemsByOrder,
            countBudgetItems
        }
    }
}
