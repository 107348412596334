import { DependencyList, EffectCallback, MutableRefObject, useEffect, useRef } from 'react'
import { useFindListState } from '../state/reducers/hooks'
import { DataType, TableName } from '../tables'

export const useEffectAfterMount = (effect: EffectCallback, deps?: DependencyList) => {
    const didMount = useRef(false)

    useEffect(() => {
        if (didMount.current) effect()
        else didMount.current = true
    }, deps)
}

export const useGetDefault = () => {
    const findListState = useFindListState()

    return (tableName: TableName) => {    
        const listState: DataType[] = findListState(tableName)
        return listState.length > 0 ? listState[0].id : undefined
    }
}

export const useOutsideClickHandler = (refs: MutableRefObject<any>[], onClickOutside: () => void) => {
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            const clicked = refs.filter(ref => ref.current?.contains(event.target))
            if (clicked.length === 0) {
                onClickOutside()
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, []);
}
