import { ListResponse } from "../../actions/types"
import { DynamoUserApi } from "../../services"
import { AttributeMap, useDynamoActions } from "../../services/dynamo"
import { toDynamoListCallback } from "../../services/utils"
import { DataType, TableName, TablesData } from "../../tables"
import { define } from "../../utils/typeUtils"

export const useChangedEnumValueMigration = () => {
    const dynamoActions = useDynamoActions()

    return (
        companyIds: string[],
        table: TableName,
        propName: string,
        oldEnumValue: string,
        newEnumValue: string
    ) => {
        companyIds.forEach(companyId => {
            const { getAll, createOrUpdateMultiple } = dynamoActions(DynamoUserApi, companyId)
            const { dbTableName } = define(TablesData.get(table))
            const onGetAll = (response: ListResponse) => {
                const updatedDataTypes = response.dataTypes
                    .filter(dataType => dataType[propName as keyof DataType] === oldEnumValue)
                    .map(dataType => ({
                        ...dataType,
                        [propName]: newEnumValue
                    })) as AttributeMap[]
                createOrUpdateMultiple(dbTableName, updatedDataTypes)
            }
            getAll(dbTableName, undefined, undefined, undefined, toDynamoListCallback(onGetAll))
        })
    }
}
