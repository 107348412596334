import { BaseCallback, ItemCallback } from "../../../../actions/types"
import { Data } from "../../../../features/ui"
import { DataType } from "../../../../tables"
import { define } from "../../../../utils/typeUtils"
import { Settings, SettingsModule, useAdministrationActions } from "../../../administration"
import { OrderDelivery, OrderStatus, useOperationsActions } from "../../../operations"
import { useSystemStateHooks } from "../../../system"
import { HoardOrder, HoardItem, HoardType } from "../../state/types"
import { useCommonActions } from "./commonActions"
import { useFinancialHoardActions } from "./financialHoardActions"
import { useProductHoardActions } from "./productHoardActions"

export const useHoardActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId
    const commonActions = useCommonActions()
    const productHoardActions = useProductHoardActions()
    const financialHoardActions = useFinancialHoardActions()
    const administrationActions = useAdministrationActions()
    const operationsActions = useOperationsActions()

    return (paramCompanyId?: string) => {
        const companyId = define(paramCompanyId || stateCompanyId)
        const hoardCommonActions = commonActions(companyId)
        
        const fetchAllHoards = hoardCommonActions.fetchAllHoards

        const countHoards = hoardCommonActions.countHoards

        const saveHoard = async (
            hoard: HoardOrder,
            hoardItemsData: Data[],
            callback?: ItemCallback
        ) => {
            if (hoard.type === HoardType.PRODUCT) {
                productHoardActions(companyId).saveProductHoard(hoard, hoardItemsData, callback)
            } else {
                const savedHoard = await hoardCommonActions.saveHoard(hoard)
                callback && callback(savedHoard)
            }
        }

        const removeHoard = (id: string, callback?: BaseCallback) => {
            const { fetchHoard, removeHoard, fetchHoardItemsByOrder, removeHoardItems } = commonActions(companyId)
            const { fetchDeliveries, removeDeliveries } = operationsActions()
            
            const onFinish = () => callback && callback()

            const onRemoveHoardItems = async () => {
                const deliveries = await fetchDeliveries(id) as OrderDelivery[]
                const deliveryIds = deliveries.map(delivery => delivery.id)
                removeDeliveries(deliveryIds, onFinish)
            }

            const onRemoveHoard = async () => {
                const hoardItems = await fetchHoardItemsByOrder(id) as HoardItem[]
                removeHoardItems(hoardItems.map(hoardItem => hoardItem.id), onRemoveHoardItems)
            }
            
            const onFetchHoard = (hoardDT?: DataType) => {
                const hoard = define(hoardDT) as HoardOrder
                if (hoard.status !== OrderStatus.DELIVERED) {
                    removeHoard(id, onRemoveHoard)
                } else {
                    onFinish()
                }
            }
            
            fetchHoard(id, onFetchHoard)
        }

        const fetchHoardItemsByOrder = commonActions(companyId).fetchHoardItemsByOrder

        const countHoardItems = hoardCommonActions.countHoardItems

        const saveHoardDelivery = async (
            hoard: HoardOrder,
            hoardItemsData: Data[],
            delivery: OrderDelivery
        ) => {
            if (hoard.type === HoardType.PRODUCT) {
                return productHoardActions(companyId).saveProductHoardDelivery(hoard, hoardItemsData as HoardItem[], delivery)
            } else {
                return financialHoardActions(companyId).saveFinancialHoardDelivery(hoard, hoardItemsData, delivery)
            }
        }

        const saveHoardIgnoredProducts = (productIds: string[], callback?: ItemCallback) => {
            const { fetchSettings, saveSettings } = administrationActions()

            const onFetchSalesSettings = (settingsDT?: DataType) => {
                const settings = define(settingsDT) as Settings
                const updatedSettings: Settings = {
                    ...settings,
                    data: {
                        ...settings.data,
                        hoardIgnoredProductIds: productIds
                    }                
                }
                saveSettings(updatedSettings, callback)
            }

            fetchSettings(SettingsModule.SALES, onFetchSalesSettings)
        }

        const closeHoard = async (
            hoard: HoardOrder,
            callback?: ItemCallback
        ) => {
            const closedHoard: HoardOrder = {
                ...hoard,
                status: OrderStatus.DELIVERED
            }

            const savedHoard = await hoardCommonActions.saveHoard(closedHoard) as HoardOrder
            callback && callback(savedHoard)
        }

        return {
            fetchAllHoards,
            countHoards,
            saveHoard,
            removeHoard,
            fetchHoardItemsByOrder,
            countHoardItems,
            saveHoardDelivery,
            saveHoardIgnoredProducts,
            closeHoard
        }
    }
}
