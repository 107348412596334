import { useEffect, useState } from "react"
import { Data, EditableFormField, EditablePanel, FieldType, FieldValue, ModalId, TabsPanel, UIPermission, useUIActions } from "../../../../../features/ui"
import { CategorySaleYield, useAdministrationHooks } from "../../../../administration"
import { useSystemStateHooks } from "../../../../system"
import { useActions } from "./actions"
import { define } from "../../../../../utils/typeUtils"
import { TableName } from "../../../../../tables"
import { CategorySaleYieldsTable } from "../../category-sale-yield/category-sale-yields-table/categorySaleYieldsTable"
import { CategorySaleYieldFormModal } from "../../category-sale-yield/category-sale-yield-form-modal/categorySaleYieldFormModal"
import { RemoveCategorySaleYieldModal } from "../../category-sale-yield/remove-category-sale-yield-modal/removeCategorySaleYieldModal"

export const PurchasesSettingsPanel = () => {
    const { getSalesSettingsData } = useAdministrationHooks()
    const { saleYield, categorySaleYields } = getSalesSettingsData()
    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_PURCHASES_SETTINGS)

    const [saleYieldState, setSaleYieldState] = useState<number | undefined>(saleYield)
    const [categorySaleYieldsData, setCategorySaleYieldsData] = useState<CategorySaleYield[]>(categorySaleYields)
    const [currentCategorySaleYield, setCurrentCategorySaleYield] = useState<Data>()

    const { updateSaleYield, addCategoryConfiguration, removeCategoryConfiguration } = useActions()
    const { openModal } = useUIActions()

    useEffect(() => {
        setSaleYieldState(saleYield)
    }, [saleYield])

    useEffect(() => {
        setCategorySaleYieldsData(categorySaleYields)
    }, [categorySaleYields])

    const categoryIdsData = categorySaleYieldsData.map(data => data.categoryId)
    const filterCategories = (categoryId: string) => currentCategorySaleYield?.categoryId === categoryId || !categoryIdsData.includes(categoryId)

    const onSaveSaleYield = (value?: FieldValue) => {
        const newSaleYield = value as number | undefined
        if (newSaleYield !== saleYieldState) {
            setSaleYieldState(newSaleYield)
            updateSaleYield(define(newSaleYield))
        }
    }

    const onAddCategorySaleYield = () => {
        openModal(ModalId.CATEGORY_SALE_YIELD_FORM)
        setCurrentCategorySaleYield(undefined)
    }

    const onEditCategorySaleYield = (categoryId: string) => {
        setCurrentCategorySaleYield(categorySaleYieldsData.find(data => data.categoryId === categoryId))
        openModal(ModalId.CATEGORY_SALE_YIELD_FORM)
    }

    const onRemoveCategorySaleYield = (categoryId: string) => {
        setCurrentCategorySaleYield(categorySaleYieldsData.find(data => data.categoryId === categoryId))
        openModal(ModalId.REMOVE_CATEGORY_SALE_YIELD)
    }

    const fields: EditableFormField[] = [
        {
            name: 'saleYield',
            type: FieldType.PERCENTAGE,
            label: 'Rentabilidad General',
            defaultValue: saleYieldState,
            onSave: onSaveSaleYield
        },
        {
            name: 'categorySaleYields',
            type: FieldType.TABLE,
            table: TableName.CATEGORIES,
            label: 'Rentabilidad por Categoría',
            render: () => (
                <CategorySaleYieldsTable
                    rows={categorySaleYieldsData}
                    readOnly={readOnly}
                    onAdd={onAddCategorySaleYield}
                    onEditRow={onEditCategorySaleYield}
                    onRemoveRow={onRemoveCategorySaleYield}
                />
            )
        }
    ]

    const tabs = [
        {
            id: 'hoards',
            title: 'Rentabilidad',
            content: <EditablePanel
                fields={fields}
                readOnly={readOnly}
            />
        },
        {
            id: 'others',
            title: 'Otras',
            content: <></>
        }
    ]

    const onRemoveCategoryConfiguration = (categoryId: string) => {
        removeCategoryConfiguration(categoryId)
        setCurrentCategorySaleYield(undefined)
    }

    return (
        <>
            <TabsPanel
                title="Configuración"
                tabs={tabs}
                wide={false}
            />
            <CategorySaleYieldFormModal
                categorySaleYield={currentCategorySaleYield}
                filterCategories={filterCategories}
                onSubmit={addCategoryConfiguration}
            />
            <RemoveCategorySaleYieldModal
                categoryId={currentCategorySaleYield?.categoryId as string | undefined}
                onSubmit={onRemoveCategoryConfiguration}
            />
        </>
    )
}
