import styles from './staticValue.module.css'
import { EmptyImgImg } from "../../../../components/constants"
import { TableName } from "../../../../tables"
import { useDataTypesHooks } from "../../../data-types"
import { FieldType, FieldValue } from "../types"
import { liveUrl } from '../../utils/uiUtils'

type Props = {
    type: FieldType
    table?: TableName
    value?: FieldValue
}

export const StaticValue = (props: Props) => {
    const { type, table, value } = props
    const { formatValue } = useDataTypesHooks()

    if (type === FieldType.IMAGE) {
        const imgSrc = value ? liveUrl(value as string) : EmptyImgImg
        return <img src={imgSrc} alt={`${table} image.`} className={styles.img} />
    }

    return <>{formatValue(value, type, table)}</>
}
