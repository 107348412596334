import { Table, Column, FieldType, FieldValue } from "../../../../../features/ui"
import { Section } from "../../../../../components/section/section"
import { BudgetPriceItem } from "../../../hooks/types"
import { define } from "../../../../../utils/typeUtils"
import { useFind } from "../../../../../state/reducers/hooks"
import { TableName } from "../../../../../tables"
import { PaymentType, PaymentTypeName } from "../../../../administration"

type Props = {
    priceItems: BudgetPriceItem[]
    collapsable?: boolean
}

export const BudgetPriceList = (props: Props) => {
    const { priceItems, collapsable } = props
    
    const find = useFind()

    const pricesColumns: Column[] = [
        {
            name: 'paymentTypeId',
            type: FieldType.TEXT,
            label: 'Medio de Pago',
            render: (value?: FieldValue) => {
                const paymentType = define(find(TableName.PAYMENT_TYPES, value as string)) as PaymentType
                return <PaymentTypeName paymentType={paymentType} />
            }
        },
        {
            name: 'price',
            type: FieldType.PRICE,
            label: 'Precio',
        }
    ]

    const rows = priceItems.map(priceItem => ({
        paymentTypeId: priceItem.paymentType.id,
        price: priceItem.price
    }))
    

    return (
        <Section id="priceList" collapsable={collapsable}>
            <Table
                columns={pricesColumns}
                rows={rows}
                rowId="paymentTypeId"
                readOnly
            />
        </Section>
    )
}
