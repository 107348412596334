export enum ModalId {
    BUDGET_PDF = 'budget-pdf-modal',
    CATEGORY_SALE_YIELD_FORM = 'category-sale-yield-form-modal',
    CLOSE_OPERATION = 'close-operation-modal',
    EXPENSE_RECEIPT = 'expense-receipt',
    FINISH_WIZARD = 'finish-wizard-modal',
    LOT_DATA_FORM = 'lot-data-form-modal',
    MEASURE_FORM = 'measure-form-modal',
    MESSAGE = 'message-modal',
    NON_HOARDABLE_PRODUCT_FORM = 'non-hoardable-product-form-modal',
    OPEN_WIZARD = 'open-wizard-modal',
    OPERATION_ITEM_FORM = 'operation-item-form-modal',
    QUICK_FORM = 'quick-form-modal',
    REMOVE = 'remove-modal',
    REMOVE_CATEGORY_SALE_YIELD = 'remove-category-sale-yield-modal',
    RESOLVE_REQUEST = 'resolve-request-modal',
    SPINNER = 'spinner-modal',
    UNIGNORE_PRODUCT = 'unignore-product-modal',
    VALIDATION = 'validation-modal'
}
