import { define } from '../utils/typeUtils'
import { DataType, TableName, TablesData } from '../tables'
import { BaseCallback, ListCallback, ListResponse } from './types'
import { AttributeMap, useDynamoActions, ListCallback as DynamoListCallback, DynamoApi } from '../services/dynamo'
import { RangeParams, TextParams } from '../state/reducers/types'
import { toListResponse } from '../services/utils'

export const useDefaultDbActions = () => {
    const dynamoActions = useDynamoActions()

    const getDbTableName = (tableName: TableName) => define(TablesData.get(tableName)).dbTableName

    return (dynamoApi: DynamoApi, companyId?: string) => {
        const getAll = (
            tableName: TableName,
            limit?: number,
            startKey?: TextParams,
            propName?: string,
        ) => {
            const { getAll_async } = dynamoActions(dynamoApi, companyId)
            return getAll_async(getDbTableName(tableName), limit, startKey, propName)
                .then(toListResponse)
        }

        const getByParams = (
            tableName: TableName,
            paramsFilter: TextParams,
            callback?: ListCallback
        ) => {
            const { getByParams } = dynamoActions(dynamoApi, companyId)
            getByParams(getDbTableName(tableName), paramsFilter, callback as DynamoListCallback)
        }

        const getByParams_async = (
            tableName: TableName,
            paramsFilter: TextParams
        ): Promise<DataType[]> => {
            const { getByParams_async } = dynamoActions(dynamoApi, companyId)            
            return getByParams_async(getDbTableName(tableName), paramsFilter)
                .then(response => response as DataType[])
        }

        const getByIds = (
            tableName: TableName,
            ids: string[],
            callback?: ListCallback
        ) => {
            const { getMultiple } = dynamoActions(dynamoApi, companyId)
            getMultiple(getDbTableName(tableName), ids, callback as DynamoListCallback)
        }

        const getByIds_async = (
            tableName: TableName,
            ids: string[]
        ): Promise<DataType[]> => {
            const { getMultiple_async } = dynamoActions(dynamoApi, companyId)
            return getMultiple_async(getDbTableName(tableName), ids)
                .then(response => response as DataType[])
        }

        const getFiltered = (
            tableName: TableName,
            propFilters?: TextParams,
            rangeFilters?: RangeParams
        ): Promise<ListResponse> => {
            const { getFiltered } = dynamoActions(dynamoApi, companyId)            
            return getFiltered(getDbTableName(tableName), propFilters, rangeFilters)
                .then(response => toListResponse(response))
        }

        const count = (
            tableName: TableName,
            paramsFilter: TextParams
        ): Promise<number> => {
            const { count } = dynamoActions(dynamoApi, companyId)            
            return count(getDbTableName(tableName), paramsFilter)
        }

        const save = (
            tableName: TableName,
            dataTypes: DataType[],
            callback?: BaseCallback
        ) => {
            const { createOrUpdateMultiple } = dynamoActions(dynamoApi, companyId)
            const onCreateOrUpdateMultiple = (_: AttributeMap[]) => callback && callback()
            createOrUpdateMultiple(getDbTableName(tableName), dataTypes as AttributeMap[], onCreateOrUpdateMultiple)
        }

        const save_async = (
            tableName: TableName,
            dataTypes: DataType[]
        ): Promise<DataType[]> => {
            const { createOrUpdateMultiple_async } = dynamoActions(dynamoApi, companyId)            
            return createOrUpdateMultiple_async(getDbTableName(tableName), dataTypes as AttributeMap[])
                .then(unprocessedItems => unprocessedItems as DataType[])
        }

        const remove = (
            tableName: TableName,
            ids: string[],
            callback: BaseCallback
        ) => {
            const { removeMultiple } = dynamoActions(dynamoApi, companyId)
            const onRemoveMultiple = (_: AttributeMap[]) => callback && callback()
            removeMultiple(getDbTableName(tableName), ids, onRemoveMultiple)
        }

        const remove_async = (
            tableName: TableName,
            ids: string[]
        ): Promise<void> => {
            const { removeMultiple_async } = dynamoActions(dynamoApi, companyId)
            return removeMultiple_async(getDbTableName(tableName), ids)
                .then(_ => {})
        }

        return {
            getAll,
            getByParams,
            getByParams_async,
            getByIds,
            getByIds_async,
            getFiltered,
            count,
            save,
            save_async,
            remove,
            remove_async
        }
    }
}
