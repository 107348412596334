import { fileName, useFileActions } from '../../../../features/files'
import { FileTile, FileType, Modal, ModalId } from '../../../../features/ui'
import { TableName } from '../../../../tables'
import { useAdministrationHooks } from '../../hooks/administrationHooks'
import { Expense } from '../../state/types'

type Props = {
    expense?: Expense
}

export const ExpenseReceiptModal = (props: Props) => {
    const { expense } = props

    const { getSalaryExpenseTypeId } = useAdministrationHooks()
    
    const isSalaryType = expense?.expenseTypeId === getSalaryExpenseTypeId()
    const expenseFileName = expense?.receiptUrl ? fileName(TableName.EXPENSES, expense, isSalaryType ? 'Sueldo_Liquidado' : undefined) : undefined

    const { downloadFile } = useFileActions()

    const onSubmit = () => {
        if (expense?.receiptUrl && expenseFileName) {
            downloadFile(expense.receiptUrl, expenseFileName)
        }
    }
    
    return (
        <Modal
            modalId={ModalId.EXPENSE_RECEIPT}
            title="Descargar Comprobante"
            submitLabel="Descargar"
            submitIcon="download"
            onSubmit={onSubmit}
        >
            <FileTile fileType={FileType.PDF} fileName={expenseFileName}/>
        </Modal>
    )
}
