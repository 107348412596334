import { DataType, TableName } from '../tables'
import { BaseCallback, ItemCallback, ListCallback, ListResponse, OptionalItemCallback } from './types'
import { RangeParams, TextParams } from '../state/reducers/types'
import { useDefaultDbActions } from './defaultDbActions'
import { useFindByParams, useFindByIds, useFindReducerRemover, useFindReducerUpdater } from '../state/reducers/hooks'
import { DynamoApi } from '../services/dynamo'
import { firstItem } from '../utils/typeUtils'

export const useDefaultModuleActions = () => {
    const defaultDbActions = useDefaultDbActions()
    const findReducerUpdater = useFindReducerUpdater()
    const findReducerRemover = useFindReducerRemover()
    const findByParams = useFindByParams()
    const findByIds = useFindByIds()

    const getUpdater = (tableName: TableName) => findReducerUpdater(tableName) as any
    const getRemover = (tableName: TableName) => findReducerRemover(tableName) as any

    return (dynamoApi: DynamoApi, companyId?: string) => {
        const fetchAll = async (
            tableName: TableName,
            limit?: number,
            startKey?: TextParams
        ) => {
            return defaultDbActions(dynamoApi, companyId).getAll(tableName, limit, startKey, undefined)
                .then(response => {
                    response.dataTypes.length > 0 && getUpdater(tableName)(response.dataTypes)
                    return response
                })
        }

        const fetchByParams = (
            tableName: TableName,
            paramsFilter: TextParams,
            callback?: OptionalItemCallback
        ) => {
            const item = findByParams(tableName, paramsFilter)
            if (item) {
                callback && callback(item)
            } else {
                const onGetByParams = (dataTypes: DataType[]) => {
                    const hasItem = dataTypes.length > 0
                    hasItem && getUpdater(tableName)(dataTypes)
                    callback && callback(hasItem ? dataTypes[0] : undefined)
                }
                defaultDbActions(dynamoApi, companyId).getByParams(tableName, paramsFilter, onGetByParams)
            }
        }

        const fetchByParams_async = async (
            tableName: TableName,
            paramsFilter: TextParams
        ): Promise<DataType | null> => {
            const item = findByParams(tableName, paramsFilter)
            if (item) {
                return new Promise(resolve => resolve(item))
            } else {
                return defaultDbActions(dynamoApi, companyId).getByParams_async(tableName, paramsFilter)
                    .then(dataTypes => {
                        const item = firstItem(dataTypes)
                        item && getUpdater(tableName)(dataTypes)
                        return item || null
                    })
            }
        }

        const fetchMultipleByParams = (
            tableName: TableName,
            paramsFilter: TextParams,
            callback?: ListCallback
        ) => {
            const onGetByParams = (dataTypes: DataType[]) => {
                dataTypes.length > 0 && getUpdater(tableName)(dataTypes)
                callback && callback(dataTypes)
            }
            defaultDbActions(dynamoApi, companyId).getByParams(tableName, paramsFilter, onGetByParams)
        }

        const fetchMultipleByParams_async = (
            tableName: TableName,
            paramsFilter: TextParams
        ): Promise<DataType[]> => {
            return defaultDbActions(dynamoApi, companyId).getByParams_async(tableName, paramsFilter)
                .then(dataTypes => {
                    dataTypes.length > 0 && getUpdater(tableName)(dataTypes)
                    return dataTypes
                })
        }

        const fetchByIds = (
            tableName: TableName,
            ids: string[],
            callback?: ListCallback
        ) => {
            const list = findByIds(tableName, ids)
            if (list.length === ids.length) {
                callback && callback(list)
            } else {
                const onGetByIds = (dataTypes: DataType[]) => {
                    dataTypes.length > 0 && getUpdater(tableName)(dataTypes)
                    callback && callback(dataTypes)
                }
                defaultDbActions(dynamoApi, companyId).getByIds(tableName, ids, onGetByIds)
            }
        }

        const fetchFiltered = async (
            tableName: TableName,
            propFilters?: TextParams,
            rangeFilters?: RangeParams
        ): Promise<ListResponse> => {
            return defaultDbActions(dynamoApi, companyId).getFiltered(tableName, propFilters, rangeFilters)
                .then(response => {
                    response.dataTypes.length > 0 && getUpdater(tableName)(response.dataTypes)
                    return response
                })
        }

        const count = async (
            tableName: TableName,
            paramsFilter: TextParams
        ): Promise<number> => {
            return defaultDbActions(dynamoApi, companyId).count(tableName, paramsFilter)
        }
        
        const save = (
            tableName: TableName,
            dataType: DataType,
            callback?: ItemCallback
        ) => {
            const dataTypes = [dataType]
            const onSave = () => {
                getUpdater(tableName)(dataTypes)
                callback && callback(dataType)
            }
            defaultDbActions(dynamoApi, companyId).save(tableName, dataTypes, onSave)
        }

        const save_async = async (
            tableName: TableName,
            dataType: DataType
        ): Promise<DataType> => {
            const dataTypes = [dataType]
            return defaultDbActions(dynamoApi, companyId).save_async(tableName, dataTypes)
                .then(_ => {
                    getUpdater(tableName)(dataTypes)
                    return dataType
                })
        }

        const saveMultiple = (
            tableName: TableName,
            dataTypes: DataType[],
            callback?: ListCallback
        ) => {
            if (dataTypes.length > 0) {
                const onSave = () => {
                    getUpdater(tableName)(dataTypes)
                    callback && callback(dataTypes)
                }
                defaultDbActions(dynamoApi, companyId).save(tableName, dataTypes, onSave)
            } else {
                callback && callback([])
            }
        }
        
        const saveMultiple_async = async (
            tableName: TableName,
            dataTypes: DataType[]
        ): Promise<DataType[]> => {
            if (dataTypes.length > 0) {
                return defaultDbActions(dynamoApi, companyId).save_async(tableName, dataTypes)
                    .then(_ => {
                        getUpdater(tableName)(dataTypes)
                        return dataTypes
                    })
            } else {
                return new Promise(resolve => resolve([]))
            }
        }

        const remove = (
            tableName: TableName,
            id: string,
            callback?: BaseCallback
        ) => {
            const ids = [id]
            const onRemove = () => {
                getRemover(tableName)(ids)
                callback && callback()
            }
            defaultDbActions(dynamoApi, companyId).remove(tableName, ids, onRemove)
        }

        const remove_async = async (
            tableName: TableName,
            id: string
        ): Promise<void> => {
            const ids = [id]
            return defaultDbActions(dynamoApi, companyId).remove_async(tableName, ids)
                .then(() => getRemover(tableName)(ids))
        }

        const removeMultiple = (
            tableName: TableName,
            ids: string[],
            callback?: BaseCallback
        ) => {
            if (ids.length > 0) {
                const onRemove = () => {
                    getRemover(tableName)(ids)
                    callback && callback()
                }
                defaultDbActions(dynamoApi, companyId).remove(tableName, ids, onRemove)
            } else {
                callback && callback()
            }
        }

        const removeMultiple_async = async (
            tableName: TableName,
            ids: string[]
        ): Promise<void> => {
            if (ids.length > 0) {
                return defaultDbActions(dynamoApi, companyId).remove_async(tableName, ids)
                    .then(_ => getRemover(tableName)(ids))    
            } else {
                return new Promise(resolve => resolve())
            }
        }

        return {
            fetchAll,
            fetchByParams,
            fetchByParams_async,
            fetchMultipleByParams,
            fetchMultipleByParams_async,
            fetchByIds,
            fetchFiltered,
            count,
            save,
            save_async,
            saveMultiple,
            saveMultiple_async,
            remove,
            remove_async,
            removeMultiple,
            removeMultiple_async
        }
    }
}
