import { TableName } from "../../../tables"
import { RoutePath } from "../types"

export const getRouteTableNames = () => new Map<string, TableName>()
    .set(RoutePath.BRANCHES, TableName.BRANCHES)
    .set(RoutePath.BUDGETS, TableName.BUDGETS)
    .set(RoutePath.CASH_FUNDS, TableName.CASH_FUNDS)
    .set(RoutePath.CATEGORIES, TableName.CATEGORIES)
    .set(RoutePath.CUSTOMERS, TableName.CUSTOMERS)
    .set(RoutePath.CUSTOMER_TYPES, TableName.CUSTOMER_TYPES)
    .set(RoutePath.EMPLOYEES, TableName.EMPLOYEES)
    .set(RoutePath.EXPENSES, TableName.EXPENSES)
    .set(RoutePath.EXPENSE_TYPES, TableName.EXPENSE_TYPES)
    .set(RoutePath.EXPENSE_SUPPLIERS, TableName.SUPPLIERS)
    .set(RoutePath.HOARDS, TableName.HOARD_ORDERS)
    .set(RoutePath.PAYMENT_TYPES, TableName.PAYMENT_TYPES)
    .set(RoutePath.PRODUCTS, TableName.PRODUCTS)
    .set(RoutePath.PURCHASES, TableName.PURCHASE_ORDERS)
    .set(RoutePath.SALARIES, TableName.EXPENSES)
    .set(RoutePath.SALES, TableName.SALE_ORDERS)
    .set(RoutePath.STOCK, TableName.STOCK)
    .set(RoutePath.SUPPLIERS, TableName.SUPPLIERS)
    .set(RoutePath.TRANSACTIONS, TableName.TRANSACTIONS)

export const getRouteFromTableName = (tableName: TableName) => {
    const pair = Array.from(getRouteTableNames()).find(([, value]) => value === tableName)
    return pair ? pair[0] as RoutePath : undefined
}
