import { define } from "../../../utils/typeUtils"
import { Area } from "../state/types"

export const areaLabel = (area: Area) => {
    const labels = new Map<Area, string>()
        .set(Area.MANAGMENT, 'Dirección')    
        .set(Area.ADMINISTRATION, 'Administración')
        .set(Area.PURCHASES, 'Compras')
        .set(Area.SALES, 'Ventas')
        .set(Area.WAREHOUSE, 'Depósito')
        .set(Area.HR, 'Recursos Humanos')
    return define(labels.get(area))
}
