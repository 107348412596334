import styles from './priceDetail.module.css'
import { useDataTypesHooks } from "../../../../features/data-types"
import { FieldType } from '../../../../features/ui'
import { define, isDefined } from '../../../../utils/typeUtils'

type Props = {
    subtotal: number
    customerAdjustment?: number
    paymentTypeAdjustment?: number
    total: number
}

export const PriceDetail = (props: Props) => {
    const { subtotal, customerAdjustment, paymentTypeAdjustment, total } = props
    const priceDiscount = total - subtotal
    const { formatValue } = useDataTypesHooks()

    const paymentTypeDiscount = isDefined(customerAdjustment) ? priceDiscount - define(customerAdjustment) : paymentTypeAdjustment
    
    return (
        <div className={styles.container}>
            {priceDiscount !== 0 && (<>
                <div>Subtotal: {formatValue(subtotal, FieldType.PRICE)}</div>
                {customerAdjustment ? (
                    <div>Descuento Cliente: {formatValue(customerAdjustment, FieldType.PRICE)}</div>
                ) : ''}
                {paymentTypeAdjustment ? (
                    <div>Descuento Medio de Pago: {formatValue(paymentTypeDiscount, FieldType.PRICE)}</div>
                ) : ''}
            </>)}
            <div className={styles.totalPrice}>
                Total: {formatValue(total, FieldType.PRICE)}
            </div>
        </div>
    )
}
