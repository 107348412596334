
import { 
  IAMClient,
  CreateUserCommand,
  CreatePolicyCommand,
  CreatePolicyVersionCommand,
  GetUserCommand,
  AttachGroupPolicyCommand,
  CreateGroupCommand,
  AddUserToGroupCommand,
  DeleteUserCommand,
  RemoveUserFromGroupCommand,
  ListGroupsForUserCommand,
  CreateRoleCommand,
  AttachRolePolicyCommand,
  DeletePolicyVersionCommand,
  ListPolicyVersionsCommand,
  PolicyVersion
} from "@aws-sdk/client-iam"
import { STSClient, AssumeRoleCommand } from "@aws-sdk/client-sts"
import { ErrorType } from "../../actions/types"
import { policyArn, roleArn, sessionName, tableAction, tableArn, userArn } from "../../utils/policiesUtils"
import { PolicyAction, PolicyType, StatementType } from "./types"
import { awsRegion } from "../constants"

export class IAMApi {
  readonly iamClient: IAMClient
  readonly stsClient: STSClient

  constructor(
    readonly accessKeyId: string,
    readonly secretAccessKey: string,
    readonly sessionToken?: string
  ) {
    const config = {
        region: awsRegion,
        credentials: {
          accessKeyId,
          secretAccessKey,
          sessionToken
        },
        protocol: 'https'
    }
    this.iamClient = new IAMClient(config)
    this.stsClient = new STSClient(config)
  }

  private iamError(error: Error) {
    return new Error(`IAM Error: ${(error as ErrorType).message}`)
  }

  async fetchUser(userName: string) {
    const params = { UserName: userName } 
    
    try {
      const data = await this.iamClient.send(new GetUserCommand(params))
      return data.User
    } catch (error) {
      throw this.iamError(error as Error)
    }
  }

  async createUser(userName: string) {
    const params = { UserName: userName } 

    try {
      await this.iamClient.send(new CreateUserCommand(params))
    } catch (error) {
      throw this.iamError(error as Error)
    }
  }

  async createGroup(groupName: string, policies: string[]) {
    const createParams = { GroupName: groupName }
    
    try {
      await this.iamClient.send(new CreateGroupCommand(createParams))
      
      policies.forEach(async (policyName) => {
        const attachParams = {
          GroupName: groupName,
          PolicyArn: policyArn(policyName)
        }

        await this.iamClient.send(new AttachGroupPolicyCommand(attachParams))
      })
      
    } catch (error) {
      throw this.iamError(error as Error)
    }
  }

  async createRole(roleName: string, trustedUserName: string) {
    const params = {
      RoleName: roleName,
      AssumeRolePolicyDocument: JSON.stringify({
        "Version": "2012-10-17",
        "Statement": [
            {
                "Effect": "Allow",
                "Principal": {
                    "Service": "dax.amazonaws.com",
                    "AWS": userArn(trustedUserName)
                },
                "Action": "sts:AssumeRole"
            }
        ]
      })
    }

    try {
      await this.iamClient.send(new CreateRoleCommand(params))
    } catch (error) {
      throw this.iamError(error as Error)
    }
  }

  async addPolicyToRole(roleName: string, policyName: string) {
    const params = {
      RoleName: roleName,
      PolicyArn: policyArn(policyName)
    }

    try {
      await this.iamClient.send(new AttachRolePolicyCommand(params))
    } catch (error) {
      throw this.iamError(error as Error)
    }
  }

  async assumeRole(roleName: string, userName: string) {
    const params = {
      RoleArn: roleArn(roleName),
      RoleSessionName: sessionName(userName)
    }

    try {
      return await this.stsClient.send(new AssumeRoleCommand(params))
    } catch (error) {
      throw this.iamError(error as Error)
    }
  }

  async createOrUpdatePolicy(policyName: string, permissions: { tables: string[], actions: PolicyAction[] }[], tenantId: string, create = false) {
    const condition = {
      "ForAllValues:StringEquals": {
        "dynamodb:LeadingKeys": tenantId
      }
    }
    const statement: StatementType[] = []
    permissions.forEach(({ tables, actions }) => {
      statement.push({
        Effect: "Allow",
        Action: actions.map(tableAction),
        Resource: tables.map(tableArn),
        Condition: condition
      })
    })

    const policy = {
      Version: "2012-10-17",
      Statement: statement
    }

    return await this.createOrUpdatePolicyFromJson(policyName, policy, create)
  }

  async getPolicyVersions(policyName: string): Promise<PolicyVersion[] | undefined> {
    try {
      const params = {
        PolicyArn: policyArn(policyName)
      }
      const data = await this.iamClient.send(new ListPolicyVersionsCommand(params))
      return data.Versions
    } catch (error) {
      throw this.iamError(error as Error)
    }
  }

  async createOrUpdatePolicyFromJson(policyName: string, policy: PolicyType, create = false) {
    try {
      if (create) {
        const params = {
          PolicyName: policyName,
          PolicyDocument: JSON.stringify(policy),
        }
        await this.iamClient.send(new CreatePolicyCommand(params))
      } else {
        const params = {
          PolicyArn: policyArn(policyName),
          PolicyDocument: JSON.stringify(policy),
          SetAsDefault: true
        }
        await this.iamClient.send(new CreatePolicyVersionCommand(params))
      }
    } catch (error) {
      throw this.iamError(error as Error)
    }
  }

  async addUserToGroup(userName: string, groupName: string) {
    const params = {
      UserName: userName,
      GroupName: groupName
    } 
    
    try {
      await this.iamClient.send(new AddUserToGroupCommand(params))
    } catch (error) {
      throw this.iamError(error as Error)
    }
  }

  async removeUser(userName: string) {
    const params = { UserName: userName } 

    try {
      const { Groups } = await this.iamClient.send(new ListGroupsForUserCommand(params))
      Groups?.forEach(async group => {
        if (group.GroupName) {
          await this.removeUserFromGroup(userName, group.GroupName)
        }
      })
      await this.iamClient.send(new DeleteUserCommand(params))
    } catch (error) {
      throw this.iamError(error as Error)
    }
  }

  async removeUserFromGroup(userName: string, groupName: string) {
    const params = {
      UserName: userName,
      GroupName: groupName
    } 
    
    try {
      await this.iamClient.send(new RemoveUserFromGroupCommand(params))
    } catch (error) {
      throw this.iamError(error as Error)
    }
  }

  async removePolicyVersion(policyName: string, versionId: string) {
    try {
      const params = {
        PolicyArn: policyArn(policyName),
        VersionId: versionId
      }
      await this.iamClient.send(new DeletePolicyVersionCommand(params))
    } catch (error) {
      throw this.iamError(error as Error)
    }
  }
}
