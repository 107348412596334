import { useDefaultModuleActions } from "../../../actions/defaultModuleActions"
import { DynamoUserApi } from "../../../services"
import { TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { useSystemStateHooks } from "../../system"
import { ProductPrices } from "../state/types"

export const useProductPricesActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId
    const defaultModuleActions = useDefaultModuleActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const { fetchAll, fetchByParams_async, saveMultiple_async, remove_async } = defaultModuleActions(DynamoUserApi, define(companyId))

        const fetchAllProductPrices = async () => {
            return fetchAll(TableName.PRODUCT_PRICES)
        }

        const fetchProductPrices = (productId: string) => {
            return fetchByParams_async(TableName.PRODUCT_PRICES, { productId })
        }

        const saveProductPrices = (productPricesList: ProductPrices[]) => {
            return saveMultiple_async(TableName.PRODUCT_PRICES, productPricesList)
        }

        const removeProductPrices = (id: string) => {
            return remove_async(TableName.PRODUCT_PRICES, id)
        }

        return {
            fetchAllProductPrices,
            fetchProductPrices,
            saveProductPrices,
            removeProductPrices
        }
    }
}
