import { TableName } from '../../../tables'
import { usePurchasesActions } from '../../../modules/purchases'
import { useSalesActions } from '../../../modules/sales'
import { useManagerActions } from '../../../modules/manager'
import { useAdministrationActions, useAdministrationHooks } from '../../../modules/administration'
import { BaseCallback } from '../../../actions/types'
import { useProductsActions } from '../../../modules/products'

type RemoveAction = (id: string, callback?: BaseCallback) => void
type RemoveValidationFn = (id: string) => void

export const useDataTypeActions = () => {
    const { getSalesSettingsData } = useAdministrationHooks()

    const administrationActions = useAdministrationActions()
    const managerActions = useManagerActions()
    const purchasesActions = usePurchasesActions()
    const salesActions = useSalesActions()
    const productsActions = useProductsActions()

    const getRemoveAction = (
        tableName: TableName
    ): RemoveAction | undefined => {
        const removeCategory = (id: string, callback?: BaseCallback) => {
            purchasesActions().removeCategory(id).then(callback)
        }
        const removeCustomerType = (id: string, callback?: BaseCallback) => {
            salesActions().removeCustomerType(id).then(callback)
        }
        const removeExpense = (id: string, callback?: BaseCallback) => {
            administrationActions().removeExpense(id).then(callback)
        }
        const removeExpenseType = (id: string, callback?: BaseCallback) => {
            administrationActions().removeExpenseType(id).then(callback)
        }
        const removeProduct = (id: string, callback?: BaseCallback) => {
            purchasesActions().removeProduct(id).then(callback)
        }
        const removePaymentType = (id: string, callback?: BaseCallback) => {
            administrationActions().removePaymentType(id).then(callback)
        }

        const removeActions = new Map<TableName, RemoveAction>()
            .set(TableName.BRANCHES, managerActions().removeBranch)
            .set(TableName.BUDGETS, salesActions().removeBudget)
            .set(TableName.CASH_FUNDS, administrationActions().removeCashFund)
            .set(TableName.CATEGORIES, removeCategory)
            .set(TableName.CUSTOMER_TYPES, removeCustomerType)
            .set(TableName.CUSTOMERS, salesActions().removeCustomer)
            .set(TableName.EMPLOYEES, administrationActions().removeEmployee)
            .set(TableName.EXPENSE_TYPES, removeExpenseType)
            .set(TableName.EXPENSES, removeExpense)
            .set(TableName.PAYMENT_TYPES, removePaymentType)
            .set(TableName.PRODUCTS, removeProduct)
            .set(TableName.SUPPLIERS, purchasesActions().removeSupplier)
        
        return removeActions.get(tableName)
    }

    const getRemoveValidation = (
        tableName: TableName
    ): RemoveValidationFn | undefined => {
        if (tableName === TableName.CATEGORIES) {
            return async (categoryId: string) => {
                const { categorySaleYields } = getSalesSettingsData()
                return !categorySaleYields.some(categorySaleYield => categorySaleYield.categoryId === categoryId) &&
                    await productsActions().countProducts('categoryId', categoryId) === 0
            }
        } else if (tableName === TableName.CASH_FUNDS) {
            return async (cashFundId: string) =>
                await administrationActions().countTransactions('cashFundId', cashFundId) === 0
        } else if (tableName === TableName.CUSTOMER_TYPES) {
            return async (customerTypeId: string) =>
                await salesActions().countCustomers('customerTypeId', customerTypeId) === 0
        } else if (tableName === TableName.CUSTOMERS) {
            return async (customerId: string) => {
                const { countBudgets, countSales, countHoards } = salesActions()
                return await countBudgets('customerId', customerId) === 0 &&
                    await countSales('customerId', customerId) === 0 &&
                    await countHoards('customerId', customerId) === 0
            }
        } else if (tableName === TableName.EMPLOYEES) {
            return async (employeeId: string) => {
                const { countBudgets, countSales, countHoards } = salesActions()
                return await administrationActions().countExpenses('employeeId', employeeId) === 0 &&
                    await countBudgets('sellerId', employeeId) === 0 &&
                    await countSales('sellerId', employeeId) === 0 &&
                    await countHoards('sellerId', employeeId) === 0
            }
        } else if (tableName === TableName.EXPENSE_TYPES) {
            return async (expenseTypeId: string) =>
                await administrationActions().countExpenses('expenseTypeId', expenseTypeId) === 0
        } else if (tableName === TableName.PAYMENT_TYPES) {
            return async (paymentTypeId: string) => {
                const { countSales, countHoards } = salesActions()
                return await countSales('paymentTypeId', paymentTypeId) === 0 &&
                    await countHoards('paymentTypeId', paymentTypeId) === 0
            }
        } else if (tableName === TableName.PRODUCTS) {
            return async (productId: string) => {
                const { hoardIgnoredProductIds } = getSalesSettingsData()
                const { countBudgetItems, countSaleItems, countHoardItems } = salesActions()
                return !hoardIgnoredProductIds.some(hoardIgnoredProductId => hoardIgnoredProductId === productId) &&
                    await purchasesActions().countPurchaseItems('productId', productId) === 0 &&
                    await countBudgetItems('productId', productId) === 0 &&
                    await countSaleItems('productId', productId) === 0 &&
                    await countHoardItems('productId', productId) === 0
            }
        } else if (tableName === TableName.SUPPLIERS) {
            return async (supplierId: string) =>
                await administrationActions().countExpenses('supplierId', supplierId) === 0 &&
                await productsActions().countProducts('supplierId', supplierId) === 0 &&
                await purchasesActions().countPurchases('supplierId', supplierId) === 0
        }
    }

    return {
        getRemoveAction,
        getRemoveValidation
    }
}
