import { showToast } from "../../../features/ui"
import { FieldValues } from "../../types"
import { useAdministrationActions, useAdministrationHooks } from "../../../modules/administration"

export const useActions = () => {
    const { getSalesSettingsData } = useAdministrationHooks()
    
    const administrationActions = useAdministrationActions()
    
    const onUpdateSettings = () => showToast('La configuración fue actualizada.')

    const addSaleTax = (saleTax: FieldValues) => {
        const { saleTaxes } = getSalesSettingsData()
        const newSaleTax = {
            name: saleTax.name as string,
            yield: saleTax.yield as number
        }
        const newSaleTaxes = saleTaxes.concat(newSaleTax)
        administrationActions().saveSaleTaxes(newSaleTaxes, onUpdateSettings)
    }

    const removeSaleTax = (name: string) => {
        const { saleTaxes } = getSalesSettingsData()
        const newSaleTaxes = saleTaxes.filter(saleTax => saleTax.name !== name)
        administrationActions().saveSaleTaxes(newSaleTaxes, onUpdateSettings)
    }

    return {
        addSaleTax,
        removeSaleTax,
    }
}
