import { capitalize, define } from '../../../../utils/typeUtils'
import { Button } from '../../../../components/button/button'
import styles from './accessRequestPanel.module.css'
import { RequestStatus, UserRole, useSystemState } from '../..'
import { useActions } from './actions'

export const AccessRequestPanel = () => {
    const { company } = useSystemState()
    const definedCompany = define(company)
    const { userRequest } = useSystemState()
    const { requestAccess } = useActions()
    const isNotApproved = userRequest && userRequest.status !== RequestStatus.APPROVED
    
    const onRequestAccess = (role: UserRole) => () => requestAccess(role)

    return (
        <div className="container">
            <section className={`section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4 ${styles.section}`}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                            <div className="d-flex justify-content-center py-4">
                                <div className="logo d-flex align-items-center w-auto">
                                    <img src="/assets/img/logo.png" alt="" />
                                    <span className="d-none d-lg-block">{capitalize(definedCompany.name)}</span>
                                </div>
                            </div>
                            {isNotApproved ? 'Tu solicitud de acceso fue enviada.' : (
                                <div className="card mb-3">                        
                                    <div className="card-body">
                                        <div className="pt-4 pb-2">
                                            <p className="text-center">Solicitar acceso para obtener permisos de lectura y edición en esta compañía.</p>
                                        </div>
                                        <div className={`col-12 ${styles.btnContainer}`}>
                                            <Button
                                                label='Solicitar Usuario Manager'
                                                type='submit'
                                                kind='secondary'
                                                icon='unlock-fill'
                                                className={styles.btn}
                                                onClick={onRequestAccess(UserRole.MANAGER)}
                                            />
                                        </div>
                                        <div className={`col-12 ${styles.btnContainer}`}>
                                            <Button
                                                label='Solicitar Usuario de Administración'
                                                type='submit'
                                                icon='unlock-fill'
                                                className={styles.btn}
                                                onClick={onRequestAccess(UserRole.ADMINISTRATION)}
                                            />
                                        </div>
                                        <div className={`col-12 ${styles.btnContainer}`}>
                                            <Button
                                                label='Solicitar Usuario de Compras'
                                                type='submit'
                                                icon='unlock-fill'
                                                className={styles.btn}
                                                onClick={onRequestAccess(UserRole.PURCHASES)}
                                            />
                                        </div>
                                        <div className={`col-12 ${styles.btnContainer}`}>
                                            <Button
                                                label='Solicitar Usuario de Ventas'
                                                type='submit'
                                                icon='unlock-fill'
                                                className={styles.btn}
                                                onClick={onRequestAccess(UserRole.SALES)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
