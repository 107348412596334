import styles from './hoardDeliveryForm.module.css'
import { useEffect, useMemo, useState } from "react"
import { Data, FieldType, FormField, ModalId, useUIActions } from "../../../../../features/ui"
import { WizardStep, useWizardActions } from "../../../../../features/wizard"
import { DataType, TableName } from "../../../../../tables"
import { Operation, OperationItemsTable, usePricesHooks } from "../../../../operations"
import { HoardItem, HoardOrder } from "../../../state/types"
import { define } from "../../../../../utils/typeUtils"
import { SalePriceItem } from "../../../../operations"
import { useEffectAfterMount } from "../../../../../components/hooks"
import { useFind } from "../../../../../state/reducers/hooks"
import { Lot, LotAttribute, NO_LOT } from "../../../../products"
import { useDataTypesHooks } from "../../../../../features/data-types"
import { lotAttributeLabel } from "../../../../../tables/types/labels"
import { useSalesActions } from '../../../actions/salesActions'

type Props = {
    wizardKey: string
    stepIndex: number
    hoard: HoardOrder
    hoardItemsData: Data[]
    onCreateHoardItem: () => void
    onEditHoardItem: (productId: string, lotId?: string) => void
    onRemoveHoardItem: (productId: string, lotId?: string) => void
}

export const HoardSelectItemsStep = (props: Props) => {
    const { wizardKey, stepIndex, hoard, hoardItemsData, onCreateHoardItem, onEditHoardItem, onRemoveHoardItem } = props

    const [hoardItems, setHoardItems] = useState<HoardItem[]>([])

    const pricesHooks = usePricesHooks()
    const { formatValue } = useDataTypesHooks()
    const find = useFind()

    const salesActions = useSalesActions()
    const { toggleModal } = useUIActions()
    const { updatedData, saveData } = useWizardActions()

    useEffect(() => {
        salesActions().fetchHoardItemsByOrder(hoard.id).then((hoardItemsDT: DataType[]) => {
            setHoardItems(hoardItemsDT as HoardItem[])
        })
    }, [])

    useEffectAfterMount(() => {
        const hoardItemsDataStr = JSON.stringify(hoardItemsData)
        const newWizardData = updatedData(wizardKey, stepIndex, 'hoardItemsData', hoardItemsDataStr)
        saveData(wizardKey, newWizardData)
    }, [hoardItemsData])

    const openFormModal = () => toggleModal(ModalId.OPERATION_ITEM_FORM)

    const handleCreateHoardItem = () => {
        onCreateHoardItem()
        openFormModal()
    }

    const handleEditHoardItem = (productId: string, lotId?: string) => {
        onEditHoardItem(productId, lotId)
        openFormModal()
    }

    const handleRemoveHoardItem = (productId: string, lotId?: string) => {
        onRemoveHoardItem(productId, lotId)
    }

    const remainingCredit = useMemo(() =>
        pricesHooks.hoard.remainingCredit(define(hoard.value), (hoardItems as Data[]).concat(hoardItemsData))
    , [hoardItems, hoardItemsData])

    const renderItemDetail = (row: Data) => {
        const lot = find(TableName.LOTS, row.lotId as string | undefined) as Lot | undefined
        if (!lot) {
            return <>{NO_LOT}</>
        }
        
        const { code, attributes } = lot
        const attributesData: [string, string][] = []
        if (attributes) {
            Object.keys(attributes).map(key => {
                const attrKey = key as LotAttribute
                const value = attributes[attrKey] as string
                const isDate = attrKey === LotAttribute.DUE_DATE
                const attrValue = formatValue(isDate ? new Date(value) : value, isDate ? FieldType.DATE : FieldType.TEXT)
                attributesData.push([lotAttributeLabel(attrKey), attrValue])
            })
        }
        return <div className={styles.detailContainer}>
            <span>Detalle de Partida {code}</span>
            {attributesData.map(([label, value]) => (
                <span key={value}>{label}: {value}</span>
            ))}
        </div>
    }
    
    const fields: FormField[] = [
        {
            name: 'value',
            type: FieldType.PRICE,
            label: 'Acopio Total',
            value: define(hoard.value),
            disabled: true
        },
        {
            name: 'pricesDate',
            type: FieldType.DATE,
            label: 'Fecha Lista de Precios',
            value: new Date(hoard.creationDate),
            disabled: true
        },
        {
            name: 'hoardItems',
            type: FieldType.TABLE,
            table: TableName.HOARD_ITEMS,
            render: () => (
                <OperationItemsTable
                    rows={hoardItemsData}
                    operation={Operation.SALE}
                    onCreate={handleCreateHoardItem}
                    onEditRow={handleEditHoardItem}
                    onRemoveRow={handleRemoveHoardItem}
                    renderDetail={renderItemDetail}
                />
            )
        },
        {
            name: 'credit',
            type: FieldType.PRICE,
            label: 'Acopio Restante',
            value: remainingCredit,
            disabled: true
        }
    ]

    return <WizardStep
        wizardKey={wizardKey}
        stepIndex={stepIndex}
        fields={fields}
    />
}
