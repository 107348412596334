import { PanelWrapper } from "../../../../components/wrappers/panel-wrapper/panelWrapper"
import { DataTypeList } from "../../../../features/data-types"
import { Column, FieldType, UIPermission } from "../../../../features/ui"
import { useSystemStateHooks } from "../../../system"

export const BranchList = () => {
    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_BRANCHES)

    const columns: Column[] = [
        { name: 'code', type: FieldType.TEXT, label: 'Código' },
        { name: 'name', type: FieldType.TEXT, label: 'Nombre' },
        { name: 'address', type: FieldType.TEXT, label: 'Dirección' }
    ]

    return (
        <PanelWrapper title="Sucursales" wide>
            <DataTypeList
                columns={columns}
                readOnly={readOnly}
                minItems={1}
            />
        </PanelWrapper>
    )
}
