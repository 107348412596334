import styles from './profilePanel.module.css'
import 'jquery-ui-dist/jquery-ui'
import $ from 'jquery'
import { EmptyImgImg } from '../../../../components/constants'
import { FieldRow } from '../field-row/fieldRow'
import { StaticValue } from '../static-value/staticValue'
import { Data, DetailFieldProps, FieldType, FormField } from '../types'
import { Form } from '../form/form'
import { liveUrl } from '../../utils/uiUtils'

type Props = {
  infoFields: DetailFieldProps[]
  fields: FormField[]
  data: Data
  subtitle?: string,
  infoTabLabel?: string,
  editTabLabel?: string,
  readOnly?: boolean,
  onSubmit: (data: Data) => Promise<void>
}

export const ProfilePanel = (props: Props) => {
  const { infoFields, fields, data, subtitle, infoTabLabel, readOnly, onSubmit } = props
  const name = data.name as string
  const imgSrc = liveUrl((data.imageUrl || EmptyImgImg) as string)

  const fieldsWithDefault = fields.map(field => {
    return {
      ...field,
      defaultValue: data[field.name]
    }
  })

  const defaultRender = (field: DetailFieldProps) => {
    const rawValue = data[field.name]
    const value = field.type === FieldType.DATE ? new Date(rawValue as string) : rawValue
    return (
      <StaticValue type={field.type} table={field.table} value={value} />
    )
  }

  const goToOverview = () => $('#overview-tab').trigger('click')

  const handleSubmit = (data: Data) => {
    onSubmit(data).then(goToOverview)
  }

  return (
    <section className="section profile">
      <div className="row">
        <div className="col-xl-4">
          <div className="card">
            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
              <img src={imgSrc} alt="Profile" className={`rounded-circle ${styles.profileImg}`} />
              <h2>{name}</h2>
              <h3>{subtitle}</h3>
            </div>
          </div>
        </div>

        <div className="col-xl-8">
          <div className="card">
            <div className="card-body pt-3">
              <ul className="nav nav-tabs nav-tabs-bordered">
                <li className="nav-item">
                  <button id="overview-tab" className="nav-link active" data-bs-toggle="tab" data-bs-target="#overview">{infoTabLabel}</button>
                </li>
                {!readOnly && (
                  <li className="nav-item">
                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#edit">Editar Información</button>
                  </li>
                )}
              </ul>

              <div className="tab-content pt-4">
                <div className="tab-pane fade show active profile-overview" id="overview">
                  {infoFields.map(field => {
                    const value = data[field.name]
                    return (
                      <FieldRow
                        key={field.name}
                        label={field.label}
                      >
                        {field.render ?
                          field.render(value) : defaultRender(field)
                        }
                      </FieldRow>
                    )
                  })}
                </div>

                {!readOnly && (
                  <div className="tab-pane fade pt-3" id="edit">
                    <Form
                      formId='profile-form'
                      fields={fieldsWithDefault}
                      createMode={false}
                      onSubmit={handleSubmit}
                      onCancel={goToOverview}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
