import { useEffect, useState } from "react"
import { EmployeeSearchField } from "../../../../../components/fields/search-field/employee-search-field/employeeSearchField"
import { PanelWrapper } from "../../../../../components/wrappers/panel-wrapper/panelWrapper"
import { FormField, Data, ModalId, useUIActions, FieldType, Validatable, Spinner, useGetCrumbs } from "../../../../../features/ui"
import { Budget, BudgetItem, Customer } from "../../../state/types"
import { Area, Employee, useAdministrationActions, useAdministrationState } from "../../../../administration"
import { useSalesActions } from "../../../actions/salesActions"
import { DataType, TableName } from "../../../../../tables"
import { CustomerSearchField } from "../../../../../components/fields/search-field/customer-search-field/customerSearchField"
import { define } from "../../../../../utils/typeUtils"
import { DataTypeForm, useDescriptorHooks } from "../../../../../features/data-types"
import { Product } from "../../../../products"
import { useGenerateNextValue } from "../../../../../tables/hooks"
import { Operation, OperationItemFormModal, OperationItemsTable, usePricesHooks, PriceDetail } from '../../../../operations'
import { useActions } from "./actions"
import { useSalesHooks } from "../../../hooks/salesHooks"
import { BudgetPriceList } from "../budget-price-list/budgetPriceList"
import { BudgetPriceItem } from "../../../hooks/types"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"

export const RefreshBudgetForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const budget = define(dataType) as Budget
    
    const { currentEmployee } = useAdministrationState()

    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [code, setCode] = useState(budget?.code || 1)
    const [seller, setSeller] = useState<Employee | undefined>(currentEmployee)
    const [customer, setCustomer] = useState<Customer>()
    const [budgetItemsData, setBudgetItemsData] = useState<Data[]>([])
    const [currentBudgetItem, setCurrentBudgetItem] = useState<Data>()
    const [subtotalPrice, setSubtotalPrice] = useState<number>(0)
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [priceItems, setPriceItems] = useState<BudgetPriceItem[]>([])
    const [validations, setValidations] = useState<string[]>([])

    const { getBudgetPricesList } = useSalesHooks()
    const pricesHooks = usePricesHooks()
    const descriptorHooks = useDescriptorHooks()
    const generateNextValue = useGenerateNextValue()
    const navigate = useNavigate()

    const { refreshBudget } = useActions()
    const administrationActions = useAdministrationActions()
    const salesActions = useSalesActions()
    const { toggleModal } = useUIActions()

    const setSellerFromId = (id?: string, callback?: () => void) => {
        if (id) {
            const onFetchSeller = (sellerDT?: DataType) => {
                setSeller(sellerDT as Employee)
                callback && callback()
            }
            administrationActions().fetchEmployee(id, onFetchSeller)
        } else {
            setSeller(undefined)
        }   
    }

    const setCustomerFromId = (id?: string, callback?: () => void) => {
        if (id) {
            const onFetchCustomer = (customerDT?: DataType) => {
                setCustomer(customerDT as Customer)
                callback && callback()
            }
            salesActions().fetchCustomer(id, onFetchCustomer)
        } else {
            setCustomer(undefined)
        }
    }

    useEffect(() => {
        generateNextValue(TableName.BUDGETS, 'code', setCode)
        setPriceItems(getBudgetPricesList(budget.totalPrice))
        const onSetSeller = () => setAsyncDataLoaded(true)
        const onSetCustomer = () => setSellerFromId(budget.sellerId, onSetSeller)
        const toDefaultBudgetItemData = (budgetItemDT: DataType)  => {
            const budgetItem = budgetItemDT as BudgetItem
            const price = pricesHooks.product.basePrice(budgetItem.productId)
            return {
                ...budgetItemDT,
                id: undefined,
                budgetId: undefined,
                price
            } as Data
        }
        const onFetchBudgetItems = (budgetItemsDT: DataType[]) => {
            setBudgetItemsData(budgetItemsDT.map(toDefaultBudgetItemData))
            setCustomerFromId(budget.customerId, onSetCustomer)
        }
        salesActions().fetchBudgetItemsByOrder(budget.id, onFetchBudgetItems)
    }, [])

    useEffect(() => {
        const salePriceItems = budgetItemsData.map(data => ({ productId: data.productId as string, quantity: data.quantity as number }))
        const fullPrice = pricesHooks.sale.fullPrice(salePriceItems)
        setSubtotalPrice(fullPrice)
        const adjustedPrice = pricesHooks.sale.adjustedPrice(salePriceItems, undefined, customer)
        setTotalPrice(adjustedPrice)
        setPriceItems(getBudgetPricesList(adjustedPrice))
    }, [budgetItemsData, customer])

    const crumbs = useGetCrumbs(
        'Presupuestos',
        RoutePath.BUDGETS,
        descriptorHooks.budget.code(budget),
        budget.id,
        'Actualizar'
    )

    const filterProducts = (product: Product) =>
        currentBudgetItem?.productId === product.id || !budgetItemsData.map(budgetItemData => budgetItemData.productId).includes(product.id)

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

    const fields: FormField[] = [
        {
            name: 'date',
            type: FieldType.DATE,
            label: 'Fecha',
            defaultValue: new Date(),
            disabled: true
        },
        {
            name: 'sellerId',
            type: FieldType.TABLE,
            table: TableName.EMPLOYEES,
            label: 'Vendedor',
            render: () => renderAsync(
                <EmployeeSearchField
                    id='sellerId'
                    defaultValue={seller?.id}
                    areas={[Area.MANAGMENT, Area.SALES]}
                    onChange={setSellerFromId}
                />
            )
        },
        {
            name: 'customerId',
            type: FieldType.TABLE,
            table: TableName.CUSTOMERS,
            label: 'Cliente',
            render: () => renderAsync(
                <CustomerSearchField
                    id='customerId'
                    defaultValue={customer?.id}
                    onChange={setCustomerFromId}
                />
            )
        },
        {
            name: 'budgetItems',
            type: FieldType.TABLE,
            table: TableName.BUDGET_ITEMS,
            label: 'Productos',
            render: () => renderAsync(
                <Validatable validations={validations}>
                    <OperationItemsTable
                        rows={budgetItemsData as Data[]}
                        operation={Operation.SALE}
                        onCreate={onCreateBudgetItem}
                        onEditRow={onEditBudgetItem}
                        onRemoveRow={onRemoveBudgetItem}
                    />
                </Validatable>
            )
        },
        {
            name: 'totalPrice',
            type: FieldType.PRICE,
            label: 'Precio',
            render: () => <PriceDetail
                subtotal={subtotalPrice}
                customerAdjustment={totalPrice - subtotalPrice}
                total={totalPrice}
            />
        }
    ]

    if (priceItems.length > 0 && totalPrice !== 0) {
        fields.push({
            name: 'priceList',
            type: FieldType.TABLE,
            table: TableName.PAYMENT_TYPES,
            label: 'Medios de Pago',
            render: () => renderAsync(
                <BudgetPriceList priceItems={priceItems} collapsable />
            )
        })
    }

    const onSaveBudgetItem = (newData: Data) => {
        const newBudgetItemsData = currentBudgetItem ?
            budgetItemsData.map(budgetItemData => budgetItemData.productId === currentBudgetItem.productId ? newData : budgetItemData) :
            [newData, ...budgetItemsData]
        setBudgetItemsData(newBudgetItemsData)
    }

    const openFormModal = () => toggleModal(ModalId.OPERATION_ITEM_FORM)

    const onCreateBudgetItem = () => {
        setCurrentBudgetItem(undefined)
        setValidations([])
        openFormModal()
    }

    const onEditBudgetItem = (productId: string) => {
        setCurrentBudgetItem(budgetItemsData.find(budgetItemData => budgetItemData.productId === productId))
        openFormModal()
    }

    const onRemoveBudgetItem = (productId: string) => {
        const newBudgetItemsData = budgetItemsData.filter(budgetItemData => budgetItemData.productId !== productId)
        setBudgetItemsData(newBudgetItemsData)
    }

    const validate = (): boolean => {
        if (budgetItemsData.length === 0) {
            setValidations(['Debe agregar al menos 1 producto'])
            return false
        }

        return true
    }

    const onSubmit = (budgetData: Data) => {
        if (validate()) {
            refreshBudget(
                budgetData,
                code,
                define(totalPrice),
                define(seller).id,
                define(customer).id,
                budgetItemsData
            )
        }
    }

    const onCancel = () => navigate(-1)

    return (
        <PanelWrapper title={`Actualizar Presupuesto #${budget.code}`} crumbs={crumbs} wide>
            <DataTypeForm
               formId="budget-form"
               fields={fields}
               createMode={true}
               createLabel="Crear Nuevo"
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
            <OperationItemFormModal
                operationItem={currentBudgetItem}
                operation={Operation.SALE}
                filterProducts={filterProducts}
                onSubmit={onSaveBudgetItem}
            />
        </PanelWrapper>
    )
}
