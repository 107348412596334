import { usePurchasesActions } from "../../../purchases"
import { useSalesActions } from "../../../sales"
import { useAdministrationActions } from "../../actions/administrationActions"
import { Transaction, TransactionSourceType } from "../../state/types"

export const useActions = () => {
    const administrationActions = useAdministrationActions()
    const purchasesActions = usePurchasesActions()
    const salesActions = useSalesActions()

    const fetchTransactions = async (
        dateFrom: Date,
        dateTo: Date,
        callback: (transactions: Transaction[]) => void
    ) => {
        const { fetchTransactions } = administrationActions()
        const sourceId = (transaction: Transaction) => transaction.sourceId

        const response = await fetchTransactions(dateFrom, dateTo)

        const transactions = (response.dataTypes as Transaction[]).sort((a, b) => a.date < b.date ? 1 : -1)
        
        const onFetchSales = () => {
            callback(transactions)
        }

        const onFetchPurchases = () => {
            const saleIds = transactions.filter(transaction => transaction.sourceType === TransactionSourceType.SALE).map(sourceId)
            salesActions().fetchSales(saleIds, onFetchSales)
        }

        const purchaseIds = transactions.filter(transaction => transaction.sourceType === TransactionSourceType.PURCHASE).map(sourceId)
        purchasesActions().fetchPurchases(purchaseIds, onFetchPurchases)
    }
    
    return {
        fetchTransactions
    }
}
