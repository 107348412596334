import { DataType } from "../../../tables"
import { normalize } from "../../../utils/typeUtils"

export const useFiltersHooks = () => {
    const search = (
        dataTypes: DataType[],
        matchFn: (dataType: DataType, words: string[]) => number,
        sortFn: (dataTypeA: DataType, dataTypeB: DataType) => number,
        text = '',
        showAll = false
    ): DataType[] => {
        const words = normalize(text).split(/\s+/).filter(word => word)
        
        return dataTypes
            .sort((dataTypeA, dataTypeB) =>
                words.length > 0 ?
                    matchFn(dataTypeB, words) - matchFn(dataTypeA, words) :
                    sortFn(dataTypeA, dataTypeB)
            )
            .filter(dataType => showAll || words.length === 0 || matchFn(dataType, words) > 0)
    }

    return {
        search
    }
}
