import { useSelector } from "react-redux"
import { SalesState } from "../state/reducer"
import { State } from "../../../state"
import { bindActionCreators } from "redux"
import { useDispatch } from "react-redux"
import * as actionCreators from '../state/actionCreators'
import { Employee, useAdministrationHooks, useAdministrationState } from "../../administration"
import { BudgetPriceItem } from "./types"
import { Budget, Customer, CustomerType, HoardOrder, SaleOrder } from "../state/types"
import { define, normalize } from "../../../utils/typeUtils"
import { DataType, TableName } from "../../../tables"
import { useFind } from "../../../state/reducers/hooks"
import { useFiltersHooks } from "../../../features/ui"

export const useSalesState = (): SalesState => useSelector((state: State) => state.salesReducer)

export const useSalesStateActions = () => bindActionCreators(actionCreators, useDispatch())

export const useSalesHooks = () => {
    const { sales, hoards } = useSalesState()
    const { paymentTypes } = useAdministrationState()
    const { applyPaymentTypeAdjustment } = useAdministrationHooks()
    const { search } = useFiltersHooks()
    const find = useFind()

    const getBranchSales = (branchId: string) => sales.filter(sale => sale.branchId === branchId)

    const getBranchHoards = (branchId: string) => hoards.filter(hoard => hoard.branchId === branchId)

    const getBudgetPricesList = (basePrice: number): BudgetPriceItem[] => {
        const list: BudgetPriceItem[] = []

        paymentTypes.forEach(paymentType => {
            list.push({
                paymentType: paymentType,
                price: applyPaymentTypeAdjustment(basePrice, paymentType)
            })
        })

        const budgetPriceList = list.sort((a, b) => a.price - b.price)
        return budgetPriceList
    }

    const getBudgetMatchFn = (budget: Budget, words: string[]) => {
        const { code, sellerId, customerId } = budget
        const seller = define(find(TableName.EMPLOYEES, sellerId)) as Employee
        const customer = define(find(TableName.CUSTOMERS, customerId)) as Customer
        
        const values = [
            code.toString(),
            normalize(seller.name),
            normalize(customer.name)
        ]

        const matchCount = words.reduce((count, word) => 
            values.some(value => value.includes(word)) ? count + 1 : count
        , 0)

        return matchCount
    }

    const searchBudgets = (budgets: Budget[], text = '', showAll = false): Budget[] => {
        const matchFn = (budgetDT: DataType, words: string[]) =>
            getBudgetMatchFn(budgetDT as Budget, words)
        const sortFn = (budgetDT_A: DataType, budgetDT_B: DataType) => 
            (budgetDT_B as Budget).date.localeCompare((budgetDT_A as Budget).date)
        
        return search(budgets, matchFn, sortFn, text, showAll) as Budget[]
    }

    const getSaleMatchFn = (sale: SaleOrder, words: string[]) => {
        const { code, sellerId, customerId } = sale
        const seller = define(find(TableName.EMPLOYEES, sellerId)) as Employee
        const customer = define(find(TableName.CUSTOMERS, customerId)) as Customer

        const values = [
            code.toString(),
            normalize(seller.name),
            normalize(customer.name)
        ]

        const matchCount = words.reduce((count, word) => 
            values.some(value => value.includes(word)) ? count + 1 : count
        , 0)

        return matchCount
    }

    const searchSales = (saleList: SaleOrder[], text = '', showAll = false): SaleOrder[] => {
        const matchFn = (saleDT: DataType, words: string[]) =>
            getSaleMatchFn(saleDT as SaleOrder, words)
        const sortFn = (saleDT_A: DataType, saleDT_B: DataType) => 
            (saleDT_B as SaleOrder).deliveryDate.localeCompare((saleDT_A as SaleOrder).deliveryDate)
        
        return search(saleList, matchFn, sortFn, text, showAll) as SaleOrder[]
    }

    const getHoardMatchFn = (hoard: HoardOrder, words: string[]) => {
        const { code, sellerId, customerId } = hoard
        const seller = define(find(TableName.EMPLOYEES, sellerId)) as Employee
        const customer = define(find(TableName.CUSTOMERS, customerId)) as Customer

        const values = [
            code.toString(),
            normalize(seller.name),
            normalize(customer.name)
        ]

        const matchCount = words.reduce((count, word) => 
            values.some(value => value.includes(word)) ? count + 1 : count
        , 0)

        return matchCount
    }

    const searchHoards = (hoardList: HoardOrder[], text = '', showAll = false): HoardOrder[] => {
        const matchFn = (hoardDT: DataType, words: string[]) =>
            getHoardMatchFn(hoardDT as HoardOrder, words)
        const sortFn = (hoardDT_A: DataType, hoardDT_B: DataType) => 
            (hoardDT_B as HoardOrder).dueDate.localeCompare((hoardDT_A as HoardOrder).dueDate)
        
        return search(hoardList, matchFn, sortFn, text, showAll) as HoardOrder[]
    }

    const getCustomerMatchFn = (customer: Customer, words: string[]) => {
        const { name, identifier, email } = customer
        
        const values = [
            normalize(name),
            identifier.toString(),
            email ? normalize(email) : ''
        ]

        const matchCount = words.reduce((count, word) => 
            values.some(value => value.includes(word)) ? count + 1 : count
        , 0)

        return matchCount
    }

    const searchCustomers = (customers: Customer[], text = '', showAll = false): Customer[] => {
        const matchFn = (customerDT: DataType, words: string[]) =>
            getCustomerMatchFn(customerDT as Customer, words)
        const sortFn = (customerDT_A: DataType, customerDT_B: DataType) => 
            (customerDT_A as Customer).name.localeCompare((customerDT_B as Customer).name)        
        
        return search(customers, matchFn, sortFn, text, showAll) as Customer[]
    }

    const getCustomerTypeMatchFn = (customerType: CustomerType, words: string[]) => {
        const { name } = customerType
        const values = [normalize(name)]

        const matchCount = words.reduce((count, word) => 
            values.some(value => value.includes(word)) ? count + 1 : count
        , 0)

        return matchCount
    }

    const searchCustomerTypes = (customerTypes: CustomerType[], text = '', showAll = false): CustomerType[] => {
        const matchFn = (customerTypeDT: DataType, words: string[]) =>
            getCustomerTypeMatchFn(customerTypeDT as CustomerType, words)
        const sortFn = (customerTypeDT_A: DataType, customerTypeDT_B: DataType) => 
            (customerTypeDT_A as CustomerType).name.localeCompare((customerTypeDT_B as CustomerType).name)        
        
        return search(customerTypes, matchFn, sortFn, text, showAll) as CustomerType[]
    }

    return {
        getBranchSales,
        getBranchHoards,
        getBudgetPricesList,
        searchBudgets,
        searchSales,
        searchHoards,
        searchCustomers,
        searchCustomerTypes
    }
}
