import { useSystemStateHooks } from '../../modules/system'
import styles from './footer.module.css'

export const Footer = () => {
  const { hasCompanyAccess } = useSystemStateHooks()

  return (
    <footer id="footer" className={`footer ${hasCompanyAccess() ? '' : styles.noMargin}`}>
      <div className="copyright">
        &copy; Copyright <strong><span>Construfy</span></strong>
      </div>
    </footer>
  )
}
