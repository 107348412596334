import { Supplier, usePurchasesActions } from "../../../modules/purchases"
import { useSystemStateHooks } from "../../../modules/system"
import { newId } from "../../../tables/utils"
import { define, toNumber } from "../../../utils/typeUtils"
import { CsvColumn, CsvResult, CsvRow } from "../types"
import { useImportCsv } from "./importCsv"
import { showErrorStrToast } from "../../ui"
import { TaxCategory } from "../../../modules/operations"

export const useImportSuppliers = () => {
    const stateCompanyId = useSystemStateHooks().companyId
    const importCsv = useImportCsv()

    const purchasesActions = usePurchasesActions()

    const importSuppliers = (
        file: File,
        companyId: string,
        stateSuppliers: Supplier[]
    ) => {
        const validateCode = (value: string) =>
            !stateSuppliers.map(stateSupplier => stateSupplier.code).includes(define(toNumber(value)))
        
        const validateTaxCategory = (value: string) =>
            !!Object.values(TaxCategory).find(taxCategory => taxCategory === value)

        const columns: CsvColumn[] = [
            { name: "code", validate: validateCode },
            { name: "name" },
            { name: 'taxId' },
            { name: 'taxCategory', validate: validateTaxCategory },
            { name: 'address', optional: true }
        ]

        const manageData = (data: CsvRow[]) => {
            const suppliers: Supplier[] = []
    
            data.forEach(row => {
                const supplier: Supplier = {
                    companyId,
                    id: newId(),
                    code: define(toNumber(row.code)),
                    name: row.name,
                    taxId: define(toNumber(row.taxId)),
                    taxCategory: row.taxCategory as TaxCategory,
                    address: row.address,
                    productSupplier: true
                }
                suppliers.push(supplier)
            })
    
            purchasesActions(companyId).saveSuppliers(suppliers)
        }

        const onImport = (result: CsvResult) => {
            if (result.error) {
                showErrorStrToast(result.error)
            } else {
                console.log('SUCCESS')
                console.log('Processed rows: ', result.processedRows)
                console.log('Unprocessed rows: ', result.unprocessedRows)
            }
        }

        importCsv(file, columns, manageData, onImport)
    }
    
    return async (file: File, paramCompanyId?: string) => {
        const companyId = define(paramCompanyId || stateCompanyId)
        const response = await purchasesActions(companyId).fetchAllSuppliers()
        const stateSuppliers = response.dataTypes as Supplier[]
        importSuppliers(file, companyId, stateSuppliers)
    }
}
