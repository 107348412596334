import { useDefaultModuleActions } from "../../../actions/defaultModuleActions"
import { BaseCallback, OptionalItemCallback } from "../../../actions/types"
import { DynamoUserApi } from "../../../services"
import { TextParams } from "../../../state/reducers/types"
import { TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { useSystemStateHooks } from "../../system"
import { Customer, CustomerType } from "../state/types"

export const useCustomerActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId

    const defaultModuleActions = useDefaultModuleActions()

    return (paramCompanyId?: string) => {
        const companyId = define(paramCompanyId || stateCompanyId)
        const { fetchAll, fetchByParams, count, save_async, remove, remove_async } = defaultModuleActions(DynamoUserApi, companyId)

        const fetchAllCustomerTypes = async () => {
            return fetchAll(TableName.CUSTOMER_TYPES).then(response => response.dataTypes as CustomerType[])
        }

        const fetchCustomerType = (id: string, callback?: OptionalItemCallback) => {
            fetchByParams(TableName.CUSTOMER_TYPES, { id }, callback)
        }

        const saveCustomerType = (customerType: CustomerType) => {
            return save_async(TableName.CUSTOMER_TYPES, customerType)
        }

        const removeCustomerType = async (id: string) => {
            const customerTypes = await fetchAllCustomerTypes()
            if (customerTypes.length > 1) {
                return remove_async(TableName.CUSTOMER_TYPES, id)
            }
        }

        const fetchAllCustomers = async (limit?: number, startKey?: TextParams) => {
            return fetchAll(TableName.CUSTOMERS, limit, startKey)
        }

        const fetchCustomer = (id: string, callback?: OptionalItemCallback) => {
            fetchByParams(TableName.CUSTOMERS, { id }, callback)
        }

        const countCustomers = (propName: string, propValue: string) => {
            return count(TableName.CUSTOMERS, { [propName]: propValue })
        }

        const saveCustomer = async (customer: Customer) => {
            return save_async(TableName.CUSTOMERS, customer)
        }

        const removeCustomer = (id: string, callback?: BaseCallback) => {
            remove(TableName.CUSTOMERS, id, callback)
        }

        return {
            fetchAllCustomerTypes,
            fetchCustomerType,
            saveCustomerType,
            removeCustomerType,
            fetchAllCustomers,
            fetchCustomer,
            countCustomers,
            saveCustomer,
            removeCustomer
        }
    }
}
