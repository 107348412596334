import { PurchaseOrder } from "../../modules/purchases"
import { Budget, HoardOrder, SaleOrder } from "../../modules/sales"
import { DataType, TableName } from "../types/types"

export const IdentifierLabels = new Map<TableName, (dataType: DataType) => string>()
    .set(TableName.HOARD_ORDERS,
        (dataType: DataType) => `#${(dataType as HoardOrder).code.toString()}`
    )    
    .set(TableName.PURCHASE_ORDERS,
        (dataType: DataType) => `#${(dataType as PurchaseOrder).code.toString()}`
    )
    .set(TableName.SALE_ORDERS,
        (dataType: DataType) => `#${(dataType as SaleOrder).code.toString()}`
    )
    .set(TableName.BUDGETS,
        (dataType: DataType) => `#${(dataType as Budget).code.toString()}`
    )
    