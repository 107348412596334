import { useState } from "react"
import { Badge } from "../../../../../components/badge/badge"
import { PanelWrapper } from "../../../../../components/wrappers/panel-wrapper/panelWrapper"
import { DataTypeList, useDataTypesHooks } from "../../../../../features/data-types"
import { Column, Data, FieldType, FieldValue, FormatType, SpinnerDiv, UIPermission } from "../../../../../features/ui"
import { useFind } from "../../../../../state/reducers/hooks"
import { DataType, TableName } from "../../../../../tables"
import { customerCategoryLabel } from "../../../../../tables/types/labels"
import { define } from "../../../../../utils/typeUtils"
import { useSystemStateHooks } from "../../../../system"
import { Customer, CustomerCategory, CustomerType } from "../../../state/types"
import { CustomerFilters } from "./customerFilters"

export const CustomerList = () => {
    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_CUSTOMERS)
    const { formatCustomValue } = useDataTypesHooks()
    const find = useFind()

    const columns: Column[] = [
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre'
        },
        {
            name: 'category',
            type: FieldType.TEXT,
            label: 'Categoría',
            render: (value?: FieldValue) => <Badge label={customerCategoryLabel(define(value) as CustomerCategory)} />
        },
        {
            name: 'identifier',
            type: FieldType.TEXT,
            label: 'DNI / CUIT',
            render: (value?: FieldValue) => {
                const identifier = value as number | undefined
                const format = identifier?.toString().length === 11 ? FormatType.CUIT_CUIL : FormatType.DNI
                return formatCustomValue(identifier, format) || <Badge label="Vacío" />
            }
        },
        {
            name: 'customerTypeId',
            type: FieldType.TABLE,
            table: TableName.CUSTOMER_TYPES,
            label: 'Tipo',
            render: (value?: FieldValue) => {
                const customerType = define(find(TableName.CUSTOMER_TYPES, value as string)) as CustomerType
                return <Badge label={customerType.name} />
            }
        },
        {
            name: 'email',
            type: FieldType.TEXT,
            label: 'Email',
            render: (value?: FieldValue) => {
                const email = value as string | undefined
                return email || <Badge label="Vacío" />
            }
        }
    ]

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRows(dataTypes as Customer[])
        setAsyncDataLoaded(true)
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <PanelWrapper title="Clientes" wide>
            <CustomerFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone}/>
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    readOnly={readOnly}
                    showDetail={false}
                />
            )}
        </PanelWrapper>
    )
}
