import { useState } from "react"
import { FieldProps } from "../../types"
import { EditableRow } from "./editableRow"

type Props = {
    fields: FieldProps[]
}

export const EditablePanel_OLD = (props: Props) => {
    const [editingField, setEditingField] = useState<string>()

    const onEditingChange = (fieldName: string) => (editing: boolean) => {
        setEditingField(editing ? fieldName : undefined)
    }

    const disableEdit = (fieldName: string) => !!editingField && editingField !== fieldName

    return (
        <div>
            {props.fields.map(field => {
                return field.editableRowRenderer ? field.editableRowRenderer() : (
                    <EditableRow
                        key={field.name}
                        field={field}
                        disableEdit={disableEdit(field.name)}
                        onEditingChange={onEditingChange(field.name)}
                    />
                )
            })}
        </div>
    )
}