import styles from './productSelectField.module.css'
import { Product, ProductName, useProductsHooks } from '../..'
import { FieldValue, Option, SelectField, Validatable, option } from '../../../../features/ui'
import { TableName } from '../../../../tables'
import { define } from '../../../../utils/typeUtils'
import { useFind } from '../../../../state/reducers/hooks'
import { useDescriptorHooks } from '../../../../features/data-types'
import { Supplier } from '../../../purchases'

type Props = {
    productList: Product[]
    branchId: string
    defaultValue?: string
    onChange: (value?: FieldValue) => void
}

export const ProductSelectField = (props: Props) => {
    const { productList, branchId, defaultValue, onChange } = props

    const descriptorHooks = useDescriptorHooks()
    const { searchProducts } = useProductsHooks()
    const find = useFind()

    const options = productList.map(product => option(product.id, product.name))

    const searchProductsFn = (text?: string): Option[] => {
        return searchProducts(productList, text, true).map(product => option(product.id, product.name))
    }

    const renderInputLabel = (productId: string) => {
        const product = productList.find(product => product.id === productId)
        const supplier = find(TableName.SUPPLIERS, product?.supplierId) as Supplier | undefined
        
        return product && supplier ? `${product.name} (${product.supplierCode}) - ${supplier.name}` : ''
    }

    const renderOption = (option: Option, onClick?: (e: React.MouseEvent<HTMLElement>) => void) => {
        const product = define(find(TableName.PRODUCTS, option.value)) as Product
        const stockInfo = descriptorHooks.product.availableStock(product, branchId)
        const stockClassName = `${styles.stock} ${stockInfo.startsWith('0') ? styles.noStock : ''}`
        return (
            <div className={styles.option} onClick={onClick}>
                <ProductName product={product} showSupplierCode />
                <span className={stockClassName}>
                    {stockInfo}
                </span>
            </div>
        )
    }
    
    return (
        <Validatable validations={[]} left>
            <SelectField
                id="productId"
                defaultValue={defaultValue}
                options={options}
                placeholder="Seleccionar producto"
                searchFn={searchProductsFn}
                renderInputLabel={renderInputLabel}
                renderOption={renderOption}
                onChange={onChange}
            />
        </Validatable>
    )
}
