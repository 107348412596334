import { NativeType } from '../types'
import { TableName } from '../../tables'
import { useFind } from '../../state/reducers/hooks'
import { isTypeNative } from '../utils'
import { define, toNumber } from '../../utils/typeUtils'
import { identifier } from '../../tables/utils'
import { FieldType } from '../../features/ui'

type Props = {
    value?: NativeType
    type?: FieldType,
    table?: TableName
}

export const Label = (props: Props) => {
    const { value, type, table } = props
    const find = useFind()

    let valuelLabel = value as string
    if (type && !isTypeNative(type)) {
        const tableName = define(table) as TableName
        const dataType = define(find(tableName, valuelLabel))
        valuelLabel = define(identifier(tableName, dataType))
    } else if (type === FieldType.PRICE) {
        const price = define(toNumber(valuelLabel))
        valuelLabel = `$ ${price.toFixed(2)}`
    } else if (type === FieldType.PERCENTAGE) {
        valuelLabel = `${valuelLabel}%`
    }

    return <span><label>{valuelLabel}</label></span>
}
