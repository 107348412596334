import styles from './table.module.css'
import { Button } from '../button/button'
import { FormField } from '../fields/form-field/formField'
import { ChangeValueType, FieldProps, TableRow, TextOrNumberType } from '../types'
import { useEffect, useMemo, useState } from 'react'
import { Icon } from '../icon/icon'
import { Label } from '../label/label'

type Props = {
    fields: FieldProps[]
    identifierField: string
    items: TableRow[]
    pageLength?: number
    readOnly?: boolean
    onSaveNewRow: (newRow: TableRow) => void
    onRemove: (id: string) => void
}

// TODO migrar todo a la nueva table y borrar esta
export const Table_OLD = (props: Props) => {
    const { fields, identifierField, items, pageLength = 5, readOnly, onSaveNewRow, onRemove } = props
    const defaultNewRow = fields.reduce((defaultValues, field) => {
        if (field.defaultValue) {
            defaultValues[field.name] = field.defaultValue as TextOrNumberType
        }
        return defaultValues
    }, {} as TableRow)
    const [newRow, setNewRow] = useState<TableRow | undefined>(defaultNewRow)
    const [addingNewRow, setAddingNewRow] = useState(false)
    const [addedRowIds, setAddedRowIds] = useState<string[]>([])
    const [currentPage, setCurrentPage] = useState(0)

    const pagesAmount = Math.ceil(items.length / pageLength)
    const sortedItems = items.sort((a, b) => {
        const indexA = addedRowIds.indexOf(a[identifierField] as string)
        const indexB = addedRowIds.indexOf(b[identifierField] as string)
        return indexA !== -1 ? (indexB !== -1 ? indexA - indexB : -1) : (indexB !== -1 ? 1 : 0)
    })    
    const pageItems = useMemo(() => {
        const start = currentPage * pageLength
        return sortedItems.slice(start, start + pageLength)
    }, [currentPage, sortedItems])
    const requiredProps = fields.filter(field => !field.optional).map(({ name }) => name)
    const canSubmit = newRow && requiredProps.every(prop => newRow[prop] !== undefined)

    useEffect(() => {
        pageItems.length === 0 && currentPage > 0 && goToPreviousPage()
    }, [pageItems, currentPage])

    const handleAdd = () => {
        setAddingNewRow(true)
    }

    const handleChange = (columnName: string) => (value?: ChangeValueType) => {
        setNewRow({
            ...newRow,
            [columnName]: value as string | undefined
        })
    }

    const handleSave = () => {
        if (newRow) {
            onSaveNewRow(newRow)
            setAddingNewRow(false)
            setNewRow(defaultNewRow)
            setAddedRowIds([newRow[identifierField] as string, ...addedRowIds])
            goToFirstPage()
        }
    }

    const handleCancel = () => {
        setAddingNewRow(false)
        setNewRow(defaultNewRow)
    }

    const handleRemove = (id: string) => () => onRemove(id)

    const goToFirstPage = () => setCurrentPage(0)
    const goToPreviousPage = () => setCurrentPage(page => page - 1)
    const goToNextPage = () => setCurrentPage(page => page + 1)
    
    return (<>
        <table className="table table-hover">
            <thead>
                <tr>
                    {fields.map(field => (
                        <th key={field.name} scope="col">
                            <div className={styles.header}>
                                {(pageItems.length > 0 || addingNewRow) && field.label}
                            </div>
                        </th>
                    ))}
                    {!readOnly && (
                        <th scope="col" className={styles.actions}>
                            <div className={styles.header}>
                                <Button
                                    kind='tertiary'
                                    label='Agregar'
                                    icon='plus'
                                    small
                                    disabled={addingNewRow}
                                    onClick={handleAdd}
                                />
                            </div>
                        </th>
                    )}
                </tr>
            </thead>
            <tbody>
                {addingNewRow && (
                    <tr>
                        {fields.map((field, index) => (
                            <td key={field.name}>
                                <div className={styles.newRow}>
                                    {field.readOnly ? (
                                        field.value && (
                                            <Label
                                                value={field.value}
                                                type={field.type}
                                                table={field.table}
                                            />
                                        )
                                    ) : (
                                        <FormField
                                            name={field.name}
                                            type={field.type}
                                            table={field.table}
                                            defaultValue={field.defaultValue}
                                            disabled={field.readOnly}
                                            options={field.options}
                                            focusOnLoad={index === 0}
                                            onChange={handleChange(field.name)}
                                        />
                                    )}
                                </div>
                            </td>
                        ))}
                        <td className={styles.actions}>
                            <div className={styles.newRow}>
                                <Button
                                    icon="check-lg"
                                    small
                                    rightMargin
                                    disabled={!canSubmit}
                                    onClick={handleSave}
                                />
                                <Button
                                    kind='secondary'
                                    icon='x-lg'
                                    small
                                    onClick={handleCancel}
                                />
                            </div>
                        </td>
                    </tr>
                )}
                {pageItems.map((item, index) => (
                    <tr key={index}>
                        {fields.map(field => (
                            <td key={field.name}>
                                <Label
                                    value={item[field.name]}
                                    type={field.type}
                                    table={field.table}
                                />
                            </td>
                        ))} 
                        {!readOnly && (
                            <td className={styles.actions}>
                                <Button
                                    kind="secondary"
                                    icon="trash-fill"
                                    small
                                    onClick={handleRemove(item[identifierField] as string)}
                                />
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
        {pagesAmount > 1 && (
            <div className={styles.arrows}>
                <div className={styles.arrowContainer}>
                    {currentPage !== 0 && (
                        <Icon
                            icon='arrow-left-circle'
                            color='tertiary'
                            className={styles.arrowIcon}
                            onClick={goToPreviousPage}
                        />
                    )}
                </div>
                <span className={styles.pagesText}>
                    Pág. {currentPage + 1} de {pagesAmount}
                </span>
                <div className={styles.arrowContainer}>
                    {currentPage + 1 < pagesAmount && (
                        <Icon
                            icon='arrow-right-circle'
                            color='tertiary'
                            className={styles.arrowIcon}
                            onClick={goToNextPage}
                        />
                    )}
                </div>
            </div>
        )}
    </>)
}
