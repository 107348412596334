import { ReducerState } from "../../../state/reducers/types"
import { addOrUpdate, remove } from "../../../state/reducers/utils"
import { ActionType, PurchasesAction } from './actionTypes'
import { PurchaseItem, PurchaseOrder, Supplier } from "./types"

export type PurchasesState = {
    purchases: PurchaseOrder[],
    purchaseItems: PurchaseItem[],
    suppliers: Supplier[]
}

const initialState: PurchasesState = {
    purchases: [],
    purchaseItems: [],
    suppliers: []
}

const reducer = (state = initialState, action: PurchasesAction) => {
    switch(action.type) {
        case ActionType.UPDATE_PURCHASES:
            return { 
                ...state,
                purchases: addOrUpdate(state, action, 'purchases' as keyof ReducerState)
            }
        case ActionType.REMOVE_PURCHASES:
            return { 
                ...state,
                purchases: remove(state, action, 'purchases' as keyof ReducerState)
            }
        case ActionType.UPDATE_PURCHASE_ITEMS:
            return { 
                ...state,
                purchaseItems: addOrUpdate(state, action, 'purchaseItems' as keyof ReducerState)
            }
        case ActionType.REMOVE_PURCHASE_ITEMS:
            return { 
                ...state,
                purchaseItems: remove(state, action, 'purchaseItems' as keyof ReducerState)
            }
        case ActionType.UPDATE_SUPPLIERS:
            return { 
                ...state,
                suppliers: addOrUpdate(state, action, 'suppliers' as keyof ReducerState)
            }
        case ActionType.REMOVE_SUPPLIERS:
            return { 
                ...state,
                suppliers: remove(state, action, 'suppliers' as keyof ReducerState)
            }
        default:
            return state
    }
}

export default reducer
