import { Transaction, TransactionSourceType } from "../../state/types"
import { Data, showToast } from "../../../../features/ui"
import { useAdministrationActions } from "../../actions/administrationActions"
import { Currency } from "../../../../tables/types/types"
import { useSystemStateHooks } from "../../../system"
import { define } from "../../../../utils/typeUtils"
import { newId } from "../../../../tables/utils"
import { useNavigate } from "react-router-dom"

export const useActions = () => {
    const { companyId } = useSystemStateHooks()
    const navigate = useNavigate()

    const administrationActions = useAdministrationActions()

    const submitTransfer = (
        transferData: Data,
        concept: string
    ) => {
        const { sourceId, destinationId, amount } = transferData
        const transaction: Transaction = {
            companyId: define(companyId),
            id: newId(),
            date: new Date().toISOString(),
            cashFundId: define(destinationId) as string,
            sourceType: TransactionSourceType.CASH_FUND,
            sourceId: define(sourceId) as string,
            amount: define(amount) as number,
            currency: Currency.ARS,
            concept
        }
        
        const onSaveTransaction = () => {
            showToast('La transferencia fue completada.')
            navigate(-1)
        }
        administrationActions().transferFromCashFund(transaction, onSaveTransaction)
    }
    
    return { submitTransfer }
}
