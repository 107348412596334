import { Data, useUIActions } from "../../../../../features/ui"
import { Currency } from "../../../../../tables/types/types"
import { define } from "../../../../../utils/typeUtils"
import { OrderStatus } from "../../../../operations"
import { usePurchasesActions } from "../../../actions/purchasesActions"
import { PurchaseOrder } from "../../../state/types"

export const useActions = () => {
    const purchasesActions = usePurchasesActions()
    const { onSubmitDataType } = useUIActions()
    
    const submitPurchase = (
        purchaseData: Data,
        code: number,
        totalPrice: number,
        purchaseItemsData: Data[],
    ) => {
        const deliveryDate = define(purchaseData.deliveryDate) as Date
        const purchase: PurchaseOrder = {
            companyId: purchaseData.companyId as string,
            id: purchaseData.id as string,
            code,
            creationDate: new Date().toISOString(),
            supplierId: define(purchaseData.supplierId) as string,
            totalPrice,
            currency: Currency.ARS,
            deliveryDate: deliveryDate.toISOString(),
            deliveryBranchId: define(purchaseData.deliveryBranchId) as string,
            status: purchaseData.status as OrderStatus || OrderStatus.PENDING
        }

        const onSavePurchase = () => onSubmitDataType(true)
        purchasesActions().savePurchaseWithItems(purchase, purchaseItemsData, onSavePurchase)
    }
    
    return { submitPurchase }
}
