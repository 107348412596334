import { DataTypeForm, validateTaxId } from "../../../../../features/data-types"
import { Data, FieldType, FormField, ModalId, option, useUIActions } from "../../../../../features/ui"
import { useActions } from "./actions"
import { Supplier } from "../../../state/types"
import { useEffect, useState } from "react"
import { useGenerateNextValue } from "../../../../../tables/hooks"
import { TableName } from "../../../../../tables"
import { TaxCategory } from "../../../../operations"
import { taxCategoryLabel } from "../../../../../tables/types/labels"

type Props = {
    productSupplier?: boolean
    onSubmit: (supplier: Supplier) => void
}

export const SupplierQuickForm = (props: Props) => {
    const { productSupplier = true, onSubmit } = props

    const [code, setCode] = useState(1)

    const generateNextValue = useGenerateNextValue()

    const { submitSupplier } = useActions()
    const { toggleModal } = useUIActions()

    useEffect(() => {
        const onGenerateNumber = (n: number) => setCode(n)
        generateNextValue(TableName.SUPPLIERS, 'code', onGenerateNumber)
    }, [])

    const fields: FormField[] = [
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre'
        },
        {
            name: 'taxId',
            type: FieldType.NUMBER,
            label: 'CUIT / CUIL',
            validate: validateTaxId
        },
        {
            name: 'taxCategory',
            type: FieldType.SELECT,
            label: 'Cateogría Fiscal',
            options: Object.values(TaxCategory).map(taxCategory => option(taxCategory, taxCategoryLabel(taxCategory)))
        }
    ]

    const handleSubmit = (supplierData: Data) => {
        submitSupplier(supplierData, code, productSupplier, onSubmit)
    }

    const onCancel = () => toggleModal(ModalId.QUICK_FORM)

    return (
        <DataTypeForm
            formId="supplier-quick-form"
            fields={fields}
            createMode={true}
            onSubmit={handleSubmit}
            onCancel={onCancel}
        />
    )
}
