import { Emitter, Event } from "../../../../features/event-emitter"
import { Data, showToast } from "../../../../features/ui"
import { Branch } from "../../../manager"
import { Company, useSystemActions } from "../../../system"
import { define } from "../../../../utils/typeUtils"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../features/routes"

export const useActions = () => {
    const navigate = useNavigate()
    const { getRoutePath } = useRoutesHooks()

    const systemActions = useSystemActions()

    const onBranchClick = (branch: Branch) => () => {
        navigate(getRoutePath(RoutePath.BRANCHES, branch.id))
    }

    const updateCompany = (
        company: Company,
        companyData: Data,
        logoFile?: File
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const { websiteUrl, email, address, phone } = companyData
        const updatedCompany: Company = {
            ...company,
            websiteUrl: define(websiteUrl) as string,
            email: define(email) as string,
            address: define(address) as string,
            phone: phone as number | undefined
        }

        return systemActions().saveCompany(updatedCompany, logoFile).then(() => {
            Emitter.emit(Event.DB_SUBMIT_DONE)
            showToast('Se actualizó la información de la compañía.')
        })
    }
    
    return {
        onBranchClick,
        updateCompany
    }
}
