import styles from './priceField.module.css'
import { InputGroup } from '../input-group/inputGroup'
import { TextOrNumberType } from '../../types'
import { Currency } from '../../../tables/types/types'

type Props = {
    id: string
    inputKey?: string
    value?: number
    defaultValue?: number
    min?: number
    max?: number
    optional?: boolean
    disabled?: boolean
    onChange?: (value?: number) => void
}

export const PriceField = (props: Props) => {
    const { id, inputKey, value, defaultValue, min = 0, max, optional, disabled, onChange } = props

    const handleChange = (newValue?: TextOrNumberType) => {
        onChange && onChange(newValue as number | undefined)
    }

    return (
        <InputGroup
            id={id}
            inputKey={inputKey}
            value={value}
            defaultValue={defaultValue}
            preLabel='$'
            postLabel={Currency.ARS}
            min={min}
            max={max}
            optional={optional}
            disabled={disabled}
            fit={true}
            className={styles.field}
            onChange={handleChange}
            aria-label={`${id} price field.`}
        />
    )
}
