import { SpinnerDiv } from "../.."

type Props = {
  ready: boolean
  children: JSX.Element
}

export const PanelLoader = (props: Props) => {
  const { ready, children } = props

  return ready ? children : <SpinnerDiv />
}
