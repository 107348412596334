import { AttributeMap, BatchWriteItemRequestMap } from 'aws-sdk/clients/dynamodb'
import { createBatches, getList } from './utils'
import { ItemCallback, ListCallback, KeyCallback, KeyParams, PaginatedListCallback, BaseCallback, RangeParams } from './types'
import { DynamoApi } from './dynamo'
import { callSequentially } from '../../utils/fnUtils'
import { define } from '../../utils/typeUtils'

export const useDynamoActions = () => {
    return (dynamoApi: DynamoApi, tKeyValue?: string) => {
        const getAll = (
            dbTableName: string,
            limit?: number,
            startKey?: KeyParams,
            propName?: string,
            callback?: PaginatedListCallback,
        ) => {
            dynamoApi.getAll(dbTableName, tKeyValue, limit, startKey, propName, callback)
        }

        const getAll_async = (
            dbTableName: string,
            limit?: number,
            startKey?: KeyParams,
            propName?: string,
        ) => {
            return dynamoApi.getAll_async(dbTableName, tKeyValue, limit, startKey, propName)
        }

        const getByParams = (dbTableName: string, params: KeyParams, callback?: ListCallback) => {
            dynamoApi.getByParams(dbTableName, params, tKeyValue, callback)
        }

        const getByParams_async = (
            dbTableName: string,
            params: KeyParams
        ) => {
            return dynamoApi.getByParams_async(dbTableName, params, tKeyValue)
        }

        const getMultiple = (dbTableName: string, uKeyValues: string[], callback?: ListCallback) => {
            dynamoApi.getMultiple(dbTableName, uKeyValues, tKeyValue, callback)
        }

        const getMultiple_async = (
            dbTableName: string,
            uKeyValues: string[]
        ) => {
            return dynamoApi.getMultiple_async(dbTableName, uKeyValues, tKeyValue)
        }

        const getFiltered = (
            dbTableName: string,
            propFilters?: KeyParams,
            rangeFilters?: RangeParams
        ) => {
            return dynamoApi.getFiltered(dbTableName, define(tKeyValue), propFilters, rangeFilters)
        }

        const count = (
            dbTableName: string,
            params: KeyParams
        ) => {
            return dynamoApi.count(dbTableName, params, tKeyValue)
        }
        
        const create = (dbTableName: string, item: AttributeMap, callback?: ItemCallback) => {
            const onCreate = () => callback && callback(item)
            dynamoApi.create(dbTableName, item, onCreate)
        }

        const update = (dbTableName: string, item: AttributeMap, callback?: ItemCallback) => {
            const onUpdate = () => callback && callback(item)
            dynamoApi.update(dbTableName, item, onUpdate)
        }

        // TODO ARREGLAR
        // los logs de processed/unprocessed items se loguean antes de hacer los calls sequenciales
        // probar nuevamente si fallan los que ya existen y se agregan a los unprocessed list
        const createOrUpdateMultiple = (dbTableName: string, list: AttributeMap[], callback?: ListCallback) => {
            let unprocessedItemsList: AttributeMap[] = []
            const onFinish = () => {
                callback && callback(unprocessedItemsList)
            }

            const batches = createBatches(list)
            const fns = batches.map(batch => {
                return (onCreateOrUpdateBatch: BaseCallback) => {
                    const onCreateOrUpdateMultiple = (unprocessedItems?: BatchWriteItemRequestMap) => {
                        unprocessedItemsList = unprocessedItemsList.concat(getList(dbTableName, unprocessedItems))
                        onCreateOrUpdateBatch()
                    }
                    dynamoApi.createOrUpdateMultiple(dbTableName, batch, onCreateOrUpdateMultiple)
                }
            })
            callSequentially(fns, onFinish)
        }

        // TODO implement batches
        const createOrUpdateMultiple_async = (
            dbTableName: string,
            list: AttributeMap[]
        ) => {
            return dynamoApi.createOrUpdateMultiple_async(dbTableName, list)
                .then(unprocessedItems => getList(dbTableName, unprocessedItems))
        }

        const remove = (dbTableName: string, uKeyValue: string, callback?: KeyCallback) => {
            const onRemove = () => callback && callback(uKeyValue)
            dynamoApi.remove(dbTableName, uKeyValue, tKeyValue, onRemove)
        }

        const remove_async = (
            dbTableName: string,
            uKeyValue: string
        ) => {
            return dynamoApi.remove_async(dbTableName, uKeyValue, tKeyValue)
                .then(() => uKeyValue)
        }

        const removeMultiple = (dbTableName: string, uKeyValues: string[], callback?: ListCallback) => {
            const onRemoveMultiple = (unprocessedItems?: BatchWriteItemRequestMap) => {
                const list: AttributeMap[] = getList(dbTableName, unprocessedItems)
                callback && callback(list)
            }
            dynamoApi.removeMultiple(dbTableName, uKeyValues, tKeyValue, onRemoveMultiple)
        }

        const removeMultiple_async = (
            dbTableName: string,
            uKeyValues: string[]
        ) => {
            return dynamoApi.removeMultiple_async(dbTableName, uKeyValues, tKeyValue)
                .then(unprocessedItems => getList(dbTableName, unprocessedItems))
        }

        return {
            getAll,
            getAll_async,
            getByParams,
            getByParams_async,
            getMultiple,
            getMultiple_async,
            getFiltered,
            count,
            create,
            update,
            createOrUpdateMultiple,
            createOrUpdateMultiple_async,
            remove,
            remove_async,
            removeMultiple,
            removeMultiple_async
        }
    }
}
