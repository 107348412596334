import { useEffect, useRef, useState } from 'react'
import { Button, ButtonKind } from '../button'

type Props = {
    id: string
    value?: string
    label?: string
    kind?: ButtonKind
    fileTypes?: string
    rightMargin?: boolean
    onSelectFile: (file: File, url?: string, inputValue?: string) => void
}

export const UploadButton = (props: Props) => {
    const { id, value = '', label, kind, fileTypes = '.txt', rightMargin, onSelectFile } = props
    const [uploadRef, setUploadRef] = useState<HTMLLabelElement | null>()
    const fileInputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (fileInputRef?.current && fileInputRef.current.value !== value) {
            fileInputRef.current.value = value
        }
    }, [value])

    const handleSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (files) {
            const file = files[0]
            onSelectFile(file, URL.createObjectURL(file), fileInputRef?.current?.value)
        }
    }

    const onClickUpload = () => uploadRef?.click()

    return (<>
        <input  
            type="file"
            id={id}
            ref={fileInputRef}
            onChange={handleSelectFile}
            accept={fileTypes}
            hidden
        />
        <label htmlFor={id} ref={ref => setUploadRef(ref)}>
            <Button
                label={label}
                kind={kind}
                icon='upload'
                rightMargin={rightMargin}
                onClick={onClickUpload}
            />
        </label>
    </>)
}
