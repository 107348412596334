import { useState } from 'react'
import { PanelWrapper } from '../../../../components/wrappers/panel-wrapper/panelWrapper'
import { firstItem } from '../../../../utils/typeUtils'
import { Tab } from '../types'

type Props = {
    title?: string
    tabs: Tab[],
    wide?: boolean
}

export const TabsPanel = (props: Props) => {
    const { title, tabs, wide = true } = props
    
    const [currentTab, setCurrentTab] = useState<string | undefined>(firstItem(tabs.map(tab => tab.id)))

    const tabTarget = (tab: Tab) => `#${tab.id}-tab`
    const onTabClick = (tabId: string) => () => setCurrentTab(tabId)

    return (
        <PanelWrapper
            title={title}
            wide={wide}
        >
            <ul className="nav nav-tabs nav-tabs-bordered">
                {tabs.map((tab, index) => {
                    const tabClassName = `nav-link ${currentTab === tab.id ? 'active' : ''}`
                    return (
                        <li key={index} onClick={onTabClick(tab.id)} className="nav-item">
                            <button className={tabClassName} data-bs-toggle="tab" data-bs-target={tabTarget(tab)}>
                                {tab.title}
                            </button>
                        </li>
                    )
                })}
            </ul>
            <div className="tab-content pt-2">
                {tabs.map((tab, index) => {
                    const contentClassName = `tab-pane fade pt-3 ${currentTab === tab.id ? 'show active' : ''}`
                    return (
                        <div key={index} className={contentClassName} id={tabTarget(tab)}>
                            {tab.content}
                        </div>
                    )
                })}
            </div>
        </PanelWrapper>
    )
}
