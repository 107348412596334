import styles from './editablePanel.module.css'
import { useMemo, useState } from "react"
import { Data, Field, FieldRow, FieldValue, EditableFormField } from "../.."
import { Button } from "../../../../components/button/button"

type Props = {
    fields: EditableFormField[]
    readOnly?: boolean
}

export const EditablePanel = (props: Props) => {
    const { fields, readOnly } = props

    const [enabledField, setEnabledField] = useState<string>()
    const [data, setData] = useState<Data>({})

    const isEnabled = (field: EditableFormField) => enabledField === field.name

    const editableFields = useMemo(() => fields.map(field => ({
        ...field,
        value: data[field.name] || field.defaultValue,
        disabled: !isEnabled(field)
    })), [fields, data, enabledField])

    const onChange = (fieldName: string) => (value?: FieldValue) => {
        value && setData(prevData => ({
            ...prevData,
            [fieldName]: value
        }))
    }

    const onEdit = (fieldName: string) => () => {
        setEnabledField(fieldName)
    }

    const onSave = (field: EditableFormField) => () => {
        field.onSave && field.onSave(data[field.name])
        setEnabledField(undefined)
    }

    const onCancel = (fieldName: string, defaultValue?: FieldValue) => () => {
        setData(prevData => ({
            ...prevData,
            [fieldName]: defaultValue
        }))
        setEnabledField(undefined)
    }

    return (
        <div>
            {editableFields.map(field => {
                return (
                    <FieldRow
                        key={field.name}
                        id={field.name}
                        label={field.label}
                        hint={field.hint}
                        narrow={field.narrow}
                    >
                        {field.render ? field.render() : (<>
                            <Field
                                key={field.key}
                                field={field}
                                onChange={onChange(field.name)}
                            />
                            {!readOnly && (
                                <div className={styles.btnsContainer}>
                                    {!isEnabled(field) ? (
                                        <Button
                                            icon='pencil-fill'
                                            kind='tertiary'
                                            tooltip="Editar"
                                            small
                                            className={styles.btn}
                                            onClick={onEdit(field.name)}
                                        />
                                    ) : (<>
                                        <Button
                                            icon='check-lg'
                                            tooltip='Guardar'
                                            small
                                            rightMargin
                                            className={styles.btn}
                                            onClick={onSave(field)}
                                        />
                                        <Button
                                            icon='x-lg'
                                            kind='secondary'
                                            tooltip='Cancelar'
                                            small
                                            className={styles.btn}
                                            onClick={onCancel(field.name, field.defaultValue)}
                                        />
                                    </>)}
                                </div>
                            )}
                        </>)}
                    </FieldRow>
                )
            })}
        </div>
    )
}
