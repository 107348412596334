import styles from './list.module.css'
import { useEffect, useState } from "react"
import { Action, Column, Data } from "../types"
import { Table } from "../table/table"
import { SpinnerDiv } from "../spinner/spinnerDiv"
import { Button } from '../../../../components/button/button'

type Props = {
    columns: Column[]
    rowId?: string
    fetchRows: (
        callback: (rows: Data[]) => void
    ) => void
    filters: () => JSX.Element[]
    actions?: (rowIdValue: string) => Action[]
    readOnly?: boolean
}

export const ListWithFilters = (props: Props) => {
    const { columns, rowId = 'id', fetchRows, filters, actions, readOnly } = props
    
    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)

    const onFetchRows = (newRows: Data[]) => {
        setRows(newRows)
        setAsyncDataLoaded(true)
    }

    useEffect(() => {
        fetchRows(onFetchRows)
    }, [])

    const onApplyFilters = () => {
        setAsyncDataLoaded(false)
        fetchRows(onFetchRows)
    }

    return (
        <>
            <div className={`row mb-3 ${styles.filtersContainer}`}>
                <div className={`col-sm-1 ${styles.filtersBtn}`}>
                    <Button
                        label="Filtros"
                        icon="filter"
                        disabled
                        small
                    />
                </div>
                {filters()}
                <div className="col-sm-2">
                    <Button
                        label="Aplicar"
                        icon="check-lg"
                        small
                        onClick={onApplyFilters}
                    />
                </div>
            </div>
            {asyncDataLoaded ? (
                rows.length > 0 ? (
                    <Table
                        columns={columns}
                        rows={rows}
                        rowId={rowId}
                        actions={actions}
                        readOnly={readOnly}
                    />
                ) : (
                    <div className={styles.empty}>
                        <i className={`bi bi-bricks`} />
                        <span>No se encontraron resultados</span>
                    </div>
                )
            ) : (
                <SpinnerDiv />
            )}
        </>
    )
}
