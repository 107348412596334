import { ListResponse } from "../../actions/types"
import { NativeType } from "../../components/types"
import { DynamoUserApi } from "../../services"
import { AttributeMap, useDynamoActions } from "../../services/dynamo"
import { toDynamoListCallback } from "../../services/utils"
import { TableName, TablesData } from "../../tables"
import { define } from "../../utils/typeUtils"

export const useAddedRequiredPropMigration = () => {
    const dynamoActions = useDynamoActions()

    return (
        companyIds: string[],
        table: TableName,
        propName: string,
        defaultValue: NativeType
    ) => {
        companyIds.forEach(companyId => {
            const { getAll, createOrUpdateMultiple } = dynamoActions(DynamoUserApi, companyId)
            const { dbTableName } = define(TablesData.get(table))
            const onGetAll = (response: ListResponse) => {
                const updatedDataTypes: AttributeMap[] = []
                response.dataTypes.forEach(dataType => {
                    if (!dataType.hasOwnProperty(propName)) {
                        const updatedDataType = {
                            ...dataType,
                            [propName]: defaultValue
                        } as AttributeMap
                        updatedDataTypes.push(updatedDataType)
                    }
                })
                createOrUpdateMultiple(dbTableName, updatedDataTypes)
            }
            getAll(dbTableName, undefined, undefined, undefined, toDynamoListCallback(onGetAll))
        })
    }
}
