import styles from './imageField.module.css'
import { useState } from 'react'
import { Button } from '../../button/button'
import { ImageFieldStatus, OnChangeImageFieldType } from './types'
import { EmptyImgImg } from '../../constants'
import { UploadButton } from '../../button/upload-button/uploadButton_OLD'

type Props = {
    id: string
    defaultUrl: string
    alt?: string
    hideReset?: boolean
    onChange: OnChangeImageFieldType
}

export const ImageField = (props: Props) => {
    const { id, defaultUrl, alt, hideReset, onChange } = props
    const [url, setUrl] = useState<string | undefined>(defaultUrl)
    const [fileRefValue, setFileRefValue] = useState<string>()

    const onSelectFile = (newFile?: File, newUrl?: string, inputValue?: string) => {
        setUrl(newUrl)
        setFileRefValue(inputValue)
        onChange(ImageFieldStatus.FILE_ADDED, newFile)
    }

    const onClickRemove = () => {
        setUrl(undefined)
        if (fileRefValue) {
            setFileRefValue('')
        }
        const status = defaultUrl ? ImageFieldStatus.FILE_REMOVED : ImageFieldStatus.NO_CHANGES
        onChange(status)
    }

    const onClickReset = () => {
        setUrl(defaultUrl)
        if (fileRefValue) {
            setFileRefValue('')
        }
        onChange(ImageFieldStatus.NO_CHANGES)
    }

    return (
        <div>
            <img src={url || EmptyImgImg} alt={alt || 'Image'} className={styles.img} />
            <div className="pt-2">
                <UploadButton
                    id={id}
                    value={fileRefValue}
                    fileTypes="image/jpeg, image/jpg, image/png"
                    rightMargin
                    onSelectFile={onSelectFile}
                />
                <Button
                    icon='trash-fill'
                    kind='secondary'
                    rightMargin
                    disabled={!url}
                    onClick={onClickRemove}
                />
                {!hideReset && (
                    <Button
                        icon='arrow-counterclockwise'
                        kind='tertiary'
                        disabled={url === defaultUrl}
                        onClick={onClickReset}
                    />
                )}
            </div>
        </div>
    )
}
