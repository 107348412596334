import { Modal, ModalId } from "../../../ui"

type Props = {
    onFinish: () => void
}

export const FinishWizardModal = (props: Props) => {
    const { onFinish } = props

    const title = `Finalizar proceso?`
    
    const renderBody = () => <>Desea confirmar la finalización del proceso?</>
    
    return (
        <Modal
            modalId={ModalId.FINISH_WIZARD}
            title={title}
            submitLabel="Confirmar"
            onSubmit={onFinish}
        >
            <>{renderBody()}</>
        </Modal>
    )
}
