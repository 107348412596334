import { Action } from "../../../features/roles";
import { readPermissions } from '../../../permissions/permissions'
import { TableName } from '../../../tables'
import { administrationPermissions } from '../../administration'
import { purchasesPermissions } from '../../purchases'
import { salesPermissions } from '../../sales'

export const managerPermissions = () => readPermissions()
    .concat([
        { tableName: TableName.BRANCHES, actions: [Action.SAVE, Action.REMOVE] },
        { tableName: TableName.EMPLOYEES, actions: [Action.SAVE, Action.REMOVE] },
    ])
    .concat(administrationPermissions())
    .concat(purchasesPermissions())
    .concat(salesPermissions())
