import { Section } from "../../../../components/section/section"
import { Table, Column, Data, FieldType, FieldValue } from "../../../../features/ui"
import { TableName } from "../../../../tables"
import { define } from "../../../../utils/typeUtils"
import { Lot, Product, ProductName } from "../../../products"
import { useFind } from "../../../../state/reducers/hooks"
import { Operation } from "../../state/types"
import { useDescriptorHooks } from "../../../../features/data-types"

type Props = {
    rows: Data[]
    operation: Operation
    readOnly?: boolean
    disableCreate?: boolean
    onCreate?: () => void
    onEditRow?: (productId: string, lotId?: string) => void
    onRemoveRow?: (productId: string, lotId?: string) => void
    renderDetail?: (row: Data) => JSX.Element
}

export const OperationItemsTable = (props: Props) => {
    const { rows, operation, readOnly, disableCreate, onCreate, onEditRow, onRemoveRow, renderDetail } = props
    const isSale = operation === Operation.SALE

    const descriptorHooks = useDescriptorHooks()
    const find = useFind()

    const findProduct = (id?: string) => define(find(TableName.PRODUCTS, id)) as Product

    const productLotKey = (productId: string, lotId?: string) => lotId ? `${productId}:${lotId}` : productId

    const parseProductLotKey = (key: string): { productId?: string, lotId?: string } => {
        const keyParts = key.split(':')
        const productId = keyParts[0]
        let lotId: string | undefined = undefined
        if (keyParts.length === 2) {
            lotId = keyParts[1]
        }

        return { productId, lotId }
    }

    const columns: Column[] = [
        {
            name: 'productLotKey',
            type: FieldType.TEXT,
            label: 'Producto',
            render: (value?: FieldValue) => {
                const { productId, lotId } = parseProductLotKey(define(value) as string)
                const product = findProduct(productId)
                const lot = find(TableName.LOTS, lotId) as Lot | undefined
                return <ProductName product={product} lot={lot} showSupplierCode />
            }
        },
        {
            name: 'price',
            type: FieldType.PRICE,
            label: `Precio de ${isSale ? 'Lista' : 'Compra'}`,
        },
        {
            name: 'quantityDetail',
            type: FieldType.TEXT,
            label: 'Cantidad'
        },
        {
            name: 'totalPrice',
            type: FieldType.PRICE,
            label: 'Precio Total',
        }
    ]

    const itemRows = rows.map(row => {
        const productId = define(row.productId) as string
        const product = findProduct(productId)
        const lotId = row.lotId as string | undefined
        const quantity = row.quantity as number
        return {
            ...row,
            quantityDetail: descriptorHooks.product.unitsDetail(quantity, product),
            productLotKey: productLotKey(productId, lotId),
            totalPrice: (row.price as number) * (row.quantity as number)
        }
    })

    const title = itemRows.length === 0 ? 'Productos' : undefined

    const handleEditRow = (rowIdValue: string) => {
        const { productId, lotId } = parseProductLotKey(rowIdValue)
        onEditRow && onEditRow(define(productId), lotId)
    }

    const handleRemoveRow = (rowIdValue: string) => {
        const { productId, lotId } = parseProductLotKey(rowIdValue)
        onRemoveRow && onRemoveRow(define(productId), lotId)
    }

    return (
        <Section
            id='operationItems'
            title={title}
            space={12}
        >
            <Table
                columns={columns}
                rows={itemRows}
                rowId='productLotKey'
                readOnly={readOnly}
                createIcon="plus"
                disableCreate={disableCreate}
                small
                onCreate={onCreate}
                onEdit={handleEditRow}
                onRemove={handleRemoveRow}
                renderDetail={renderDetail}
            />
        </Section>
    )
}
