import styles from './paymentTypeName.module.css'
import { PaymentType } from '../../state/types'
import { Badge } from '../../../../components/badge/badge'
import { useDataTypesHooks } from '../../../../features/data-types'
import { FieldType } from '../../../../features/ui'

type Props = {
    paymentType?: PaymentType
}

export const PaymentTypeName = (props: Props) => {
    const { paymentType } = props
    if (!paymentType) {
        return <></>
    }
    
    const { formatValue } = useDataTypesHooks()

    const yieldLabel = formatValue(paymentType.yield, FieldType.PERCENTAGE)

    return (
        <div className={styles.container}>
            {paymentType.name}
            <Badge
                label={yieldLabel}
                small
                className={styles.badge}
            />
        </div>
    )
}
