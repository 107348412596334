import { useEffect, useState } from "react"
import { useLoad } from "./load"
import { PanelLoader } from "../../../ui"

export const IndexElement = () => {
    const [ready, setReady] = useState(false)

    const load = useLoad()

    useEffect(() => {
        load().then(() => setReady(true))
    }, [])
    
    return (
        <PanelLoader ready={ready}>
            <></>
        </PanelLoader>
    )
}
