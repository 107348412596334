import { BaseCallback } from "../actions/types"
import { DataType } from "../tables"

export const callSequentially = (
    fns: ((callback: BaseCallback) => void)[],
    onFinish: BaseCallback,
    index: number = 0
) => {
    if (index < fns.length) {
        const fn = fns[index]
        fn(() => {
            callSequentially(fns, onFinish, index + 1)
        })
    } else {
        onFinish()
    }
}

export const callSequentially_async = (
    fns: (() => Promise<void>)[],
    index: number = 0
): Promise<void> => {
    if (index < fns.length) {
        const fn = fns[index]
        return fn().then(() => callSequentially_async(fns, index + 1))
    } else {
        return new Promise(resolve => resolve())
    }
}

export const callSequentiallyForDataTypes = (
    dataTypes: DataType[],
    fn: (dataType: DataType, callback: BaseCallback) => void,
    onFinish: () => void
) => {
    const fns = dataTypes.map(dataType => {
        return (callback: BaseCallback) => fn(dataType, callback)
    })
    callSequentially(fns, onFinish)
}
