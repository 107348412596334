import { DataType } from '../../../tables'
import { toDate } from '../../../utils/typeUtils'
import { FieldType, FieldValue } from '../../ui'

export const toFieldTypeValue = (fieldType: FieldType, value?: FieldValue): FieldValue | undefined => {
    if (fieldType === FieldType.DATE) {
        return value ? toDate(value as string) : undefined
    } else {
        return value
    }
}

export const areDataTypesEqual = (dataType1: DataType, dataType2: DataType): boolean => {
    const props1 = Object.keys(dataType1)
    const props2 = Object.keys(dataType2)
    if (props1.length !== props2.length) {
        return false
    }

    for (const prop of props1) {
        const dataTypeProp = prop as keyof DataType
        if (dataType1[dataTypeProp] !== dataType2[dataTypeProp]) {
            return false
        }
    }

    return true
}

export const areDataTypesListsEqual = (dataTypes1: DataType[], dataTypes2: DataType[]) => {
    if (dataTypes1.length !== dataTypes2.length) {
        return false
    }

    const tuples = dataTypes1.map(dataType1 => [dataType1, dataTypes2.find(dataType2 => dataType2.id === dataType1.id)])
    return tuples.every(tuple => {
        const dataType1 = tuple[0]
        const dataType2 = tuple[1]
        return dataType1 && dataType2 && areDataTypesEqual(dataType1, dataType2)
    })
}
