import styles from './productName.module.css'
import { useFind } from '../../../../state/reducers/hooks'
import { define } from '../../../../utils/typeUtils'
import { TableName } from '../../../../tables'
import { Lot, Product } from '../../state/types'
import { Supplier } from '../../../purchases'
import { Badge } from '../../../../components/badge/badge'
import { NO_LOT } from '../../utils/lotUtils'

type Props = {
    product?: Product
    lot?: Lot
    showCode?: boolean
    showSupplierCode?: boolean
}

export const ProductName = (props: Props) => {
    const { product, lot, showCode, showSupplierCode } = props
    if (!product) {
        return <></>
    }
    
    const find = useFind()

    const codeLabel = `(#${product.code}) `
    const supplierCodeLabel = ` (${product.supplierCode}) `
    const lotLabel = lot?.code === NO_LOT ? lot.code : `Partida: ${lot?.code}`
    const supplier = define(find(TableName.SUPPLIERS, product.supplierId)) as Supplier

    return (
        <div className={styles.container}>
            {showCode && codeLabel}
            {product.name}
            {showSupplierCode && supplierCodeLabel}
            {product.loted && lot && (
                <Badge
                    label={lotLabel}
                    small
                    className={styles.badge}
                />
            )}
            <Badge
                label={supplier.name}
                small
                className={styles.badge}
            />
        </div>
    )
}
