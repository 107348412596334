import styles from './stockList.module.css'
import { FieldType, UIPermission, Action, Column, StockAction, FieldValue, Data, SpinnerDiv } from "../../../../features/ui"
import { useSystemStateHooks } from "../../../system"
import { define } from "../../../../utils/typeUtils"
import { PanelWrapper } from "../../../../components/wrappers/panel-wrapper/panelWrapper"
import { List } from "../../../../features/ui"
import { useActions } from "./actions"
import { DataType } from "../../../../tables"
import { Badge } from '../../../../components/badge/badge'
import { useStockHooks } from '../../hooks/stockHooks'
import { BranchStockTables } from '../branch-stock-tables/branchStockTables'
import { useDescriptorHooks } from '../../../../features/data-types'
import { StockFilters } from './stockFilters'
import { useState } from 'react'
import { Stock } from '../../state/types'
import { useNavigate } from 'react-router-dom'

export const StockList = () => {
    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)

    const { getBaseStock, getStockActionRoute } = useStockHooks()
    const { userHasPermission } = useSystemStateHooks()
    const navigate = useNavigate()
    const descriptorHooks = useDescriptorHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_STOCK)

    const { findStock, findProduct, findSupplier } = useActions()

    const columns: Column[] = [
        {
            name: 'productImageUrl',
            type: FieldType.IMAGE,
            label: ' '
        },
        {
            name: 'productCode',
            type: FieldType.TEXT,
            label: 'Código',
            render: (productCode?: FieldValue) => `#${productCode}`
        },
        {
            name: 'productId',
            type: FieldType.TEXT,
            label: 'Producto',
            render: (productId?: FieldValue) => findProduct(define(productId) as string).name
        },
        {
            name: 'productSupplier',
            type: FieldType.TEXT,
            label: 'Proveedor',
            render: (supplierId?: FieldValue) => {
                const supplier = findSupplier(define(supplierId) as string)
                return <Badge label={supplier.name} />
            }
        },
        {
            name: 'availableUnits',
            type: FieldType.NUMBER,
            label: 'Disponible',
            render: (stockId?: FieldValue) => {
                const stock = findStock(define(stockId) as string)
                const product = findProduct(define(stock.productId) as string)
                return descriptorHooks.product.availableStock(product, stock.branchId)
            }
        },
        {
            name: 'reservedUnits',
            type: FieldType.NUMBER,
            label: 'Reservado',
            render: (stockId?: FieldValue) => {
                const stock = findStock(define(stockId) as string)
                const product = findProduct(define(stock.productId) as string)
                return descriptorHooks.product.reservedStock(product, stock.branchId)
            }
        }
    ]

    const onView = (productId?: string) => {
        const baseStock = getBaseStock(define(productId))
        navigate(baseStock.id)
    }

    const onEdit = (stockAction: StockAction, productId?: string) => {
        const baseStock = getBaseStock(define(productId))
        const actionPath = getStockActionRoute(stockAction)
        navigate(`${baseStock.id}/${actionPath}`)
    }

    const onIncreaseStock = (productId?: string) => onEdit(StockAction.ADD, productId)
    const onDecreaseStock = (productId?: string) => onEdit(StockAction.SUBSTRACT, productId)

    const actions = (): Action[] => {
        const actionList: Action[] = [
            { icon: 'eye-fill', kind: 'tertiary', tooltip: 'Ver Detalle', onClick: onView },
        ] 
        if (!readOnly) {
            actionList.push(
                { icon: 'plus-circle-fill', tooltip: 'Alta manual', onClick: onIncreaseStock },
                { icon: 'dash-circle-fill', kind: 'secondary', tooltip: 'Baja manual', onClick: onDecreaseStock }
            )
        }

        return actionList
    }

    const renderDetail = (row: Data) => (
        <div className={styles.detailContainer}>
            <BranchStockTables productId={define(row.productId) as string} />
        </div>
    )

    const setRowsState = (stockList2: Stock[]) => {
        const rowsMap = stockList2.reduce((map, stock) => {
            const product = findProduct(stock.productId)
            const row = map.get(product.id)
            
            if (!row) {
                const newRow: Data = {
                    productImageUrl: product.imageUrl,
                    productCode: product.code,
                    productId: product.id,
                    productSupplier: product.supplierId,
                    availableUnits: stock.id,
                    reservedUnits: stock.id
                }
                map.set(product.id, newRow)
            }
    
            return map
        }, new Map<string, Data>())
        
        setRows(Array.from(rowsMap.values()))
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRowsState(dataTypes as Stock[])
        setAsyncDataLoaded(true)
    }

    return (
        <PanelWrapper title="Stock" wide>
            <StockFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone} />
            {renderAsync(
                <List
                    columns={columns}
                    rows={rows}
                    rowId="productId"
                    actions={actions}
                    readOnly={readOnly}
                    renderDetail={renderDetail}
                />
            )}
        </PanelWrapper>
    )
}
