import { FormField, Data } from "../types";

export const getDefaultData = (fields: FormField[]) => {
    const defaultData: Data = {}
    fields.forEach(field => {
        const defaultValue = field.value || field.defaultValue
        if (defaultValue) {
            defaultData[field.name] = defaultValue
        }
    })

    return defaultData
}
