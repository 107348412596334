import { Action, TableActions } from "../../../features/roles";
import { readPermissions } from '../../../permissions/permissions'
import { TableName } from '../../../tables'

export const SystemPolicyName = 'system'
export const SystemUserName = 'system.user'

export const systemPermissions = (): TableActions[] => readPermissions().concat([
    { tableName: TableName.ADMIN, actions: [Action.GET_ALL, Action.GET_BY_PARAMS, Action.GET_BY_IDS] },
    { tableName: TableName.COMPANIES, actions: [Action.GET_ALL, Action.GET_BY_PARAMS, Action.GET_BY_IDS, Action.SAVE] },
    { tableName: TableName.REQUESTS, actions: [Action.GET_ALL, Action.GET_BY_PARAMS, Action.GET_BY_IDS, Action.SAVE, Action.REMOVE] },
    { tableName: TableName.USERS, actions: [Action.GET_ALL, Action.GET_BY_PARAMS, Action.GET_BY_IDS, Action.SAVE, Action.REMOVE] }
])
