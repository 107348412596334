import { useEffect, useState } from 'react'
import { DataType } from '../../../../tables'
import { Expense, ExpenseStatus } from '../../state/types'
import { useAdministrationHooks, useAdministrationState } from '../../hooks/administrationHooks'
import { useAdministrationActions } from '../../actions/administrationActions'
import { FieldType, FieldValue, FilterField, Filters, option } from '../../../../features/ui'
import { expenseStatusLabel } from '../../../../tables/types/labels'
import { usePurchasesState } from '../../../purchases'
import { useManagerState } from '../../../manager'
import { define } from '../../../../utils/typeUtils'

type Props = {
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const ExpenseFilters = (props: Props) => {
    const { onSearchStart, onSearchDone } = props

    const administrationState = useAdministrationState()
    const stateExpenses = administrationState.expenses
    const expenseTypes = administrationState.expenseTypes

    const [expenses, setExpenses] = useState<Expense[]>()
    const [searchText, setSearchText] = useState<string>()
    const [status, setStatus] = useState<ExpenseStatus>()
    const [expenseTypeId, setExpenseTypeId] = useState<string>()
    const [supplierId, setSupplierId] = useState<string>()
    const [dateFrom, setDateFrom] = useState<Date>()
    const [dateTo, setDateTo] = useState<Date>()
    const [stateExpensesLength, setStateExpensesLength] = useState<number>(stateExpenses.length)

    const { suppliers } = usePurchasesState()
    const { currentBranch } = useManagerState()
    const currentBranchId = define(currentBranch).id

    const { getSalaryExpenseTypeId, searchExpenses } = useAdministrationHooks()

    const administrationActions = useAdministrationActions()

    const isNonSalaryExpense = (expense: Expense) => expense.expenseTypeId !== getSalaryExpenseTypeId()

    const fetchAndSearch = async () => {
        onSearchStart()
        const branchExpenses = await administrationActions().fetchAllExpenses(currentBranchId) as Expense[]
        const nonSalaryExpenses = branchExpenses.filter(isNonSalaryExpense)
        setExpenses(nonSalaryExpenses)
        search(nonSalaryExpenses, searchText, status, expenseTypeId, supplierId, dateFrom, dateTo)
    }

    useEffect(() => {
        if (stateExpenses.length < stateExpensesLength) {
            fetchAndSearch()
        }
        setStateExpensesLength(stateExpenses.length)
    }, [stateExpenses])

    useEffect(() => {
        fetchAndSearch()
    }, [currentBranchId])

    const search = (
        baseExpenseList: Expense[] = [],
        searchTextParam?: string,
        statusParam?: string,
        expenseTypeIdParam?: string,
        supplierIdParam?: string,
        dateFromParam?: Date,
        dateToParam?: Date
    ) => {
        onSearchStart()
        let expenseList = baseExpenseList.filter(expense => expense.branchId === currentBranchId)
        expenseList = expenseList.filter(expense => !statusParam || expense.status === statusParam)
        expenseList = expenseList.filter(expense => !expenseTypeIdParam || expense.expenseTypeId === expenseTypeIdParam)
        expenseList = expenseList.filter(expense => !supplierIdParam || expense.supplierId === supplierIdParam)
        expenseList = dateFromParam ? expenseList.filter(expense => new Date(expense.date) >= dateFromParam) : expenseList
        expenseList = dateToParam ? expenseList.filter(expense => new Date(expense.date) <= dateToParam) : expenseList
        expenseList = searchExpenses(expenseList, searchTextParam)
        setTimeout(() => onSearchDone(expenseList), 100)
    }

    const handleSearch = (value?: FieldValue) => {
        const newSearchText = value as string | undefined
        setSearchText(newSearchText)
        search(expenses, newSearchText, status, expenseTypeId, supplierId, dateFrom, dateTo)
    }

    const onChangeStatus = (value?: FieldValue) => {
        const newStatus = value as ExpenseStatus | undefined
        if (newStatus !== status) {
            setStatus(newStatus)
            search(expenses, searchText, newStatus, expenseTypeId, supplierId, dateFrom, dateTo)
        }
    }

    const onChangeExpenseType = (value?: FieldValue) => {
        const newExpenseTypeId = value as string | undefined
        if (newExpenseTypeId !== expenseTypeId) {
            setExpenseTypeId(newExpenseTypeId)
            search(expenses, searchText, status, newExpenseTypeId, supplierId, dateFrom, dateTo)
        }
    }

    const onChangeSupplier = (value?: FieldValue) => {
        const newSupplierId = value as string | undefined
        if (newSupplierId !== supplierId) {
            setSupplierId(newSupplierId)
            search(expenses, searchText, status, expenseTypeId, newSupplierId, dateFrom, dateTo)
        }
    }

    const onChangeDateFrom = (value?: FieldValue) => {
        const newDateFrom = value as Date | undefined
        if (newDateFrom !== dateFrom) {
            setDateFrom(newDateFrom)
            search(expenses, searchText, status, expenseTypeId, supplierId, newDateFrom, dateTo)
        }
    }
    
    const onChangeDateTo = (value?: FieldValue) => {
        const newDateTo = value as Date | undefined
        if (newDateTo !== dateTo) {
            setDateTo(newDateTo)
            search(expenses, searchText, status, expenseTypeId, supplierId, dateFrom, newDateTo)
        }
    }

    const searchField: FilterField = {
        name: 'search',
        type: FieldType.TEXT,
        placeholder: 'Buscar por código o nombre',
        icon: 'search',
        onChange: handleSearch
    }

    const supplierOptions = suppliers
        .filter(supplier => !supplier.productSupplier)
        .map(supplier => option(supplier.id, supplier.name))

    const filterFields: FilterField[] = [
        {
            name: 'status',
            type: FieldType.SELECT,
            options: Object.values(ExpenseStatus).map(status => option(status, expenseStatusLabel(status))),
            label: 'Estado',
            space: 2,
            onChange: onChangeStatus
        },
        {
            name: 'expenseType',
            type: FieldType.SELECT,
            options: expenseTypes.map(type => option(type.id, type.name)),
            label: 'Tipo',
            space: 3,
            onChange: onChangeExpenseType
        },
        {
            name: 'supplier',
            type: FieldType.SELECT,
            options: supplierOptions,
            label: 'Proveedor',
            space: 3,
            onChange: onChangeSupplier
        },
        {
            name: 'dateFrom',
            type: FieldType.DATE,
            label: 'Desde',
            space: 2,
            onChange: onChangeDateFrom
        },
        {
            name: 'dateTo',
            type: FieldType.DATE,
            label: 'Hasta',
            space: 2,
            onChange: onChangeDateTo
        }
    ]

    return (
        <Filters
            searchField={searchField}
            fields={filterFields}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
