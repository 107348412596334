import { TableName } from "../types/types"

export const MascPrefix = 'el'
export const FemPrefix = 'la'

export const dataTypePrefix = (tableName: TableName): string => {
    const femTableNames = [
        TableName.BRANCHES,
        TableName.CATEGORIES,
        TableName.ORDER_DELIVERIES,
        TableName.PURCHASE_ORDERS,
        TableName.SALE_ORDERS
    ]
    return femTableNames.includes(tableName) ? FemPrefix : MascPrefix
}
