import { PanelWrapper } from "../../../../../components/wrappers/panel-wrapper/panelWrapper"
import { DataTypeForm, validateTaxId } from "../../../../../features/data-types"
import { Data, FieldType, FormField, option, useGetCrumbs } from "../../../../../features/ui"
import { useActions } from "./actions"
import { Supplier } from "../../../state/types"
import { useEffect, useState } from "react"
import { useGenerateNextValue } from "../../../../../tables/hooks"
import { TableName } from "../../../../../tables"
import { TaxCategory } from "../../../../operations"
import { taxCategoryLabel } from "../../../../../tables/types/labels"
import { isDefined } from "../../../../../utils/typeUtils"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"

type Props = {
    productSupplier?: boolean
}

export const SupplierForm = (props: Props) => {
    const { dataType } = useRoutesHooks().parseUrl()
    const supplier = dataType as Supplier | undefined
    const createMode = !supplier
    const productSupplier = isDefined(props.productSupplier) ? !!props.productSupplier : (
        isDefined(supplier?.productSupplier) ? !!supplier?.productSupplier : true
    )

    const [code, setCode] = useState(supplier?.code || 1)

    const navigate = useNavigate()
    const generateNextValue = useGenerateNextValue()

    const { submitSupplier } = useActions()

    useEffect(() => {
        if (createMode) {
            const onGenerateNumber = (n: number) => setCode(n)
            generateNextValue(TableName.SUPPLIERS, 'code', onGenerateNumber)
        }
    }, [])

    const supplierLabel = supplier ? `: ${supplier.name}` : ''
    const title = `${createMode ? 'Crear' : 'Editar'} Proveedor${supplierLabel}`

    const crumbs = useGetCrumbs(
        'Proveedores',
        productSupplier ? RoutePath.SUPPLIERS : RoutePath.EXPENSE_SUPPLIERS,
        supplier?.name,
        undefined,
        createMode ? 'Crear' : 'Editar'
    )

    const fields: FormField[] = [
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre',
            defaultValue: supplier?.name,
        },
        {
            name: 'taxId',
            type: FieldType.NUMBER,
            label: 'CUIT / CUIL',
            validate: validateTaxId
        },
        {
            name: 'taxCategory',
            type: FieldType.SELECT,
            label: 'Cateogría Fiscal',
            options: Object.values(TaxCategory).map(taxCategory => option(taxCategory, taxCategoryLabel(taxCategory))),
            defaultValue: supplier?.taxCategory,
        },
        {
            name: 'address',
            type: FieldType.TEXT,
            label: 'Dirección',
            defaultValue: supplier?.address,
            optional: true
        }
    ]

    const onSubmit = (supplierData: Data) => {
        submitSupplier(supplierData, code, productSupplier, createMode)
    }

    const onCancel = () => navigate(-1)

    return (
        <PanelWrapper title={title} crumbs={crumbs}>
            <DataTypeForm
               formId="supplier-form"
               fields={fields}
               createMode={createMode}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
        </PanelWrapper>
    )
}
