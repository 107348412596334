import { Currency } from "../../../tables/types/types"
import { OrderStatus } from "../../operations"
import { IdentifierType } from "../../system"

export type Budget = {
    companyId: string
    id: string
    code: number
    date: string
    totalPrice: number
    currency: Currency
    customerId: string
    sellerId: string
}

export type BudgetItem = {
    companyId: string
    id: string
    budgetId: string
    productId: string
    quantity: number
    price: number
    currency: Currency
}

export type SaleOrder = {
    companyId: string
    id: string
    code: number
    creationDate: string
    totalPrice: number
    currency: Currency
    deliveryDate: string
    paymentTypeId: string
    sellerId: string
    customerId: string
    status: OrderStatus
    branchId: string
}

export type SaleItem = {
    companyId: string
    id: string
    saleId: string
    productId: string
    lotId: string
    quantity: number
    price: number
    currency: Currency
}

export enum HoardType {
    FINANCIAL = 'Financial',
    PRODUCT = 'Product'
}

export type PurchasePricesList = {
    [key: string]: number
}

export type HoardOrder = {
    companyId: string
    id: string
    code: number
    type: HoardType
    value?: number
    totalPrice: number
    currency: Currency
    creationDate: string
    dueDate: string
    paymentTypeId: string
    sellerId: string
    customerId: string
    status: OrderStatus
    purchasePricesList: PurchasePricesList
    branchId: string
}

export type HoardItem = {
    companyId: string
    id: string
    hoardId: string
    productId: string
    lotId: string
    quantity: number
    price: number
    currency: Currency
}

export type CustomerType = {
    companyId: string
    id: string
    name: string
    yield: number
}

export enum CustomerCategory {
    NATURAL = 'Natural',
    LEGAL = 'Legal'
}

export type Customer = {
    companyId: string
    id: string
    name: string
    identifierType: IdentifierType
    identifier: number
    category: CustomerCategory
    customerTypeId: string
    email?: string
    phone?: number
    address?: string
}
