import { useEffect, useState } from "react"
import { useLoad } from "./load"
import { PanelLoader } from "../../../ui/components/panel-loader/panelLoader"
import { AccessRequestPanel } from "../../../../modules/system"

export const AccessRequestElement = () => {  
  const [ready, setReady] = useState(false)

  const load = useLoad()

  useEffect(() => {
    load().then(() => setReady(true))
  }, [])

  return (
    <PanelLoader ready={ready}>
      <AccessRequestPanel />      
    </PanelLoader>
  )
}
