import { Data } from "../types"

export const validateIdentifier = (data: Data): string | null => {
    const value = data.identifier?.toString()
    return value && !/^\d{7,8}$/.test(value) ? 'El valor debe contener de 7 a 8 dígitos numéricos' : null
}

export const validateTaxId = (data: Data): string | null => {
    const value = data.taxId?.toString()
    return value && !/^\d{11}$/.test(value) ? 'El valor debe contener 11 dígitos numéricos' : null
}

export const validatePhone = (data: Data): string | null => {
    const value = data.phone?.toString()
    return value && !/^\d{8,15}$/.test(value) ? 'El valor debe contener de 8 a 15 dígitos numéricos' : null
}
