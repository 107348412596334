import styles from './lotsStockTable.module.css'
import { Section } from "../../../../components/section/section"
import { FieldType, Column, Table, FieldValue } from "../../../../features/ui"
import { useStockHooks } from "../../hooks/stockHooks"
import { TableName } from '../../../../tables'
import { useFind } from '../../../../state/reducers/hooks'
import { Lot, Product } from '../../../products'
import { Branch } from '../../../manager'
import { define } from '../../../../utils/typeUtils'
import { useDescriptorHooks } from '../../../../features/data-types'

type Props = {
    productId: string
    branch: Branch
    className?: string
}

export const LotsStockTable = (props: Props) => {
    const { productId, branch, className } = props
    
    const { getBranchStock } = useStockHooks()
    const stockList = getBranchStock(branch.id)
    const descriptorHooks = useDescriptorHooks()
    const find = useFind()

    const product = define(find(TableName.PRODUCTS, productId)) as Product

    const findLot = (id: string) => define(find(TableName.LOTS, id)) as Lot

    const detailColumns: Column[] = [
        { name: 'lot', type: FieldType.TEXT, label: 'Partida' },
        {
            name: 'availableUnits',
            type: FieldType.TEXT,
            label: 'Disponible',
            render: (lotId?: FieldValue) => {
                const lot = findLot(define(lotId) as string)
                return descriptorHooks.product.lot.availableStock(product, branch.id, lot)
            }
        },
        {
            name: 'reservedUnits',
            type: FieldType.TEXT,
            label: 'Reservado',
            render: (lotId?: FieldValue) => {
                const lot = findLot(define(lotId) as string)
                return descriptorHooks.product.lot.reservedStock(product, branch.id, lot)
            }
        }
    ]

    const productStockList = stockList.filter(stock => stock.productId === productId)
    const detailRows = productStockList
        .filter(stock => productStockList.length === 1 || stock.availableUnits + stock.reservedUnits > 0)
        .sort((stock1, stock2) => {
            if (stock1.lotId && stock2.lotId) {
                return stock1.lotId.localeCompare(stock2.lotId)
            } else {
                return stock1.lotId ? -1 : 1
            }
        })
        .map(stock => {
            const lot = define(find(TableName.LOTS, stock.lotId)) as Lot
            return {
                lot: lot.code,
                availableUnits: stock.lotId,
                reservedUnits: stock.lotId
            }
        })
    
    return (
        <Section
            id="stock-list-detail"
            space={12}
            className={`${className} ${styles.section}`}
            smallFont
        >
            <Table
                columns={detailColumns}
                rows={detailRows}
                rowId="lot"
                readOnly
            />
        </Section>
    )
}
