import { Employee, Expense, ExpenseType, PaymentType, Settings } from "../../modules/administration"
import { Branch } from "../../modules/manager"
import { OrderDelivery } from "../../modules/operations"
import { Measure, Product, ProductPrices, Category } from "../../modules/products"
import { PurchaseItem, PurchaseOrder, Supplier } from "../../modules/purchases"
import { Budget, BudgetItem, Customer, CustomerType, HoardItem, HoardOrder, SaleItem, SaleOrder } from "../../modules/sales"
import { Stock, UnitType } from "../../modules/stock"
import { Company, Request, User } from "../../modules/system"

export type DataType =
    Company |
    UnitType |
    User |
    TenantDataType

export type TenantDataType =
    Branch |
    Budget |
    BudgetItem |
    Category |
    Customer |
    CustomerType |
    ExpenseType |
    Expense |
    HoardItem |
    HoardOrder |
    Measure |
    OrderDelivery |
    PaymentType |
    Product |
    ProductPrices |
    PurchaseItem |
    PurchaseOrder |
    Request |
    SaleItem |
    SaleOrder |
    Settings |
    Stock |
    Supplier

export type NamedDataType =
    Branch |
    Category |
    CustomerType |
    Company |
    Customer |
    Employee |
    PaymentType |
    Product |
    Supplier |
    UnitType |
    User

export type MeasureableDataType =
    Product

export type NumberProp = {
    n?: number
}

export enum TableName {
    ADMIN = 'Admin',
    BRANCHES = 'Branches',
    BUDGETS = 'Budgets',
    BUDGET_ITEMS = 'BudgetItems',
    CASH_FUNDS = 'CashFunds',
    CATEGORIES = 'Categories',
    COMPANIES = 'Companies',
    CUSTOMERS = 'Customers',
    CUSTOMER_TYPES = 'CustomerTypes',
    EMPLOYEES = 'Employees',
    EXPENSE_TYPES = 'ExpenseTypes',
    EXPENSES = 'Expenses',
    HOARD_ITEMS = 'HoardItems',
    HOARD_ORDERS = 'HoardOrders',
    LOTS = 'Lots',
    MEASURES = 'Measures',
    ORDER_DELIVERIES = 'OrderDeliveries',
    PAYMENT_TYPES = 'PaymentTypes',
    PRODUCTS = 'Products',
    PRODUCT_PRICES = 'ProductPrices',
    PURCHASE_ITEMS = 'PurchaseItems',
    PURCHASE_ORDERS = 'PurchaseOrders',
    REQUESTS = 'Requests',
    SALE_ITEMS = 'SaleItems',
    SALE_ORDERS = 'SaleOrders',
    SETTINGS = 'Settings',
    STOCK = 'Stock',
    SUPPLIERS = 'Suppliers',
    TRANSACTIONS = 'Transactions',
    UNIT_TYPES = 'UnitTypes',
    USERS = 'Users'
}

export enum Currency {
    ARS = 'ARS',
    USD = 'USD'
}
