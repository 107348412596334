import { useState } from "react"
import { FieldType, UIPermission, Action, Column, FieldValue, SpinnerDiv, Data } from "../../../../../features/ui"
import { DataType, TableName } from "../../../../../tables"
import { useSystemStateHooks } from "../../../../system"
import { PurchaseOrder, Supplier } from "../../../state/types"
import { useFind } from "../../../../../state/reducers/hooks"
import { define } from "../../../../../utils/typeUtils"
import { PanelWrapper } from "../../../../../components/wrappers/panel-wrapper/panelWrapper"
import { DataTypeList } from "../../../../../features/data-types"
import { orderStatusLabel } from "../../../../../tables/types/labels"
import { OrderStatus } from "../../../../operations"
import { OpenWizardModal, useWizardActions } from "../../../../../features/wizard"
import { purchaseWizardKey } from "../../../utils/purchaseUtils"
import { Badge } from "../../../../../components/badge/badge"
import { Branch } from "../../../../manager"
import { PurchaseFilters } from "./purchaseFilters"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "../../../../../features/routes"

export const PurchaseList = () => {
    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [currentPurchase, setCurrentPurchase] = useState<PurchaseOrder>()
    const wizardKey = currentPurchase ? purchaseWizardKey(currentPurchase) : undefined

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_PURCHASES)
    const hasDeliveryPermission = userHasPermission(UIPermission.SAVE_PURCHASE_DELIVERIES)
    const navigate = useNavigate()
    const find = useFind()

    const { goToWizard } = useWizardActions()

    const deliveryPath = (purchase?: PurchaseOrder) => purchase ? `${purchase.id}/${RoutePath.DELIVERY}` : '#'

    const setRowsState = (purchases: PurchaseOrder[]) => {
        setRows(purchases)
        setAsyncDataLoaded(true)
    }

    const columns: Column[] = [
        { name: 'code', type: FieldType.NUMBER, label: '#', render: (value?: FieldValue) => `#${value}` },
        {
            name: 'deliveryBranchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            label: 'Sucursal de Recepción',
            render: (value?: FieldValue) => {
                const branch = define(find(TableName.BRANCHES, value as string)) as Branch
                return <Badge label={branch.name} />
            }
        },
        { name: 'deliveryDate', type: FieldType.DATE, label: 'Fecha de Recepción' },
        {
            name: 'supplierId',
            type: FieldType.TABLE,
            table: TableName.SUPPLIERS,
            label: 'Proveedor',
            render: (value?: FieldValue) => {
                const supplier = define(find(TableName.SUPPLIERS, value as string)) as Supplier
                return <Badge label={supplier.name} />
            }
        },
        { name: 'totalPrice', type: FieldType.PRICE, label: 'Precio' },
        { name: 'status', type: FieldType.TEXT, label: 'Estado', render: (value?: FieldValue) => orderStatusLabel(define(value) as OrderStatus) }
    ]

    const findPurchase = (code?: string) => define(find(TableName.PURCHASE_ORDERS, code, 'code') as PurchaseOrder)

    const onView = (code?: string) => {
        const purchase = findPurchase(code)
        if (purchase) {
            navigate(purchase.id)
        }
    }

    const onDeliver = (code?: string) => {
        const purchase = findPurchase(code)
        setCurrentPurchase(purchase)
        goToWizard(purchaseWizardKey(purchase), deliveryPath(purchase))
    }

    const onArchive = (code?: string) => {
        findPurchase(code)
    }

    const actions = (code?: string) => {
        const actionList: Action[] = [
            { icon: 'eye-fill', kind: 'tertiary', tooltip: 'Ver Detalle', onClick: onView }
        ]
        if (hasDeliveryPermission) {
            const purchase = findPurchase(code)
            const archiveAction = { icon: 'archive', kind: 'secondary', tooltip: 'Archivar', onClick: onArchive }
            const deliverAction = { icon: 'truck', tooltip: 'Registrar Recepción', onClick: onDeliver }
            actionList.push(purchase.status === OrderStatus.DELIVERED ? archiveAction : deliverAction)
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRowsState(dataTypes as PurchaseOrder[])
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <PanelWrapper title="Compras" wide>
            <PurchaseFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone}/>
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    rowId="code"
                    currentDataTypeParam={currentPurchase}
                    actions={actions}
                    readOnly={readOnly}
                />
            )}
            <OpenWizardModal
                wizardKey={wizardKey}
                path={deliveryPath(currentPurchase)}
            />
        </PanelWrapper>
    )
}
