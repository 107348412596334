import { ReducerName } from "../state/reducers/types"
import { TableData } from "./tableData"
import { TableName } from "./types/types"

export const TablesData = new Map<TableName, TableData>()

export const initTablesData = () => {
    registerTableData(TableName.ADMIN, false, ReducerName.SYSTEM_REDUCER, 'construfyAdminId', 'Admin', 'Admin')
    registerTableData(TableName.BRANCHES, true, ReducerName.MANAGER_REDUCER, 'branches', 'Sucursal', 'Branches')
    registerTableData(TableName.BUDGET_ITEMS, true, ReducerName.SALES_REDUCER, 'budgetItems', 'Item de Presupuesto', 'BudgetItems')
    registerTableData(TableName.BUDGETS, true, ReducerName.SALES_REDUCER, 'budgets', 'Presupuesto', 'Budgets')
    registerTableData(TableName.CASH_FUNDS, true, ReducerName.ADMINISTRATION_REDUCER, 'cashFunds', 'Caja', 'CashFunds')
    registerTableData(TableName.CATEGORIES, true, ReducerName.PRODUCTS_REDUCER, 'categories', 'Categoría', 'Categories')
    registerTableData(TableName.COMPANIES, false, ReducerName.SYSTEM_REDUCER, 'company', 'Compañía', 'Companies')
    registerTableData(TableName.CUSTOMERS, true, ReducerName.SALES_REDUCER, 'customers', 'Cliente', 'Customers')
    registerTableData(TableName.CUSTOMER_TYPES, true, ReducerName.SALES_REDUCER, 'customerTypes', 'Tipo de Cliente', 'CustomerTypes')
    registerTableData(TableName.EMPLOYEES, true, ReducerName.ADMINISTRATION_REDUCER, 'employees', 'Empleado', 'Employees')
    registerTableData(TableName.EXPENSE_TYPES, true, ReducerName.ADMINISTRATION_REDUCER, 'expenseTypes', 'Tipo de Gasto', 'ExpenseTypes')
    registerTableData(TableName.EXPENSES, true, ReducerName.ADMINISTRATION_REDUCER, 'expenses', 'Gasto', 'Expenses')
    registerTableData(TableName.HOARD_ITEMS, true, ReducerName.SALES_REDUCER, 'hoardItems', 'Item de Acopio', 'HoardItems')
    registerTableData(TableName.HOARD_ORDERS, true, ReducerName.SALES_REDUCER, 'hoards', 'Acopio', 'HoardOrders')
    registerTableData(TableName.LOTS, true, ReducerName.PRODUCTS_REDUCER, 'lots', 'Partida', 'Lots')
    registerTableData(TableName.MEASURES, true, ReducerName.PRODUCTS_REDUCER, 'measures', 'Medida', 'Measures')
    registerTableData(TableName.ORDER_DELIVERIES, true, ReducerName.OPERATIONS_REDUCER, 'deliveries', 'Entregas', 'OrderDeliveries')
    registerTableData(TableName.PAYMENT_TYPES, true, ReducerName.ADMINISTRATION_REDUCER, 'paymentTypes', 'Medio de Pago', 'PaymentTypes')
    registerTableData(TableName.PRODUCTS, true, ReducerName.PRODUCTS_REDUCER, 'products', 'Producto', 'Products')
    registerTableData(TableName.PRODUCT_PRICES, true, ReducerName.PRODUCTS_REDUCER, 'productPrices', '', 'ProductPrices')
    registerTableData(TableName.PURCHASE_ITEMS, true, ReducerName.PURCHASES_REDUCER, 'purchaseItems', 'Item de Compra', 'PurchaseItems')
    registerTableData(TableName.PURCHASE_ORDERS, true, ReducerName.PURCHASES_REDUCER, 'purchases', 'Compra', 'PurchaseOrders')
    registerTableData(TableName.REQUESTS, true, ReducerName.SYSTEM_REDUCER, 'requests', 'Solicitud de Acceso', 'Requests')
    registerTableData(TableName.SALE_ITEMS, true, ReducerName.SALES_REDUCER, 'saleItems', 'Item de Venta', 'SaleItems')
    registerTableData(TableName.SALE_ORDERS, true, ReducerName.SALES_REDUCER, 'sales', 'Venta', 'SaleOrders')
    registerTableData(TableName.SETTINGS, true, ReducerName.ADMINISTRATION_REDUCER, 'settings', 'Configuración', 'Settings')
    registerTableData(TableName.SUPPLIERS, true, ReducerName.PURCHASES_REDUCER, 'suppliers', 'Proveedor', 'Suppliers')
    registerTableData(TableName.STOCK, true, ReducerName.STOCK_REDUCER, 'stock', 'Stock', 'Stock')
    registerTableData(TableName.TRANSACTIONS, true, ReducerName.ADMINISTRATION_REDUCER, 'transactions', 'Movimiento', 'Transactions')
    registerTableData(TableName.UNIT_TYPES, false, ReducerName.STOCK_REDUCER, 'unitTypes', 'Unidad De Medida', 'UnitTypes')
    registerTableData(TableName.USERS, false, ReducerName.SYSTEM_REDUCER, 'user', 'Usuario', 'Users')
}

const registerTableData = (
    tableName: TableName,
    hasTKey: boolean,
    reducerName: ReducerName,
    stateName: string,
    singular: string,
    dbTableName: string
) => {
    const data = new TableData(
        tableName,
        hasTKey,
        reducerName,
        stateName,
        singular,
        dbTableName
    )
    TablesData.set(tableName, data)
}
