import { useDefaultModuleActions } from "../../../actions/defaultModuleActions"
import { BaseCallback, ItemCallback } from "../../../actions/types"
import { Emitter, Event } from "../../../features/event-emitter"
import { DynamoUserApi } from "../../../services"
import { DataType, TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { Company, Request, RequestStatus, useSystemActions, useSystemStateHooks } from "../../system"
import { Branch } from "../state/types"

export const useManagerActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId
    
    const defaultModuleActions = useDefaultModuleActions()
    const systemActions = useSystemActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const { fetchAll, save, remove } = defaultModuleActions(DynamoUserApi, define(companyId))

        const saveCompany = systemActions().saveCompany

        const fetchAllBranches = async () => {
            return fetchAll(TableName.BRANCHES).then(response => response.dataTypes as Branch[])
        }

        const saveBranch = (branch: Branch, callback?: ItemCallback) => {
            save(TableName.BRANCHES, branch, callback)
        }
        
        const removeBranch = (id: string, callback?: BaseCallback) => {
            remove(TableName.BRANCHES, id, callback)
        }

        const resolveRequest = (
            request: Request,
            company: Company,
            resolution: RequestStatus,
            saveState = true,
            callback?: ItemCallback
        ) => {
            const onSaveRequest = (requestDT: DataType) => {
                Emitter.emit(Event.RESOLVE_REQUEST, requestDT as Request)
                callback && callback(requestDT)
            }

            const resolvedRequest: Request = {
                ...request as Request,
                status: resolution
            }
            systemActions(company.id).saveRequest(resolvedRequest, saveState, onSaveRequest)
        }

        return {
            saveCompany,
            fetchAllBranches,
            saveBranch,
            removeBranch,
            resolveRequest,
        }
    }
}
