import { useEffect, useState } from 'react'
import { useFind } from '../../../state/reducers/hooks'
import { TableName } from '../../../tables'
import { identifier } from '../../../tables/utils'
import { define, isDefined, toNumber } from '../../../utils/typeUtils'
import { FieldProps, NativeType } from '../../types'
import { isTypeNative } from '../../utils'
import { FormField } from '../form-field/formField'
import { FieldType } from '../../../features/ui'

type Props = {
    field: FieldProps
    editing: boolean
    onChange: (newValue?: NativeType) => void
}

export const EditableField = (props: Props) => {
    const { field, editing, onChange } = props
    const { name, type, table, value, defaultValue, min, max, allowDecimals } = field
    const [stateValue, setStateValue] = useState(isDefined(value) ? value : defaultValue)
    const find = useFind()

    const reset = () => {
        setStateValue(isDefined(value) ? value : defaultValue)
    }

    useEffect(reset, [value, editing])

    const onChangeField = (newValue?: NativeType) => {
        setStateValue(newValue)
        onChange(newValue)
    }

    const getValueLabel = () => {
        let valuelLabel = stateValue as string
        if (type && !isTypeNative(type)) {
            const tableName = define(field.table) as TableName
            const dataType = define(find(tableName, valuelLabel))
            valuelLabel = define(identifier(tableName, dataType))
        } else if (type === FieldType.PRICE) {
            const price = define(toNumber(valuelLabel))
            valuelLabel = `$ ${price.toFixed(2)}`
        } else if (type === FieldType.PERCENTAGE) {
            valuelLabel = `${valuelLabel}%`
        }

        return <label>{valuelLabel}</label>
    }

    return editing ? (
        <FormField
            name={name}
            type={type}
            table={table}
            defaultValue={stateValue}
            min={min}
            max={max}
            allowDecimals={allowDecimals}
            onChange={onChangeField}
        />
    ) : (
        <span>{getValueLabel()}</span>
    )
}
