import { useEffect, useState } from "react"
import { PanelWrapper } from "../../../../../components/wrappers/panel-wrapper/panelWrapper"
import { FormField, Data, useUIStateActions, ModalId, useUIActions, FieldValue, FieldType, option, useGetCrumbs } from "../../../../../features/ui"
import { TableName } from "../../../../../tables"
import { useActions } from "./actions"
import { define } from "../../../../../utils/typeUtils"
import { DataTypeForm } from "../../../../../features/data-types"
import { Product } from "../../../../products"
import { useGenerateNextValue } from "../../../../../tables/hooks"
import { Validatable } from "../../../../../features/ui"
import { Operation, OperationItemFormModal, OperationItemsTable, usePricesHooks, PriceDetail } from '../../../../operations'
import { useFind } from "../../../../../state/reducers/hooks"
import { Supplier } from "../../../state/types"
import { useManagerState } from "../../../../manager"
import { usePurchasesState } from "../../../hooks/purchasesHooks"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "../../../../../features/routes"

export const PurchaseForm = () => {
    const { currentBranch } = useManagerState()
    const { suppliers } = usePurchasesState()
    
    const [code, setCode] = useState(1)
    const [supplier, setSupplier] = useState<Supplier>()
    const [branchId, setBranchId] = useState<string>()
    const [purchaseItemsData, setPurchaseItemsData] = useState<Data[]>([])
    const [currentPurchaseItem, setCurrentPurchaseItem] = useState<Data>()
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [validations, setValidations] = useState<string[]>([])

    const pricesHooks = usePricesHooks()
    const generateNextValue = useGenerateNextValue()
    const navigate = useNavigate()
    const find = useFind()

    const { submitPurchase } = useActions()
    const { setDisableBranchSelector } = useUIStateActions()
    const { toggleModal } = useUIActions()

    const crumbs = useGetCrumbs('Compras', RoutePath.PURCHASES, undefined, undefined, 'Crear')

    useEffect(() => {
        setDisableBranchSelector(true)
        
        const onGenerateNumber = (n: number) => setCode(n)
        generateNextValue(TableName.PURCHASE_ORDERS, 'code', onGenerateNumber)
        
        return () => {
            setDisableBranchSelector(false)
        }
    }, [])

    useEffect(() => {
        const purchasePriceItems = purchaseItemsData.map(data => ({ productId: data.productId as string, quantity: data.quantity as number }))
        const fullPrice = pricesHooks.purchase.fullPrice(purchasePriceItems)
        setTotalPrice(fullPrice)
    }, [purchaseItemsData])

    const filterProducts = (product: Product) =>
        product.supplierId === supplier?.id &&
        (currentPurchaseItem?.productId === product.id || !purchaseItemsData.map(saleItemData => saleItemData.productId).includes(product.id))

    const supplierOptions = suppliers
        .filter(supplier => supplier.productSupplier)
        .map(supplier => option(supplier.id, supplier.name))
    
    const openFormModal = () => toggleModal(ModalId.OPERATION_ITEM_FORM)

    const purchaseItemMatches = (data: Data, productId: string) => data.productId === productId

    const onSupplierChange = (value?: FieldValue) => {
        const newSupplier = find(TableName.SUPPLIERS, value as string | undefined) as Supplier | undefined
        setSupplier(newSupplier)
        setPurchaseItemsData([])
    }

    const onBranchChange = (value?: FieldValue) => {
        setBranchId(value as string | undefined)
    }

    const onCreatePurchaseItem = () => {
        setCurrentPurchaseItem(undefined)
        setValidations([])
        openFormModal()
    }

    const onEditPurchaseItem = (productId: string) => {
        setCurrentPurchaseItem(purchaseItemsData.find(saleItemData => purchaseItemMatches(saleItemData, productId)))
        openFormModal()
    }

    const onRemovePurchaseItem = (productId: string) => {
        const newPurchaseItemsData = purchaseItemsData.filter(saleItemData => !purchaseItemMatches(saleItemData, productId))
        setPurchaseItemsData(newPurchaseItemsData)
    }

    const fields: FormField[] = [
        {
            name: 'supplierId',
            type: FieldType.TABLE,
            table: TableName.SUPPLIERS,
            label: 'Proveedor',
            options: supplierOptions,
            onChange: onSupplierChange
        },
        {
            name: 'deliveryBranchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            defaultValue: currentBranch?.id,
            label: 'Sucursal de Recepción',
            onChange: onBranchChange
        },
        {
            name: 'deliveryDate',
            type: FieldType.DATE,
            label: 'Fecha de Recepción',
            min: new Date()
        },
        {
            name: 'purchaseItems',
            type: FieldType.TABLE,
            table: TableName.PURCHASE_ITEMS,
            render: () => (
                <Validatable validations={validations}>
                    <OperationItemsTable
                        rows={purchaseItemsData}
                        operation={Operation.PURCHASE}
                        disableCreate={!supplier}
                        onCreate={onCreatePurchaseItem}
                        onEditRow={onEditPurchaseItem}
                        onRemoveRow={onRemovePurchaseItem}
                    />
                </Validatable>
            )
        },
        {
            name: 'totalPrice',
            type: FieldType.PRICE,
            label: 'Precio',
            render: () => <PriceDetail
                subtotal={totalPrice}
                total={totalPrice}
            />
        }
    ]

    const validate = (): boolean => {
        if (purchaseItemsData.length === 0) {
            setValidations(['Debe agregar al menos 1 producto'])
            return false
        }

        return true
    }

    const onSubmit = (purchaseData: Data) => {
        if (validate()) {
            submitPurchase(
                purchaseData,
                code,
                define(totalPrice),
                purchaseItemsData
            )
        }
    }

    const onCancel = () => navigate(-1)

    const onSavePurchaseItem = (newData: Data) => {
        let newPurchaseItemsData = purchaseItemsData
        if (currentPurchaseItem) {
            const { productId } = currentPurchaseItem
            newPurchaseItemsData = purchaseItemsData.map(saleItemData => purchaseItemMatches(saleItemData, define(productId) as string) ? newData : saleItemData)  
        } else {
            newPurchaseItemsData = [newData, ...purchaseItemsData]
        }
        setPurchaseItemsData(newPurchaseItemsData)
    }
    
    return (
        <PanelWrapper title="Crear Compra" crumbs={crumbs} wide>
            <DataTypeForm
               formId="purchase-form"
               fields={fields}
               createMode
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
            <OperationItemFormModal
                operationItem={currentPurchaseItem}
                operation={Operation.PURCHASE}
                branchId={branchId}
                filterProducts={filterProducts}
                onSubmit={onSavePurchaseItem}
            />
        </PanelWrapper>
    )
}
