import { DataType, TableName } from '../../../../../tables'
import { Data, UIPermission, DetailPanel, DetailFieldProps, FieldType, FieldValue, Spinner, Action, Tab, useGetCrumbs } from '../../../../../features/ui'
import { define } from '../../../../../utils/typeUtils'
import { useEffect, useState } from 'react'
import { Operation, OperationItemsTable, OrderDelivery, OrderStatus, PriceDetail, useOperationsActions, DeliveryList, PendingItemsList } from '../../../../operations'
import { useSystemStateHooks } from '../../../../system'
import { PurchaseItem, PurchaseOrder } from '../../../state/types'
import { purchaseWizardKey } from '../../../utils/purchaseUtils'
import { usePurchasesActions } from '../../../actions/purchasesActions'
import { OpenWizardModal, useWizardActions } from '../../../../../features/wizard'
import { orderStatusLabel } from '../../../../../tables/types/labels'
import { RoutePath, useRoutesHooks } from '../../../../../features/routes'
import { useDescriptorHooks } from '../../../../../features/data-types'

export const PurchaseDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const purchase = define(dataType) as PurchaseOrder
  const wizardKey = purchaseWizardKey(purchase)

  const [purchaseItems, setPurchaseItems] = useState<PurchaseItem[]>([])
  const [deliveries, setDeliveries] = useState<OrderDelivery[]>([])
  const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
  
  const { userHasPermission } = useSystemStateHooks()
  const descriptorHooks = useDescriptorHooks()

  const { goToWizard } = useWizardActions()
  const purchasesActions = usePurchasesActions()
  const operationsActions = useOperationsActions()

  const readOnly = !userHasPermission(UIPermission.SAVE_PURCHASES)

  useEffect(() => {
    purchasesActions().fetchPurchaseItemsByOrder(purchase.id).then((purchaseItemsDT: DataType[]) => {
      setPurchaseItems(purchaseItemsDT as PurchaseItem[])
      operationsActions().fetchDeliveries(purchase.id).then((deliveriesDT: DataType[]) => {
        setDeliveries(deliveriesDT as OrderDelivery[])
        setAsyncDataLoaded(true)
      })
    })
  }, [])

  const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

  const crumbs = useGetCrumbs('Compras', RoutePath.PURCHASES, descriptorHooks.purchase.code(purchase))

  const fields: DetailFieldProps[] = [
    { name: 'supplierId', type: FieldType.TABLE, table: TableName.SUPPLIERS, label: 'Proveedor' },
    { name: 'deliveryBranchId', type: FieldType.TABLE, table: TableName.BRANCHES, label: 'Sucursal de Entrega' },
    { name: 'deliveryDate', type: FieldType.DATE, label: 'Fecha de Entrega' },
    {
      name: 'purchaseItems',
      type: FieldType.TABLE,
      table: TableName.PURCHASE_ITEMS,
      render: () => renderAsync(
        <OperationItemsTable
            rows={purchaseItems as Data[]}
            operation={Operation.PURCHASE}
            readOnly
        />
      )
    },
    {
      name: 'totalPrice',
      type: FieldType.PRICE,
      label: 'Precio',
      render: () => (
        <PriceDetail
          subtotal={purchase.totalPrice}
          total={purchase.totalPrice}
        />
      )
    },
    {
      name: 'status',
      type: FieldType.TEXT,
      label: 'Estado',
      render: (value?: FieldValue) => <>{orderStatusLabel(define(value) as OrderStatus)}</>
    }
  ]
  if (purchase.status !== OrderStatus.DELIVERED && deliveries.length > 0) {
    const pendingItemsField = {
      name: 'pendingItems',
      type: FieldType.TABLE,
      table: TableName.PURCHASE_ITEMS,
      render: () => renderAsync(
        <PendingItemsList orderId={purchase.id} operation={Operation.PURCHASE} />
      )
    }
    fields.splice(4, 0, pendingItemsField)
  }

  const onDeliver = () => {
    goToWizard(purchaseWizardKey(purchase), RoutePath.DELIVERY)
  }

  const onArchive = () => {}

  const actions: Action[] = []
  if (!readOnly) {
    const archiveAction = { label: 'Archivar', icon: 'archive', kind: 'secondary', onClick: onArchive }
    const deliverAction = { label: 'Registrar Recepción', icon: 'truck', onClick: onDeliver }
    actions.push(purchase.status === OrderStatus.DELIVERED ? archiveAction : deliverAction)
  }

  const tabs: Tab[] = deliveries.length > 0 ? [
    {
      id: 'deliveries',
      title: 'Entregas',
      content: <DeliveryList
        orderId={purchase.id}
        operation={Operation.PURCHASE}
      />
    }
  ] : []

  return (<>
    <DetailPanel
      tableName={TableName.PURCHASE_ORDERS}
      crumbs={crumbs}
      fields={fields}
      data={purchase}
      icon='cart3'
      imgSpace={3}
      tabs={tabs}
      readOnly={readOnly}
      actions={actions}
    />
    <OpenWizardModal
      wizardKey={wizardKey}
      path={RoutePath.DELIVERY}
    />
  </>)
}
