import { useState } from "react"
import { FieldType, ModalId, UIPermission, useUIActions, Action, Column, FieldValue, Data, SpinnerDiv } from "../../../../../features/ui"
import { DataType, TableName } from "../../../../../tables"
import { useSystemStateHooks } from "../../../../system"
import { Budget } from "../../../state/types"
import { BudgetPDFModal } from "../budget-pdf-modal/budgetPDFModal"
import { useFind } from "../../../../../state/reducers/hooks"
import { define } from "../../../../../utils/typeUtils"
import { PanelWrapper } from "../../../../../components/wrappers/panel-wrapper/panelWrapper"
import { DataTypeList } from "../../../../../features/data-types"
import { BudgetFilters } from "./budgetFilters"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "../../../../../features/routes"

export const BudgetList = () => {
    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [currentBudget, setCurrentBudget] = useState<Budget>()
    
    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_BUDGETS)
    const navigate = useNavigate()
    const find = useFind()

    const { toggleModal } = useUIActions()

    const setRowsState = (budgets: Budget[]) => {
        setRows(budgets)
        setAsyncDataLoaded(true)
    }

    const columns: Column[] = [
        { name: 'code', type: FieldType.NUMBER, label: '#', render: (value?: FieldValue) => `#${value}` },
        { name: 'date', type: FieldType.DATE, label: 'Fecha' },
        { name: 'totalPrice', type: FieldType.PRICE, label: 'Precio' },
        { name: 'sellerId', type: FieldType.TABLE, table: TableName.EMPLOYEES, label: 'Vendedor' },
        { name: 'customerId', type: FieldType.TABLE, table: TableName.CUSTOMERS, label: 'Cliente' },
    ]

    const findBudget = (code?: string) => define(find(TableName.BUDGETS, code, 'code') as Budget)

    const onView = (code?: string) => {
        const budget = findBudget(code)
        if (budget) {
            navigate(budget.id)
        }
    }

    const onDownloadPDF = (code?: string) => {
        setCurrentBudget(findBudget(code))
        toggleModal(ModalId.BUDGET_PDF)
    }

    const onRefresh = (code?: string) => {
        const budget = findBudget(code)
        if (budget) {
            navigate(`${budget.id}/${RoutePath.UPDATE}`)
        }
    }

    const onCreateSale = (code?: string) => {
        const budget = findBudget(code)
        if (budget) {
            navigate(`${budget.id}/${RoutePath.CREATE_SALE}`)
        }
    }

    const onRemove = (code?: string) => {
        setCurrentBudget(findBudget(code))
        toggleModal(ModalId.REMOVE)
    }

    const actions = (): Action[] => {
        const actionList: Action[] = [
            { icon: 'eye-fill', kind: 'tertiary', tooltip: 'Ver Detalle', onClick: onView },
            { icon: 'download', kind: 'tertiary', tooltip: 'Descargar', onClick: onDownloadPDF }
        ]
        if (!readOnly) {
            actionList.push(
                { icon: 'arrow-repeat', tooltip: 'Actualizar', onClick: onRefresh },
                { icon: 'tags-fill', tooltip: 'Crear Venta', onClick: onCreateSale },
                { icon: 'trash-fill', kind: 'secondary', tooltip: 'Eliminar', onClick: onRemove }
            )
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRowsState(dataTypes as Budget[])
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <PanelWrapper title="Presupuestos" wide>
            <BudgetFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone}/>
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    rowId="code"
                    currentDataTypeParam={currentBudget}
                    actions={actions}
                    readOnly={readOnly}
                    showDetail={false}
                />
            )}
            <BudgetPDFModal budget={currentBudget} />
        </PanelWrapper>
    )
}
