import { define } from '../../../../utils/typeUtils'
import { requestStatusLabel, userRoleLabel } from '../../../../tables/types/labels'
import { Action, Column, Data, FieldType, FieldValue, List, ModalId, RequestAction, UIPermission, useUIActions } from '../../../../features/ui'
import { Request, RequestStatus, UserRole, useSystemState, useSystemStateHooks } from '../../../system'
import { PanelWrapper } from '../../../../components/wrappers/panel-wrapper/panelWrapper'
import { Badge } from '../../../../components/badge/badge'
import { ResolveRequestModal } from '../..'
import { useState } from 'react'

export const RequestList = () => {
    const { requests, userRequest, companyUsers } = useSystemState()
    
    const [currentRequest, setCurrentRequest] = useState<Request>()
    const [currentRequestAction, setCurrentRequestAction] = useState<RequestAction>()
    
    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_REQUESTS)
    
    const { toggleModal } = useUIActions()
    
    const columns: Column[] = [
        {
            name: 'userName',
            type: FieldType.TEXT,
            label: 'Usuario'
        },
        {
            name: 'userEmail',
            type: FieldType.TEXT,
            label: 'Email'
        },
        {
            name: 'role',
            type: FieldType.TEXT,
            label: 'Rol',
            render: (value?: FieldValue) => <Badge label={userRoleLabel(define(value) as UserRole)} />
        },
        {
            name: 'status',
            type: FieldType.TEXT,
            label: 'Estado',
            render: (value?: FieldValue) => <Badge label={requestStatusLabel(define(value) as RequestStatus)} />
        },
    ]

    const rows: Data[] = requests
        .filter(request => request.id !== userRequest?.id)
        .map(request => {
            const companyUser = define(companyUsers.find(user => user.id === request.userId))

            return {
                id: request.id,
                userName: companyUser.name,
                userEmail: companyUser.email,
                role: request.role,
                status: request.status
            }
        })

    const onResolve = (requestAction: RequestAction) => (requestId?: string) => {
        setCurrentRequest(requests.find(request => request.id === define(requestId)))
        setCurrentRequestAction(requestAction)
        toggleModal(ModalId.RESOLVE_REQUEST)
    }

    const actions = (requestId?: string): Action[] => {
        const request = requests.find(request => request.id === define(requestId))
        const isPending = request?.status === RequestStatus.PENDING
        
        return isPending && !readOnly ? [
            { icon: 'patch-check-fill', tooltip: 'Aprobar', onClick: onResolve(RequestAction.APPROVE) },
            { icon: 'patch-minus-fill', kind: 'secondary', tooltip: 'Denegar', onClick: onResolve(RequestAction.DENY) }
        ] : []
    }

    return (
        <PanelWrapper title="Solicitudes de Acceso" wide>
            <List
                columns={columns}
                rows={rows}
                rowId="id"
                actions={actions}
                readOnly={readOnly}
            />
            <ResolveRequestModal
                request={currentRequest}
                requestAction={currentRequestAction}
            />
        </PanelWrapper>
    )
}
