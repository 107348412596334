import { AttributeMap, BatchWriteItemRequestMap } from "aws-sdk/clients/dynamodb"

export const createBatches = (list: AttributeMap[], batchSize = 25): AttributeMap[][] => {
    const batches: AttributeMap[][] = []
    for (let i = 0; i < list.length; i += batchSize) {
        batches.push(list.slice(i, i + batchSize))
    }
    return batches
}

export const getList = (dbTableName: string, unprocessedItems?: BatchWriteItemRequestMap) => {
    if (unprocessedItems && unprocessedItems.hasOwnProperty(dbTableName)) {
        return unprocessedItems[dbTableName]
            .map(request => request.PutRequest?.Item)
            .filter(item => !!item) as AttributeMap[]
    }
    return []
}
