import { DataType, TablesData } from "../tables";
import { TableName } from "../tables/types/types";
import { usePermissionsActions } from "./permissionsActions";
import { newId } from "../tables/utils";
import { define, firstItem } from "../utils/typeUtils";
import { unitTypes } from "../tables/standards/unitTypes";
import { settings } from "../tables/standards/settings";
import { paymentTypes } from "../tables/standards/paymentTypes";
import { tableAction, tableArn } from "../utils/policiesUtils";
import { AttributeMap } from "../services/dynamo"
import { useChangedIdMigration } from "../guides/migrations/changedId";
import { useRemovedPropMigration } from "../guides/migrations/removedProp";
import { StatementType } from "../services/iam";
import { useAddedRequiredPropMigration } from "../guides/migrations/addedRequiredProp";
import { useRenamedPropMigration } from "../guides/migrations/renamedProp";
import { branches } from "../tables/standards/branches";
import { useChangedEnumValueMigration } from "../guides/migrations/changedEnumValue";
import { useRemovedTableTenantKeyMigration } from "../guides/migrations/removedTableTenantKey";
import { Company, IdentifierType, Request, RequestStatus, systemPermissions, SystemPolicyName, User, UserRole, useSystemActions } from "../modules/system";
import { showErrorStrToast } from "../features/ui";
import { administrationPermissions } from "../modules/administration";
import { purchasesPermissions } from "../modules/purchases";
import { salesPermissions } from "../modules/sales";
import { construfyAdminPermissions, ConstrufyAdminPolicyName, ConstrufyAdminRoleName } from "../modules/construfy-admin";
import { managerPermissions, useManagerActions } from "../modules/manager";
import { warehousePermissions } from "../modules/warehouse";
import { TableActions, policyName, roleName, toIamPermissions, toPermissionList } from "../features/roles";
import { DynamoUserApi } from "../services";
import { customerTypes } from "../tables/standards/customerTypes";
import { useImportSuppliers } from "../features/importer";
import { useUpdatedValueMigration } from "../guides/migrations/updatedValue";
import { categories } from "../tables/standards/categories";
import { expenseTypes } from "../tables/standards/expenseTypes";
import { TaxCategory } from "../modules/operations";
import { NativeType } from "../components/types";
import { awsS3Bucket, awsS3PublicBucket } from "../services/constants";

export const useConstrufyAdminActions = () => {
    const systemActions = useSystemActions()
    const managerActions = useManagerActions()
    const permissionsActions = usePermissionsActions()
    const changedId = useChangedIdMigration()
    const removedProp = useRemovedPropMigration()
    const renamedProp = useRenamedPropMigration()
    const addedRequiredProp = useAddedRequiredPropMigration()
    const updatedValue = useUpdatedValueMigration()
    const changedEnumValue = useChangedEnumValueMigration()
    const removedTableTenantKey = useRemovedTableTenantKeyMigration()
    const importSuppliers = useImportSuppliers()
    
    const createCompany = async (code: string, name: string, websiteUrl: string, email: string, address: string) => {
        const { fetchCompanyByCode, saveCompany } = systemActions()
        const company = await fetchCompanyByCode(code)
        if (company) {
            showErrorStrToast('La compañía ya existe.')
        } else {
            const newCompany: Company = {
                id: newId(),
                code,
                name,
                websiteUrl,
                email,
                address
            }
            saveCompany(newCompany)
        }
    }

    const resolveRequest = async (companyCode: string, userEmail: string, resolution: RequestStatus) => {
        const onFetchUser = (company: Company) => async (userDT?: DataType) => {
            const user = define(userDT) as User
            const request = define(await systemActions(company.id).fetchRequest(company.id, user.id)) as Request
            managerActions(company.id).resolveRequest(request, company, resolution, false)
        }
        const company = define(await systemActions().fetchCompanyByCode(companyCode))
        systemActions().fetchUserByEmail(userEmail, onFetchUser(company))
    }

    const saveSystemPolicy = () => {
        const tablesStatements: StatementType[] = []
        toIamPermissions(toPermissionList(systemPermissions())).forEach(permission => {
            tablesStatements.push({
                Effect: "Allow",
                Action: permission.actions.map(action => tableAction(action)),
                Resource: permission.tables.map(table => tableArn(table))
            })
        })

        const policy = {
            "Version": "2012-10-17",
            "Statement": tablesStatements.concat(
                {
                    "Effect": "Allow",
                    "Action": [
                        "iam:AddUserToGroup",
                        "iam:CreateAccessKey",
                        "iam:CreateUser",
                        "iam:DeleteUser",
                        "iam:GetUser",
                        "iam:ListGroupsForUser",
                        "iam:RemoveUserFromGroup",
                        "sts:AssumeRole"
                    ],
                    "Resource": [
                        "*"
                    ]
                },
                {
                    "Effect": "Allow",
                    "Action": ["s3:*"],
                    "Resource": [
                        `arn:aws:s3:::${awsS3Bucket}/*`,
                        `arn:aws:s3:::${awsS3PublicBucket}/*`

                    ]
                }
            )
        }

        permissionsActions.savePolicyFromJson(SystemPolicyName, policy)
    }

    const saveConstrufyAdminPolicy = () => {
        const tablesStatements: StatementType[] = []
        toIamPermissions(toPermissionList(construfyAdminPermissions())).forEach(permission => {
            tablesStatements.push({
                Effect: "Allow",
                Action: permission.actions.map(action => tableAction(action)),
                Resource: permission.tables.map(table => tableArn(table))
            })
        })

        const policy = {
            "Version": "2012-10-17",
            "Statement": tablesStatements.concat({
                "Effect": "Allow",
                "Action": [
                    "dynamodb:CreateTable",
                    "dynamodb:DeleteTable",
                    "iam:AddUserToGroup",
                    "iam:AttachGroupPolicy",
                    "iam:AttachRolePolicy",
                    "iam:CreateGroup",
                    "iam:CreatePolicy",
                    "iam:CreateRole",
                    "iam:CreatePolicyVersion",
                    "iam:CreateUser",
                    "iam:DeletePolicyVersion",
                    "iam:GetUser",
                    "iam:ListPolicyVersions"
                ],
                "Resource": "*"
            })
        }

        permissionsActions.savePolicyFromJson(ConstrufyAdminPolicyName, policy)
        // permissionsActions.createRole(ConstrufyAdminRoleName, ConstrufyAdminPolicyName)
    }

    const createCompanyPolicies = (companyId: string, companyCode: string) => {
        const createPolicy = (userRole: UserRole, permissions: TableActions[]) => {
            const policy = policyName(companyCode, userRole)
            permissionsActions.savePolicy(policy, toPermissionList(permissions), companyId, true)
            const role = roleName(companyCode, userRole)
            permissionsActions.createRole(role, policy)
        }

        createPolicy(UserRole.MANAGER, managerPermissions())
        createPolicy(UserRole.ADMINISTRATION, administrationPermissions())
        createPolicy(UserRole.PURCHASES, purchasesPermissions())
        createPolicy(UserRole.SALES, salesPermissions())
        createPolicy(UserRole.WAREHOUSE, warehousePermissions())
    }

    const saveCompanyPolicies = async () => { 
        const companies = await systemActions().fetchAllCompanies()
        companies.forEach(company => {
            const managerPolicy = policyName(company.code, UserRole.MANAGER)
            const adminstrationPolicy = policyName(company.code, UserRole.ADMINISTRATION)
            const purchasesPolicy = policyName(company.code, UserRole.PURCHASES)
            const salesPolicy = policyName(company.code, UserRole.SALES)
            const warehousePolicy = policyName(company.code, UserRole.WAREHOUSE)

            permissionsActions.savePolicy(managerPolicy, toPermissionList(managerPermissions()), company.id)
            permissionsActions.savePolicy(adminstrationPolicy, toPermissionList(administrationPermissions()), company.id)
            permissionsActions.savePolicy(purchasesPolicy, toPermissionList(purchasesPermissions()), company.id)
            permissionsActions.savePolicy(salesPolicy, toPermissionList(salesPermissions()), company.id)
            permissionsActions.savePolicy(warehousePolicy, toPermissionList(warehousePermissions()), company.id)
        })
    }

    const createTables = () => {
        const tableParams = (tableName: TableName, hasTKey: boolean) => ({
            name: define(TablesData.get(tableName)).dbTableName,
            hasTKey
        })
        const tables = [
            // tableParams(TableName.BRANCHES, true),
            // tableParams(TableName.BUDGETS, true),
            // tableParams(TableName.BUDGET_ITEMS, true),
            tableParams(TableName.CASH_FUNDS, true),
            // tableParams(TableName.CATEGORIES, true),
            // tableParams(TableName.COMPANIES, false),
            // tableParams(TableName.CUSTOMER_TYPES, true),
            // tableParams(TableName.CUSTOMERS, true),
            // tableParams(TableName.EMPLOYEES, true),
            tableParams(TableName.EXPENSE_TYPES, true),
            tableParams(TableName.EXPENSES, true),
            // tableParams(TableName.HOARD_ITEMS, true),
            // tableParams(TableName.HOARD_ORDERS, true),
            // tableParams(TableName.LOTS, true),
            // tableParams(TableName.MEASURES, true),
            // tableParams(TableName.ORDER_DELIVERIES, true),
            // tableParams(TableName.PAYMENT_TYPES, true),
            // tableParams(TableName.PRODUCT_PRICES, true),
            // tableParams(TableName.PRODUCTS, true),
            // tableParams(TableName.PURCHASE_ITEMS, true),
            // tableParams(TableName.PURCHASE_ORDERS, true),
            // tableParams(TableName.REQUESTS, true),
            // tableParams(TableName.SALE_ITEMS, true),
            // tableParams(TableName.SALE_ORDERS, true),
            // tableParams(TableName.SETTINGS, true),
            // tableParams(TableName.STOCK, true),
            // tableParams(TableName.SUPPLIERS, true),
            tableParams(TableName.TRANSACTIONS, true),
            // tableParams(TableName.UNIT_TYPES, false),
            // tableParams(TableName.USERS, false)
        ]
        DynamoUserApi.createTables(tables)
    }

    const createUnitTypes = () => {
        const UnitTypesData = define(TablesData.get(TableName.UNIT_TYPES))
        const defaultUnitTypesDT = unitTypes() as DataType[]
        defaultUnitTypesDT.forEach(unitType => DynamoUserApi.create(UnitTypesData.dbTableName, unitType as AttributeMap))
    }

    const createDefaultData = async () => {
        const companies = await systemActions().fetchAllCompanies()
        companies.forEach(company => {
            const companyId = company.id
            const defaultSettings = settings(companyId)
            const defaultBranches = branches(companyId)
            const defaultCategories = categories(companyId)
            const defaultCustomerTypes = customerTypes(companyId)
            const defaultPaymentTypes = paymentTypes(companyId)
            const defaultExpenseTypes = expenseTypes(companyId)
            
            // const defaultSettingsDT = defaultSettings as DataType[]
            // const SettingsData = define(TablesData.get(TableName.SETTINGS))
            // DynamoUserApi.createOrUpdateMultiple(SettingsData.dbTableName, defaultSettingsDT as AttributeMap[])
    
            // const defaultBranchesDT = defaultBranches as DataType[]
            // const BranchesData = define(TablesData.get(TableName.BRANCHES))
            // DynamoUserApi.createOrUpdateMultiple(BranchesData.dbTableName, defaultBranchesDT as AttributeMap[])

            // const defaultCategoriesDT = defaultCategories as DataType[]
            // const CategoriesData = define(TablesData.get(TableName.CATEGORIES))
            // DynamoUserApi.createOrUpdateMultiple(CategoriesData.dbTableName, defaultCategoriesDT as AttributeMap[])
    
            // const defaultCustomerTypesDT = defaultCustomerTypes as DataType[]
            // const CustomerTypesData = define(TablesData.get(TableName.CUSTOMER_TYPES))
            // DynamoUserApi.createOrUpdateMultiple(CustomerTypesData.dbTableName, defaultCustomerTypesDT as AttributeMap[])
    
            // const defaultPaymentTypesDT = defaultPaymentTypes as DataType[]
            // const PaymentTypesData = define(TablesData.get(TableName.PAYMENT_TYPES))
            // DynamoUserApi.createOrUpdateMultiple(PaymentTypesData.dbTableName, defaultPaymentTypesDT as AttributeMap[])

            const defaultExpenseTypesDT = defaultExpenseTypes as DataType[]
            const ExpenseTypesData = define(TablesData.get(TableName.EXPENSE_TYPES))
            DynamoUserApi.createOrUpdateMultiple(ExpenseTypesData.dbTableName, defaultExpenseTypesDT as AttributeMap[])
        })
    }

    const migrate = async () => {        
        const companies = await systemActions().fetchAllCompanies()
        const companyIds = companies.map(({ id }) => id)

        // renamedProp(companyIds, TableName.SUPPLIERS, 'cuit', 'taxId')

        // updatedValue(companyIds, TableName.CUSTOMERS, 'identifier', (value: NativeType) => {
        //     const identifier = value as string
        //     const a = identifier.split('-').join('')
        //     return parseInt(a)
        // })

        // addedRequiredProp(companyIds, TableName.CUSTOMERS, 'identifierType', IdentifierType.TAX_ID)
    }

    const removeUser = (userEmail: string) => {
        const onFetchRequests = (requestsDT: DataType[]) => {
            const requests = requestsDT as Request[]
            requests.forEach(request => {
                systemActions(request.companyId).removeRequest(request.id)
            })
        }
        const onRemoveUser = (userId: string) => () => {
            systemActions().fetchRequests(userId).then(onFetchRequests)
        }
        const onFetchUsers = (usersDT: DataType[]) => {
            const user = define(usersDT[0]) as User
            systemActions().removeUser(user.id, onRemoveUser(user.id))
        }
        systemActions().fetchUsersByEmail(userEmail, onFetchUsers)
    }

    return {
        createCompany,
        resolveRequest,
        saveConstrufyAdminPolicy,
        saveSystemPolicy,
        createCompanyPolicies,
        saveCompanyPolicies,
        createTables,
        createUnitTypes,
        createDefaultData,
        migrate,
        removeUser,
        importSuppliers
    }
}
