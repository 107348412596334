import { PanelWrapper } from "../../../../components/wrappers/panel-wrapper/panelWrapper"
import { DataTypeList } from "../../../../features/data-types"
import { Column, FieldType, UIPermission } from "../../../../features/ui"
import { useFind } from "../../../../state/reducers/hooks"
import { TableName } from "../../../../tables"
import { SalaryExpenseTypeName } from "../../../../tables/standards/expenseTypes"
import { useSystemStateHooks } from "../../../system"
import { ExpenseType } from "../../state/types"

export const ExpenseTypeList = () => {
    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_EXPENSE_TYPES)

    const find = useFind()

    const columns: Column[] = [
        { name: 'name', type: FieldType.TEXT, label: 'Nombre' }
    ]

    const isEditable = (rowIdValue?: string) => {
        const expenseType = find(TableName.EXPENSE_TYPES, rowIdValue) as ExpenseType | undefined
        return expenseType?.name !== SalaryExpenseTypeName
    }

    return (
        <PanelWrapper title="Tipos de Gasto">
            <DataTypeList
                columns={columns}
                readOnly={readOnly}
                minItems={1}
                showDetail={false}
                isEditable={isEditable}
            />
        </PanelWrapper>
    )
}
