import { useDefaultModuleActions } from "../../../../actions/defaultModuleActions"
import { BaseCallback, ListCallback, OptionalItemCallback } from "../../../../actions/types"
import { DynamoUserApi } from "../../../../services"
import { TableName } from "../../../../tables"
import { HoardOrder, HoardItem } from "../../state/types"

export const useCommonActions = () => {
    const defaultModuleActions = useDefaultModuleActions()

    return (companyId: string) => {
        const { fetchByParams, fetchMultipleByParams_async, fetchByIds, count, save_async, saveMultiple_async, remove, removeMultiple } = defaultModuleActions(DynamoUserApi, companyId)

        const fetchAllHoards = (branchId: string) => {
            return fetchMultipleByParams_async(TableName.HOARD_ORDERS, { branchId })
        }

        const fetchHoard = (id: string, callback?: OptionalItemCallback) => {
            fetchByParams(TableName.HOARD_ORDERS, { id }, callback)
        }

        const countHoards = (propName: string, propValue: string) => {
            return count(TableName.HOARD_ORDERS, { [propName]: propValue })
        }

        const saveHoard = (hoard: HoardOrder) => {
            return save_async(TableName.HOARD_ORDERS, hoard)
        }

        const removeHoard = (id: string, callback?: BaseCallback) => {
            remove(TableName.HOARD_ORDERS, id, callback)
        }

        const fetchHoardItemsByOrder = (hoardId: string) => {
            return fetchMultipleByParams_async(TableName.HOARD_ITEMS, { hoardId })
        }

        const fetchHoardItemsByIds = (ids: string[], callback?: ListCallback) => {
            fetchByIds(TableName.HOARD_ITEMS, ids, callback)
        }

        const countHoardItems = (propName: string, propValue: string) => {
            return count(TableName.HOARD_ITEMS, { [propName]: propValue })
        }

        const saveHoardItems = (hoardItems: HoardItem[]) => {
            return saveMultiple_async(TableName.HOARD_ITEMS, hoardItems)
        }

        const removeHoardItems = (ids: string[], callback?: BaseCallback) => {
            removeMultiple(TableName.HOARD_ITEMS, ids, callback)
        }

        return {
            fetchAllHoards,
            fetchHoard,
            countHoards,
            saveHoard,
            removeHoard,
            fetchHoardItemsByOrder,
            fetchHoardItemsByIds,
            countHoardItems,
            saveHoardItems,
            removeHoardItems
        }
    }
}
