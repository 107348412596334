import { DataType, TableName } from '../../../../../tables'
import { Data, UIPermission, DetailPanel, DetailFieldProps, FieldType, FieldValue, Action, Spinner, Tab, useGetCrumbs } from '../../../../../features/ui'
import { Customer, SaleItem, SaleOrder } from '../../../state/types'
import { define } from '../../../../../utils/typeUtils'
import { useSalesActions } from '../../../actions/salesActions'
import { useEffect, useState } from 'react'
import { Operation, OperationItemsTable, OrderStatus, usePricesHooks, PriceDetail, DeliveryList, OrderDelivery, useOperationsActions, PendingItemsList } from '../../../../operations'
import { useSystemStateHooks } from '../../../../system'
import { PaymentType } from '../../../../administration'
import { saleWizardKey } from '../../../utils/salesUtils'
import { OpenWizardModal, useWizardActions } from '../../../../../features/wizard'
import { orderStatusLabel } from '../../../../../tables/types/labels'
import { RoutePath, useRoutesHooks } from '../../../../../features/routes'
import { useFind } from '../../../../../state/reducers/hooks'
import { useDescriptorHooks } from '../../../../../features/data-types'

export const SaleDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const sale = define(dataType) as SaleOrder
  const wizardKey = saleWizardKey(sale)

  const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
  const [saleItems, setSaleItems] = useState<SaleItem[]>([])
  const [customer, setCustomer] = useState<Customer>()
  const [customerAdjustment, setCustomerAdjustment] = useState<number>(0)
  const [paymentTypeAdjustment, setPaymentTypeAdjustment] = useState<number>(0)
  const [subtotalPrice, setSubtotalPrice] = useState<number>(sale.totalPrice)
  const [deliveries, setDeliveries] = useState<OrderDelivery[]>([])
  
  const { userHasPermission } = useSystemStateHooks()
  const pricesHooks = usePricesHooks()
  const descriptorHooks = useDescriptorHooks()
  const find = useFind()

  const { goToWizard } = useWizardActions()
  const salesActions = useSalesActions()
  const operationsActions = useOperationsActions()

  const readOnly = !userHasPermission(UIPermission.SAVE_SALES)

  useEffect(() => {
    const onFetchCustomer = (customerDT?: DataType) => {
      setCustomer(customerDT as Customer)
      setAsyncDataLoaded(true)
    }
    
    salesActions().fetchSaleItemsByOrder(sale.id).then((saleItemsDT: DataType[]) => {
      const stateSaleItems = saleItemsDT as SaleItem[]
      setSaleItems(stateSaleItems)
      const fullPrice = pricesHooks.sale.fullPriceFromItems(stateSaleItems)
      setSubtotalPrice(fullPrice)
      operationsActions().fetchDeliveries(sale.id).then((deliveriesDT: DataType[]) => {
        setDeliveries(deliveriesDT as OrderDelivery[])
        salesActions().fetchCustomer(sale.customerId, onFetchCustomer)
      })
    })
  }, [])

  useEffect(() => {
    const salePriceItems = saleItems.map(saleItem => ({ productId: saleItem.productId as string, quantity: saleItem.quantity as number }))
    const customerAdjustedPrice = pricesHooks.sale.adjustedPrice(salePriceItems, undefined, customer)
    setCustomerAdjustment(customerAdjustedPrice - subtotalPrice)
    const paymentType = define(find(TableName.PAYMENT_TYPES, sale.paymentTypeId)) as PaymentType
    const paymentTypeAdjustedPrice = pricesHooks.sale.adjustedPrice(salePriceItems, paymentType)
    setPaymentTypeAdjustment(paymentTypeAdjustedPrice - subtotalPrice)
  }, [saleItems, subtotalPrice, customer])

  const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

  const crumbs = useGetCrumbs('Ventas', RoutePath.SALES, descriptorHooks.sale.code(sale))

  const fields: DetailFieldProps[] = [
    { name: 'branchId', type: FieldType.TABLE, table: TableName.BRANCHES, label: 'Sucursal de Entrega' },
    { name: 'deliveryDate', type: FieldType.DATE, label: 'Fecha de Entrega' },
    { name: 'sellerId', type: FieldType.TABLE, table: TableName.EMPLOYEES, label: 'Vendedor' },
    { name: 'customerId', type: FieldType.TABLE, table: TableName.CUSTOMERS, label: 'Cliente' },
    {
      name: 'saleItems',
      type: FieldType.TABLE,
      table: TableName.SALE_ITEMS,
      render: () => renderAsync(
        <OperationItemsTable
            rows={saleItems as Data[]}
            operation={Operation.SALE}
            readOnly
        />
      )
    },
    { name: 'paymentTypeId', type: FieldType.TABLE, table: TableName.PAYMENT_TYPES, label: 'Medio de Pago' },
    {
      name: 'totalPrice',
      type: FieldType.PRICE,
      label: 'Precio',
      render: () => (
        <PriceDetail
          subtotal={subtotalPrice}
          customerAdjustment={customerAdjustment}
          paymentTypeAdjustment={paymentTypeAdjustment}
          total={sale.totalPrice}
        />
      )
    },
    {
      name: 'status',
      type: FieldType.TEXT,
      label: 'Estado',
      render: (value?: FieldValue) => <>{orderStatusLabel(define(value) as OrderStatus)}</>
    }
  ]
  if (sale.status !== OrderStatus.DELIVERED && deliveries.length > 0) {
    const pendingItemsField = {
      name: 'pendingItems',
      type: FieldType.TABLE,
      table: TableName.SALE_ITEMS,
      render: () => renderAsync(
        <PendingItemsList orderId={sale.id} operation={Operation.SALE} />
      )
    }
    fields.splice(5, 0, pendingItemsField)
  }

  const onDeliver = () => {
    goToWizard(saleWizardKey(sale), RoutePath.DELIVERY)
  }

  const onArchive = () => {}

  const actions: Action[] = []
  if (!readOnly) {
    const archiveAction = { label: 'Archivar', icon: 'archive', kind: 'secondary', onClick: onArchive }
    const deliverAction = { label: 'Registrar Entrega', icon: 'truck', onClick: onDeliver }
    actions.push(sale.status === OrderStatus.DELIVERED ? archiveAction : deliverAction)
  }

  const tabs: Tab[] = deliveries.length > 0 ? [
    {
      id: 'deliveries',
      title: 'Entregas',
      content: <DeliveryList
        orderId={sale.id}
        operation={Operation.SALE}
      />
    }
  ] : []

  return (<>
    <DetailPanel
      tableName={TableName.SALE_ORDERS}
      crumbs={crumbs}
      fields={fields}
      data={sale}
      icon='tags'
      imgSpace={3}
      tabs={tabs}
      readOnly={readOnly}
      actions={actions}
    />
    <OpenWizardModal
      wizardKey={wizardKey}
      path={RoutePath.DELIVERY}
    />
  </>)
}
