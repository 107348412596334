import { FieldType, Option } from "../features/ui"
import { DataType, TableName } from "../tables"

export type TextOrNumberType = string | number
export type NativeType = TextOrNumberType | boolean | Date | object | object[]
export type ChangeValueType = TextOrNumberType | boolean | Date

export type FieldValues = {
    [key: string]: NativeType | undefined
}

export enum FieldSize {
    S, M, L
}

export type FieldProps = {
    name: string
    type?: FieldType
    table?: TableName
    label?: string
    value?: NativeType
    defaultValue?: NativeType
    size?: FieldSize
    optional?: boolean
    disabled?: boolean
    min?: number
    max?: number
    allowDecimals?: boolean
    minLength?: number
    maxLength?: number
    options?: Option[]
    noLabel?: boolean
    readOnly?: boolean
    preLabel?: string
    postLabel?: string
    onChange?: OnChangeFieldType
    onSave?: OnSaveFieldType
    fieldRenderer?: (dataType?: DataType) => JSX.Element | JSX.Element[]
    editableRowRenderer?: () => JSX.Element | JSX.Element[]
}

export type TableRow = {
    [key: string]: TextOrNumberType | object[] | undefined
}

export type OnChangeTextOrNumberType = (value?: TextOrNumberType) => void
export type OnChangeFieldType = (value?: ChangeValueType) => void
export type OnSaveFieldType = (value?: NativeType) => void
