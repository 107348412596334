import { useDefaultModuleActions } from "../../../actions/defaultModuleActions"
import { BaseCallback, ItemCallback, OptionalItemCallback } from "../../../actions/types"
import { useDataTypeFileActions } from "../../../features/data-types"
import { DynamoUserApi } from "../../../services"
import { TextParams } from "../../../state/reducers/types"
import { DataType, TableName } from "../../../tables"
import { SalaryExpenseTypeName } from "../../../tables/standards/expenseTypes"
import { define } from "../../../utils/typeUtils"
import { Company, useSystemActions, useSystemStateHooks } from "../../system"
import { CashFund, Employee, Expense, ExpenseType, PaymentType, SaleTax, Settings, SettingsModule, Transaction } from "../state/types"

export const useAdministrationActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId

    const defaultModuleActions = useDefaultModuleActions()
    const systemActions = useSystemActions()
    const { saveWithFile, removeWithFile } = useDataTypeFileActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const { fetchAll, fetchByParams, fetchByParams_async, fetchMultipleByParams_async, fetchFiltered, count, save, save_async, remove, remove_async } = defaultModuleActions(DynamoUserApi, define(companyId))

        const fetchAllSettings = async () => {
            return fetchAll(TableName.SETTINGS).then(response => response.dataTypes)
        }

        const fetchSettings = (module: SettingsModule, callback?: OptionalItemCallback) => {
            fetchByParams(TableName.SETTINGS, { module } , callback)
        }
        
        const saveSettings = (settings: Settings, callback?: ItemCallback) => {
            save(TableName.SETTINGS, settings, callback)
        }

        const saveSaleTaxes = (saleTaxes: SaleTax[], callback?: ItemCallback) => {
            const onFetchSalesSettings = (settingsDT?: DataType) => {
                const settings = define(settingsDT) as Settings
                const updatedSettings: Settings = {
                    ...settings,
                    data: {
                        ...settings.data,
                        saleTaxes
                    }                
                }
                saveSettings(updatedSettings, callback)
            }

            fetchSettings(SettingsModule.SALES, onFetchSalesSettings)
        }

        const saveCompany = (company: Company) => {
            return systemActions().saveCompany(company)
        }

        const fetchAllCashFunds = async (limit?: number, startKey?: TextParams) => {
            return fetchAll(TableName.CASH_FUNDS, limit, startKey)
        }

        const fetchCashFund = (id: string, callback?: OptionalItemCallback) => {
            fetchByParams(TableName.CASH_FUNDS, { id }, callback)
        }

        const saveCashFund = (cashFund: CashFund, callback?: ItemCallback) => {
            save(TableName.CASH_FUNDS, cashFund, callback)
        }

        const transferFromCashFund = (transaction: Transaction, callback?: BaseCallback) => {
            const onUpdateDestinationCashFund = () => {
                saveTransaction(transaction, callback)
            }

            const onFetchDestinationCashFund = (cashFundDT?: DataType) => {
                const destinationCashFund = define(cashFundDT) as CashFund
                const udpatedDestinationCashFund = {
                    ...destinationCashFund,
                    amount: destinationCashFund.amount + transaction.amount
                }
                saveCashFund(udpatedDestinationCashFund, onUpdateDestinationCashFund)
            }

            const onUpdateSourceCashFund = () => {
                fetchCashFund(transaction.cashFundId, onFetchDestinationCashFund)
            }

            const onFetchSourceCashFund = (cashFundDT?: DataType) => {
                const sourceCashFund = define(cashFundDT) as CashFund
                const updatedSourceCashFund = {
                    ...sourceCashFund,
                    amount: sourceCashFund.amount - transaction.amount
                }
                saveCashFund(updatedSourceCashFund, onUpdateSourceCashFund)
            }

            fetchCashFund(transaction.sourceId, onFetchSourceCashFund)
        }

        const removeCashFund = (id: string, callback?: BaseCallback) => {
            remove(TableName.CASH_FUNDS, id, callback)
        }

        const fetchTransactions = (dateFrom: Date, dateTo: Date) => {
            const rangeFilters = {
                date: {
                    start: dateFrom.toISOString(),
                    end: dateTo.toISOString()
                }
            }
            return fetchFiltered(TableName.TRANSACTIONS, undefined, rangeFilters)
        }

        const countTransactions = (propName: string, propValue: string) => {
            return count(TableName.TRANSACTIONS, { [propName]: propValue })
        }

        const saveTransaction = (transaction: Transaction, callback?: ItemCallback) => {
            save(TableName.TRANSACTIONS, transaction, callback)
        }

        const fetchAllEmployees = async (limit?: number, startKey?: TextParams) => {
            return fetchAll(TableName.EMPLOYEES, limit, startKey)
        }

        const fetchEmployee = (id: string, callback?: OptionalItemCallback) => {
            fetchByParams(TableName.EMPLOYEES, { id }, callback)
        }

        const fetchEmployeeByEmail = (email: string) => {
            return fetchByParams_async(TableName.EMPLOYEES, { email })
        }

        const saveEmployee = (employee: Employee) => {
            return save_async(TableName.EMPLOYEES, employee)
        }

        const removeEmployee = (id: string, callback?: BaseCallback) => {
            remove(TableName.EMPLOYEES, id, callback)
        }

        const fetchAllPaymentTypes = async () => {
            return fetchAll(TableName.PAYMENT_TYPES).then(response => response.dataTypes as PaymentType[])
        }        

        const fetchPaymentType = (id: string, callback?: OptionalItemCallback) => {
            fetchByParams(TableName.PAYMENT_TYPES, { id }, callback)
        }

        const savePaymentType = async (paymentType: PaymentType) => {
            return save_async(TableName.PAYMENT_TYPES, paymentType)
        }

        const removePaymentType = async (id: string) => {
            const paymentTypes = await fetchAllPaymentTypes()
            if (paymentTypes.length > 1) {
                return remove_async(TableName.PAYMENT_TYPES, id)
            }
        }

        const fetchAllExpenseTypes = async () => {
            return fetchAll(TableName.EXPENSE_TYPES).then(response => response.dataTypes as ExpenseType[])
        }

        const saveExpenseType = (expenseType: ExpenseType) => {
            return save_async(TableName.EXPENSE_TYPES, expenseType)
        }

        const removeExpenseType = async (id: string) => {
            const expenseTypes = await fetchAllExpenseTypes()
            const isSalary = expenseTypes.find(expenseType => expenseType.id === id)?.name === SalaryExpenseTypeName
            if (expenseTypes.length > 1 && !isSalary) {
                return remove_async(TableName.EXPENSE_TYPES, id)
            }
        }

        const fetchAllExpenses = (branchId: string) => {
            return fetchMultipleByParams_async(TableName.EXPENSES, { branchId })
        }

        const countExpenses = (propName: string, propValue: string) => {
            return count(TableName.EXPENSES, { [propName]: propValue })
        }

        const saveExpense = async (
            expense: Expense,
            receiptFile?: File
        ) => {
            return saveWithFile(TableName.EXPENSES, expense, 'receiptUrl' as keyof DataType, receiptFile)
        }

        const removeExpense = (id: string) => {
            return removeWithFile(TableName.EXPENSES, id, 'receiptUrl' as keyof DataType)
        }
        
        return {
            fetchAllSettings,
            fetchSettings,
            saveSettings,
            saveSaleTaxes,
            saveCompany,
            fetchAllCashFunds,
            saveCashFund,
            transferFromCashFund,
            removeCashFund,
            fetchTransactions,
            countTransactions,
            saveTransaction,
            fetchAllPaymentTypes,
            fetchPaymentType,
            savePaymentType,
            removePaymentType,
            fetchAllEmployees,
            fetchEmployee,
            fetchEmployeeByEmail,
            saveEmployee,
            removeEmployee,
            fetchAllExpenseTypes,
            saveExpenseType,
            removeExpenseType,
            fetchAllExpenses,
            countExpenses,
            saveExpense,
            removeExpense
        }
    }
}
