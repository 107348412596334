import { Action, TableActions } from "../../../features/roles";
import { readPermissions } from '../../../permissions/permissions'
import { TableName } from '../../../tables'

export const administrationPermissions = (): TableActions[] => readPermissions().concat([
    { tableName: TableName.CASH_FUNDS, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.EMPLOYEES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.EXPENSE_TYPES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.EXPENSES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.PAYMENT_TYPES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.SETTINGS, actions: [Action.SAVE] },
    { tableName: TableName.TRANSACTIONS, actions: [Action.SAVE, Action.REMOVE] },
])
