import styles from './formField.module.css'
import { useFindListState } from '../../../state/reducers/hooks'
import { NamedDataType, TableName } from '../../../tables'
import { define, toDate } from '../../../utils/typeUtils'
import { ImageField } from '../image-field/imageField'
import { OnChangeImageFieldType } from '../image-field/types'
import { PriceField } from '../price-field/priceField'
import { FieldSize, NativeType, OnChangeFieldType, OnChangeTextOrNumberType, TextOrNumberType } from '../../types'
import { isTypeNative } from '../../utils'
import { TextArea } from '../text-area/textArea'
import { InputGroup } from '../input-group/inputGroup'
import { PercentField } from '../percent-field/percentField'
import { NumberField } from '../number-field/numberField'
import { TextField } from '../text-field/textField'
import { FieldType, BooleanField, DateField, SelectField, option, Option } from '../../../features/ui'

type Props = {
    name: string,
    type?: FieldType,
    table?: TableName,
    label?: string,
    value?: NativeType,
    defaultValue?: NativeType,
    size?: FieldSize,
    options?: Option[]
    optional?: boolean
    disabled?: boolean
    preLabel?: string
    postLabel?: string
    min?: number
    max?: number
    allowDecimals?: boolean
    minLength?: number
    maxLength?: number
    focusOnLoad?: boolean
    hideReset?: boolean
    onChange?: OnChangeFieldType | OnChangeImageFieldType
}

export const FormField = (props: Props) => {
    const {
        name,
        type = FieldType.TEXT,
        table,
        label,
        value,
        defaultValue,
        size,
        options,
        optional,
        disabled,
        preLabel,
        postLabel,
        min,
        max,
        allowDecimals,
        minLength,
        maxLength,
        hideReset,
        focusOnLoad,
        onChange
    } = props

    const findListState = useFindListState()

    const renderTextField = () => {
        return (
            <TextField
                id={name}
                value={value as string | undefined}
                defaultValue={defaultValue as string | undefined}
                freeValue={true}
                options={options}
                minLength={minLength}
                maxLength={maxLength}
                optional={optional}
                disabled={disabled}
                focusOnLoad={focusOnLoad}
                onChange={onChange as OnChangeFieldType}
            />
        )
    }

    const renderNumberField = () => {
        return (
            <NumberField
                id={name}
                value={value as number | undefined}
                defaultValue={defaultValue as number | undefined}
                min={min}
                max={max}
                allowDecimals={allowDecimals}
                optional={optional}
                disabled={disabled}
                focusOnLoad={focusOnLoad}
                onChange={onChange as OnChangeFieldType}
            />
        )
    }

    const renderBooleanField = () => {
        return (
            <BooleanField
                id={name}
                label={label}
                value={(value || false) as boolean}
                defaultValue={(defaultValue || false) as boolean}
                optional={true}
                disabled={disabled}
                onChange={onChange as OnChangeFieldType}
            />
        )
    }

    const renderInputGroup = () => {
        return (
            <InputGroup
                id={name}
                type={type}
                value={value as TextOrNumberType | undefined}
                defaultValue={defaultValue as TextOrNumberType | undefined}
                optional={optional}
                disabled={disabled}
                preLabel={preLabel}
                postLabel={postLabel}
                onChange={onChange as OnChangeTextOrNumberType}
            />
        )
    }

    const renderSelectField = () => {
        let selectOptions = options
        if (type === FieldType.TABLE && !options) {
            const list = findListState(define(table) as TableName) as NamedDataType[]
            selectOptions = list.map(dataType => option(dataType.id, dataType.name || dataType.id))
        }
        
        return (
            <SelectField
                id={name}
                defaultValue={defaultValue as string | undefined}
                options={selectOptions || []}
                optional={optional}
                disabled={disabled}
                focusOnLoad={focusOnLoad}
                onChange={onChange as OnChangeFieldType}
                aria-label={`${name} select field`}
            />
        )
    }

    const renderDateField = () => {
        return (
            <DateField
                id={name}
                value={toDate(value as string | undefined)}
                defaultValue={toDate(defaultValue as string | undefined)}
                optional={optional}
                disabled={disabled}
                onChange={onChange as OnChangeFieldType}
            />
        )
    }

    const renderPriceField = () => {
        return (
            <PriceField
                id={name}
                defaultValue={defaultValue as number | undefined}
                optional={optional}
                disabled={disabled}
                onChange={onChange as OnChangeFieldType}
            />
        )
    }

    const renderPercentageField = () => {
        return (
            <PercentField
                id={name}
                value={value as number | undefined}
                defaultValue={defaultValue as number | undefined}
                onChange={onChange as OnChangeFieldType}
            />
        )
    }

    const renderImgField = () => {
        return (
            <ImageField
                id={name}
                defaultUrl={defaultValue as string}
                alt='Image'
                hideReset={hideReset}
                onChange={onChange as OnChangeImageFieldType}
            />
        )
    }

    const renderTextArea = () => {
        return (
            <TextArea
                id={name}
                defaultValue={defaultValue as string | undefined}
                optional={optional}
                disabled={disabled}
                onChange={onChange as OnChangeFieldType}
                className={`form-control ${styles.textarea}`}
            />
        )
    }

    const renderField = () => {
        if (isTypeNative(type)) {
            if (type === FieldType.SELECT) {
                return renderSelectField()
            } else if (type === FieldType.DATE) {
                return renderDateField()
            } else if (type === FieldType.PRICE) {
                return renderPriceField()
            } else if (type === FieldType.PERCENTAGE) {
                return renderPercentageField()
            } else if (type === FieldType.IMAGE) {
                return renderImgField()
            } else if (type === FieldType.TEXT && size === FieldSize.L) {
                return renderTextArea()
            } else if (!!preLabel || !!postLabel) {
                return renderInputGroup()
            } else if (type === FieldType.NUMBER) {
                return renderNumberField()
            } else if (type === FieldType.BOOLEAN) {
                return renderBooleanField()
            } else {
                return renderTextField()
            }
        } else {
            return renderSelectField()
        }
    }

    return renderField()
}
