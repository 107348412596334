import { useEffect, useState } from "react"
import { EmployeeSearchField } from "../../../../../components/fields/search-field/employee-search-field/employeeSearchField"
import { PanelWrapper } from "../../../../../components/wrappers/panel-wrapper/panelWrapper"
import { FormField, Data, useUIStateActions, ModalId, useUIActions, FieldValue, FieldType, option, useGetCrumbs } from "../../../../../features/ui"
import { Customer, HoardType } from "../../../state/types"
import { Area, Employee, PaymentType, PaymentTypeField, useAdministrationActions, useAdministrationHooks, useAdministrationState } from "../../../../administration"
import { useSalesActions } from "../../../actions/salesActions"
import { DataType, TableName } from "../../../../../tables"
import { CustomerSearchField } from "../../../../../components/fields/search-field/customer-search-field/customerSearchField"
import { useActions } from "./actions"
import { define, isDefined } from "../../../../../utils/typeUtils"
import { DataTypeForm } from "../../../../../features/data-types"
import { Lot, Product } from "../../../../products"
import { useGenerateNextValue } from "../../../../../tables/hooks"
import { Validatable } from "../../../../../features/ui"
import { Operation, OperationItemFormModal, OperationItemsTable, usePricesHooks, PriceDetail } from '../../../../operations'
import { useFind } from "../../../../../state/reducers/hooks"
import { hoardTypeLabel } from "../../../../../tables/types/labels"
import { useSalesUIHooks } from "../../salesUiHooks"
import { Branch, useManagerState } from "../../../../manager"
import { Badge } from "../../../../../components/badge/badge"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "../../../../../features/routes"

export const HoardForm = () => {
    const { currentBranch } = useManagerState()
    const branchId = currentBranch?.id
    const { currentEmployee } = useAdministrationState()

    const find = useFind()
    const findPaymentType = (id?: string) => find(TableName.PAYMENT_TYPES, id) as PaymentType | undefined

    const [code, setCode] = useState(1)
    const [type, setType] = useState<HoardType | undefined>(HoardType.PRODUCT)
    const [seller, setSeller] = useState<Employee | undefined>(currentEmployee)
    const [customer, setCustomer] = useState<Customer>()
    const [hoardItemsData, setHoardItemsData] = useState<Data[]>([])
    const [currentHoardItem, setCurrentHoardItem] = useState<Data>()
    const [paymentType, setPaymentType] = useState<PaymentType>()
    const [subtotalPrice, setSubtotalPrice] = useState<number>(0)
    const [customerAdjustment, setCustomerAdjustment] = useState<number>(0)
    const [paymentTypeAdjustment, setPaymentTypeAdjustment] = useState<number>(0)
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [validations, setValidations] = useState<string[]>([])

    const { validateItemsData } = useSalesUIHooks()
    const { getSalesSettingsData } = useAdministrationHooks()
    const { hoardIgnoredProductIds } = getSalesSettingsData()
    const pricesHooks = usePricesHooks()
    const purchasePricesList = pricesHooks.product.purchasePrices()
    const generateNextValue = useGenerateNextValue()
    const navigate = useNavigate()

    const { submitHoard } = useActions()
    const { setDisableBranchSelector } = useUIStateActions()
    const administrationActions = useAdministrationActions()
    const salesActions = useSalesActions()
    const { toggleModal } = useUIActions()

    const isProductHoard = type === HoardType.PRODUCT

    const setSellerFromId = (id?: string, callback?: () => void) => {
        if (id) {
            const onFetchSeller = (sellerDT?: DataType) => {
                setSeller(sellerDT as Employee)
                callback && callback()
            }
            administrationActions().fetchEmployee(id, onFetchSeller)
        } else {
            setSeller(undefined)
        }   
    }

    const setCustomerFromId = (id?: string, callback?: () => void) => {
        if (id) {
            const onFetchCustomer = (customerDT?: DataType) => {
                setCustomer(customerDT as Customer)
                callback && callback()
            }
            salesActions().fetchCustomer(id, onFetchCustomer)
        } else {
            setCustomer(undefined)
        }
    }

    useEffect(() => {
        setDisableBranchSelector(true)

        const onGenerateNumber = (n: number) => setCode(n)
        generateNextValue(TableName.HOARD_ORDERS, 'code', onGenerateNumber)

        return () => {
            setDisableBranchSelector(false)
        }
    }, [])

    useEffect(() => setSubtotalPrice(0), [type])

    useEffect(() => {
        const hoardPriceItems = hoardItemsData.map(data => ({ productId: data.productId as string, quantity: data.quantity as number }))
        const fullPrice = pricesHooks.sale.fullPrice(hoardPriceItems)
        setSubtotalPrice(fullPrice)
    }, [hoardItemsData])

    useEffect(() => {
        const hoardPriceItems = hoardItemsData.map(data => ({ productId: data.productId as string, quantity: data.quantity as number }))
        
        const customerAdjustedPrice = isProductHoard ?
            pricesHooks.sale.adjustedPrice(hoardPriceItems, undefined, customer) :
            pricesHooks.adjustedPrice(subtotalPrice, undefined, customer)
        setCustomerAdjustment(customerAdjustedPrice - subtotalPrice)

        const paymentTypeAdjustedPrice = isProductHoard ?
            pricesHooks.sale.adjustedPrice(hoardPriceItems, paymentType) :
            pricesHooks.adjustedPrice(subtotalPrice, paymentType)
        setPaymentTypeAdjustment(paymentTypeAdjustedPrice - subtotalPrice)

        const adjustedPrice = isProductHoard ?
            pricesHooks.sale.adjustedPrice(hoardPriceItems, paymentType, customer) :
            pricesHooks.adjustedPrice(subtotalPrice, paymentType, customer)
        setTotalPrice(adjustedPrice)
    }, [subtotalPrice, customer, paymentType])

    const crumbs = useGetCrumbs('Acopios', RoutePath.HOARDS, undefined, undefined, 'Crear')

    const defaultDueDate = new Date()
    defaultDueDate.setMonth(defaultDueDate.getMonth() + 3)

    const filterProducts = (product: Product) =>
        !hoardIgnoredProductIds.includes(product.id) &&
        (currentHoardItem?.productId === product.id || !hoardItemsData.map(hoardItemData => hoardItemData.productId).includes(product.id))
    const filterLots = (lot: Lot) =>
        currentHoardItem?.lotId === lot.id || !hoardItemsData.map(hoardItemData => hoardItemData.lotId).includes(lot.id)

    const openFormModal = () => toggleModal(ModalId.OPERATION_ITEM_FORM)

    const hoardItemMatches = (data: Data, productId: string, lotId?: string) =>
        data.productId === productId && data.lotId === lotId

    const onTypeChange = (value?: FieldValue) => {
        const newType = value as HoardType | undefined
        setType(newType)
        if (newType === HoardType.FINANCIAL) {
            setHoardItemsData([])
        }
    }

    const onPaymentTypeChange = (value?: FieldValue) => setPaymentType(findPaymentType(value as string | undefined))

    const onCreateHoardItem = () => {
        setCurrentHoardItem(undefined)
        setValidations([])
        openFormModal()
    }

    const onEditHoardItem = (productId: string, lotId?: string) => {
        setCurrentHoardItem(hoardItemsData.find(hoardItemData => hoardItemMatches(hoardItemData, productId, lotId)))
        setValidations([])
        openFormModal()
    }

    const onRemoveHoardItem = (productId: string, lotId?: string) => {
        const newHoardItemsData = hoardItemsData.filter(hoardItemData => !hoardItemMatches(hoardItemData, productId, lotId))
        setHoardItemsData(newHoardItemsData)
        setValidations([])
    }

    const onValueChange = (value?: FieldValue) => {
        isDefined(value) && setSubtotalPrice(value as number)
    }

    const fields: FormField[] = [
        {
            name: 'branchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            label: 'Sucursal de Entrega',
            render: () => {
                const branch = define(find(TableName.BRANCHES, branchId))as Branch
                return <Badge label={branch.name} />
            }
        },
        {
            name: 'type',
            type: FieldType.SELECT,
            label: 'Tipo',
            defaultValue: type,
            options: Object.values(HoardType).map(type => option(type, hoardTypeLabel(type))),
            onChange: onTypeChange
        },
        {
            name: 'sellerId',
            type: FieldType.TABLE,
            table: TableName.EMPLOYEES,
            label: 'Vendedor',
            render: () => (
                <EmployeeSearchField
                    id='sellerId'
                    defaultValue={seller?.id}
                    areas={[Area.MANAGMENT, Area.SALES]}
                    onChange={setSellerFromId}
                />
            )
        },
        {
            name: 'customerId',
            type: FieldType.TABLE,
            table: TableName.CUSTOMERS,
            label: 'Cliente',
            render: () => (
                <CustomerSearchField
                    id='customerId'
                    onChange={setCustomerFromId}
                />
            )
        },
        {
            name: 'dueDate',
            type: FieldType.DATE,
            label: 'Fecha Límite',
            defaultValue: defaultDueDate,
            min: new Date()
        },
        PaymentTypeField({
            onChange: onPaymentTypeChange
        }),
        {
            name: 'totalPrice',
            type: FieldType.PRICE,
            label: 'Precio',
            render: () => <PriceDetail
                subtotal={subtotalPrice}
                customerAdjustment={customerAdjustment}
                paymentTypeAdjustment={paymentTypeAdjustment}
                total={totalPrice}
            />
        }
    ]
    if (isProductHoard) {
        const itemsField = {
            name: 'hoardItems',
            type: FieldType.TABLE,
            table: TableName.HOARD_ITEMS,
            render: () => (
                <Validatable validations={validations}>
                    <OperationItemsTable
                        rows={hoardItemsData}
                        operation={Operation.SALE}
                        onCreate={onCreateHoardItem}
                        onEditRow={onEditHoardItem}
                        onRemoveRow={onRemoveHoardItem}
                    />
                </Validatable>
            )
        }
        fields.splice(3, 0, itemsField)
    } else {
        const valueField = {
            name: 'value',
            type: FieldType.PRICE,
            label: 'Monto',
            onChange: onValueChange
        }
        fields.splice(4, 0, valueField)
    }

    const validate = async () => {
        if (isProductHoard) {
            const itemValidations = await validateItemsData(hoardItemsData, define(branchId))
            setValidations(itemValidations)
            return itemValidations.length === 0
        } else {
            return true
        }
    }

    const onSubmit = async (hoardData: Data) => {
        const valid = await validate()
        if (valid) {
            submitHoard(
                hoardData,
                code,
                define(type),
                define(branchId),
                define(totalPrice),
                define(seller).id,
                define(customer).id,
                hoardItemsData,
                define(paymentType).id,
                purchasePricesList
            )
        }
    }

    const onCancel = () => navigate(-1)

    const onSaveHoardItem = (newData: Data) => {
        let newHoardItemsData = hoardItemsData
        if (currentHoardItem) {
            const { productId, lotId } = currentHoardItem
            newHoardItemsData = hoardItemsData.map(hoardItemData => hoardItemMatches(hoardItemData, define(productId) as string, lotId as string | undefined) ? newData : hoardItemData)
        } else {
            newHoardItemsData = [newData, ...hoardItemsData]
        }
        setHoardItemsData(newHoardItemsData)
    }
    
    return (
        <PanelWrapper title="Crear Acopio" crumbs={crumbs} wide>
            <DataTypeForm
               formId="hoard-form"
               fields={fields}
               createMode
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
            <OperationItemFormModal
                operationItem={currentHoardItem}
                operation={Operation.SALE}
                withLots
                filterProducts={filterProducts}
                filterLots={filterLots}
                onSubmit={onSaveHoardItem}
            />
        </PanelWrapper>
    )
}
