import { Button } from '../../../../components/button/button'
import { FileTile, FileType, UploadButton } from '../..'
import { useEffect, useState } from 'react'

type Props = {
    id: string
    value?: File
    onChange: (value?: File) => void
}

export const FileField = (props: Props) => {
    const { id, value, onChange } = props

    const [file, setFile] = useState<File>()
    
    useEffect(() => {
        setFile(value)
    }, [value])

    const onChangeFile = (newFile?: File) => {
        setFile(newFile)
        onChange(newFile)
    }

    const onClickRemove = () => {
        setFile(undefined)
        onChange(undefined)
    }

    return (
        <div>
            <FileTile fileType={FileType.PDF} fileName={file?.name} />
            <div className="pt-2">
                <UploadButton
                    id={id}
                    fileTypes={[FileType.PDF]}
                    onChange={onChangeFile}
                />
                <Button
                    icon='trash-fill'
                    kind='secondary'
                    tooltip='Eliminar'
                    disabled={!file}
                    rightMargin
                    onClick={onClickRemove}
                />
            </div>
        </div>
    )
}
