import { PurchaseItem } from "../../.."
import { DataType, TableName } from "../../../../../tables"
import { Data, FieldList, FieldType, FieldValue, FormField, ModalId, RadioField, option, useUIActions } from "../../../../../features/ui"
import { Product, useProductsActions, Lot, LotAttribute, LotDataTable, useProductsHooks, NO_LOT } from "../../../../products"
import { useFind } from "../../../../../state/reducers/hooks"
import { define, firstItem } from "../../../../../utils/typeUtils"
import { WizardStep, useWizardActions, useWizardHooks } from "../../../../../features/wizard"
import { Section } from "../../../../../components/section/section"
import { useEffect, useState } from "react"
import { useDescriptorHooks } from "../../../../../features/data-types"

type Props = {
    wizardKey: string
    stepIndex: number
    purchaseItem: PurchaseItem
    pendingQuantity: number
    lotData: Data[]
    onCreateLotDataItem: () => void
    onEditLotDataItem: (attr: LotAttribute) => void
    onRemoveLotDataItem: (attr: LotAttribute) => void
}

export const PurchaseItemStep = (props: Props) => {
    const { wizardKey, stepIndex, purchaseItem, pendingQuantity, lotData, onCreateLotDataItem, onEditLotDataItem, onRemoveLotDataItem } = props
    
    const { getStepData } = useWizardHooks()
    const { getBaseLot } = useProductsHooks()
    const descriptorHooks = useDescriptorHooks()
    const find = useFind()
    const product = define(find(TableName.PRODUCTS, purchaseItem.productId)) as Product
    
    const productsActions = useProductsActions()
    const { toggleModal } = useUIActions()
    const { updatedData, saveData } = useWizardActions()
    const defaultData = getStepData(wizardKey, stepIndex)
    
    const createOption = option('create-new', 'Crear nueva partida')
    const selectOption = option('select-existing', 'Seleccionar partida existente')
    const noLotOption = option('no-lot', NO_LOT)
    const defaultChoice = product.loted ? createOption.value : noLotOption.value
    const stepDefaultChoice = defaultData?.choice as string | undefined
    
    const [lots, setLots] = useState<Lot[]>([])
    const [choice, setChoice] = useState<string | undefined>(stepDefaultChoice || defaultChoice)

    useEffect(() => {
        const onFetchLots = (lotsDT: DataType[]) => {
            setLots(lotsDT as Lot[])
            !defaultData?.lotId && saveWizardData('lotId', firstItem(lotsDT)?.id)
        }
        
        productsActions().fetchLots(product.id, onFetchLots)
        !stepDefaultChoice && saveWizardData('choice', defaultChoice)
    }, [])

    const saveWizardData = (key: string, value?: string) => {
        const newWizardData = updatedData(wizardKey, stepIndex, key, value)
        saveData(wizardKey, newWizardData)
    }

    const onChangeChoice = (value?: FieldValue) => {
        const newChoice = value as string | undefined
        setChoice(newChoice)
        saveWizardData('choice', newChoice)
    }
    
    const openFormModal = () => toggleModal(ModalId.LOT_DATA_FORM)

    const onCreateLotAttr = () => {
        onCreateLotDataItem()
        openFormModal()
    }

    const onEditLotAttr = (attr: string) => {
        onEditLotDataItem(attr as LotAttribute)
        openFormModal()
    }

    const handleChange = (field: FormField, value?: FieldValue) => {
        saveWizardData(field.name, value as string | undefined)
    }

    const onRemoveLotAttr = (attr: string) => onRemoveLotDataItem(attr as LotAttribute)

    const fields: FormField[] = [
        {
            name: 'productId',
            type: FieldType.TABLE,
            table: TableName.PRODUCTS,
            label: 'Producto',
            value: purchaseItem.productId,
            disabled: true
        },
        {
            name: 'pendingQuantity',
            type: FieldType.TEXT,
            label: 'Unidades Pendientes de Recepción',
            value: descriptorHooks.product.unitsDetail(pendingQuantity, product),
            disabled: true
        },
        {
            name: 'deliveredQuantity',
            type: FieldType.NUMBER,
            label: 'Unidades Recibidas',
            defaultValue: pendingQuantity,
            min: 0,
            max: pendingQuantity,
            allowDecimals: false,
            focusOnLoad: true
        }
    ]

    const renderLotSection = () => {
        const choiceOptions = [createOption, selectOption, noLotOption]
        
        const disabledOptions = lots.length === 0 ? [selectOption.value] : []
        const lotFields: FormField[] = [
            {
                name: 'choice',
                type: FieldType.SELECT,
                label: 'Partida',
                render: () => <RadioField
                    id='choice'
                    defaultValue={choice}
                    options={choiceOptions}
                    disabledOptions={disabledOptions}
                    onChange={onChangeChoice}
                />
            }
        ]

        if (choice === createOption.value) {
            const codeField: FormField = {
                name: 'lotCode',
                type: FieldType.TEXT,
                defaultValue: defaultData?.lotCode,
                label: 'Código'
            }
            const attributesField = {
                name: 'lotData',
                type: FieldType.TABLE,
                label: 'Atributos',
                hint: 'Definir tono, calibre y/o fecha de vencimiento.',
                render: () => <LotDataTable
                    rows={lotData}
                    onCreate={onCreateLotAttr}
                    onEditRow={onEditLotAttr}
                    onRemoveRow={onRemoveLotAttr}
                />
            }
            
            lotFields.push(codeField, attributesField)
        } else if (choice === selectOption.value && lots.length > 0) {
            const baseLotId = getBaseLot(product.id).id
            const lotOptions = lots
                .filter(lot => lot.id !== baseLotId)
                .map(lot => option(lot.id, lot.code))

            const lotField: FormField = {
                name: 'lotId',
                type: FieldType.TABLE,
                table: TableName.LOTS,
                defaultValue: defaultData?.lotId || firstItem(lotOptions)?.value,
                options: lotOptions,
                label: 'Código'
            }
    
            lotFields.push(lotField)
        }

        return (
            <Section id="lot" space={12}>
                <FieldList
                    fields={lotFields}
                    onChange={handleChange}
                />
            </Section>
        )
    }

    if (product.loted) {
        const lotFields: FormField = {
            name: 'lot',
            type: FieldType.TABLE,
            table: TableName.LOTS,
            render: renderLotSection
        }
        fields.push(lotFields)
    }

    return <WizardStep
        wizardKey={wizardKey}
        stepIndex={stepIndex}
        fields={fields}
    />
}
