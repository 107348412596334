export enum RoutePath {
    ACCESS = 'acceso',
    ADD = 'alta',
    ADMIN = '/admin',
    BRANCHES = 'sucursales',
    BUDGETS = 'presupuestos',
    CASH_FUNDS = 'cajas',
    CATEGORIES = 'categories',
    COMPANY = 'compania',
    CREATE = 'crear',
    CREATE_SALE = 'crear-venta',
    CUSTOMER_TYPES = 'tipos-de-cliente',
    CUSTOMERS = 'clientes',
    DELIVERY = 'entrega',
    EDIT = 'editar',
    EMPLOYEES = 'empleados',
    ERROR = '/error',
    EXPENSE_SUPPLIERS = 'proveedores-de-gasto',
    EXPENSE_TYPES = 'tipos-de-gasto',
    EXPENSES = 'gastos',
    HOARDS = 'acopios',
    PAYMENT_TYPES = 'medios-de-pago',
    PRICES = 'precios',
    PRODUCTS = 'productos',
    PURCHASES = 'compras',
    REQUESTS = 'solicitudes',
    SALARIES = 'salarios',
    SALE_YIELD = 'rentabilidad',
    SALES = 'ventas',
    SETTINGS = 'configuracion',
    STOCK = 'stock',
    SUPPLIERS = 'proveedores',
    SUBSTRACT = 'baja',
    TAXES = 'impuestos',
    TRANSACTIONS = 'transacciones',
    TRANSFER = 'transferir',
    UPDATE = 'actualizar',
    USER = 'usuario'
}
