import { DataType, TableName } from '../../../tables'
import { fileSubPath, useFileActions } from '../../files'
import { useDefaultModuleActions } from '../../../actions/defaultModuleActions'
import { DynamoUserApi } from '../../../services'
import { define } from '../../../utils/typeUtils'
import { useSystemStateHooks } from '../../../modules/system'

export const useDataTypeFileActions = () => {
    const { companyId } = useSystemStateHooks()

    const defaultModuleActions = useDefaultModuleActions()
    const { saveFile, removeFile } = useFileActions()

    const saveWithFile = async (
        tableName: TableName,
        dataType: DataType,
        fileUrlProp: keyof DataType,
        file?: File,
        isImage = false
    ): Promise<DataType> => {
        const { fetchByParams_async, save_async } = defaultModuleActions(DynamoUserApi, define(companyId))

        const newDataType = {
            ...dataType
        }

        if (file) {
            const fileUrl = await saveFile(fileSubPath(tableName, dataType.id), file, isImage)
            newDataType[fileUrlProp] = fileUrl
        } else {
            const stateDT = await fetchByParams_async(tableName, { id: dataType.id })
            if (stateDT && stateDT[fileUrlProp]) {
                await removeFile(stateDT[fileUrlProp], isImage)
            }
            delete (newDataType as Partial<DataType>)[fileUrlProp]
        }

        await save_async(tableName, newDataType)

        return newDataType
    }

    const saveWithImage = async (
        tableName: TableName,
        dataType: DataType,
        fileUrlProp: keyof DataType,
        file?: File
    ): Promise<DataType>  => {
        return saveWithFile(tableName, dataType, fileUrlProp, file, true)
    }

    const removeWithFile = async (
        tableName: TableName,
        id: string,
        fileUrlProp: keyof DataType,
        isImage = false
    ): Promise<void> => {
        const { fetchByParams_async, remove_async } = defaultModuleActions(DynamoUserApi, define(companyId))

        const stateDT = await fetchByParams_async(tableName, { id })
        if (stateDT && stateDT[fileUrlProp]) {
            await removeFile(stateDT[fileUrlProp], isImage)
        }

        await remove_async(tableName, id)
    }

    const removeWithImage = async (
        tableName: TableName,
        id: string,
        fileUrlProp: keyof DataType
    ) => {
        return removeWithFile(tableName, id, fileUrlProp, true)
    }

    return {
        saveWithFile,
        saveWithImage,
        removeWithFile,
        removeWithImage
    }
}
