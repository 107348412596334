import { ButtonKind } from "../../../components/button/button"
import { TableName } from "../../../tables"

export enum FieldType {
    TEXT = 'text',
    NUMBER = 'number',
    BOOLEAN = 'boolean',
    PRICE = 'price',
    PERCENTAGE = 'percentage',
    SELECT = 'select',
    DATE = 'date',
    IMAGE = 'image',
    FILE = 'file',
    TABLE = 'table'
}

export enum FormatType {
    DNI,
    CUIT_CUIL,
    PHONE
}

export type FieldValue = string | number | boolean | Date | File

export type FormFieldProps<T> = {
    key?: string
    name: string
    type: FieldType
    table?: TableName
    label?: string
    value?: T
    defaultValue?: T
    hint?: string
    options?: Option[]
    placeholder?: string
    min?: number | Date
    max?: number | Date
    allowDecimals?: boolean
    icon?: string
    optional?: boolean
    disabled?: boolean
    narrow?: boolean
    focusOnLoad?: boolean
    render?: () => JSX.Element
    renderOption?: (option: Option, onClick?: (e: React.MouseEvent<HTMLElement>) => void) => JSX.Element
    onChange?: (value?: T) => void
    onCreateNew?: () => void
    validate?: (data: Data) => string | null
}

export type FormField = FormFieldProps<FieldValue>

export type EditableFormField = FormField & {
    onSave?: (value?: FieldValue) => void
}

export type FilterField = FormField & {
    space?: number
    onChange: (value?: FieldValue) => void
}

export type Data = {
    [key: string]: FieldValue | undefined
}

export type Column = {
    name: string,
    type: FieldType
    table?: TableName
    label: string,
    render?: (value?: FieldValue) => string | JSX.Element
}

export type Option = {
    value: string
    label: string
}

export type DetailFieldProps = {
    name: string,
    type: FieldType
    table?: TableName
    label?: string,
    render?: (value?: FieldValue) => JSX.Element,
}

export type Tab = {
    id: string
    title: string
    content: JSX.Element
}

export type Action = {
    label?: string,
    kind?: ButtonKind,
    icon?: string,
    tooltip?: string,
    narrowTooltip?: boolean,
    disabled?: boolean,
    onClick: (value?: string) => void
}

export type Crumb = {
    label: string
    path?: string
    onClick?: () => void
}
