import { useEffect, useState } from "react"
import { useConstrufyAdminActions } from "../../../../actions/construfyAdminActions"
import { RequestStatus } from "../../../../modules/system"
import { UploadButton } from "../../../../components/button/upload-button/uploadButton_OLD"
import { useLoad } from "./load"
import { PanelLoader } from "../../../ui/components/panel-loader/panelLoader"

export const AdminElement = () => {
  const {
    createTables,
    createUnitTypes,
    createDefaultData,
    createCompany,
    createCompanyPolicies,
    saveConstrufyAdminPolicy,
    saveSystemPolicy,
    saveCompanyPolicies,
    resolveRequest,
    migrate,
    removeUser,
    importSuppliers
  } = useConstrufyAdminActions()
  
  const [ready, setReady] = useState(false)

  const load = useLoad()

  useEffect(() => {
    load().then(() => setReady(true))
  }, [])

  // const companyId = '81412324-60c0-4a29-aee3-0644f49c9cda' // comp
  // const companyId = 'a5278352-3f25-42d7-b3a6-aa15e642de56' // acme
  // const companyId = '4a1d9866-aa7d-458e-b521-d70710608ed1' // builder
  const companyId = '788ebe22-5f05-4664-8ee0-81a6538580b1' // base
  
  const [file, setFile] = useState<File>()
  
  const onSelectFile = (newFile?: File) => setFile(newFile)
  
  const onImportSuppliers = () => file && importSuppliers(file, companyId)

  return (
    <PanelLoader ready={ready}>
      <>
        <button onClick={createTables}>
          Create tables
        </button>
        <button onClick={() => createCompany('base', 'Base', 'base.com', 'info@base.com', 'Juncal 1000')}>
          Create company
        </button>
        <button onClick={createUnitTypes}>
          Create unit types
        </button>
        <button onClick={createDefaultData}>
          Create default data
        </button>
        <button onClick={() => createCompanyPolicies(companyId, 'base')}>
          Create company policies
        </button>
        <button onClick={saveConstrufyAdminPolicy}>
          Save CONSTRUFY ADMIN policy
        </button>
        <button onClick={saveSystemPolicy}>
          Save SYSTEM policy
        </button>
        <button onClick={saveCompanyPolicies}>
          Save company policies
        </button>
        <button onClick={() => resolveRequest('comp', 'travelmindblow92@gmail.com', RequestStatus.APPROVED)}>
          Approve company manager
        </button>
        <button onClick={migrate}>
          Migrate
        </button>
        <button onClick={() => removeUser('distinctplaces@gmail.com')}>
          Remove user
        </button>
        <UploadButton
            id='import-csv'
            label='Importar Archivo .csv'
            fileTypes='.csv'
            onSelectFile={onSelectFile}
        />
        <button onClick={onImportSuppliers}>
          Import SUPPLIERS
        </button>
      </>
    </PanelLoader>
  )
}
