import { PaginatedListCallback, ListResponse } from "../actions/types"
import { DataType } from "../tables"
import { PaginatedListCallback as DynamoPaginatedListCallback, ListResponse as DynamoListResponse } from "./dynamo"

export const toDynamoListCallback = (callback?: PaginatedListCallback): DynamoPaginatedListCallback =>
    (dynamoResponse) => {
        const response: ListResponse = {
            dataTypes: dynamoResponse.Items as DataType[],
            lastKey: dynamoResponse.LastEvaluatedKey
        }
        callback && callback(response)
    }

export const toListResponse = (dynamoResponse: DynamoListResponse): ListResponse => ({
    dataTypes: dynamoResponse.Items as DataType[],
    lastKey: dynamoResponse.LastEvaluatedKey
})
