import { useDefaultModuleActions } from "../../../actions/defaultModuleActions"
import { BaseCallback, ListCallback, OptionalItemCallback } from "../../../actions/types"
import { TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { useSystemStateHooks } from "../../system"
import { DynamoUserApi } from "../../../services"
import { Lot } from "../state/types"
import { TextParams } from "../../../state/reducers/types"

export const useLotsActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId

    const defaultModuleActions = useDefaultModuleActions()

    return (paramCompanyId?: string) => {
        const companyId = define(paramCompanyId || stateCompanyId)
        const { fetchAll, fetchByParams, fetchMultipleByParams, fetchMultipleByParams_async, save_async, remove, remove_async } = defaultModuleActions(DynamoUserApi, companyId)

        const fetchAllLots = async (limit?: number, startKey?: TextParams) => {
            return fetchAll(TableName.LOTS, limit, startKey)
        }

        const fetchLot = (id: string, callback?: OptionalItemCallback) => {
            fetchByParams(TableName.LOTS, { id }, callback)
        }

        const fetchLots = (productId: string, callback?: ListCallback) => {
            fetchMultipleByParams(TableName.LOTS, { productId }, callback)
        }

        const fetchLots_async = (productId: string) => {
            return fetchMultipleByParams_async(TableName.LOTS, { productId })
        }

        const saveLot = (lot: Lot) => {
            return save_async(TableName.LOTS, lot)
        }

        const removeLot = (id: string, callback?: BaseCallback) => {
            remove(TableName.LOTS, id, callback)
        }

        const removeLot_async = (id: string) => {
            return remove_async(TableName.LOTS, id)
        }

        return {
            fetchAllLots,
            fetchLot,
            fetchLots,
            fetchLots_async,
            saveLot,
            removeLot,
            removeLot_async
        }
    }
}
