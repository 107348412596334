import { useState } from "react"
import { PanelWrapper } from "../../../../../components/wrappers/panel-wrapper/panelWrapper"
import { DataTypeList, useDataTypesHooks } from "../../../../../features/data-types"
import { Column, Data, FieldType, FieldValue, FormatType, SpinnerDiv, UIPermission } from "../../../../../features/ui"
import { DataType } from "../../../../../tables"
import { taxCategoryLabel } from "../../../../../tables/types/labels"
import { define } from "../../../../../utils/typeUtils"
import { TaxCategory } from "../../../../operations"
import { useSystemStateHooks } from "../../../../system"
import { Supplier } from "../../../state/types"
import { SupplierFilters } from "./supplierFilters"
import { Badge } from "../../../../../components/badge/badge"

type Props = {
    productSupplier?: boolean
}

export const SupplierList = (props: Props) => {
    const { productSupplier = true } = props

    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    
    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_SUPPLIERS)
    const { formatCustomValue } = useDataTypesHooks()

    const title = `Proveedores${productSupplier ? '' : ' de Gasto'}`

    const columns: Column[] = [
        {
            name: 'code',
            type: FieldType.NUMBER,
            label: '#',
            render: (value?: FieldValue) => `#${value}`
        },
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre'
        },
        {
            name: 'taxId',
            type: FieldType.NUMBER,
            label: 'CUIT / CUIL',
            render: (value?: FieldValue) => {
                const taxId = define(value) as number
                return formatCustomValue(taxId, FormatType.CUIT_CUIL)
            }
        },
        {
            name: 'taxCategory',
            type: FieldType.TEXT,
            label: 'Cateogría Fiscal',
            render: (value?: FieldValue) => <Badge label={taxCategoryLabel(define(value) as TaxCategory)} />
        }
    ]

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRows(dataTypes as Supplier[])
        setAsyncDataLoaded(true)
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <PanelWrapper title={title} wide>
            <SupplierFilters
                productSupplier={productSupplier}
                onSearchStart={onSearchStart}
                onSearchDone={onSearchDone}
            />
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    readOnly={readOnly}
                    showDetail={false}
                />
            )}
        </PanelWrapper>
    )
}
