import styles from './textField.module.css'
import fieldStyles from '../fields.module.css'
import { useEffect, useMemo, useRef, useState } from 'react'
import { define } from '../../../utils/typeUtils';
import { StyledText } from '../../styled-text/styledText';
import { useOutsideClickHandler } from '../../hooks';
import { CustomOnClickEvent, CustomOnDivClickEvent } from '../../styled-text/types';
import { getOptions } from './utils';
import { Option, Validatable } from '../../../features/ui';

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    id: string
    inputKey?: string
    value?: string
    defaultValue?: string
    freeValue?: boolean
    options?: Option[]
    icon?: string,
    optional?: boolean
    focusOnLoad?: boolean
    validation?: string
    className?: string
    searchFn?: (text?: string) => Option[]
    renderOption?: (option: Option, onClick?: (e: React.MouseEvent<HTMLElement>) => void) => JSX.Element
    onChange?: (value?: string) => void
    onInputChange?: (value?: string) => void
}

export const TextField = (props: Props) => {
    const { id, inputKey, value, defaultValue, freeValue, options, icon, optional, focusOnLoad, validation, className, searchFn, renderOption, onChange, onInputChange, ...rest } = props
    const getLabel = (strValue?: string) =>
        (freeValue ? strValue : options?.find(option => option.value === strValue)?.label) || ''
    const getValue = (strLabel?: string) =>
        (freeValue ? strLabel : options?.find(option => option.label === strLabel)?.value) || undefined
    const [stateValue, setStateValue] = useState(getLabel(defaultValue))
    const [optionsVisible, setOptionsVisible] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [firstFocus, setFirstFocus] = useState(true)
    const [inputWidth, setInputWidth] = useState(0)
    const hideOptions = () => setOptionsVisible(false)
    const showOptions = () => {
        setOptionsVisible(true)
        setSelectedIndex(0)
    }
    const inputRef = useRef<HTMLInputElement>(null)
    const iconRef = useRef<any>(null)
    const optionsRef = useRef<HTMLDivElement>(null)
    const refreshInputWidth = () => setInputWidth(inputRef.current?.clientWidth || 0)
    useOutsideClickHandler([inputRef, iconRef, optionsRef], hideOptions)

    const optionsList = useMemo(() =>
        searchFn ? searchFn(stateValue) : getOptions(options || [], stateValue, freeValue),
    [searchFn, stateValue, options, freeValue])

    useEffect(() => {
        if (inputRef?.current) {
            const resizeObserver = new ResizeObserver(refreshInputWidth)
            resizeObserver.observe(inputRef.current)
            return () => resizeObserver.disconnect()
        }
    }, [])

    useEffect(() => {
        const handleResize = () => {
            refreshInputWidth()
            setTimeout(refreshInputWidth, 1000)
        }
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        if (selectedIndex !== -1) {
            const selectedOption = optionsRef.current?.children[selectedIndex]
            selectedOption?.scrollIntoView({ block: 'nearest' })
        }
        
        const handleKeyDown = (e: KeyboardEvent) => {
            const focused = inputRef.current && inputRef.current === document.activeElement
            if (!focused) {
                return
            } else if (e.key === 'Escape') {
                hideOptions()
            } else if (e.key === 'ArrowUp') {
                setSelectedIndex(Math.max(selectedIndex - 1, -1))
            } else if (e.key === 'ArrowDown') {
                setSelectedIndex(Math.min(selectedIndex + 1, optionsList.length - 1))
            } else if (e.key === 'Enter' && selectedIndex !== -1) {
                e.preventDefault()
                const selectedValue = optionsRef.current?.children[selectedIndex].id
                selectedValue && selectOption(selectedValue)
            }
        }
        document.addEventListener('keydown', handleKeyDown)
        return () => document.removeEventListener('keydown', handleKeyDown)
    }, [selectedIndex, stateValue])

    useEffect(() => {
        value && setValue(getLabel(value))
    }, [value])

    useEffect(() => {
        focusOnLoad && firstFocus && inputRef && inputRef.current?.focus()
    }, [focusOnLoad, firstFocus, inputRef])

    const setValueLabel = (value?: string) => setValue(getLabel(value))

    const setValue = (newValue: string) => {
        setStateValue(newValue)
        onChange && onChange(getValue(newValue))
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target?.value
        setValue(newValue)
        !optionsVisible && showOptions()
        onInputChange && onInputChange(newValue)
    }

    const handleFieldClick = (e: React.MouseEvent<HTMLInputElement>) => {
        !freeValue && !optionsVisible && showOptions()
    }

    const handleBlur = (e: React.FocusEvent<HTMLElement>) => {
        if (!e.relatedTarget || (
                e.relatedTarget !== inputRef.current &&
                e.relatedTarget !== iconRef.current &&
                e.relatedTarget !== optionsRef.current
            )) {
                hideOptions()
                const selectedOption = options?.find(option => option.label === stateValue)
                !freeValue && !selectedOption && setValue('')
        }
    }

    const selectOption = (optionValue: string) => {
        setValueLabel(optionValue)
        hideOptions()
        setSelectedIndex(-1)
        inputRef.current?.focus()
    }

    const handleOptionClick = (e: CustomOnDivClickEvent) => selectOption(define(e.target?.id))

    const handleOptionChildClick = (optionValue: string) => {
        const onChildClick = (e: CustomOnClickEvent) => {
            e.stopPropagation && e.stopPropagation()
            e.target?.parentElement?.click({ target: { id: optionValue }})
        }
        return onChildClick as (e: React.MouseEvent<HTMLElement>) => void
    }

    const handleInputFocus = () => {
        if (freeValue) {
            if (firstFocus) {
                setTimeout(showOptions, 1000)
                setFirstFocus(false)
            }
        } else {
            inputRef?.current?.select()
        }
    }

    const optionsStyle = { width: inputWidth || 500 }

    return (
        <div className={styles.container}>
            <Validatable validations={validation ? [validation] : []}>
                <input
                    type='text'
                    id={id}
                    key={inputKey}
                    name={id}
                    value={stateValue}
                    onChange={handleChange}
                    onClick={handleFieldClick}
                    required={!optional}
                    className={`form-control ${className || ''}`}
                    onFocus={handleInputFocus}
                    onBlur={handleBlur}
                    ref={inputRef}
                    autoComplete="off"
                    {...rest}
                />
            </Validatable>
            {(!freeValue || icon) && (
                <i
                    className={`bi bi-${icon || 'chevron-down'} ${styles.arrowIcon}`}
                    onClick={handleFieldClick}
                    ref={iconRef}
                />
            )}
            {optionsVisible && optionsList.length > 0 && (
                <div
                    ref={optionsRef}
                    className={`${styles.options} ${fieldStyles.topMargin}`}
                    style={optionsStyle}
                    onBlur={handleBlur}
                    tabIndex={0}
                >
                    {optionsList.map((option, index) => {
                        return (
                            <div
                                key={option.value}
                                id={option.value}
                                onClick={handleOptionClick}
                                className={`${styles.option} ${index === selectedIndex ? styles.selected : ''}`}
                            >
                                {renderOption ? renderOption(option, handleOptionChildClick(option.value)) : (
                                    <StyledText
                                        text={option.label}
                                        highlighted={stateValue as string}
                                        className={styles.optionLabel}
                                        onClick={handleOptionChildClick(option.value)}
                                    />
                                )}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}
