import { Action, TableActions } from "../../../features/roles";
import { readPermissions } from '../../../permissions/permissions'
import { TableName } from '../../../tables'

export const warehousePermissions = (): TableActions[] => readPermissions().concat([
    { tableName: TableName.EMPLOYEES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.ORDER_DELIVERIES, actions: [Action.SAVE] },
    { tableName: TableName.PURCHASE_ORDERS, actions: [Action.SAVE] },
    { tableName: TableName.SALE_ORDERS, actions: [Action.SAVE] },
    { tableName: TableName.STOCK, actions: [Action.SAVE] }
])
