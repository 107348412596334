import styles from './textArea.module.css'

type Props = {
    id: string
    defaultValue?: string
    optional?: boolean
    disabled?: boolean
    className?: string
    onChange: (value?: string) => void
}

export const TextArea = (props: Props) => {
    const { id, defaultValue, optional, disabled, className, onChange } = props
    
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e.target.value)
    }

    return (
        <textarea
            id={id}
            name={id}
            defaultValue={defaultValue}
            required={!optional}
            disabled={disabled}
            className={`form-control ${styles.textarea} ${className || ''}`}
            onChange={handleChange}
        />
    )
}
