import App from '../../app';
import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { AdminElement } from './components/admin-element/adminElement';
import { IndexElement } from './components/index-element/indexElement';
import { CompanyElement } from './components/company-element/companyElement';
import { NotFoundElement } from './components/not-found-element/notFoundElement';
import { AccessRequestElement } from './components/access-request-element/accessRequestElement';
import { StockDetail, StockForm, StockList } from '../../modules/stock';
import { CategoryForm, CategoryList, ImportPricesPanel, ProductDetail, ProductForm, ProductList } from '../../modules/products';
import { RoutePath } from './types';
import { StockAction } from '../ui';
import { IdElement } from './components/id-element/idElement';
import { CashFundForm, CashFundList, CompanyPanel, EmployeeForm, EmployeeList, ExpenseDetail, ExpenseForm, ExpenseList, ExpenseSupplierForm, ExpenseSupplierList, ExpenseTypeForm, ExpenseTypeList, PaymentTypeForm, PaymentTypeList, SalaryForm, SalaryList, TransactionList, TransferCashFundForm } from '../../modules/administration';
import { BranchDetail, BranchList, RequestList } from '../../modules/manager';
import { PurchaseDeliveryForm, PurchaseDetail, PurchaseForm, PurchaseList, PurchasesSettingsPanel, SupplierForm, SupplierList } from '../../modules/purchases';
import { AdminSettingsPanel } from '../../components/panels/admin-settings-panel/adminSettingsPanel';
import { BudgetDetail, BudgetForm, BudgetList, CustomerForm, CustomerList, CustomerTypeForm, CustomerTypeList, HoardDeliveryForm, HoardDetail, HoardForm, HoardList, RefreshBudgetForm, SaleDeliveryForm, SaleDetail, SaleForm, SaleList, SalesSettingsPanel } from '../../modules/sales';
import { UserPanel } from '../../modules/system';

export const Router = () => createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />}>
            <Route path="" element={<IndexElement />} />
            
            <Route path={RoutePath.ADMIN} element={<AdminElement />} />
            
            <Route path="/:companyCode" element={<CompanyElement />}>
                <Route path={RoutePath.ACCESS} element={<AccessRequestElement />} />

                <Route path={RoutePath.STOCK}>
                    <Route path="" element={<StockList />} />
                    
                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<StockDetail />} />
                        <Route path={RoutePath.ADD} element={<StockForm />} />
                        <Route path={RoutePath.SUBSTRACT} element={<StockForm stockAction={StockAction.SUBSTRACT} />} />
                    </Route>
                </Route>
               
                <Route path={RoutePath.PRODUCTS}>
                    <Route path="" element={<ProductList />} />

                    <Route path={RoutePath.PRICES} element={<ImportPricesPanel />} />

                    <Route path={RoutePath.CREATE} element={<ProductForm />} />
                    
                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<ProductDetail />} />
                        <Route path={RoutePath.EDIT} element={<ProductForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.COMPANY} element={<CompanyPanel />} />

                <Route path={RoutePath.BRANCHES}>
                    <Route path="" element={<BranchList />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<BranchDetail />} />
                    </Route>
                </Route>

                <Route path={RoutePath.REQUESTS} element={<RequestList />} />

                <Route path={RoutePath.EMPLOYEES}>
                    <Route path="" element={<EmployeeList />} />

                    <Route path={RoutePath.CREATE} element={<EmployeeForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path={RoutePath.EDIT} element={<EmployeeForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.SALARIES}>
                    <Route path="" element={<SalaryList />} />

                    <Route path={RoutePath.CREATE} element={<SalaryForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path={RoutePath.EDIT} element={<SalaryForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.CASH_FUNDS}>
                    <Route path="" element={<CashFundList />} />
                    
                    <Route path={RoutePath.CREATE} element={<CashFundForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path={RoutePath.EDIT} element={<CashFundForm />} />
                        <Route path={RoutePath.TRANSFER} element={<TransferCashFundForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.TRANSACTIONS} element={<TransactionList />} />

                <Route path={RoutePath.EXPENSES}>
                    <Route path="" element={<ExpenseList />} />
                    
                    <Route path={RoutePath.CREATE} element={<ExpenseForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<ExpenseDetail />} />
                        <Route path={RoutePath.EDIT} element={<ExpenseForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.EXPENSE_TYPES}>
                    <Route path="" element={<ExpenseTypeList />} />
                    
                    <Route path={RoutePath.CREATE} element={<ExpenseTypeForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path={RoutePath.EDIT} element={<ExpenseTypeForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.EXPENSE_SUPPLIERS}>
                    <Route path="" element={<ExpenseSupplierList />} />
                    
                    <Route path={RoutePath.CREATE} element={<ExpenseSupplierForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path={RoutePath.EDIT} element={<ExpenseSupplierForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.PAYMENT_TYPES}>
                    <Route path="" element={<PaymentTypeList />} />
                    
                    <Route path={RoutePath.CREATE} element={<PaymentTypeForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path={RoutePath.EDIT} element={<PaymentTypeForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.TAXES} element={<AdminSettingsPanel />} />

                <Route path={RoutePath.PURCHASES}>
                    <Route path="" element={<PurchaseList />} />
                    
                    <Route path={RoutePath.CREATE} element={<PurchaseForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<PurchaseDetail />} />
                        <Route path={RoutePath.DELIVERY} element={<PurchaseDeliveryForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.SUPPLIERS}>
                    <Route path="" element={<SupplierList />} />
                    
                    <Route path={RoutePath.CREATE} element={<SupplierForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path={RoutePath.EDIT} element={<SupplierForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.BUDGETS}>
                    <Route path="" element={<BudgetList />} />
                    
                    <Route path={RoutePath.CREATE} element={<BudgetForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<BudgetDetail />} />
                        <Route path={RoutePath.UPDATE} element={<RefreshBudgetForm />} />
                        <Route path={RoutePath.CREATE_SALE} element={<SaleForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.SALES}>
                    <Route path="" element={<SaleList />} />
                    
                    <Route path={RoutePath.CREATE} element={<SaleForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<SaleDetail />} />
                        <Route path={RoutePath.DELIVERY} element={<SaleDeliveryForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.HOARDS}>
                    <Route path="" element={<HoardList />} />
                    
                    <Route path={RoutePath.SETTINGS} element={<SalesSettingsPanel />} />

                    <Route path={RoutePath.CREATE} element={<HoardForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<HoardDetail />} />
                        <Route path={RoutePath.DELIVERY} element={<HoardDeliveryForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.CATEGORIES}>
                    <Route path="" element={<CategoryList />} />
                    
                    <Route path={RoutePath.CREATE} element={<CategoryForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path={RoutePath.EDIT} element={<CategoryForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.SALE_YIELD} element={<PurchasesSettingsPanel />} />

                <Route path={RoutePath.CUSTOMERS}>
                    <Route path="" element={<CustomerList />} />
                    
                    <Route path={RoutePath.CREATE} element={<CustomerForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path={RoutePath.EDIT} element={<CustomerForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.CUSTOMER_TYPES}>
                    <Route path="" element={<CustomerTypeList />} />
                    
                    <Route path={RoutePath.CREATE} element={<CustomerTypeForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path={RoutePath.EDIT} element={<CustomerTypeForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.USER} element={<UserPanel />} />
            </Route>
            
            <Route path={RoutePath.ERROR} element={<NotFoundElement />} />

            <Route path="*" element={<Navigate to={RoutePath.ERROR} />} />
        </Route>
    )
)
