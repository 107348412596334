import styles from './budgetPDFPage.module.css'
import { Budget, BudgetItem, Customer } from "../../../state/types"
import { useSystemState } from '../../../../system'
import { define } from '../../../../../utils/typeUtils'
import { useEffect, useMemo, useState } from 'react'
import { useSalesActions } from '../../../actions/salesActions'
import { DataType } from '../../../../../tables'
import { Employee, useAdministrationActions, useAdministrationState } from '../../../../administration'
import { Product, useProductsActions } from '../../../../products'
import { useDataTypesHooks, useDescriptorHooks } from '../../../../../features/data-types'
import { useManagerState } from '../../../../manager'
import { usePricesHooks } from '../../../../operations'
import { FieldType } from '../../../../../features/ui'
import { BudgetItemPDFRow } from './types'
import { EmptyImgImg } from '../../../../../components/constants'

type Props = {
    pageIndex: number
    budget: Budget
    budgetItems: BudgetItem[]
    pagesAmount: number
}

export const BudgetPDFPage = (props: Props) => {
    const { pageIndex, budget, budgetItems, pagesAmount } = props
    const isLastPage = pageIndex === pagesAmount - 1
    
    const { company } = useSystemState()
    const definedCompany = define(company)
    const { branches } = useManagerState()
    const { paymentTypes } = useAdministrationState()

    const { formatValue } = useDataTypesHooks()
    const descriptorHooks = useDescriptorHooks()
    const pricesHooks = usePricesHooks()

    const administrationActions = useAdministrationActions()
    const productsActions = useProductsActions()
    const salesActions = useSalesActions()

    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [products, setProducts] = useState<Product[]>([])
    const [customer, setCustomer] = useState<Customer>()
    const [seller, setSeller] = useState<Employee>()
    const [subtotalPrice, setSubtotalPrice] = useState<number>()
    const [priceDiscount, setPriceDiscount] = useState<number>()

    useEffect(() => {
        const onFetchEmployee = (employeeDT?: DataType) => {
            setSeller(define(employeeDT) as Employee)
            setAsyncDataLoaded(true)
        }
        const onFetchCustomer = (customerDT?: DataType) => {
            setCustomer(define(customerDT) as Customer)   
            administrationActions().fetchEmployee(budget.sellerId, onFetchEmployee)
        }
        const onFetchProducts = (stateProductsDT: DataType[]) => {
            setProducts(stateProductsDT as Product[])
            salesActions().fetchCustomer(budget.customerId, onFetchCustomer)
        }
        
        const fullPrice = pricesHooks.sale.fullPriceFromItems(budgetItems)
        setSubtotalPrice(fullPrice)
        setPriceDiscount(budget.totalPrice - fullPrice)
        productsActions().fetchProductsByIds(budgetItems.map(budgetItem => budgetItem.productId), onFetchProducts)
    }, [budget, budgetItems])

    const columns = [
        { name: 'product', label: 'Producto' },
        { name: 'content', label: 'Contenido' },
        { name: 'price', label: 'P. Unitario' },
        { name: 'quantity', label: 'Cantidad' },
        { name: 'finalPrice', label: 'P. Final' },
    ]

    const rows: BudgetItemPDFRow[] = useMemo(() => {
        return asyncDataLoaded ? budgetItems.map(budgetItem => {
            const product = define(products.find(product => product.id === budgetItem.productId)) as Product
            return {
                product: product.name,
                content: descriptorHooks.product.content(product),
                price: formatValue(budgetItem.price, FieldType.PRICE),
                quantity: descriptorHooks.product.unitsDetail(budgetItem.quantity, product),
                finalPrice: formatValue(budgetItem.quantity * budgetItem.price, FieldType.PRICE)
            }
        }) : []
    }, [asyncDataLoaded])

    const branchesInfo = branches.map(branch => {
        const mailInfo = branch.email ? ` - Mail: ${branch.email}` : ''
        return `Sucursal ${branch.name}: ${branch.address}${mailInfo}`
    })

    const paymentTypesInfo = paymentTypes
        .sort((paymentType1, paymentType2) => paymentType1.yield - paymentType2.yield)
        .map(paymentType => `${paymentType.name} (${formatValue(paymentType.yield, FieldType.PERCENTAGE)})`)
        .map(info => info.replace('Tarjeta de ', ''))
        .join(', ')

    return (
        <div className={styles.container}>
            <div className={styles.body}>                
                <div className={styles.row}>
                    <div className={styles.header}>
                        {/* <img src={definedCompany.logoUrl || EmptyImgImg} className={styles.headerImg} /> */}
                        <img src={EmptyImgImg} alt={''} className={styles.headerImg} />
                        <span className={styles.headerTitle}>
                            {definedCompany.name}
                        </span>
                    </div>
                    <div className={styles.noValidTicket}>
                        <span className={styles.noValidTicketX}>X</span>
                        <div className={styles.noValidTicketText}>
                            Documento NO Válido como Factura
                        </div>
                    </div>
                </div>

                <div className={styles.separator} />

                {pageIndex === 0 && (<>
                    <div className={styles.row}>
                        <span className={styles.budgetTitle}>
                            Presupuesto #{budget.code}
                        </span>
                        <div className={styles.date}>
                            Fecha: {formatValue(new Date(budget.date), FieldType.DATE)}
                        </div>
                    </div>

                    <div className={`${styles.row} ${styles.alignStart}`}>
                        <div className={styles.personalInfo}>
                            <span>Cliente: {customer?.name}</span>
                            <span>Dirección: {customer?.address || '-'}</span>
                            <span>DNI/CUIT: {customer?.identifier}</span>
                            <span>Teléfono: {customer?.phone || '-'}</span>
                        </div>
                        <div className={styles.personalInfo}>
                            <span>Vendedor: {seller?.name}</span>
                            <span>Teléfono: {seller?.phone || '-'}</span>
                        </div>
                    </div>
                </>)}

                <div className={styles.tableRow}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                {columns.map((column, index) => (
                                    <th key={index}>
                                        {column.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                                {rows.map((row, index) => (
                                    <tr key={index}>
                                        {columns.map((column, index) => (
                                            <td key={index}>
                                                {row[column.name as keyof BudgetItemPDFRow]}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                <div className={styles.pagination}>
                    {pageIndex + 1} / {pagesAmount}
                </div>
                

                {isLastPage && (
                    <div className={`${styles.row} ${styles.priceDetail}`}>
                        <span>
                            Subtotal: {formatValue(subtotalPrice, FieldType.PRICE)}
                        </span>
                        <span>
                            Descuento: {formatValue(priceDiscount, FieldType.PRICE)}
                        </span>
                        <span className={styles.totalPrice}>
                            TOTAL: {formatValue(budget.totalPrice, FieldType.PRICE)}
                        </span>
                    </div>
                )}

                <div className={styles.footer}>
                    <div className={styles.separator} />
                    <div className={`${styles.row} ${styles.footerRow}`}>
                        {branchesInfo.map((branchInfo, index) => (
                            <span key={index}>{branchInfo}</span>
                        ))}
                        <span className={styles.marginTop}>{paymentTypesInfo}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
