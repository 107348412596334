import { Lot, Product } from "../../../modules/products"
import { PurchaseOrder } from "../../../modules/purchases"
import { Budget, HoardOrder, SaleOrder } from "../../../modules/sales"
import { Stock, UnitType } from "../../../modules/stock"
import { useFind, useFindByParams, useFindMultipleByParams } from "../../../state/reducers/hooks"
import { TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { FieldType } from "../../ui"
import { useDataTypesHooks } from "./dataTypesHooks"

export const useDescriptorHooks = () => {
    const { formatValue } = useDataTypesHooks()
    const find = useFind()
    const findByParams = useFindByParams()
    const findMultipleByParams = useFindMultipleByParams()

    const content = (units: number, unitTypeId: string): string => {
        const unitType = define(find(TableName.UNIT_TYPES, unitTypeId)) as UnitType
        return `${formatValue(units, FieldType.NUMBER)} ${unitType.abbreviation}`
    }

    const productCode = (product: Product) => `#${product.code}`

    const productContent = (product: Product): string => {
        return content(product.units, product.unitTypeId)
    }

    const unitsDetail = (
        units: number,
        product: Product
    ) => {
        const totalUnits = units * product.units
        const contentInfo = totalUnits && product.units !== 1 ? ` (${content(totalUnits, product.unitTypeId)})` : ''
        return `${formatValue(units, FieldType.NUMBER)}${contentInfo}`
    }

    const productAvailableStock = (
        product: Product,
        branchId: string
    ): string => {
        const stockList = findMultipleByParams(TableName.STOCK, { productId: product.id, branchId }) as Stock[]
        const units = stockList.reduce((sum, stock) => sum + stock.availableUnits, 0)
        return unitsDetail(units, product)
    }

    const productReservedStock = (
        product: Product,
        branchId: string
    ): string => {
        const stockList = findMultipleByParams(TableName.STOCK, { productId: product.id, branchId }) as Stock[]
        const units = stockList.reduce((sum, stock) => sum + stock.reservedUnits, 0)
        return unitsDetail(units, product)
    }

    const productLotAvailableStock = (
        product: Product,
        branchId: string,
        lot: Lot
    ): string => {
        const params = { productId: product.id, branchId, lotId: lot.id }
        const stock = findByParams(TableName.STOCK, params) as Stock | undefined
        const units = stock?.availableUnits || 0
        return unitsDetail(units, product)
    }

    const productLotReservedStock = (
        product: Product,
        branchId: string,
        lot: Lot
    ): string => {
        const params = { productId: product.id, branchId, lotId: lot.id }
        const stock = findByParams(TableName.STOCK, params) as Stock | undefined
        const units = stock?.reservedUnits || 0
        return unitsDetail(units, product)
    }

    const lot = {
        availableStock: productLotAvailableStock,
        reservedStock: productLotReservedStock,
    }

    const product = {
        code: productCode,
        content: productContent,
        unitsDetail,
        availableStock: productAvailableStock,
        reservedStock: productReservedStock,
        lot
    }

    const purchaseCode = (purchase: PurchaseOrder) => `#${purchase.code}`

    const purchase = {
        code: purchaseCode
    }

    const budgetCode = (budget: Budget) => `#${budget.code}`

    const budget = {
        code: budgetCode
    }

    const saleCode = (sale: SaleOrder) => `#${sale.code}`

    const sale = {
        code: saleCode
    }

    const hoardCode = (hoard: HoardOrder) => `#${hoard.code}`

    const hoard = {
        code: hoardCode
    }

    return {
        content,
        product,
        purchase,
        budget,
        sale,
        hoard
    }
}
