import styles from './pagination.module.css'
import { useEffect, useState } from 'react'
import { Icon } from '../../../../components/icon/icon'

type Props = {
    pagesAmount: number
    page?: number
    onGoToPage: (page: number) => void
}

export const Pagination = (props: Props) => {
    const { pagesAmount, page = 1, onGoToPage } = props

    const [currentPage, setCurrentPage] = useState<number>(page)

    useEffect(() => setCurrentPage(page), [page])

    if (pagesAmount <= 1) {
        return <></>
    }
    
    const goToPreviousPage = () => {
        if (currentPage > 1) {
            const newPage = currentPage - 1
            setCurrentPage(newPage)
            onGoToPage(newPage)
        }
    }

    const goToNextPage = () => {
        if (currentPage < pagesAmount) {
            const newPage = currentPage + 1
            setCurrentPage(newPage)
            onGoToPage(newPage)
        }
    }

    return (
        <div className={styles.arrows}>
            <div className={styles.arrowContainer}>
                {currentPage > 1 && (
                    <Icon
                        icon='arrow-left-circle'
                        color='tertiary'
                        className={styles.arrowIcon}
                        onClick={goToPreviousPage}
                    />
                )}
            </div>
            <span className={styles.pagesText}>
                Pág. {currentPage} de {pagesAmount}
            </span>
            <div className={styles.arrowContainer}>
                {currentPage < pagesAmount && (
                    <Icon
                        icon='arrow-right-circle'
                        color='tertiary'
                        className={styles.arrowIcon}
                        onClick={goToNextPage}
                    />
                )}
            </div>
        </div>
    )
}
