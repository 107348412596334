import { ListResponse } from "../../actions/types"
import { DynamoUserApi } from "../../services"
import { AttributeMap, useDynamoActions } from "../../services/dynamo"
import { toDynamoListCallback } from "../../services/utils"
import { DataType, TableName, TablesData } from "../../tables"
import { define } from "../../utils/typeUtils"

// todavia no lo probe
export const useChangedIdMigration = () => {
    const dynamoActions = useDynamoActions()

    return (
        companyId: string,
        dataType: DataType,
        tablesToUpdate: TableName[],
        propName: string
    ) => {
        const { getAll, createOrUpdateMultiple } = dynamoActions(DynamoUserApi, companyId)
        tablesToUpdate.forEach(tableToUpdate => {
            const { dbTableName } = define(TablesData.get(tableToUpdate))
            const onGetAll = (response: ListResponse) => {
                const updatedDataTypes = response.dataTypes
                    .filter(dataTypeToUpdate => dataTypeToUpdate[propName as keyof DataType] === dataType.id)
                    .map(dataTypeToUpdate => ({
                        ...dataTypeToUpdate,
                        [propName]: dataType.id
                    })) as AttributeMap[]
                createOrUpdateMultiple(dbTableName, updatedDataTypes)
            }
            getAll(dbTableName, undefined, undefined, undefined, toDynamoListCallback(onGetAll))
        })
    }
}
