import { Data, FieldType, useUIStateActions } from '../../../../../features/ui'
import { WizardPanel, useWizardActions, useWizardHooks } from '../../../../../features/wizard'
import { define } from '../../../../../utils/typeUtils'
import { PurchaseItem, PurchaseOrder, usePurchasesActions } from '../../..'
import { DataType } from '../../../../../tables'
import { ReactElement, useEffect, useState } from 'react'
import { useDataTypesHooks, useDescriptorHooks } from '../../../../../features/data-types'
import { OrderDelivery, useOperationsActions, useOperationsHooks } from '../../../../operations'
import { purchaseWizardKey } from '../../../utils/purchaseUtils'
import { useActions } from './actions'
import { PurchaseItemData } from './types'
import { PurchaseInfoStep } from './purchaseInfoStep'
import { PurchaseItemStep } from './purchaseItemStep'
import { LotDataFormModal, LotAttribute } from '../../../../products'
import { toLotAttributesList } from './utils'
import { useRoutesHooks } from '../../../../../features/routes'

export const PurchaseDeliveryForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const purchase = define(dataType) as PurchaseOrder
    const wizardKey = purchaseWizardKey(purchase)

    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const [purchaseItems, setPurchaseItems] = useState<PurchaseItem[]>([])
    const [deliveries, setDeliveries] = useState<OrderDelivery[]>([])
    const [steps, setSteps] = useState<ReactElement[]>([])
    const [purchaseItemsData, setPurchaseItemsData] = useState<PurchaseItemData[]>([])
    const [lotData, setLotData] = useState<Data[]>([])
    const [lotDataStr, setLotDataStr] = useState<Data[]>([])
    const [currentLotDataItem, setCurrentLotDataItem] = useState<Data>()

    const { getStepData } = useWizardHooks()
    const descriptorHooks = useDescriptorHooks()
    const { getPendingQuantity } = useOperationsHooks()
    const { formatValue } = useDataTypesHooks()

    const { finishDelivery } = useActions()
    const { setDisableBranchSelector } = useUIStateActions()
    const purchasesActions = usePurchasesActions()
    const operationsActions = useOperationsActions()
    const { updatedData, saveData } = useWizardActions()

    useEffect(() => {
        setDisableBranchSelector(true)

        operationsActions().fetchDeliveries(purchase.id).then((deliveriesDT: DataType[]) => {
            setDeliveries(deliveriesDT as OrderDelivery[])
            purchasesActions().fetchPurchaseItemsByOrder(purchase.id).then((purchaseItemsDT: DataType[]) => {
                const sortedPurchaseItems = purchaseItemsDT.sort((item1, item2) => item1.id > item2.id ? 1 : -1) as PurchaseItem[]
                setPurchaseItems(sortedPurchaseItems)
            })
        })

        return () => {
            setDisableBranchSelector(false)
        }
    }, [])

    useEffect(() => {
        const newLotDataStr = lotData.map(lotDataItem => lotDataItem.lotAttr === LotAttribute.DUE_DATE ? {
            lotAttr: LotAttribute.DUE_DATE,
            lotAttrValue: formatValue(lotDataItem.lotAttrValue, FieldType.DATE)
        }: lotDataItem)
        setLotDataStr(newLotDataStr)
    }, [lotData])

    const saveWizardData = (key: string, value?: string) => {
        const newWizardData = updatedData(wizardKey, currentStepIndex, key, value)
        saveData(wizardKey, newWizardData)
    }

    const onCreateLotDataItem = () => {
        setCurrentLotDataItem(undefined)
    }

    const onEditLotDataItem = (attr: LotAttribute) => {
        setCurrentLotDataItem(lotData.find(lotDataItem => lotDataItem.lotAttr === attr))
    }

    const onRemoveLotDataItem = (attr: LotAttribute) => {
        const newLotData = lotData.filter(lotDataItem => lotDataItem.lotAttr !== attr)
        setLotData(newLotData)
        saveWizardData('lotData', JSON.stringify(newLotData))
    }

    useEffect(() => {
        if (purchaseItems.length === 0) {
            return
        }

        const infoStep = <PurchaseInfoStep
            wizardKey={wizardKey}
            stepIndex={0}
            purchase={purchase}
        />
        const stepList = [infoStep]
            
        const purchaseItemsDataList: PurchaseItemData[] = []
        purchaseItems.forEach(purchaseItem => {
            const pendingQuantity = getPendingQuantity(purchaseItem, deliveries)
            if (pendingQuantity > 0) {
                const stepIndex = stepList.length
                const itemStep = <PurchaseItemStep
                    key={stepIndex}
                    wizardKey={wizardKey}
                    stepIndex={stepIndex}
                    purchaseItem={purchaseItem}
                    pendingQuantity={pendingQuantity}
                    lotData={lotDataStr}
                    onCreateLotDataItem={onCreateLotDataItem}
                    onEditLotDataItem={onEditLotDataItem}
                    onRemoveLotDataItem={onRemoveLotDataItem}
                />
                purchaseItemsDataList.push({ stepIndex, purchaseItem })
                stepList.push(itemStep)
            }
        })

        setSteps(stepList)
        setPurchaseItemsData(purchaseItemsDataList)
    }, [purchaseItems, deliveries, lotDataStr])

    const title = `Recepción de Compra ${descriptorHooks.purchase.code(purchase)}`
    const filterLotAttributes = (attr: LotAttribute) =>
        currentLotDataItem?.lotAttr === attr || !lotData.map(lotDataItem => lotDataItem.lotAttr).includes(attr)
    
    const onFinish = () => finishDelivery(wizardKey, purchase, purchaseItemsData)

    const onGoToStep = (stepIndex: number) => {
        setCurrentStepIndex(stepIndex)
        setCurrentLotDataItem(undefined)
        const stepData = getStepData(wizardKey, stepIndex)
        const defaultLotData = stepData.lotData ? toLotAttributesList(stepData.lotData) : []
        setLotData(defaultLotData)
    }

    const onSaveLotDataItem = (newData: Data) => {
        const newLotData = currentLotDataItem ?
            lotData.map(lotDataItem => lotDataItem.lotAttr === currentLotDataItem.lotAttr ? newData : lotDataItem) :
            [newData, ...lotData]
        setLotData(newLotData)
        saveWizardData('lotData', JSON.stringify(newLotData))
    }

    return (<>
        <WizardPanel
            wizardKey={wizardKey}
            title={title}
            steps={steps}
            onGoToStep={onGoToStep}
            onFinish={onFinish}
        />
        <LotDataFormModal
            lotDataItem={currentLotDataItem}
            filterAttributes={filterLotAttributes}
            onSubmit={onSaveLotDataItem}
        />
    </>)
}
