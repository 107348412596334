import styles from './companyElement.module.css'
import { Outlet } from "react-router-dom"
import { PanelLoader, Sidebar, SpinnerModal } from "../../../ui"
import { useLoad } from "./load"
import { useEffect, useState } from "react"
import { useSystemStateHooks } from "../../../../modules/system"

export const CompanyElement = () => {
    const [ready, setReady] = useState(false)
    
    const load = useLoad()
    
    const hasAccess = useSystemStateHooks().hasCompanyAccess()

    useEffect(() => {
        load().then(() => setReady(true))
    }, [])

    const marginClassName = hasAccess ? styles.leftMargin : ''
    
    return (
        <PanelLoader ready={ready}>
            <>
                {hasAccess && <Sidebar />}
                <div className={marginClassName}>
                    <Outlet />
                </div>
                <SpinnerModal />
            </>
        </PanelLoader>
    )
}
