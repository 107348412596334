import { Section } from "../../../../../components/section/section"
import { Table, Column, Data, FieldType, FieldValue } from "../../../../../features/ui"
import { lotAttributeLabel } from "../../../../../tables/types/labels"
import { define } from "../../../../../utils/typeUtils"
import { LotAttribute } from "../../../state/types"

type Props = {
    rows: Data[]
    readOnly?: boolean
    onCreate?: () => void
    onEditRow?: (rowIdValue: string) => void
    onRemoveRow?: (rowIdValue: string) => void
}

export const LotDataTable = (props: Props) => {
    const { rows, readOnly, onCreate, onEditRow, onRemoveRow } = props

    const columns: Column[] = [
        {
            name: 'lotAttr',
            type: FieldType.TEXT,
            label: 'Atributo',
            render: (value?: FieldValue) => lotAttributeLabel(define(value) as LotAttribute)
        },
        {
            name: 'lotAttrValue',
            type: FieldType.TEXT,
            label: 'Valor',
        }
    ]

    return (
        <Section
            id='lotDataItems'
            space={12}
        >
            <Table
                columns={columns}
                rows={rows}
                rowId='lotAttr'
                readOnly={readOnly}
                createIcon="plus"
                disableCreate={rows.length >= 3}
                small
                onCreate={onCreate}
                onEdit={onEditRow}
                onRemove={onRemoveRow}
            />
        </Section>
    )
}
