import styles from './selectField.module.css'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Option } from '../types'
import { Validatable } from '../validatable/validatable'
import { Button } from '../../../../components/button/button'
import { useOutsideClickHandler } from '../../../../components/hooks'
import { CustomOnClickEvent, CustomOnDivClickEvent } from '../../../../components/styled-text/types'
import { define, normalize } from '../../../../utils/typeUtils'
import { StyledText } from '../../../../components/styled-text/styledText'

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    id: string
    value?: string
    defaultValue?: string
    options: Option[]
    optional?: boolean
    focusOnLoad?: boolean
    validation?: string
    sorted?: boolean
    className?: string
    searchFn?: (text?: string) => Option[]
    renderInputLabel?: (optionValue: string) => string
    renderOption?: (option: Option, onClick?: (e: React.MouseEvent<HTMLElement>) => void) => JSX.Element
    onChange?: (value?: string) => void
    onCreateNew?: () => void
}

export const SelectField = (props: Props) => {
    const { id, value, defaultValue, options, optional, placeholder, focusOnLoad, validation, sorted, className, searchFn, renderInputLabel, renderOption, onChange, onCreateNew, ...rest } = props
    
    const toLabel = (optionValue?: string) => {
        const defaultLabelFn = options.find(option => option.value === optionValue)?.label
        return optionValue && (renderInputLabel ? renderInputLabel(optionValue) : defaultLabelFn)
    }

    const [inputLabel, setInputLabel] = useState(toLabel(defaultValue))
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [optionsVisible, setOptionsVisible] = useState(false)
    const [inputWidth, setInputWidth] = useState(0)
    const inputRef = useRef<HTMLInputElement>(null)
    const iconRef = useRef<any>(null)
    const optionsRef = useRef<HTMLDivElement>(null)
    
    const sortedOptions = sorted ? options?.sort((a, b) => a.label.localeCompare(b.label)) : options

    const defaultSearchFn = (sourceOptions: Option[], text = '') => {
        const words = normalize(text).split(/\s+/)
        const matchFn = (option: Option) => {
            return words.reduce((count, word) => normalize(option.label).includes(word) ? count + 1 : count, 0)
        }
        return sourceOptions.sort((optionA, optionB) => matchFn(optionB) - matchFn(optionA))
    }

    const optionsList = useMemo(() =>
        searchFn ? searchFn(inputLabel) : defaultSearchFn(sortedOptions || [], inputLabel),
    [searchFn, inputLabel, sortedOptions])

    const hideOptions = () => setOptionsVisible(false)
    const showOptions = () => {
        setSelectedIndex(0)
        setOptionsVisible(true)
    }
    const selectOption = (newValue: string) => {
        setInputLabel(toLabel(newValue))
        onChange && onChange(newValue)
        hideOptions()
        setSelectedIndex(-1)
        inputRef.current?.focus()
    }

    const refreshInputWidth = () => setInputWidth(inputRef.current?.clientWidth || 0)
    useOutsideClickHandler([inputRef, iconRef, optionsRef], hideOptions)

    useEffect(() => {
        if (inputRef?.current) {
            const resizeObserver = new ResizeObserver(refreshInputWidth)
            resizeObserver.observe(inputRef.current)
            return () => resizeObserver.disconnect()
        }
        const handleResize = () => {
            refreshInputWidth()
            setTimeout(refreshInputWidth, 1000)
        }
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        if (selectedIndex !== -1) {
            const selectedOption = optionsRef.current?.children[selectedIndex]
            selectedOption?.scrollIntoView({ block: 'nearest' })
        }
        
        const handleKeyDown = (e: KeyboardEvent) => {
            const focused = inputRef.current && inputRef.current === document.activeElement
            if (!focused) {
                return
            } else if (e.key === 'Escape') {
                hideOptions()
            } else if (e.key === 'ArrowUp') {
                setSelectedIndex(Math.max(selectedIndex - 1, -1))
            } else if (e.key === 'ArrowDown') {
                setSelectedIndex(Math.min(selectedIndex + 1, optionsList.length - 1))
            } else if (e.key === 'Enter' && selectedIndex !== -1) {
                e.preventDefault()
                const selectedValue = optionsRef.current?.children[selectedIndex].id
                selectedValue && selectOption(selectedValue)
            }
        }

        document.addEventListener('keydown', handleKeyDown)
        return () => document.removeEventListener('keydown', handleKeyDown)
    }, [selectedIndex, inputLabel])

    useEffect(() => {
        if (value) {
            setInputLabel(toLabel(value))
            onChange && onChange(value)
        }
    }, [value])

    useEffect(() => {
        focusOnLoad && inputRef && inputRef.current?.focus()
    }, [focusOnLoad, inputRef])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newInputLabel = e.target?.value
        setInputLabel(newInputLabel)
        !optionsVisible && showOptions()
    }

    const handleClick = () => !optionsVisible && showOptions()
    
    const handleFocus = () => inputRef?.current?.select()

    const handleBlur = (e: React.FocusEvent<HTMLElement>) => {
        if (!e.relatedTarget || (
                e.relatedTarget !== inputRef.current &&
                e.relatedTarget !== iconRef.current &&
                e.relatedTarget !== optionsRef.current
        )) {
            hideOptions()
            const selectedOption = sortedOptions?.find(option => toLabel(option.value) === inputLabel)
            if (!selectedOption) {
                setInputLabel('')
                onChange && onChange(undefined)
            }
        }
    }
    
    const handleOptionClick = (e: CustomOnDivClickEvent) => selectOption(define(e.target?.id))

    const handleOptionChildClick = (newValue: string) => {
        const onChildClick = (e: CustomOnClickEvent) => {
            e.stopPropagation && e.stopPropagation()
            e.target?.parentElement?.click({ target: { id: newValue }})
        }
        return onChildClick as (e: React.MouseEvent<HTMLElement>) => void
    }

    const optionsStyle = { width: inputWidth || 500 }

    return (
        <div className={styles.container}>
            <div className={styles.selectContainer}>
                <Validatable validations={validation ? [validation] : []}>
                    <input
                        type='text'
                        id={id}
                        name={id}
                        value={inputLabel || ''}
                        required={!optional}
                        placeholder={placeholder || 'Seleccionar opción'}
                        onChange={handleChange}
                        onClick={handleClick}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        className={`form-control ${className || ''}`}
                        ref={inputRef}
                        autoComplete="off"
                        {...rest}
                    />
                </Validatable>
                <i
                    className={`bi bi-chevron-down ${styles.arrowIcon}`}
                    onClick={handleClick}
                    ref={iconRef}
                />
                {optionsVisible && optionsList.length > 0 && (
                    <div
                        ref={optionsRef}
                        className={`${styles.options} ${styles.topMargin}`}
                        style={optionsStyle}
                        onBlur={handleBlur}
                        tabIndex={0}
                    >
                        {optionsList.map((option, index) => {
                            return (
                                <div
                                    key={option.value}
                                    id={option.value}
                                    onClick={handleOptionClick}
                                    className={`${styles.option} ${index === selectedIndex ? styles.selected : ''}`}
                                >
                                    {renderOption ? renderOption(option, handleOptionChildClick(option.value)) : (
                                        <StyledText
                                            text={option.label}
                                            highlighted={inputLabel as string}
                                            className={styles.optionLabel}
                                            onClick={handleOptionChildClick(option.value)}
                                        />
                                    )}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
            {onCreateNew && (
                <Button
                    kind="tertiary"
                    label="Crear"
                    icon="plus"
                    small
                    onClick={onCreateNew}
                    className={styles.createBtn}
                />
            )}
        </div>
    )
}
