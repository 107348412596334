import { define, normalize } from "../../../utils/typeUtils"
import { useFind, useFindByParams } from "../../../state/reducers/hooks"
import { DataType, TableName } from "../../../tables"
import { Lot, Product, ProductPrices } from "../state/types"
import { ProductsState } from "../state/reducer"
import { useSelector } from "react-redux"
import { State } from "../../../state"
import { bindActionCreators } from "redux"
import { NO_LOT, actionCreators } from ".."
import { useDispatch } from "react-redux"
import { usePricesHooks } from "../../operations"
import { CustomerType, useSalesState } from "../../sales"
import { useAdministrationState } from "../../administration"
import { PriceItem } from "./types"
import { Supplier } from "../../purchases"
import { useFiltersHooks } from "../../../features/ui"

export const useProductsState = (): ProductsState => useSelector((state: State) => state.productsReducer)

export const useProductsStateActions = () => bindActionCreators(actionCreators, useDispatch())

export const useProductsHooks = () => {
    const { paymentTypes } = useAdministrationState()
    const { customerTypes } = useSalesState()

    const pricesHooks = usePricesHooks()
    const { search } = useFiltersHooks()
    const find = useFind()
    const findByParams = useFindByParams()

    const getPurchasePrice = (productId: string) => (define(find(TableName.PRODUCT_PRICES, productId, 'productId')) as ProductPrices).purchasePrice

    const getBaseLot = (productId: string): Lot => {
        const params = { productId, code: NO_LOT }
        return define(findByParams(TableName.LOTS, params)) as Lot
    }

    const getPriceList = (productId: string): PriceItem[] => {
        const list: PriceItem[] = []
        paymentTypes.forEach(paymentType => {
            customerTypes.forEach(customerType => {
                const price = pricesHooks.product.adjustedPrice(productId, paymentType, customerType)
                list.push({
                    customerTypeId: customerType.id,
                    paymentTypeId: paymentType.id,
                    price
                })
            })
        })

        const findCustomerType = (id: string) => define(find(TableName.CUSTOMER_TYPES, id)) as CustomerType

        const salePriceList = list.sort((a, b) => {
            const customerTypeA = findCustomerType(a.customerTypeId)
            const customerTypeB = findCustomerType(b.customerTypeId)
            if (customerTypeA.name < customerTypeB.name) return -1
            if (customerTypeA.name > customerTypeB.name) return 1
            return a.price - b.price
        })
        return salePriceList
    }

    const getProductMatchFn = (product: Product, words: string[]) => {
        const { code, supplierCode, name, supplierId } = product
        const supplier = define(find(TableName.SUPPLIERS, supplierId)) as Supplier
        
        const values = [
            code.toString(),
            supplierCode ? normalize(supplierCode) : '',
            normalize(name),
            normalize(supplier.name)
        ]

        const matchCount = words.reduce((count, word) => 
            values.some(value => value.includes(word)) ? count + 1 : count
        , 0)

        return matchCount
    }

    const searchProducts = (products: Product[], text = '', showAll = false): Product[] => {
        const matchFn = (productDT: DataType, words: string[]) =>
            getProductMatchFn(productDT as Product, words)
        const sortFn = (productDT_A: DataType, productDT_B: DataType) => 
            (productDT_A as Product).name.localeCompare((productDT_B as Product).name)
        
        return search(products, matchFn, sortFn, text, showAll) as Product[]
    }

    return {
        getPurchasePrice,
        getBaseLot,
        getPriceList,
        getProductMatchFn,
        searchProducts
    }
}
