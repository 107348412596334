import styles from './companyPanel.module.css'
import { define } from '../../../../utils/typeUtils'
import { Link } from '../../../../components/link/link'
import { useSystemState, useSystemStateHooks } from '../../../system'
import { useActions } from './actions'
import { useManagerState } from '../../../manager'
import { Data, DetailFieldProps, FieldType, FieldValue, FormField, FormatType, ImageField, ProfilePanel, Spinner, UIPermission } from '../../../../features/ui'
import { useEffect, useState } from 'react'
import { useFileActions } from '../../../../features/files'
import { useDataTypesHooks, validatePhone } from '../../../../features/data-types'

export const CompanyPanel = () => {
  const { branches } = useManagerState()
  const { company } = useSystemState()
  const definedCompany = define(company)

  const [asyncDataLoaded, setAsyncDataLoaded] = useState(!definedCompany.logoUrl)
  const [logoFile, setLogoFile] = useState<File>()
  
  const { userHasPermission } = useSystemStateHooks()
  const readOnly = !userHasPermission(UIPermission.SAVE_COMPANY)
  const { formatCustomValue } = useDataTypesHooks()
  
  const { onBranchClick, updateCompany } = useActions()
  const { fetchFile } = useFileActions()

  useEffect(() => {
    if (definedCompany.logoUrl) {
      fetchFile(definedCompany.logoUrl, 'company-logo', true).then(file => {
        setLogoFile(file)
        setAsyncDataLoaded(true)
      })
    } else {
      setAsyncDataLoaded(true)
    }
  }, [])

  const companyData = {
    ...definedCompany,
    imageUrl: definedCompany.logoUrl
  }

  const infoFields: DetailFieldProps[] = [
    {
      name: 'websiteUrl',
      type: FieldType.TEXT,
      label: 'Página Web',
      render: (value?: FieldValue) => {
        const websiteUrl = define(value) as string
        return <Link
          text={websiteUrl}
          href={`https://${websiteUrl}`}
          kind="primary"
          className={styles.link}
        />
      }
    },
    {
      name: 'address',
      type: FieldType.TEXT,
      label: 'Dirección'
    },
    {
      name: 'email',
      type: FieldType.TEXT,
      label: 'Email'
    },
    {
      name: 'branches',
      type: FieldType.TEXT,
      label: 'Sucursales',
      render: () => <div className={styles.branchesContainer}>
        {branches.map(branch => <Link
          key={branch.name}
          text={branch.name}
          kind="primary"
          className={styles.link}
          onClick={onBranchClick(branch)}
        />)}
      </div>
    }
  ]
  if (definedCompany.phone) {
    const phoneField = {
      name: 'phone',
      type: FieldType.NUMBER,
      label: 'Teléfono',
      render: (value?: FieldValue) => {
        const phone = value as number | undefined
        return <>{formatCustomValue(phone, FormatType.PHONE)}</>
      }
    }
    infoFields.splice(3, 0, phoneField)
  }

  const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

  const fields: FormField[] = [
    {
      name: 'logoUrl',
      type: FieldType.IMAGE,
      label: 'Logo',
      render: () => renderAsync(<ImageField
        id="logoUrl"
        value={logoFile}
        onChange={setLogoFile}
      />)
    },
    {
      name: 'websiteUrl',
      type: FieldType.TEXT,
      label: 'Página Web'
    },
    {
      name: 'address',
      type: FieldType.TEXT,
      label: 'Dirección'
    },
    {
      name: 'email',
      type: FieldType.TEXT,
      label: 'Email'
    },
    {
      name: 'phone',
      type: FieldType.NUMBER,
      label: 'Teléfono',
      validate: validatePhone
    }
  ]

  const onSubmit = async (data: Data) => {
    return updateCompany(definedCompany, data, logoFile)
  }

  return <ProfilePanel
    infoFields={infoFields}
    fields={fields}
    data={companyData}
    subtitle={definedCompany.websiteUrl}
    infoTabLabel='Información de Compañía'
    readOnly={readOnly}
    onSubmit={onSubmit}
  />
}
