import { ListResponse } from "../../actions/types"
import { DynamoUserApi } from "../../services"
import { AttributeMap, useDynamoActions } from "../../services/dynamo"
import { toDynamoListCallback } from "../../services/utils"
import { DataType, TableName, TablesData } from "../../tables"
import { define } from "../../utils/typeUtils"

export const useRemovedTableTenantKeyMigration = () => {
    const dynamoActions = useDynamoActions()
    
    const newDbTableName = (table: string) => table + '_TEMP'

    const createTable = (
        name: string,
        hasTKey: boolean,
        callback?: () => void
    ) => {
        const tables = [{ name, hasTKey }]
        DynamoUserApi.createTables(tables, callback)
    }

    const createNewTemporaryTable = (
        sourceTable: TableName
    ) => {
        const { dbTableName } = define(TablesData.get(sourceTable))
        createTable(newDbTableName(dbTableName), false)
    }

    const fillTemporaryTable = (
        sourceTable: TableName
    ) => {
        const { getAll, createOrUpdateMultiple } = dynamoActions(DynamoUserApi)
        const { dbTableName } = define(TablesData.get(sourceTable))
        const onGetAll = (response: ListResponse) => {
            const newDataTypes = response.dataTypes.map(dataType => {
                const newDataType: DataType = {
                    id: dataType.id
                }
                Object.keys(dataType)
                    .filter(key => key !== 'companyId')
                    .forEach(key => {
                        const propName = key as keyof DataType
                        newDataType[propName] = dataType[propName]
                    })
                return newDataType
            }) as DataType[] as AttributeMap[]
            createOrUpdateMultiple(newDbTableName(dbTableName), newDataTypes)
        }
        getAll(dbTableName, undefined, undefined, undefined, toDynamoListCallback(onGetAll))
    }

    const reCreateTable = (
        sourceTable: TableName
    ) => {
        const { getAll, createOrUpdateMultiple } = dynamoActions(DynamoUserApi)
        const { dbTableName } = define(TablesData.get(sourceTable))
        const onGetAll = (response: ListResponse) => createOrUpdateMultiple(dbTableName, response.dataTypes as AttributeMap[])
        const onCreateTable = () => getAll(newDbTableName(dbTableName), undefined, undefined, undefined, toDynamoListCallback(onGetAll))
        const onRemoveTable = () => createTable(dbTableName, false, onCreateTable)
        DynamoUserApi.removeTable(dbTableName, onRemoveTable)
    }

    const removeTemporaryTable = (
        sourceTable: TableName
    ) => {
        const { dbTableName } = define(TablesData.get(sourceTable))
        DynamoUserApi.removeTable(newDbTableName(dbTableName))
    }

    return {
        createNewTemporaryTable,
        fillTemporaryTable,
        reCreateTable,
        removeTemporaryTable
    }
}
