import { DataType } from '../tables'
import { SubmitChildrenFn } from './types'
import { areDataTypesListsEqual } from '../features/data-types'

export const useChildrenActions = () => {
    const submitChildren = (
        children: DataType[],
        newChildren: DataType[],
        saveChildrenFn: SubmitChildrenFn,
        removeChildrenFn?: SubmitChildrenFn,
        callback?: () => void
    ) => {
        if (areDataTypesListsEqual(children, newChildren)) {
            return callback && callback()
        }

        const newChildrenIds = newChildren.map(newChild => newChild.id)
        const childrenToRemove = children.filter(child => !newChildrenIds.includes(child.id))
        const onSaveChildren = () => {
            if (childrenToRemove.length > 0 && removeChildrenFn) {
                removeChildrenFn(childrenToRemove, callback)
            } else {
                callback && callback()
            }
        }
        saveChildrenFn(newChildren, onSaveChildren)
    }

    const submitChildren_async = async (
        children: DataType[],
        newChildren: DataType[],
        saveChildrenFn: (children: DataType[]) => Promise<void>,
        removeChildrenFn?: (children: DataType[]) => Promise<void>
    ) => {
        if (!areDataTypesListsEqual(children, newChildren)) {
            const newChildrenIds = newChildren.map(newChild => newChild.id)
            const childrenToRemove = children.filter(child => !newChildrenIds.includes(child.id))
            
            await saveChildrenFn(newChildren)
            if (childrenToRemove.length > 0 && removeChildrenFn) {
                await removeChildrenFn(childrenToRemove)
            }
        }
    }

    return {
        submitChildren,
        submitChildren_async
    }
}
