import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import * as actionCreators from '../state/actionCreators'
import { StockState } from "../state/reducer"
import { useSelector } from "react-redux"
import { State } from "../../../state"
import { Stock } from "../state/types"
import { useManagerState } from "../../manager"
import { define } from "../../../utils/typeUtils"
import { Product, useProductsHooks } from "../../products"
import { useFind } from "../../../state/reducers/hooks"
import { DataType, TableName } from "../../../tables"
import { StockAction, useFiltersHooks } from "../../../features/ui"

export const useStockState = (): StockState => useSelector((state: State) => state.stockReducer)

export const useStockStateActions = () => bindActionCreators(actionCreators, useDispatch())

export const useStockHooks = () => {
    const { stock } = useStockState()
    const { currentBranch } = useManagerState()
    const { getBaseLot, getProductMatchFn } = useProductsHooks()
    const { search } = useFiltersHooks()
    const find = useFind()

    const getBranchStock = (branchId: string): Stock[] => stock.filter(stockItem => stockItem.branchId === branchId)

    const getCurrentBranchStock = (): Stock[] => stock.filter(stockItem => stockItem.branchId === define(currentBranch).id)

    const getBaseStock = (productId: string, branchId?: string): Stock => {
        const branchStock = branchId ? getBranchStock(branchId) : getCurrentBranchStock()
        const baseLotId = getBaseLot(productId).id
        return define(branchStock.find(stockItem => stockItem.lotId === baseLotId)) as Stock
    }

    const searchStockList = (stockList: Stock[], text = '', showAll = false): Stock[] => {
        const matchFn = (stockDT: DataType, words: string[]) => {
            const stock = stockDT as Stock
            const product = define(find(TableName.PRODUCTS, stock.productId) as Product)
            return getProductMatchFn(product, words)
        }
        const sortFn = (stockDT_A: DataType, stockDT_B: DataType) => 
            (stockDT_B as Stock).availableUnits - (stockDT_A as Stock).availableUnits
        
        return search(stockList, matchFn, sortFn, text, showAll) as Stock[]
    }

    const getStockActionRoute = (stockAction: StockAction) => stockAction === StockAction.ADD ? 'alta' : 'baja'

    return {
        getBranchStock,
        getCurrentBranchStock,
        getBaseStock,
        searchStockList,
        getStockActionRoute
    }
}
