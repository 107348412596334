import { Data, useUIActions } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { TaxCategory } from "../../../../operations"
import { usePurchasesActions } from "../../../actions/purchasesActions"
import { Supplier } from "../../../state/types"

export const useActions = () => {
    const purchasesActions = usePurchasesActions()
    const { onSubmitDataType } = useUIActions()

    const submitSupplier = async (
        supplierData: Data,
        code: number,
        productSupplier: boolean,
        createMode: boolean
    ) => {
        const { companyId, id, name, taxId, taxCategory, address } = supplierData
        const supplier: Supplier = {
            companyId: companyId as string,
            id: id as string,
            code,
            name: define(name) as string,
            taxId: define(taxId) as number,
            taxCategory: define(taxCategory) as TaxCategory,
            productSupplier,
            address: address as string | undefined
        }

        await purchasesActions().saveSupplier(supplier)
        onSubmitDataType(createMode)
    }
    
    return { submitSupplier }
}
