import { useDefaultDbActions } from "../actions/defaultDbActions";
import { NumberCallback } from "../actions/types";
import { useSystemStateHooks } from "../modules/system";
import { DynamoUserApi } from "../services";
import { define, getNextFreeNumber } from "../utils/typeUtils";
import { NumberProp, TableName } from "./types/types";

export const useGenerateNextValue = () => {
    const { companyId } = useSystemStateHooks()

    const defaultDbActions = useDefaultDbActions()

    return async (tableName: TableName, propName: string, callback: NumberCallback) => {
        const response = await defaultDbActions(DynamoUserApi, define(companyId)).getAll(tableName, undefined, undefined, propName)
        const values = response.dataTypes.map(dataType => 
            (dataType as NumberProp)[propName as keyof NumberProp] as number
        )
        callback(getNextFreeNumber(values))
    }
}
