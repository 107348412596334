import { Section } from "../../../../components/section/section"
import { Table, Column, Data, FieldType, FieldValue } from "../../../../features/ui"
import { define } from "../../../../utils/typeUtils"
import { useDescriptorHooks } from "../../../../features/data-types"
import { MeasureType } from "../../state/types"
import { measureTypeLabel } from "../../../../tables/types/labels"

type Props = {
    rows: Data[]
    readOnly?: boolean
    onCreate?: () => void
    onEditRow?: (productId: string, lotId?: string) => void
    onRemoveRow?: (productId: string, lotId?: string) => void
}

export const MeasuresTable = (props: Props) => {
    const { rows, readOnly, onCreate, onEditRow, onRemoveRow } = props

    const descriptorHooks = useDescriptorHooks()

    const columns: Column[] = [
        {
            name: 'measureType',
            type: FieldType.TEXT,
            label: '',
            render: (value?: FieldValue) => measureTypeLabel(define(value) as MeasureType)
        },
        {
            name: 'measureUnits',
            type: FieldType.TEXT,
            label: ''
        }
    ]

    const measureRows = rows.map(row => {
        const { type, units, unitTypeId } = row
        return {
            measureType: type,
            measureUnits: descriptorHooks.content(define(units) as number, define(unitTypeId) as string)
        }
    })

    return (
        <Section
            id='measures'
            title='Medidas adicionales'
            space={12}
            collapsable
            defaultExpanded={false}
        >
            <Table
                columns={columns}
                rows={measureRows}
                rowId='measureType'
                readOnly={readOnly}
                createIcon="plus"
                small
                onCreate={onCreate}
                onEdit={onEditRow}
                onRemove={onRemoveRow}
            />
        </Section>
    )
}
