import { useState } from "react"
import { FieldType, UIPermission, Action, Column, FieldValue, Data, SpinnerDiv, useUIActions, ModalId } from "../../../../../features/ui"
import { DataType, TableName } from "../../../../../tables"
import { useSystemStateHooks } from "../../../../system"
import { HoardOrder, HoardType } from "../../../state/types"
import { define } from "../../../../../utils/typeUtils"
import { PanelWrapper } from "../../../../../components/wrappers/panel-wrapper/panelWrapper"
import { DataTypeList } from "../../../../../features/data-types"
import { hoardTypeLabel, orderStatusLabel } from "../../../../../tables/types/labels"
import { CloseOperationModal, Operation, OrderStatus } from "../../../../operations"
import { OpenWizardModal, useWizardActions } from "../../../../../features/wizard"
import { hoardWizardKey } from "../../../utils/salesUtils"
import { ButtonKind } from "../../../../../components/button/button"
import { useActions } from "./actions"
import { HoardFilters } from "./hoardFilters"
import { RoutePath } from "../../../../../features/routes"
import { useNavigate } from "react-router-dom"

export const HoardList = () => {
    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [currentHoard, setCurrentHoard] = useState<HoardOrder>()
    const wizardKey = currentHoard ? hoardWizardKey(currentHoard) : undefined

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_HOARDS)
    const hasDeliveryPermission = userHasPermission(UIPermission.SAVE_HOARD_DELIVERIES)
    const navigate = useNavigate()

    const { findHoard } = useActions()
    const { goToWizard } = useWizardActions()
    const { toggleModal } = useUIActions()

    const deliveryPath = (hoard?: HoardOrder) => hoard ? `${hoard.id}/${RoutePath.DELIVERY}` : '#'

    const setRowsState = (hoards: Data[]) => {
        setRows(hoards)
        setAsyncDataLoaded(true)
    }

    const columns: Column[] = [
        { name: 'code', type: FieldType.NUMBER, label: '#', render: (value?: FieldValue) => `#${value}` },
        {
            name: 'type',
            type: FieldType.TEXT,
            label: 'Tipo',
            render: (value?: FieldValue) => hoardTypeLabel(define(value) as HoardType, true)
        },
        { name: 'dueDate', type: FieldType.DATE, label: 'Fecha Límite' },
        { name: 'totalPrice', type: FieldType.PRICE, label: 'Precio' },
        { name: 'sellerId', type: FieldType.TABLE, table: TableName.EMPLOYEES, label: 'Vendedor' },
        { name: 'customerId', type: FieldType.TABLE, table: TableName.CUSTOMERS, label: 'Cliente' },
        { name: 'status', type: FieldType.TEXT, label: 'Estado', render: (value?: FieldValue) => orderStatusLabel(define(value) as OrderStatus) }
    ]

    const onView = (code?: string) => {
        const hoard = findHoard(code)
        if (hoard) {
            navigate(hoard.id)
        }
    }

    const onDeliver = (code?: string) => {
        const hoard = findHoard(code)
        setCurrentHoard(hoard)
        goToWizard(hoardWizardKey(hoard), deliveryPath(hoard))
    }

    const onArchive = (code?: string) => {
        findHoard(code)
    }

    const onClose = (code?: string) => {
        const hoard = findHoard(code)
        setCurrentHoard(hoard)
        toggleModal(ModalId.CLOSE_OPERATION)
    }

    const actions = (code?: string) => {
        const actionList: Action[] = [
            { icon: 'eye-fill', kind: 'tertiary', tooltip: 'Ver Detalle', onClick: onView }
        ]
        if (hasDeliveryPermission) {
            const hoard = findHoard(code)
            if (hoard.status === OrderStatus.DELIVERED) {
                const archiveAction = { icon: 'archive', kind: 'secondary' as ButtonKind, tooltip: 'Archivar', onClick: onArchive }
                actionList.push(archiveAction)
            } else {
                const deliverAction = { icon: 'truck', tooltip: 'Registrar Entrega', onClick: onDeliver }
                const closeAction = { icon: 'bag-check-fill', tooltip: 'Cerrar', onClick: onClose }
                actionList.push(deliverAction, closeAction)
            }
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRowsState(dataTypes as Data[])
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <PanelWrapper title="Acopios" wide>
            <HoardFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone}/>
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    rowId="code"
                    currentDataTypeParam={currentHoard}
                    actions={actions}
                    readOnly={readOnly}
                />
            )}
            <OpenWizardModal
                wizardKey={wizardKey}
                path={deliveryPath(currentHoard)}
            />
            <CloseOperationModal
                operationType={Operation.HOARD}
                operation={currentHoard}
            />
        </PanelWrapper>
    )
}
