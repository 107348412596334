import styles from './fieldRow.module.css'
import { Hint } from '../../../../components/hint/hint'

type Props = {
    id?: string
    label?: string
    hint?: string
    hideLabel?: boolean
    labelSpace?: number
    narrow?: boolean
    children: JSX.Element[] | JSX.Element
}

export const FieldRow = (props: Props) => {
    const { id, label, hint, hideLabel, labelSpace = 3, narrow, children } = props

    const leftoverSpace = label ? 12 - labelSpace : 12
    const fieldSpace = narrow ? Math.round(leftoverSpace / 2) : leftoverSpace

    return (
        <div key={id} className={`row mb-3 ${styles.container}`}>
            {label && (
                <div className={`col-sm-${labelSpace} ${styles.label}`}>
                    <label htmlFor={id} className="col-form-label">
                        {!hideLabel && label}
                    </label>
                    {hint && (
                        <Hint text={hint} />
                    )}
                </div>
            )}
            <div className={`col-sm-${fieldSpace} ${styles.content}`}>
                {children}
            </div>
        </div>
    )
}
