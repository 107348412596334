import { useFind } from "../../../../../state/reducers/hooks"
import { TableName } from "../../../../../tables"
import { define } from "../../../../../utils/typeUtils"
import { HoardOrder } from "../../../state/types"

export const useActions = () => {
    const find = useFind()

    const findHoard = (code?: string) => define(find(TableName.HOARD_ORDERS, code, 'code') as HoardOrder)

    return {
        findHoard
    }
}
