import { useState } from "react"
import { Badge } from "../../../../components/badge/badge"
import { PanelWrapper } from "../../../../components/wrappers/panel-wrapper/panelWrapper"
import { DataTypeList, useDataTypesHooks } from "../../../../features/data-types"
import { Column, Data, FieldType, FieldValue, FormatType, SpinnerDiv, UIPermission } from "../../../../features/ui"
import { define } from "../../../../utils/typeUtils"
import { useSystemStateHooks } from "../../../system"
import { Area, Employee } from "../../state/types"
import { areaLabel } from "../../utils/administrationUtils"
import { EmployeeFilters } from "./employeeFilters"
import { DataType } from "../../../../tables"

export const EmployeeList = () => {
    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_EMPLOYEES)
    const { formatCustomValue } = useDataTypesHooks()

    const columns: Column[] = [
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre'
        },
        {
            name: 'area',
            type: FieldType.TEXT,
            label: 'Área',
            render: (value?: FieldValue) => <Badge label={areaLabel(define(value) as Area)} />
        },
        {
            name: 'identifier',
            type: FieldType.NUMBER,
            label: 'DNI',
            render: (value?: FieldValue) => {
                const identifier = value as number | undefined
                return formatCustomValue(identifier, FormatType.DNI) || <Badge label="Vacío" />
            }
        },
        {
            name: 'email',
            type: FieldType.TEXT,
            label: 'Email'
        }
    ]

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRows(dataTypes as Employee[])
        setAsyncDataLoaded(true)
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <PanelWrapper title="Empleados" wide>
            <EmployeeFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone}/>
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    readOnly={readOnly}
                    showDetail={false}
                />
            )}
        </PanelWrapper>
    )
}
