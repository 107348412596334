import { FieldType, FieldValue, FilterField, Filters, option } from "../../../../features/ui"
import { useEffect, useState } from "react"
import { DataType } from '../../../../tables'
import { useAdministrationHooks, useAdministrationState } from "../../hooks/administrationHooks"
import { CashFundType } from "../../state/types"
import { cashFundTypeLabel } from "../../../../tables/types/labels"

type Props = {
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const CashFundFilters = (props: Props) => {
    const { onSearchStart, onSearchDone } = props

    const { cashFunds } = useAdministrationState()

    const [searchText, setSearchText] = useState<string>()
    const [type, setType] = useState<CashFundType>()

    const { searchCashFunds } = useAdministrationHooks()

    useEffect(() => search(searchText, type), [cashFunds])

    const search = (searchTextParam?: string, typeParam?: CashFundType) => {
        onSearchStart()
        let cashFundList = cashFunds.filter(cashFund => !typeParam || cashFund.type === typeParam)
        cashFundList = searchCashFunds(cashFundList, searchTextParam)
        setTimeout(() => onSearchDone(cashFundList), 100)
    }

    const handleSearch = (value?: FieldValue) => {
        const newSearchText = value as string | undefined
        setSearchText(newSearchText)
        search(newSearchText, type)
    }

    const onChangeType = (value?: FieldValue) => {
        const newType = value as CashFundType | undefined
        if (newType !== type) {
            setType(newType)
            search(searchText, newType)
        }
    }

    const searchField: FilterField = {
        name: 'search',
        type: FieldType.TEXT,
        placeholder: 'Buscar por nombre o tipo',
        icon: 'search',
        onChange: handleSearch
    }

    const filterFields: FilterField[] = [
        {
            name: 'type',
            type: FieldType.SELECT,
            options: Object.values(CashFundType).map(type => option(type, cashFundTypeLabel(type))),
            label: 'Tipo',
            space: 5,
            onChange: onChangeType
        }
    ]

    return (
        <Filters
            searchField={searchField}
            fields={filterFields}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
