import { useEffect, useState } from "react"
import { EmployeeSearchField } from "../../../../../components/fields/search-field/employee-search-field/employeeSearchField"
import { PanelWrapper } from "../../../../../components/wrappers/panel-wrapper/panelWrapper"
import { FormField, Data, useUIStateActions, ModalId, useUIActions, FieldValue, FieldType, Spinner, Validatable, useGetCrumbs } from "../../../../../features/ui"
import { Budget, BudgetItem, Customer } from "../../../state/types"
import { Area, Employee, PaymentType, useAdministrationActions, useAdministrationState, PaymentTypeField } from "../../../../administration"
import { useSalesActions } from "../../../actions/salesActions"
import { DataType, TableName } from "../../../../../tables"
import { CustomerSearchField } from "../../../../../components/fields/search-field/customer-search-field/customerSearchField"
import { useActions } from "./actions"
import { define } from "../../../../../utils/typeUtils"
import { DataTypeForm } from "../../../../../features/data-types"
import { Lot, Product } from "../../../../products"
import { useGenerateNextValue } from "../../../../../tables/hooks"
import { Operation, OperationItemsTable, usePricesHooks, OperationItemFormModal, PriceDetail } from '../../../../operations'
import { useFind } from "../../../../../state/reducers/hooks"
import { useSalesUIHooks } from "../../salesUiHooks"
import { Branch, useManagerState } from "../../../../manager"
import { Badge } from "../../../../../components/badge/badge"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"

export const SaleForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const budget = dataType as Budget | undefined
    const { currentBranch } = useManagerState()
    const branchId = currentBranch?.id
    const { currentEmployee } = useAdministrationState()

    const find = useFind()
    const findPaymentType = (id?: string) => find(TableName.PAYMENT_TYPES, id) as PaymentType | undefined

    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [code, setCode] = useState<number>(1)
    const [seller, setSeller] = useState<Employee | undefined>(currentEmployee)
    const [customer, setCustomer] = useState<Customer>()
    const [saleItemsData, setSaleItemsData] = useState<Data[]>([])
    const [currentSaleItem, setCurrentSaleItem] = useState<Data>()
    const [paymentType, setPaymentType] = useState<PaymentType>()
    const [subtotalPrice, setSubtotalPrice] = useState<number>(0)
    const [customerAdjustment, setCustomerAdjustment] = useState<number>(0)
    const [paymentTypeAdjustment, setPaymentTypeAdjustment] = useState<number>(0)
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [validations, setValidations] = useState<string[]>([])

    const { validateItemsData } = useSalesUIHooks()
    const pricesHooks = usePricesHooks()
    const generateNextValue = useGenerateNextValue()
    const navigate = useNavigate()

    const { submitSale } = useActions()
    const { setDisableBranchSelector } = useUIStateActions()
    const administrationActions = useAdministrationActions()
    const salesActions = useSalesActions()
    const { toggleModal } = useUIActions()

    const setSellerFromId = (id?: string, callback?: () => void) => {
        if (id) {
            const onFetchSeller = (sellerDT?: DataType) => {
                setSeller(sellerDT as Employee)
                callback && callback()
            }
            administrationActions().fetchEmployee(id, onFetchSeller)
        } else {
            setSeller(undefined)
        }   
    }

    const setCustomerFromId = (id?: string, callback?: () => void) => {
        if (id) {
            const onFetchCustomer = (customerDT?: DataType) => {
                setCustomer(customerDT as Customer)
                callback && callback()
            }
            salesActions().fetchCustomer(id, onFetchCustomer)
        } else {
            setCustomer(undefined)
        }
    }

    useEffect(() => {
        setDisableBranchSelector(true)
        
        const onGenerateNumber = (n: number) => setCode(n)
        generateNextValue(TableName.SALE_ORDERS, 'code', onGenerateNumber)
        
        if (budget) {
            const onSetCustomer = () => setAsyncDataLoaded(true)
            const onFetchBudgetItems = (budgetItemsDT: DataType[]) => {
                const budgetItems = budgetItemsDT as BudgetItem[]
                const defaultSaleItemsData = budgetItems.map(budgetItem => ({
                    productId: budgetItem.productId,
                    price: pricesHooks.product.basePrice(budgetItem.productId),
                    quantity: budgetItem.quantity
                }))
                setSaleItemsData(defaultSaleItemsData as Data[])
                setCustomerFromId(budget.customerId, onSetCustomer)
            }
            salesActions().fetchBudgetItemsByOrder(budget.id, onFetchBudgetItems)
        } else {
            setAsyncDataLoaded(true)
        }

        return () => {
            setDisableBranchSelector(false)
        }
    }, [])

    useEffect(() => {
        const salePriceItems = saleItemsData.map(data => ({ productId: data.productId as string, quantity: data.quantity as number }))
        const fullPrice = pricesHooks.sale.fullPrice(salePriceItems)
        setSubtotalPrice(fullPrice)
        const customerAdjustedPrice = pricesHooks.sale.adjustedPrice(salePriceItems, undefined, customer)
        setCustomerAdjustment(customerAdjustedPrice - fullPrice)
        const paymentTypeAdjustedPrice = pricesHooks.sale.adjustedPrice(salePriceItems, paymentType)
        setPaymentTypeAdjustment(paymentTypeAdjustedPrice - fullPrice)
        const adjustedPrice = pricesHooks.sale.adjustedPrice(salePriceItems, paymentType, customer)
        setTotalPrice(adjustedPrice)
    }, [saleItemsData, customer, paymentType])

    const title = 'Crear Venta' + (budget ? ` (Presupuesto #${budget.code})` : '')

    const crumbs = useGetCrumbs('Ventas', RoutePath.SALES, undefined, undefined, 'Crear')

    const filterProducts = (product: Product) =>
        currentSaleItem?.productId === product.id || !saleItemsData.map(saleItemData => saleItemData.productId).includes(product.id)
    const filterLots = (lot: Lot) =>
        currentSaleItem?.lotId === lot.id || !saleItemsData.map(saleItemData => saleItemData.lotId).includes(lot.id)

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

    const openFormModal = () => toggleModal(ModalId.OPERATION_ITEM_FORM)

    const saleItemMatches = (data: Data, productId: string, lotId?: string) =>
        data.productId === productId && data.lotId === lotId

    const onPaymentTypeChange = (value?: FieldValue) => setPaymentType(findPaymentType(value as string | undefined))

    const onCreateSaleItem = () => {
        setCurrentSaleItem(undefined)
        setValidations([])
        openFormModal()
    }

    const onEditSaleItem = (productId: string, lotId?: string) => {
        setCurrentSaleItem(saleItemsData.find(saleItemData => saleItemMatches(saleItemData, productId, lotId)))
        setValidations([])
        openFormModal()
    }

    const onRemoveSaleItem = (productId: string, lotId?: string) => {
        const newSaleItemsData = saleItemsData.filter(saleItemData => !saleItemMatches(saleItemData, productId, lotId))
        setSaleItemsData(newSaleItemsData)
        setValidations([])
    }

    const fields: FormField[] = [
        {
            name: 'branchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            label: 'Sucursal de Entrega',
            render: () => {
                const branch = define(find(TableName.BRANCHES, branchId))as Branch
                return <Badge label={branch.name} />
            }
        },
        {
            name: 'sellerId',
            type: FieldType.TABLE,
            table: TableName.EMPLOYEES,
            label: 'Vendedor',
            render: () => renderAsync(
                <EmployeeSearchField
                    id='sellerId'
                    defaultValue={seller?.id}
                    areas={[Area.MANAGMENT, Area.SALES]}
                    onChange={setSellerFromId}
                />
            )
        },
        {
            name: 'customerId',
            type: FieldType.TABLE,
            table: TableName.CUSTOMERS,
            label: 'Cliente',
            render: () => renderAsync(
                <CustomerSearchField
                    id='customerId'
                    defaultValue={customer?.id}
                    onChange={setCustomerFromId}
                />
            )
        },
        {
            name: 'deliveryDate',
            type: FieldType.DATE,
            label: 'Fecha de Entrega',
            min: new Date()
        },
        {
            name: 'saleItems',
            type: FieldType.TABLE,
            table: TableName.SALE_ITEMS,
            render: () => renderAsync(
                <Validatable validations={validations}>
                    <OperationItemsTable
                        rows={saleItemsData}
                        operation={Operation.SALE}
                        onCreate={onCreateSaleItem}
                        onEditRow={onEditSaleItem}
                        onRemoveRow={onRemoveSaleItem}
                    />
                </Validatable>
            )
        },
        PaymentTypeField({
            onChange: onPaymentTypeChange
        }),
        {
            name: 'totalPrice',
            type: FieldType.PRICE,
            label: 'Precio',
            render: () => <PriceDetail
                subtotal={subtotalPrice}
                customerAdjustment={customerAdjustment}
                paymentTypeAdjustment={paymentTypeAdjustment}
                total={totalPrice}
            />
        }
    ]

    const validate = async () => {
        const itemValidations = await validateItemsData(saleItemsData, define(branchId))
        setValidations(itemValidations)
        return itemValidations.length === 0
    }

    const onSubmit = async (saleData: Data) => {
        const valid = await validate()
        if (valid) {
            submitSale(
                saleData,
                code,
                define(branchId),
                define(totalPrice),
                define(seller).id,
                define(customer).id,
                saleItemsData,
                define(paymentType).id
            )
        }
    }

    const onCancel = () => {
        navigate(-1)
    }

    const onSaveSaleItem = (newData: Data) => {
        let newSaleItemsData = saleItemsData
        if (currentSaleItem) {
            const { productId, lotId } = currentSaleItem
            newSaleItemsData = saleItemsData.map(saleItemData => saleItemMatches(saleItemData, define(productId) as string, lotId as string | undefined) ? newData : saleItemData)  
        } else {
            newSaleItemsData = [newData, ...saleItemsData]
        }
        setSaleItemsData(newSaleItemsData)
    }
    
    return (
        <PanelWrapper title={title} crumbs={crumbs} wide>
            <DataTypeForm
               formId="sale-form"
               fields={fields}
               createMode={true}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
            <OperationItemFormModal
                operationItem={currentSaleItem}
                operation={Operation.SALE}
                withLots
                filterProducts={filterProducts}
                filterLots={filterLots}
                onSubmit={onSaveSaleItem}
            />
        </PanelWrapper>
    )
}
