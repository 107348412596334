import { v4 } from "uuid";
import { define, replaceAll } from "../utils/typeUtils";
import { TablesData } from "./config";
import { IdentifierLabels } from "./labels/identifierLabels";
import { dataTypePrefix } from "./labels/prefixes";
import { DataType, NamedDataType, TableName } from "./types/types";

export const newId = () => replaceAll(v4(), ',', '')

export const identifier = (tableName: TableName, dataType: DataType) => {
    const identifierFn = IdentifierLabels.get(tableName)
    return identifierFn ? identifierFn(dataType) : (dataType as NamedDataType).name
}

export const describeDataType = (tableName: TableName, dataType: DataType, withDataTypeName = false, withPrefix = false) => {
    const tableData = define(TablesData.get(tableName))
    const prefix = dataTypePrefix(tableName)
    const identifierFn = IdentifierLabels.get(tableName)
    const maybeQuotes = withDataTypeName ? '"' : ''
    const identifier = identifierFn ? identifierFn(dataType) : maybeQuotes + (dataType as NamedDataType).name + maybeQuotes
    return `${withPrefix ? prefix + ' ' : ''}${withDataTypeName ? tableData.singular + ' ' : ''}${identifier}`
}
