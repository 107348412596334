import { useNavigate } from "react-router-dom"
import { PanelWrapper } from "../../../../components/wrappers/panel-wrapper/panelWrapper"
import { DataTypeForm } from "../../../../features/data-types"
import { Data, FieldType, FormField, option, useGetCrumbs } from "../../../../features/ui"
import { cashFundTypeLabel } from "../../../../tables/types/labels"
import { CashFund, CashFundType } from "../../state/types"
import { useActions } from "./actions"
import { RoutePath, useRoutesHooks } from "../../../../features/routes"

export const CashFundForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const cashFund = dataType as CashFund | undefined
    const createMode = !cashFund

    const navigate = useNavigate()

    const { submitCashFund } = useActions()

    const cashFundLabel = cashFund ? `: ${cashFund.name}` : ''
    const title = `${createMode ? 'Crear' : 'Editar'} Caja${cashFundLabel}`

    const crumbs = useGetCrumbs('Cajas', RoutePath.CASH_FUNDS, cashFund?.name, undefined, createMode ? 'Crear' : 'Editar')

    const fields: FormField[] = [
        { name: 'name', type: FieldType.TEXT, label: 'Nombre' },
        {
            name: 'type',
            type: FieldType.SELECT,
            label: 'Tipo de Caja',
            options: Object.values(CashFundType).map(type => option(type, cashFundTypeLabel(type)))
        }
    ]

    const onSubmit = (cashFundData: Data) => submitCashFund(cashFundData, createMode)

    const onCancel = () => navigate(-1)

    return (
        <PanelWrapper title={title} crumbs={crumbs}>
            <DataTypeForm
               formId="cash-fund-form"
               fields={fields}
               createMode={createMode}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
        </PanelWrapper>
    )
}
