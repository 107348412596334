import { PaymentType } from "../../modules/administration"
import { newId } from "../utils"

export const paymentTypes = (companyId: string): PaymentType[] => [
    { companyId, id: newId(), name: 'Transferencia', yield: 0 },
    { companyId, id: newId(), name: 'Tarjeta de Débito', yield: 0 },
    { companyId, id: newId(), name: 'Efectivo', yield: -5 },
    { companyId, id: newId(), name: 'Tarjeta de Crédito - 1 cuota', yield: 0 },
    { companyId, id: newId(), name: 'Tarjeta de Crédito - 3 cuotas', yield: 5 },
    { companyId, id: newId(), name: 'Tarjeta de Crédito - 6 cuotas', yield: 10 },
    { companyId, id: newId(), name: 'Tarjeta de Crédito - 9 cuotas', yield: 15 },
    { companyId, id: newId(), name: 'Tarjeta de Crédito - 12 cuotas', yield: 20 },
    { companyId, id: newId(), name: 'Cheque', yield: 0 },
    { companyId, id: newId(), name: 'E-Cheq', yield: 0 }
]
