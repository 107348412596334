import { Expense } from '../../../modules/administration'
import { DataType, TableName } from '../../../tables'
import { define } from '../../../utils/typeUtils'

export const fileName = (tableName: TableName, dataType: DataType, label?: string) => {
    const partsMap = new Map<TableName, [string, string]>()
        .set(TableName.EXPENSES, ['Gasto', (dataType as Expense).code.toString()])
    
    const parts = define(partsMap.get(tableName))
    return `${label || parts[0]}_${parts[1]}.pdf`
}

export const fileSubPath = (tableName: TableName, id: string) => {
    if (tableName === TableName.COMPANIES) {
        return 'logo'
    }

    const partsMap = new Map<TableName, [string, string]>()
        .set(TableName.CATEGORIES, ['category', 'image'])
        .set(TableName.EXPENSES, ['expense', 'receipt'])
        .set(TableName.PRODUCTS, ['product', 'image'])
    
    const parts = define(partsMap.get(tableName))
    return `${parts[0]}/${id}/${parts[1]}`
}
