export type Company = {
    id: string
    code: string
    name: string
    websiteUrl: string
    email: string
    phone?: number
    logoUrl?: string
    address: string
}

export type User = {
    id: string
    name?: string
    email?: string
    pictureUrl?: string
}

export enum UserRole {
    MANAGER = 'Manager',
    ADMINISTRATION = 'Administration',
    PURCHASES = 'Purchases',
    SALES = 'Sales',
    WAREHOUSE = 'Warehouse'
}

export enum RequestStatus {
    PENDING = 'Pending',
    APPROVED = 'Approved',
    DENIED = 'Denied'
}

export type Request = {
    companyId: string
    id: string
    userId: string
    role: UserRole
    status: RequestStatus
}

export enum IdentifierType {
    PERSONAL_ID = 'PersonalId',
    TAX_ID = 'TaxId'
}
