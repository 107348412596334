import { Data, useUIActions } from "../../../../features/ui"
import { useAdministrationActions } from "../../actions/administrationActions"
import { PaymentType } from "../../state/types"

export const useActions = () => {
    const administrationActions = useAdministrationActions()
    const { onSubmitDataType } = useUIActions()

    const submitPaymentType = async (
        paymentTypeData: Data,
        paymentTypeYield: number,
        createMode: boolean
    ) => {
        const { companyId, id, name } = paymentTypeData
        const paymentType: PaymentType = {
            companyId: companyId as string,
            id: id as string,
            name: name as string,
            yield: paymentTypeYield
        }

        await administrationActions().savePaymentType(paymentType)
        onSubmitDataType(createMode)
    }
    
    return { submitPaymentType }
}
