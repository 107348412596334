import { ListResponse } from "../../actions/types"
import { NativeType } from "../../components/types"
import { DynamoUserApi } from "../../services"
import { AttributeMap, useDynamoActions } from "../../services/dynamo"
import { toDynamoListCallback } from "../../services/utils"
import { DataType, TableName, TablesData } from "../../tables"
import { define } from "../../utils/typeUtils"

export const useUpdatedValueMigration = () => {
    const dynamoActions = useDynamoActions()

    return (
        companyIds: string[],
        table: TableName,
        propName: string,
        updateValue: (value: NativeType) => NativeType
    ) => {
        companyIds.forEach(companyId => {
            const { getAll, createOrUpdateMultiple } = dynamoActions(DynamoUserApi, companyId)
            const { dbTableName } = define(TablesData.get(table))
            const onGetAll = (response: ListResponse) => {
                const updatedDataTypes = response.dataTypes
                    .map(dataType => {
                        const oldValue = dataType[propName as keyof DataType] as NativeType
                        return {
                            ...dataType,
                            [propName]: updateValue(oldValue)
                        }
                    }) as AttributeMap[]
                createOrUpdateMultiple(dbTableName, updatedDataTypes)
            }
            getAll(dbTableName, undefined, undefined, undefined, toDynamoListCallback(onGetAll))
        })
    }
}
