import { showToast } from "../../../../../features/ui"
import { useAdministrationHooks } from "../../../../administration"
import { useSalesActions } from "../../.."

export const useActions = () => {
    const { getSalesSettingsData } = useAdministrationHooks()
    
    const salesActions = useSalesActions()
    
    const onUpdateSettings = () => showToast('La configuración fue actualizada.')

    const ignoreProduct = (productId: string) => {
        const { hoardIgnoredProductIds } = getSalesSettingsData()
        const newProductIds = hoardIgnoredProductIds.concat(productId)
        salesActions().saveHoardIgnoredProducts(newProductIds, onUpdateSettings)
    }

    const unignoreProduct = (productId: string) => {
        const { hoardIgnoredProductIds } = getSalesSettingsData()
        const newProductIds = hoardIgnoredProductIds.filter(id => id !== productId)
        salesActions().saveHoardIgnoredProducts(newProductIds, onUpdateSettings)
    }

    return {
        ignoreProduct,
        unignoreProduct
    }
}
