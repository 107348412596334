import "react-toastify/dist/ReactToastify.css"
import { DataType } from "./tables"
import { useAuth0, User as Auth0User } from "@auth0/auth0-react"
import { TextParams } from "./state/reducers/types"
import { define, firstItem, getUrlPath } from "./utils/typeUtils"
import { useUIStateActions } from "./features/ui"
import { awsSystemKey, awsSystemSecret } from "./services/constants"
import { initTablesData } from "./tables"
import { BaseCallback } from "./actions/types"
import { useStockActions } from "./modules/stock"
import { useProductsActions } from "./modules/products"
import { usePurchasesActions } from "./modules/purchases"
import { Company, Request, RequestStatus, User, UserRole, useSystemActions, useSystemStateActions } from "./modules/system"
import { Employee, useAdministrationActions, useAdministrationStateActions } from "./modules/administration"
import { useSalesActions } from "./modules/sales"
import { Branch, useManagerActions, useManagerStateActions } from "./modules/manager"
import { callSequentially } from "./utils/fnUtils"
import { ConstrufyAdminRoleName } from "./modules/construfy-admin"
import { roleName } from "./features/roles"
import { initSystemApis, initIamSystemApi, useServicesHooks } from "./services"
import { TenantKey, UniqueKey } from "./tables/constants"
import { Emitter, Event, useInitEmitter } from "./features/event-emitter"

const pathname = window.location.pathname
const urlPath = getUrlPath(pathname)
const isAdminPath = urlPath === 'admin'

export const pureConfig = () => {
  const run = () => {
    initTablesData()
    initSystemApis(awsSystemKey, awsSystemSecret, TenantKey, UniqueKey)
    initIamSystemApi(awsSystemKey, awsSystemSecret)
  }

  return { run }
}

export const useReduxConfig = () => {
  const { isAuthenticated, user } = useAuth0()
  const { setCurrentBranch } = useManagerStateActions()
  const { setCurrentEmployee } = useAdministrationStateActions()
  const { setUser, setConstrufyAdminId, setUserRequest } = useSystemStateActions()

  useInitEmitter()
  const { initUserApis } = useServicesHooks()

  const administrationActions = useAdministrationActions()
  const managerActions = useManagerActions()
  const productsActions = useProductsActions()
  const purchasesActions = usePurchasesActions()
  const salesActions = useSalesActions()
  const stockActions = useStockActions()
  const systemActions = useSystemActions()

  const appReady = () => {}

  const onFetchtAll = async (request: Request) => {
    if (request.role !== UserRole.MANAGER) {
      return appReady()
    }
    
    await systemActions(request.companyId).fetchAllRequests()
    appReady()
  }

  const fetchInitialData = (request: Request, userEmail: string) => {
    const { companyId } = request

    const { fetchAllSettings, fetchAllCashFunds, fetchAllPaymentTypes, fetchAllEmployees, fetchAllExpenseTypes, fetchEmployeeByEmail } = administrationActions(companyId)
    const { fetchAllCategories, fetchAllProducts, fetchAllProductPrices, fetchAllLots, fetchAllMeasures } = productsActions(companyId)
    const { fetchAllSuppliers } = purchasesActions(companyId)
    const { fetchAllBudgets, fetchAllCustomerTypes, fetchAllCustomers } = salesActions(companyId)
    const { fetchAllStock, fetchAllUnitTypes } = stockActions(companyId)
    
    const fetchEmployeeFn = (callback: BaseCallback) =>
      fetchEmployeeByEmail(userEmail).then((employeeDT: DataType | null) => {
        if (employeeDT) {
          setCurrentEmployee(employeeDT as Employee)
        } else {
          Emitter.emit(Event.EMPLOYEE_MISSING, request)
        }
        callback()
      })

    type FetchAllFn = (limit?: number, startKey?: TextParams, callback?: BaseCallback) => void
    const fn = (fetchAllFn: FetchAllFn) => (callback: BaseCallback) => fetchAllFn(undefined, undefined, callback)
    const fns = [
      fn(fetchAllSettings),
      fetchEmployeeFn,
      fn(fetchAllBudgets),
      fn(fetchAllCashFunds),
      fn(fetchAllCategories),
      fn(fetchAllCustomerTypes),
      fn(fetchAllCustomers),
      fn(fetchAllEmployees),
      fn(fetchAllExpenseTypes),
      fn(fetchAllPaymentTypes),
      fn(fetchAllProducts),
      fn(fetchAllLots),
      fn(fetchAllSuppliers),
      fn(fetchAllStock),
      fetchAllUnitTypes,
      fetchAllProductPrices,
      fetchAllMeasures
    ]
    callSequentially(fns, () => onFetchtAll(request))
  }

  const fetchBranches = (request: Request, userEmail: string) => {
    const { fetchAllBranches } = managerActions(request.companyId)
    
    const onFetchBranches = (branches: Branch[]) => {
      setCurrentBranch(define(firstItem(branches)))
      fetchInitialData(request, userEmail)
    }

    fetchAllBranches().then(onFetchBranches)
  }

  const onFetchRequest = (companyCode: string, stateUser: User) => async (requestDT: DataType | null) => {
    if (!requestDT) {
      return appReady()
    }
    const request = requestDT as Request
    setUserRequest(request)
    if (request.status !== RequestStatus.APPROVED) {
      return appReady()
    }
    
    const userEmail = define(stateUser.email)
    await initUserApis(roleName(companyCode, request.role), userEmail, TenantKey, UniqueKey)
    
    fetchBranches(request, userEmail)
  }

  const onFetchCompany = (stateUser: User) => (companyDT?: DataType) => {
    if (!companyDT) {
      return appReady()
    }
    const company = companyDT as Company
    systemActions(company.id).fetchRequest(company.id, stateUser.id).then(onFetchRequest(company.code, stateUser))
  }

  const onFetchAdmin = (stateUser: User) => (adminDT?: DataType) => {
    if (!adminDT || adminDT.id !== stateUser.id) {
      return appReady()
    }
    initUserApis(ConstrufyAdminRoleName, define(stateUser.email), TenantKey, UniqueKey)
    setConstrufyAdminId(adminDT.id)
    appReady()
  }

  const readPath = (stateUser: User) => {
    const { fetchAdmin, fetchCompanyByCode } = systemActions()
    if (isAdminPath) {
      fetchAdmin(stateUser.id).then(onFetchAdmin(stateUser))
    } else {
      fetchCompanyByCode(urlPath, true).then(onFetchCompany(stateUser))
    }
  }

  const setUserState = (stateUser: User) => {
    setUser(stateUser)
    readPath(stateUser)
  }

  const createAndSaveUser = (auth0User: Auth0User, userId: string) => {
    const { name, email, picture } = auth0User
    const newUser: User = {
      id: userId,
      name,
      email: define(email),
      pictureUrl: picture
    }
    systemActions().saveUser(newUser, true).then(setUserState)
  }

  const run = () => {
    const userId = user?.sub
    if (!isAuthenticated || !userId) {
      return appReady()
    }
    systemActions().fetchUserById(userId).then((userDT?: DataType) => {
      if (userDT) {
        setUserState(userDT as User)
      } else {
        createAndSaveUser(user, userId)
      }
    })
  }

  return { run }
}
