import { useNavigate } from "react-router-dom"
import { Action, DetailFieldProps, DetailPanel, FieldType, ModalId, SpinnerDiv, UIPermission, useGetCrumbs, useUIActions } from "../../../../features/ui"
import { TableName } from "../../../../tables"
import { useSystemStateHooks } from "../../../system"
import { Expense, ExpenseStatus } from "../../state/types"
import { ExpenseReceiptModal } from "../expense-receipt-modal/expenseReceiptModal"
import { RoutePath, useRoutesHooks } from "../../../../features/routes"

export const ExpenseDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const expense = dataType as Expense | undefined

  const { userHasPermission } = useSystemStateHooks()
  const readOnly = !userHasPermission(UIPermission.SAVE_EXPENSES)
  const navigate = useNavigate()

  const { toggleModal } = useUIActions()

  const crumbs = useGetCrumbs('Gastos', RoutePath.EXPENSES, expense?.name)

  if (!expense) return <SpinnerDiv />

  const fields: DetailFieldProps[] = [
    { name: 'expenseTypeId', type: FieldType.TABLE, table: TableName.EXPENSE_TYPES, label: 'Tipo' },
    { name: 'date', type: FieldType.DATE, label: 'Fecha' },
    { name: 'branchId', type: FieldType.TABLE, table: TableName.BRANCHES, label: 'Sucursal' },
    { name: 'amount', type: FieldType.PRICE, label: 'Monto' }
  ]
  if (expense.detail) {
    const detailField = {
      name: 'detail',
      type: FieldType.TEXT,
      label: 'Detalle'
    }
    fields.splice(3, 0, detailField)
  }

  if (expense.supplierId) {
    const supplierField = {
      name: 'supplierId',
      type: FieldType.TABLE,
      table: TableName.SUPPLIERS,
      label: 'Proveedor'
    }
    fields.splice(3, 0, supplierField)
  }

  const onDownloadReceipt = () => toggleModal(ModalId.EXPENSE_RECEIPT)

  const onEdit = () => navigate(RoutePath.EDIT)

  const onRemove = () => toggleModal(ModalId.REMOVE)

  const actions: Action[] = []
  if (expense.receiptUrl) {
    actions.push(
      { label: 'Descargar', icon: 'download', kind: 'tertiary', onClick: onDownloadReceipt }
    )
}
  if (!readOnly && expense.status === ExpenseStatus.PENDING) {
    actions.push(
      { label: 'Editar', icon: 'pencil-fill', onClick: onEdit },
      { label: 'Eliminar', kind: 'secondary', icon: 'trash-fill', onClick: onRemove }
    )
  }

  return (<>
    <DetailPanel
      tableName={TableName.EXPENSES}
      crumbs={crumbs}
      fields={fields}
      data={expense}
      icon='receipt'
      readOnly={readOnly}
      actions={actions}
    />
    <ExpenseReceiptModal expense={expense} />
  </>)
}
