export type MenuItem = {
    id: string
    label: string
    icon?: string
    path?: string
    children?: MenuItem[]
}

export enum StockAction {
    ADD = 'Add',
    SUBSTRACT = 'Substract'
}

export enum RequestAction {
    APPROVE = 'Approve',
    DENY = 'Deny'
}

export const enum ToastKind {
    GREEN = 'success',
    RED = 'error',
    YELLOW = 'warning'
}

export enum UIPermission {
    READ_PRODUCT_PURCHASE_PRICE,
    READ_TRANSACTIONS,
    SAVE_ADMINISTRATION_SETTINGS,
    SAVE_BRANCHES,
    SAVE_BUDGETS,
    SAVE_CASH_FUNDS,
    SAVE_CATEGORIES,
    SAVE_COMPANY,
    SAVE_CUSTOMER_TYPES,
    SAVE_CUSTOMERS,
    SAVE_EMPLOYEES,
    SAVE_EXPENSE_TYPES,
    SAVE_EXPENSES,
    SAVE_HOARD_DELIVERIES,
    SAVE_HOARDS,
    SAVE_PAYMENT_TYPES,
    SAVE_PRODUCT_PRICES,
    SAVE_PRODUCTS,
    SAVE_PURCHASE_DELIVERIES,
    SAVE_PURCHASES,
    SAVE_PURCHASES_SETTINGS,
    SAVE_REQUESTS,
    SAVE_SALE_DELIVERIES,
    SAVE_SALES,
    SAVE_SALES_SETTINGS,
    SAVE_STOCK,
    SAVE_SUPPLIERS,
    SAVE_TRANSACTIONS,
}
