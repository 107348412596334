import { CashFund, Employee, Expense, ExpenseType, PaymentType, Settings, Transaction } from "./types"

export enum ActionType {
    SET_CURRENT_EMPLOYEE = 'setCurrentEmployee',
    UPDATE_SETTINGS = 'updateSettings',
    UPDATE_CASH_FUNDS = 'updateCashFunds',
    REMOVE_CASH_FUNDS = 'removeCashFunds',
    UPDATE_TRANSACTIONS = 'updateTransactions',
    UPDATE_PAYMENT_TYPES = 'updatePaymentTypes',
    REMOVE_PAYMENT_TYPES = 'removePaymentTypes',
    UPDATE_EMPLOYEES = 'updateEmployees',
    REMOVE_EMPLOYEES = 'removeEmployees',
    UPDATE_EXPENSE_TYPES = 'updateExpenseTypes',
    REMOVE_EXPENSE_TYPES = 'removeExpenseTypes',
    UPDATE_EXPENSES = 'updateExpenses',
    REMOVE_EXPENSES = 'removeExpenses'
}

interface SetCurrentEmployee {
    type: ActionType.SET_CURRENT_EMPLOYEE
    payload: Employee
}

interface UpdateSettings {
    type: ActionType.UPDATE_SETTINGS
    payload: Settings[]
}

interface UpdateCashFunds {
    type: ActionType.UPDATE_CASH_FUNDS
    payload: CashFund[]
}

interface RemoveCashFunds {
    type: ActionType.REMOVE_CASH_FUNDS
    payload: string[]
}

interface UpdateTransactions {
    type: ActionType.UPDATE_TRANSACTIONS
    payload: Transaction[]
}

interface UpdatePaymentTypes {
    type: ActionType.UPDATE_PAYMENT_TYPES
    payload: PaymentType[]
}

interface RemovePaymentTypes {
    type: ActionType.REMOVE_PAYMENT_TYPES
    payload: string[]
}

interface UpdateEmployees {
    type: ActionType.UPDATE_EMPLOYEES
    payload: Employee[]
}

interface RemoveEmployees {
    type: ActionType.REMOVE_EMPLOYEES
    payload: string[]
}

interface UpdateExpenseTypes {
    type: ActionType.UPDATE_EXPENSE_TYPES
    payload: ExpenseType[]
}

interface RemoveExpenseTypes {
    type: ActionType.REMOVE_EXPENSE_TYPES
    payload: string[]
}

interface UpdateExpenses {
    type: ActionType.UPDATE_EXPENSES
    payload: Expense[]
}

interface RemoveExpenses {
    type: ActionType.REMOVE_EXPENSES
    payload: string[]
}

export type AdministrationAction =
    SetCurrentEmployee |    
    AdministrationUpdateAction |
    AdministrationRemoveAction

export type AdministrationUpdateAction =
    UpdateSettings |
    UpdateCashFunds |
    UpdateTransactions |
    UpdatePaymentTypes |
    UpdateEmployees |
    UpdateExpenseTypes |
    UpdateExpenses

export type AdministrationRemoveAction =
    RemoveCashFunds |
    RemovePaymentTypes |
    RemoveEmployees |
    RemoveExpenseTypes |
    RemoveExpenses
