import styles from './wizardPanel.module.css'
import React, { ReactElement, useEffect } from 'react'
import { Button } from '../../../../components/button/button'
import { PanelWrapper } from '../../../../components/wrappers/panel-wrapper/panelWrapper'
import { useWizard } from '../../hooks/wizard'
import { Spinner, ValidationModal } from '../../../ui'
import { FinishWizardModal } from '../finish-wizard-modal/finishWizardModal'
import { useNavigate } from 'react-router-dom'

type Props = {
    wizardKey: string
    title: string
    steps: ReactElement[]
    wide?: boolean
    validations?: string[]
    onFinish: () => void
    onGoToStep?: (stepIndex: number) => void
}

export const WizardPanel = (props: Props) => {
    const { title, steps, wide, validations = [], onFinish, onGoToStep } = props
    
    const { step, currentStepIndex, next, back, isFirstStep, isLastStep } = useWizard(steps)
    const navigate = useNavigate()

    useEffect(() => {
        onGoToStep && onGoToStep(currentStepIndex)
    }, [currentStepIndex])
    
    const renderSpinner = () => (
        <div className={styles.spinnerContainer}>
            <Spinner size='l' />
        </div>
    )

    const goBack = () => navigate(-1)
    
    const renderCancelBtn = () => (
        <Button
            label='Cancelar'
            icon='x-lg'
            kind='quaternary'
            onClick={goBack}
        />
    )

    const handleNext = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        next()
    }

    return (
        <PanelWrapper title={title} headerActionsRenderer={renderCancelBtn} wide={wide}>
            {steps.length === 0 ? renderSpinner() : (
                <div>
                    <div className={styles.header}>
                        <span className={styles.checksLabel}>
                            Paso {currentStepIndex + 1} de {steps.length}
                        </span>
                        <div className={styles.checks}>
                            {steps.map((_, index) => {
                                const isCurrent = index === currentStepIndex
                                const icon = index < currentStepIndex ? 'check-circle-fill' : isCurrent ? 'record-circle-fill' : 'circle'
                                const inProgressClass = isCurrent ? styles.inProgress : ''
                                return (
                                    <div key={index} className={styles.checkContainer}>
                                        <i className={`bi bi-${icon} ${styles.checkIcon} ${inProgressClass}`}></i>
                                        {index < steps.length - 1 && (
                                            <div className={styles.line} />
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    
                    <form onSubmit={handleNext}>
                        {step}

                        <div className={styles.btnsContainer}>
                            {!isFirstStep && (
                                <Button
                                    label='Volver'
                                    kind="tertiary"
                                    rightMargin={true}
                                    onClick={back}
                                />
                            )}
                            <Button
                                type='submit'
                                label={isLastStep ? 'Finalizar' : 'Continuar'}
                            />
                        </div>
                    </form>

                    <FinishWizardModal onFinish={onFinish} />
                    <ValidationModal
                        title="No se puede finalizar el proceso"
                        validations={validations}
                    />
                </div>
            )}
        </PanelWrapper>
    )
}
