import { Dispatch } from 'redux'
import { ActionType, PurchasesAction } from './actionTypes'
import { PurchaseItem, PurchaseOrder, Supplier } from './types'

export const updatePurchases = (purchases: PurchaseOrder[]) => {
    return (dispatch: Dispatch<PurchasesAction>) => {
        dispatch({
            type: ActionType.UPDATE_PURCHASES,
            payload: purchases
        })
    }
}

export const removePurchases = (ids: string[]) => {
    return (dispatch: Dispatch<PurchasesAction>) => {
        dispatch({
            type: ActionType.REMOVE_PURCHASES,
            payload: ids
        })
    }
}

export const updatePurchaseItems = (purchaseItems: PurchaseItem[]) => {
    return (dispatch: Dispatch<PurchasesAction>) => {
        dispatch({
            type: ActionType.UPDATE_PURCHASE_ITEMS,
            payload: purchaseItems
        })
    }
}

export const removePurchaseItems = (ids: string[]) => {
    return (dispatch: Dispatch<PurchasesAction>) => {
        dispatch({
            type: ActionType.REMOVE_PURCHASE_ITEMS,
            payload: ids
        })
    }
}

export const updateSuppliers = (suppliers: Supplier[]) => {
    return (dispatch: Dispatch<PurchasesAction>) => {
        dispatch({
            type: ActionType.UPDATE_SUPPLIERS,
            payload: suppliers
        })
    }
}

export const removeSuppliers = (ids: string[]) => {
    return (dispatch: Dispatch<PurchasesAction>) => {
        dispatch({
            type: ActionType.REMOVE_SUPPLIERS,
            payload: ids
        })
    }
}
