import styles from './transactionList.module.css'
import { FieldType, UIPermission, Column, FieldValue, Data, Spinner, Action, DateField, ListWithFilters, SelectField } from "../../../../features/ui"
import { useSystemStateHooks } from "../../../system"
import { define } from "../../../../utils/typeUtils"
import { PanelWrapper } from "../../../../components/wrappers/panel-wrapper/panelWrapper"
import { useActions } from "./actions"
import { TableName } from "../../../../tables"
import { useState } from "react"
import { Transaction } from "../../state/types"
import { LAST_14_DAYS, LAST_30_DAYS, LAST_7_DAYS, MANUAL, TODAY, periodOptions } from './utils'
import { useTransactionHooks } from '../../hooks/transactionHooks'
import { Badge } from '../../../../components/badge/badge'

export const TransactionList = () => {
    const [transactions, setTransactions] = useState<Transaction[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [period, setPeriod] = useState<string>(MANUAL)
    
    const buildDateFrom = (daysBack: number) => {
        const newDateFrom = new Date()
        newDateFrom.setDate(newDateFrom.getDate() - daysBack)
        newDateFrom.setHours(0, 0, 0, 0)
        return newDateFrom
    }
    const buildDateTo = () => {
        const newDateTo = new Date()
        newDateTo.setHours(23, 59, 59, 999)
        return newDateTo
    }
    
    const [dateFrom, setDateFrom] = useState<Date>(buildDateFrom(7))
    const [dateTo, setDateTo] = useState<Date>(buildDateTo())

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_TRANSACTIONS)
    const { getSourceInfo } = useTransactionHooks()

    const { fetchTransactions } = useActions()

    const renderAsync = (render: (value?: FieldValue) => string | JSX.Element) => asyncDataLoaded ? render : () => <Spinner size='m'/>

    const columns: Column[] = [
        {
            name: 'date',
            type: FieldType.DATE,
            label: 'Fecha'
        },
        {
            name: 'cashFundId',
            type: FieldType.TABLE,
            table: TableName.CASH_FUNDS,
            label: 'Caja'
        },
        {
            name: 'amount',
            type: FieldType.PRICE,
            label: 'Ingreso/Egreso'
        },
        {
            name: 'source',
            type: FieldType.TEXT,
            label: 'Origen',
            render: renderAsync((id?: FieldValue) => {
                const transaction = define(transactions.find(transaction => transaction.id === id)) as Transaction
                return getSourceInfo(transaction).sourceLabel
            })
        },
        {
            name: 'paymentTypeId',
            type: FieldType.TABLE,
            table: TableName.PAYMENT_TYPES,
            label: 'Medio de Pago',
            render: renderAsync((id?: FieldValue) => {
                const transaction = define(transactions.find(transaction => transaction.id === id)) as Transaction
                return <Badge label={getSourceInfo(transaction).paymentType} />
            })
        }
    ]

    const fetchRows = (callback: (rows: Data[]) => void) => {
        const onFetchTransactions = (
            newTransactions: Transaction[]
        ) => {
            setTransactions(transactions.concat(newTransactions))
            setAsyncDataLoaded(true)
            const rows: Data[] = newTransactions.map(transaction => ({
                ...transaction,
                source: transaction.id,
                paymentTypeId: transaction.id
            }))
            callback && callback(rows)
        }
        fetchTransactions(dateFrom, dateTo, onFetchTransactions)
    }

    const setPeriodDates = (daysBack: number) => {
        setDateFrom(buildDateFrom(daysBack))
        setDateTo(buildDateTo())
    }

    const onPeriodChange = (value?: string) => {
        if (value === TODAY) {
            setPeriod(TODAY)
            setPeriodDates(0)
        } else if (value === LAST_7_DAYS) {
            setPeriod(LAST_7_DAYS)
            setPeriodDates(7)
        } else if (value === LAST_14_DAYS) {
            setPeriod(LAST_14_DAYS)
            setPeriodDates(14)
        } else if (value === LAST_30_DAYS) {
            setPeriod(LAST_30_DAYS)
            setPeriodDates(30)
        } else if (value === MANUAL) {
            setPeriod(MANUAL)
        }
    }

    const onDateFromChange = (value?: Date) => {
        if (value) {
            const newDateFrom = new Date(value)
            newDateFrom.setHours(0, 0, 0, 0)
            setDateFrom(newDateFrom)
            setPeriod(MANUAL)
        }
    }

    const onDateToChange = (value?: Date) => {
        if (value) {
            const newDateTo = new Date(value)
            newDateTo.setHours(23, 59, 59, 999)
            setDateTo(newDateTo)
            setPeriod(MANUAL)
        }
    }

    const filters = () => [
        <div key='period-filter' className="col-sm-3">
            <SelectField
                id='period-filter'
                options={periodOptions()}
                value={period}
                onChange={onPeriodChange}
            />
        </div>,
        <div key='date-from-filter' className="col-sm-2">
            <DateField
                id='date-from-filter'
                value={dateFrom}
                maxDate={dateTo}
                className={styles.dateField}
                onChange={onDateFromChange}
            />
        </div>,
        <div key='date-to-filter' className="col-sm-2">
            <DateField
                id='date-to-filter'
                value={dateTo}
                minDate={dateFrom}
                className={styles.dateField}
                onChange={onDateToChange}
            />
        </div>
    ]

    const actions = (rowIdValue: string): Action[] => {
        return [
            { icon: 'eye-fill', kind: 'tertiary', tooltip: 'Ver Detalle', onClick: () => {} },
        ] 
    }

    return (
        <PanelWrapper title="Movimientos" wide>
            <ListWithFilters
                columns={columns}
                fetchRows={fetchRows}
                filters={filters}
                actions={actions}
                readOnly={readOnly}
            />
        </PanelWrapper>
    )
}
