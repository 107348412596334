import styles from './numberField.module.css'
import { useEffect, useRef, useState } from 'react'
import { isDefined, toNumber } from '../../../utils/typeUtils';

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    id: string
    inputKey?: string
    value?: number
    defaultValue?: number
    allowDecimals?: boolean
    optional?: boolean
    fit?: boolean
    focusOnLoad?: boolean
    className?: string
    onChange?: (value?: number) => void
}

export const NumberField = (props: Props) => {
    const { id, inputKey, value, defaultValue, allowDecimals = true, optional, fit, focusOnLoad, className, onChange, ...rest } = props
    const [stateValue, setStateValue] = useState(defaultValue)
    const inputRef = useRef<HTMLInputElement>(null)
    
    useEffect(() => {
        if (isDefined(value)) {
            setStateValue(value)
            onChange && onChange(value)
        }
    }, [value])

    useEffect(() => {
        focusOnLoad && inputRef && inputRef.current?.focus()
    }, [focusOnLoad, inputRef])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = toNumber(e.target?.value)
        setStateValue(newValue)
        onChange && onChange(newValue)
    }

    const inputValue = isDefined(stateValue) ? stateValue : ''

    return (
        <div className={fit ? styles.container : ''}>
            <input
                id={id}
                key={inputKey}
                type='number'
                name={id}
                value={inputValue}
                step={allowDecimals ? '0.01' : '1'}
                required={!optional}
                className={`form-control ${className || ''} ${styles.number}`}
                ref={inputRef}
                autoComplete="off"
                onChange={handleChange}
                {...rest}
            />
        </div>
    )
}
