import { define } from "../../../../utils/typeUtils"
import { ModalId, useUIActions } from "../../../../features/ui"
import { TableName } from "../../../../tables"
import { useDataTypeActions } from "../../actions/dataTypeActions"

export const useActions = () => {
    const { getRemoveAction, getRemoveValidation } = useDataTypeActions()
    const { openModal, closeModal, onRemoveDataType } = useUIActions()

    const removeDataType = async (
        tableName: TableName,
        id: string,
        onRemove?: (id: string) => void,
        afterRemove?: () => void
    ) => {
        if (onRemove) {
            onRemove(id)
            afterRemove && afterRemove()
        } else {
            openModal(ModalId.SPINNER)
            
            const removeValidation = getRemoveValidation(tableName)
            
            const canRemove = !removeValidation || await removeValidation(id)
            if (canRemove) {
                const removeAction = getRemoveAction(tableName)
                const removeCallback = () => {
                    setTimeout(() => closeModal(ModalId.SPINNER), 1000)
                    afterRemove ? afterRemove() : onRemoveDataType()
                }
                define(removeAction)(id, removeCallback)
            } else {
                closeModal(ModalId.SPINNER)
                setTimeout(() => openModal(ModalId.MESSAGE), 500)
            }
        }
    }
    
    return { removeDataType }
}
