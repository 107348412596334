import { useDefaultModuleActions } from "../../../actions/defaultModuleActions"
import { BaseCallback } from "../../../actions/types"
import { DynamoUserApi } from "../../../services"
import { TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { useSystemStateHooks } from "../../system"
import { OrderDelivery } from "../state/types"

export const useOperationsActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId

    const defaultModuleActions = useDefaultModuleActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const { fetchMultipleByParams_async, save_async, removeMultiple } = defaultModuleActions(DynamoUserApi, define(companyId))
    
        const fetchDeliveries = (orderId: string) => {
            return fetchMultipleByParams_async(TableName.ORDER_DELIVERIES, { orderId })
        }

        const saveDelivery = (delivery: OrderDelivery) => {
            return save_async(TableName.ORDER_DELIVERIES, delivery)
        }

        const removeDeliveries = (ids: string[], callback?: BaseCallback) => {
            removeMultiple(TableName.ORDER_DELIVERIES, ids, callback)
        }

        return {
            saveDelivery,
            fetchDeliveries,
            removeDeliveries
        }
    }
}
