import { Data, showToast } from "../../../../../features/ui"
import { usePurchasesActions } from "../../.."
import { CategorySaleYield, useAdministrationHooks } from "../../../../administration"
import { define } from "../../../../../utils/typeUtils"

export const useActions = () => {
    const { getSalesSettingsData } = useAdministrationHooks()
    const purchasesActions = usePurchasesActions()
    
    const onUpdateSettings = () => showToast('La configuración fue actualizada.')

    const updateSaleYield = (saleYield: number) => {
        purchasesActions().saveSaleYield(saleYield, onUpdateSettings)
    }

    const addCategoryConfiguration = (data: Data) => {
        const { categorySaleYields } = getSalesSettingsData()
        const categorySaleYield: CategorySaleYield = {
            categoryId: define(data.categoryId) as string,
            saleYield: define(data.saleYield) as number
        }
        const map = new Map(categorySaleYields.map(categorySaleYield => [categorySaleYield.categoryId, categorySaleYield]))
        map.set(categorySaleYield.categoryId, categorySaleYield)
        const newCategorySaleYields = Array.from(map.values())
        purchasesActions().saveCategorySaleYields(newCategorySaleYields, onUpdateSettings)
    }

    const removeCategoryConfiguration = (categoryId: string) => {
        const { categorySaleYields } = getSalesSettingsData()
        const newCategorySaleYields = categorySaleYields.filter(categorySaleYield => categorySaleYield.categoryId !== categoryId)
        purchasesActions().saveCategorySaleYields(newCategorySaleYields, onUpdateSettings)
    }

    return {
        updateSaleYield,
        addCategoryConfiguration,
        removeCategoryConfiguration
    }
}
