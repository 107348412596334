import { useChildrenActions } from "../../../actions/childrenActions"
import { useDefaultModuleActions } from "../../../actions/defaultModuleActions"
import { BaseCallback, ItemCallback, ListCallback, OptionalItemCallback, SubmitChildrenFn } from "../../../actions/types"
import { Data } from "../../../features/ui"
import { DynamoUserApi } from "../../../services"
import { DataType, TableName } from "../../../tables"
import { Currency } from "../../../tables/types/types"
import { newId } from "../../../tables/utils"
import { callSequentiallyForDataTypes } from "../../../utils/fnUtils"
import { define } from "../../../utils/typeUtils"
import { OrderDelivery, OrderStatus, useOperationsActions, useOperationsHooks } from "../../operations"
import { useStockActions } from "../../stock"
import { useSystemStateHooks } from "../../system"
import { SaleOrder, SaleItem } from "../state/types"
import { useBudgetActions } from "./budgetActions"
import { useCustomerActions } from "./customerActions"
import { useHoardActions } from "./hoard-actions/hoardActions"

export const useSalesActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId
    const { getPendingQuantity } = useOperationsHooks()

    const defaultModuleActions = useDefaultModuleActions()
    const budgetActions = useBudgetActions()
    const hoardActions = useHoardActions()
    const operationsActions = useOperationsActions()
    const customerActions = useCustomerActions()
    const stockActions = useStockActions()
    const { submitChildren } = useChildrenActions()

    return (paramCompanyId?: string) => {
        const companyId = define(paramCompanyId || stateCompanyId)
        const { fetchByParams, fetchMultipleByParams_async, fetchByIds, count, save_async, saveMultiple, remove, removeMultiple } = defaultModuleActions(DynamoUserApi, companyId)
        const { fetchAllBudgets, countBudgets, saveBudgetWithItems, removeBudget, fetchBudgetItemsByOrder, countBudgetItems } = budgetActions(companyId)
        const { fetchAllHoards, countHoards, saveHoard, removeHoard, fetchHoardItemsByOrder, countHoardItems, saveHoardDelivery, saveHoardIgnoredProducts, closeHoard } = hoardActions(companyId)
        const { fetchAllCustomerTypes, fetchCustomerType, saveCustomerType, removeCustomerType, fetchAllCustomers, fetchCustomer, countCustomers, saveCustomer, removeCustomer } = customerActions(companyId)
        const { fetchDeliveries, saveDelivery, removeDeliveries } = operationsActions(companyId)

        const fetchAllSales = (branchId: string) => {
            return fetchMultipleByParams_async(TableName.SALE_ORDERS, { branchId })
        }

        const fetchSale = (id: string, callback?: OptionalItemCallback) => {
            fetchByParams(TableName.SALE_ORDERS, { id }, callback)
        }

        const fetchSales = (ids: string[], callback?: ListCallback) => {
            fetchByIds(TableName.SALE_ORDERS, ids, callback)
        }

        const countSales = (propName: string, propValue: string) => {
            return count(TableName.SALE_ORDERS, { [propName]: propValue })
        }

        const saveSale = (sale: SaleOrder) => {
            return save_async(TableName.SALE_ORDERS, sale)
        }

        const saveSaleWithItems = async (
            sale: SaleOrder,
            saleItemsData: Data[],
            callback?: ItemCallback
        ) => {
            const savedSale = await saveSale(sale)
            
            const saleItems = saleItemsData.map(saleItemData => ({
                ...saleItemData,
                companyId: define(companyId),
                id: newId(),
                saleId: sale.id,
                currency: Currency.ARS
            })) as SaleItem[]

            const onFinish = () => callback && callback(savedSale)

            const saveItems = (saleItems: SaleItem[], onSaveItems?: ListCallback) =>
                saveSaleItems(saleItems, sale.branchId, onSaveItems)
            submitChildren([], saleItems, saveItems as SubmitChildrenFn, undefined, onFinish)
        }

        const removeSale = (id: string, callback?: BaseCallback) => {
            const onFinish = () => callback && callback()

            const onRemoveSaleItems = async () => {
                const deliveries = await fetchDeliveries(id) as OrderDelivery[]
                const deliveryIds = deliveries.map(delivery => delivery.id)
                removeDeliveries(deliveryIds, onFinish)
            }

            const onRemoveSale = async () => {
                const saleItems = await fetchSaleItemsByOrder(id) as SaleItem[]
                removeSaleItems(saleItems.map(saleItem => saleItem.id), onRemoveSaleItems)
            }
            
            const onFetchSale = (saleDT?: DataType) => {
                const sale = define(saleDT) as SaleOrder
                if (sale.status !== OrderStatus.DELIVERED) {
                    remove(TableName.SALE_ORDERS, id, onRemoveSale)
                } else {
                    onFinish()
                }
            }
            
            fetchSale(id, onFetchSale)
        }

        const fetchSaleItemsByOrder = (saleId: string) => {
            return fetchMultipleByParams_async(TableName.SALE_ITEMS, { saleId })
        }

        const countSaleItems = (propName: string, propValue: string) => {
            return count(TableName.SALE_ITEMS, { [propName]: propValue })
        }

        const saveSaleItems = (saleItems: SaleItem[], branchId: string, callback?: ListCallback) => {
            const { reserveStock } = stockActions()

            const onUpdateStock = () => {
                saveMultiple(TableName.SALE_ITEMS, saleItems, callback)
            }

            const updateStock = (saleItemDT: DataType, onUpdateItemStock: BaseCallback) => {
                const { productId, lotId, quantity } = saleItemDT as SaleItem
                reserveStock(productId, branchId, quantity, lotId, onUpdateItemStock)
            }

            callSequentiallyForDataTypes(saleItems, updateStock, onUpdateStock)
        }

        const removeSaleItems = (ids: string[], callback?: BaseCallback) => {
            removeMultiple(TableName.SALE_ITEMS, ids, callback)
        }

        const saveSaleDelivery = async (
            sale: SaleOrder,
            saleItems: SaleItem[],
            delivery: OrderDelivery
        ) => {
            const { decreaseReservedStock } = stockActions()
            
            const deliveries = await fetchDeliveries(sale.id) as OrderDelivery[]
            const savedDelivery = await saveDelivery(delivery) as OrderDelivery

            let pendingItemsCount = 0
            savedDelivery.deliveredItems.forEach(deliveredItem => {
                const deliveredSaleItem = saleItems.find(saleItem => saleItem.id === deliveredItem.itemId)
                const pendingQuantity = getPendingQuantity(define(deliveredSaleItem) as SaleItem, deliveries)
                deliveredItem.delivered < pendingQuantity && pendingItemsCount++
            })
            const updatedSale: SaleOrder = {
                ...sale,
                branchId: savedDelivery.branchId,
                status: pendingItemsCount === 0 ? OrderStatus.DELIVERED : OrderStatus.PARTIALLY_DELIVERED
            }

            const savedSale = await saveSale(updatedSale) as SaleOrder

            savedDelivery.deliveredItems.forEach(deliveredItem => {
                const saleItem = define(saleItems.find(saleItem => saleItem.id === deliveredItem.itemId))
                decreaseReservedStock(saleItem.productId, savedDelivery.branchId, deliveredItem.delivered, saleItem.lotId)
            })

            return savedSale
        }

        return {
            fetchAllSales,
            fetchSale,
            fetchSales,
            countSales,
            saveSale,
            saveSaleWithItems,
            removeSale,
            fetchSaleItemsByOrder,
            countSaleItems,
            saveSaleDelivery,
            saveHoardIgnoredProducts,
            fetchAllBudgets,
            countBudgets,
            saveBudgetWithItems,
            removeBudget,
            fetchBudgetItemsByOrder,
            countBudgetItems,
            fetchAllHoards,
            countHoards,
            saveHoard,
            removeHoard,
            fetchHoardItemsByOrder,
            countHoardItems,
            saveHoardDelivery,
            closeHoard,
            fetchAllCustomerTypes,
            fetchCustomerType,
            saveCustomerType,
            removeCustomerType,
            fetchAllCustomers,
            fetchCustomer,
            countCustomers,
            saveCustomer,
            removeCustomer
        }
    }
}
