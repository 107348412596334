import { TextOrNumberType } from "../../../components/types"
import { Currency } from "../../../tables/types/types"

export type SettingsDataType = TextOrNumberType | object

type SettingData = {
    [key: string]: SettingsDataType | SettingsDataType[]
}

export enum SettingsModule {
    SALES = 'Sales'
}

export type Settings = {
    companyId: string
    id: string
    module: SettingsModule
    data: SettingData
}

export type SaleTax = {
    name: string
    yield: number   
}

export type CategorySaleYield = {
    categoryId: string
    saleYield: number
}

export type SalesSettingsData = {
    valueTaxYield: number
    saleYield?: number
    categorySaleYields: CategorySaleYield[]
    saleTaxes: SaleTax[]
    hoardIgnoredProductIds: string[]
    branchData: {
        branchid: string
        saleTaxes: SaleTax[]
    }[]
}

export enum CashFundType {
    CASH = 'Cash',
    BANCK_ACCOUNT = 'Bank Account',
    CHECK = 'Check'
}

export type CashFund = {
    companyId: string
    id: string
    name: string
    type: CashFundType
    amount: number
    currency: Currency
}

export enum TransactionSourceType {
    CASH_FUND = 'Cash Fund',
    PURCHASE = 'Purchase',
    EXPENSE = 'Expense',
    SALE = 'Sale',
    HOARD = 'Hoard',
    CREDIT_NOTE = 'Credit Note',
    DEBIT_NOTE = 'Debit Note',
}

export type Transaction = {
    companyId: string
    id: string
    date: string
    cashFundId: string
    sourceType: TransactionSourceType
    sourceId: string
    amount: number
    currency: Currency
    concept?: string
}

export type PaymentType = {
    companyId: string
    id: string
    name: string
    yield: number
}

export enum Area {
    MANAGMENT = 'Managment',
    ADMINISTRATION = 'Administration',
    PURCHASES = 'Purchases',
    SALES = 'Sales',
    WAREHOUSE = 'Warehouse',
    HR = 'Human Resources'
}

export type Employee = {
    companyId: string
    id: string
    name: string
    identifier?: number
    area: Area
    email: string
    phone?: number
    address?: string
    branchId: string
}

export type Expense = {
    companyId: string
    id: string
    code: number
    name: string
    expenseTypeId: string
    date: string
    branchId: string
    amount: number
    currency: Currency
    detail?: string
    status: ExpenseStatus
    employeeId: string
    supplierId?: string
    receiptUrl?: string
}

export type ExpenseType = {
    companyId: string
    id: string
    name: string
}

export enum ExpenseStatus {
    PENDING = 'Pending',
    PAYED = 'Payed'
}
