import { useDescriptorHooks } from "../../../features/data-types"
import { useFind } from "../../../state/reducers/hooks"
import { TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { PurchaseOrder } from "../../purchases"
import { HoardOrder, SaleOrder } from "../../sales"
import { CashFund, PaymentType, Transaction, TransactionSourceType } from "../state/types"

export const useTransactionHooks = () => {
    const descriptorHooks = useDescriptorHooks()
    const find = useFind()

    const getSourceInfo = (
        transaction: Transaction
    ) => {
        const { sourceId, sourceType } = transaction
        let sourceLabel = ''
        let paymentType = ''

        const findPaymentType = (id: string) => define(find(TableName.PAYMENT_TYPES, id)) as PaymentType

        if (sourceType === TransactionSourceType.CASH_FUND) {
            const cashFund = define(find(TableName.CASH_FUNDS, sourceId)) as CashFund
            sourceLabel = `Mov. desde ${cashFund.name}`
            paymentType = 'Mov. entre Cajas'
        } else if (sourceType === TransactionSourceType.PURCHASE) {
            const purchase = define(find(TableName.PURCHASE_ORDERS, sourceId)) as PurchaseOrder
            sourceLabel = `Compra ${descriptorHooks.purchase.code(purchase)}`
            paymentType = 'Pago Pendiente'
        } else if (sourceType === TransactionSourceType.SALE) {
            const sale = define(find(TableName.SALE_ORDERS, sourceId)) as SaleOrder
            sourceLabel = `Venta ${descriptorHooks.sale.code(sale)}`
            paymentType = findPaymentType(sale.paymentTypeId).name
        } else if (sourceType === TransactionSourceType.HOARD) {
            const hoard = define(find(TableName.HOARD_ORDERS, sourceId)) as HoardOrder
            sourceLabel = `Acopio ${descriptorHooks.hoard.code(hoard)}`
            paymentType = findPaymentType(hoard.paymentTypeId).name
        }

        return {
            sourceLabel,
            paymentType
        }
    }

    return {
        getSourceInfo
    }
}
