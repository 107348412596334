import styles from './editableRow.module.css'
import { useState } from 'react'
import { Button } from '../../button/button'
import { EditableField } from '../../fields/editable-field/editableField'
import { ChangeValueType, FieldProps, NativeType } from '../../types'
import { isFieldTypeString } from '../../utils'

type Props = {
    field: FieldProps
    disableEdit?: boolean
    cancelLabel?: string
    cancelIcon?: string
    wide?: boolean
    onEditingChange?: (value: boolean) => void
    onEdit?: () => void
    onCancel?: () => void
}

export const EditableRow = (props: Props) => {
    const { field, disableEdit, cancelLabel, cancelIcon, onEditingChange, onEdit, onCancel, wide } = props
    const { label, value, readOnly, onChange, onSave } = field
    const [stateValue, setStateValue] = useState(value)
    const [editing, setEditing] = useState(false)
    const [dirty, setDirty] = useState(false)

    const changeEditing = (newEditing: boolean) => {
        setEditing(newEditing)
        onEditingChange && onEditingChange(newEditing)
    }

    const onEditField = () => {
        changeEditing(true)
        onEdit && onEdit()
    }

    const onCancelField = () => {
        changeEditing(false)
        setStateValue(value)
        setDirty(false)
        onCancel && onCancel()
    }

    const onSaveField = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        changeEditing(false)
        setDirty(false)
        onSave && onSave(stateValue)
    }

    const onChangeField = (newValue?: NativeType) => {
        setStateValue(newValue)
        setDirty(value !== newValue)
        onChange && onChange(newValue as ChangeValueType)
    }

    const strValue = isFieldTypeString(field.type) ? stateValue as string : stateValue?.toString()
    const isEmpty = strValue === undefined || strValue.trim() === ''
    const disableSave = !dirty || isEmpty
    const labelSpace = wide ? 4 : 3
    const fieldSpace = wide ? 4 : 3
    const btnsSpace = wide ? 4 : 3

    return (
        <div className={`row mb-3 ${styles.container}`}>
            <form onSubmit={onSaveField} className={styles.form}>
                <label className={`col-sm-${labelSpace} col-form-label`}>{label}</label>
                <div className={`col-sm-${fieldSpace}`}>
                    <EditableField
                        field={field}
                        editing={editing}
                        onChange={onChangeField}
                    />
                </div>
                <div className={`col-sm-${btnsSpace}`}>
                    {editing ? (
                        <>
                            <Button
                                label='Guardar'
                                icon='check-lg'
                                type='submit'
                                rightMargin
                                disabled={disableSave}
                            />
                            <Button
                                label={cancelLabel || 'Cancelar'}
                                icon={cancelIcon || 'x-lg'}
                                kind='quaternary'
                                onClick={onCancelField}
                            />
                        </>
                    ) : !readOnly && (
                        <Button
                            kind='tertiary'
                            icon='pencil-fill'
                            onClick={onEditField}
                            disabled={disableEdit}
                        />
                    )}
                </div>
            </form>
        </div>
    )
}
