import { option } from "../../../../features/ui"
import { Area, Employee } from "../../../../modules/administration"
import { DataType, TableName } from "../../../../tables"
import { SearchField } from "../searchField"

type Props = {
    id?: string
    defaultValue?: string
    areas?: Area[]
    disabled?: boolean
    onChange?: (value?: string) => void
}

export const EmployeeSearchField = (props: Props) => {
    const { id = 'employeeId', defaultValue, areas = [], disabled, onChange } = props

    const toOption = (dataType: DataType) => {
        const { id, name, identifier } = dataType as Employee
        const label = identifier ? `${name} (${identifier})` : name
        return option(id, label)
    }   

    const filterFn = (value: string) => (dataType: DataType) => {
        const employee = dataType as Employee
        return (areas.length == 0 || areas.includes(employee.area)) &&
            (employee.name.toLowerCase().includes(value) || !!employee.identifier?.toString().includes(value))
    }

    return <SearchField
        id={id}
        tableName={TableName.EMPLOYEES}
        defaultValue={defaultValue}
        placeholder='Buscar por nombre o DNI'
        disabled={disabled}
        toOption={toOption}
        filterFn={filterFn}
        onChange={onChange}
    />
}
