import { ExpenseType } from "../../modules/administration"
import { newId } from "../utils"

export const SalaryExpenseTypeName = 'Sueldos'

export const expenseTypes = (companyId: string): ExpenseType[] => [
    { companyId, id: newId(), name: 'Agua' },
    { companyId, id: newId(), name: 'Alquiler' },
    { companyId, id: newId(), name: 'Bancario' },
    { companyId, id: newId(), name: 'Combustible' },
    { companyId, id: newId(), name: 'Fletes' },
    { companyId, id: newId(), name: 'Gas' },
    { companyId, id: newId(), name: 'Indumentaria' },
    { companyId, id: newId(), name: 'Internet' },
    { companyId, id: newId(), name: 'Librería' },
    { companyId, id: newId(), name: 'Limpieza' },
    { companyId, id: newId(), name: 'Luz' },
    { companyId, id: newId(), name: 'Otros' },
    { companyId, id: newId(), name: 'Rodados' },
    { companyId, id: newId(), name: 'Seguridad' },
    { companyId, id: newId(), name: 'Seguros' },
    { companyId, id: newId(), name: 'Servicios Informáticos' },
    { companyId, id: newId(), name: 'Sistema de Gestión' },
    { companyId, id: newId(), name: SalaryExpenseTypeName },
    { companyId, id: newId(), name: 'Supermercado' },
    { companyId, id: newId(), name: 'Telefonía' }
]
