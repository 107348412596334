import { ListResponse } from "../../actions/types"
import { DynamoUserApi } from "../../services"
import { AttributeMap, useDynamoActions } from "../../services/dynamo"
import { toDynamoListCallback } from "../../services/utils"
import { DataType, TableName, TablesData } from "../../tables"
import { define } from "../../utils/typeUtils"

export const useRenamedPropMigration = () => {
    const dynamoActions = useDynamoActions()

    return (
        companyIds: string[],
        table: TableName,
        oldPropName: string,
        newPropName: string
    ) => {
        companyIds.forEach(companyId => {
            const { getAll, createOrUpdateMultiple } = dynamoActions(DynamoUserApi, companyId)
            const { dbTableName } = define(TablesData.get(table))
            const onGetAll = (response: ListResponse) => {
                const updatedDataTypes = response.dataTypes.map(dataType => {
                    const value = dataType[oldPropName as keyof DataType] || dataType[newPropName as keyof DataType]
                    return {
                        ...dataType,
                        [oldPropName]: undefined,
                        [newPropName]: value
                    }
                }) as AttributeMap[]
                createOrUpdateMultiple(dbTableName, updatedDataTypes)
            }
            getAll(dbTableName, undefined, undefined, undefined, toDynamoListCallback(onGetAll))
        })
    }
}
