import { FieldProps } from "../components/types"
import { ReducerName } from "../state/reducers/types"
import { capitalize } from "../utils/typeUtils"
import { TableName } from "./types/types"

export class TableData {
    constructor(
        readonly tableName: TableName,
        readonly hasTKey: boolean,
        readonly reducerName: ReducerName,
        readonly stateName: string,
        readonly singular: string,
        readonly dbTableName: string
    ) {}

    setter = () => `set${capitalize(this.stateName)}`

    updater = () => `update${capitalize(this.stateName)}`
    
    remover = () => `remove${capitalize(this.stateName)}`
}
