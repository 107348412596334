import { useUIActions } from "../../../../../features/ui"
import { useSalesActions } from "../../../actions/salesActions"
import { HoardOrder, HoardType } from "../../../state/types"
import { Data } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { Currency } from "../../../../../tables/types/types"
import { OrderStatus } from "../../../../operations"
import { PurchasePricesData } from "../../../../products"

export const useActions = () => {
    const salesActions = useSalesActions()
    const { onSubmitDataType } = useUIActions()
    
    const submitHoard = (
        hoardData: Data,
        code: number,
        type: HoardType,
        branchId: string,
        totalPrice: number,
        sellerId: string,
        customerId: string,
        hoardItemsData: Data[],
        paymentTypeId: string,
        purchasePricesList: PurchasePricesData
    ) => {
        const dueDate = define(hoardData.dueDate) as Date
        const value = hoardData.value as number | undefined
        const hoard: HoardOrder = {
            companyId: hoardData.companyId as string,
            id: hoardData.id as string,
            code,
            type,
            creationDate: new Date().toISOString(),
            totalPrice,
            currency: Currency.ARS,
            dueDate: dueDate.toISOString(),
            sellerId,
            customerId,
            paymentTypeId,
            branchId,
            status: hoardData.status as OrderStatus || OrderStatus.PENDING,
            purchasePricesList,
            ...(type === HoardType.FINANCIAL && { value: define(value) }),
        }

        const onSaveHoard = () => onSubmitDataType(true)
        salesActions().saveHoard(hoard, hoardItemsData, onSaveHoard)
    }
    
    return { submitHoard }
}
