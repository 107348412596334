import { CashFundType } from "../../modules/administration";
import { ExpenseStatus } from "../../modules/administration/state/types";
import { AdjustmentType, OrderStatus, TaxCategory } from "../../modules/operations";
import { LotAttribute, MeasureType } from "../../modules/products";
import { HoardType, CustomerCategory } from "../../modules/sales";
import { IdentifierType, RequestStatus, UserRole } from "../../modules/system";
import { define } from "../../utils/typeUtils";

export const userRoleLabel = (role: UserRole) => {
    const labels = new Map<UserRole, string>()
        .set(UserRole.MANAGER, 'Manager')    
        .set(UserRole.ADMINISTRATION, 'Administración')
        .set(UserRole.PURCHASES, 'Compras')
        .set(UserRole.SALES, 'Ventas')
        .set(UserRole.WAREHOUSE, 'Depósito')
    return define(labels.get(role))
}

export const identifierTypeLabel = (type: IdentifierType) => {
    const labels = new Map<IdentifierType, string>()
        .set(IdentifierType.PERSONAL_ID, 'DNI')    
        .set(IdentifierType.TAX_ID, 'CUIT / CUIL')
    return define(labels.get(type))
}

export const requestStatusLabel = (status: RequestStatus) => {
    const labels = new Map<RequestStatus, string>()
        .set(RequestStatus.APPROVED, 'Aprobada')
        .set(RequestStatus.DENIED, 'Rechazada')
        .set(RequestStatus.PENDING, 'Pendiente')
    return define(labels.get(status))
}

export const adjustmentTypeLabel = (type: AdjustmentType) => {
    const labels = new Map<AdjustmentType, string>()
        .set(AdjustmentType.DISCOUNT, 'Descuento')
        .set(AdjustmentType.SURCHARGE, 'Recargo')
    return define(labels.get(type))
}

export const hoardTypeLabel = (type: HoardType, summarized = false) => {
    const labels = new Map<HoardType, string>()
        .set(HoardType.FINANCIAL, `${summarized ? '' : 'Acopio '}Financiero`)
        .set(HoardType.PRODUCT, `${summarized ? 'De' : 'Acopio de'} Productos`)
    return define(labels.get(type))
}

export const orderStatusLabel = (status: OrderStatus) => {
    const labels = new Map<OrderStatus, string>()
        .set(OrderStatus.PENDING, 'Pendiente')
        .set(OrderStatus.PARTIALLY_DELIVERED, 'Parcialmente Entregado')
        .set(OrderStatus.DELIVERED, 'Entregado')
        .set(OrderStatus.CANCELLED, 'Cancelado')
    return define(labels.get(status))
}

export const lotAttributeLabel = (attr: LotAttribute) => {
    const labels = new Map<LotAttribute, string>()
        .set(LotAttribute.TONE, 'Tono')
        .set(LotAttribute.CALIBER, 'Calibre')
        .set(LotAttribute.DUE_DATE, 'Fecha de Vto.')
    return define(labels.get(attr))
}

export const cashFundTypeLabel = (type: CashFundType) => {
    const labels = new Map<CashFundType, string>()
        .set(CashFundType.CASH, 'Efectivo')    
        .set(CashFundType.BANCK_ACCOUNT, 'Cuenta de Banco')
        .set(CashFundType.CHECK, 'Cheque')
    return define(labels.get(type))
}

export const expenseStatusLabel = (status: ExpenseStatus) => {
    const labels = new Map<ExpenseStatus, string>()
        .set(ExpenseStatus.PENDING, 'Pendiente')
        .set(ExpenseStatus.PAYED, 'Pagado')
    return define(labels.get(status))
}

export const taxCategoryLabel = (taxCategory: TaxCategory) => {
    const labels = new Map<TaxCategory, string>()
        .set(TaxCategory.CONSUMER, 'Consumidor Final')
        .set(TaxCategory.REGISTERED, 'Responsable Inscripto')
        .set(TaxCategory.REGISTERED_AGENT, 'Ag. Ret. Responsable Inscripto')
        .set(TaxCategory.REGISTERED_FC, 'FCE Responsable Inscripto')
        .set(TaxCategory.EXEMPT, 'Exento')
        .set(TaxCategory.EXEMPT_FC, 'FCE Exento')
        .set(TaxCategory.SINGLE_TAXPAYER, 'Monotributista')
        .set(TaxCategory.M_TICKET, 'Repsonsable Factura M')
        .set(TaxCategory.NON_REGISTERED, 'No Inscripto')
    return define(labels.get(taxCategory))
}

export const customerCategoryLabel = (category: CustomerCategory) => {
    const labels = new Map<CustomerCategory, string>()
        .set(CustomerCategory.NATURAL, 'Persona Física')
        .set(CustomerCategory.LEGAL, 'Persona Jurídica')
    return define(labels.get(category))
}

export const measureTypeLabel = (type: MeasureType) => {
    const labels = new Map<MeasureType, string>()
        .set(MeasureType.AREA, 'Superficie')
        .set(MeasureType.DEPTH, 'Profundidad')
        .set(MeasureType.DIAMETER, 'Diametro')
        .set(MeasureType.HEIGHT, 'Altura')
        .set(MeasureType.THICKNESS, 'Espesor')
        .set(MeasureType.VOLUME, 'Volumen')
        .set(MeasureType.WEIGHT, 'Peso')
        .set(MeasureType.WIDTH, 'Ancho')
    return define(labels.get(type))
}
