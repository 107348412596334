import { TableName } from "../../../../../tables"
import { useDescriptorHooks } from "../../../../../features/data-types"
import { FieldType, FormField } from "../../../../../features/ui"
import { WizardStep } from "../../../../../features/wizard"
import { HoardOrder } from "../../../state/types"

type Props = {
    wizardKey: string
    stepIndex: number
    hoard: HoardOrder
}

export const HoardInfoStep = (props: Props) => {
    const { wizardKey, stepIndex, hoard } = props
    const descriptorHooks = useDescriptorHooks()

    const fields: FormField[] = [
        {
            name: 'code',
            type: FieldType.NUMBER,
            label: 'Acopio',
            render: () => <>{descriptorHooks.hoard.code(hoard)}</>
        },
        {
            name: 'dueDate',
            type: FieldType.DATE,
            label: 'Fecha Límite',
            value: new Date(hoard.dueDate),
            disabled: true
        },
        {
            name: 'date',
            type: FieldType.DATE,
            defaultValue: new Date(),
            label: 'Fecha de Entrega',
            focusOnLoad: true
        },
        {
            name: 'branchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            defaultValue: hoard.branchId,
            label: 'Sucursal de Entrega'
        }
    ]

    return <WizardStep
        wizardKey={wizardKey}
        stepIndex={stepIndex}
        fields={fields}
    />
}
