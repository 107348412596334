import { Settings, SettingsModule, SalesSettingsData } from "../../modules/administration"
import { newId } from "../utils"

export const settings = (companyId: string): Settings[] => [
    {
        companyId,
        id: newId(),
        module: SettingsModule.SALES,
        data: salesSettingsData
    }
]

const salesSettingsData: SalesSettingsData = {
    valueTaxYield: 21,
    saleYield: 10,
    categorySaleYields: [],
    saleTaxes: [
        { name: 'Seguridad e higiene', yield: 1 },
        { name: 'IIBB', yield: 2 },
        { name: 'Municipal', yield: 3 }
    ],
    hoardIgnoredProductIds: [],
    branchData: []
}
