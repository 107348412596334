import { RoutePath, useRoutesHooks } from "../../../../features/routes"
import { DetailFieldProps, DetailPanel, FieldRow, FieldType, FieldValue, SpinnerDiv, StaticValue, Tab, UIPermission, useGetCrumbs } from "../../../../features/ui"
import { TableName } from "../../../../tables"
import { usePricesHooks } from "../../../operations"
import { useSystemStateHooks } from "../../../system"
import { Product } from "../../state/types"
import { ProductPriceList } from "../product-price-list/productPriceList"

export const ProductDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const product = dataType as Product | undefined

  const { userHasPermission } = useSystemStateHooks()
  const readOnly = !userHasPermission(UIPermission.SAVE_PRODUCTS)
  const pricesHooks = usePricesHooks()
  
  const crumbs = useGetCrumbs('Productos', RoutePath.PRODUCTS, product?.name)

  if (!product) return <SpinnerDiv />

  const purchasePrice = pricesHooks.product.purchasePrice(product.id)

  const fields: DetailFieldProps[] = [
    {
      name: 'name',
      type: FieldType.TEXT,
      label: 'Nombre'
    },
    {
      name: 'code',
      type: FieldType.TEXT,
      label: 'Código',
      render: (value?: FieldValue) => <>#{value}</>
    },
    {
      name: 'supplierCode',
      type: FieldType.TEXT,
      label: 'Código Proveedor'
    },
    {
      name: 'categoryId',
      type: FieldType.TABLE,
      table: TableName.CATEGORIES,
      label: 'Categoría'
    },
    {
      name: 'supplierId',
      type: FieldType.TABLE,
      table: TableName.SUPPLIERS,
      label: 'Proveedor'
    },
    {
      name: 'loted',
      type: FieldType.BOOLEAN,
      label: 'Contiene Partidas'
    },
    {
      name: 'purchasePrice',
      type: FieldType.PRICE,
      label: 'Precio de Compra',
      render: () => (
        <StaticValue
          type={FieldType.PRICE}
          value={purchasePrice}
        />
      )
    },
  ]
  if (product.description) {
    const descriptionField = {
      name: 'description',
      type: FieldType.TEXT,
      label: 'Descripción'
    }
    fields.splice(4, 0, descriptionField)
  }

  const renderPricesContent = (<>
    <FieldRow label="Precio de Lista">
      <StaticValue type={FieldType.PRICE} value={pricesHooks.product.basePrice(product.id)} />
    </FieldRow>
    <ProductPriceList product={product} />
  </>)

  const tabs: Tab[] = [
    { id: 'prices', title: 'Precios', content: renderPricesContent }
  ]

  return (
    <DetailPanel
      tableName={TableName.PRODUCTS}
      crumbs={crumbs}
      fields={fields}
      data={product}
      icon='box-seam'
      tabs={tabs}
      readOnly={readOnly}
    />
  )
}
