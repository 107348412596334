import styles from './booleanField.module.css'
import { useEffect, useState } from 'react'

type Props = {
    id: string
    label?: string
    value?: boolean
    defaultValue?: boolean
    optional?: boolean
    disabled?: boolean
    onChange: (value?: boolean) => void
}

export const BooleanField = (props: Props) => {
    const { id, label, value, defaultValue, optional, disabled, onChange } = props
    const [checked, setChecked] = useState<boolean | undefined>(defaultValue)

    useEffect(() => {
        value && setChecked(value)
    }, [value])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.checked
        setChecked(newValue)
        onChange(newValue)
    }

    return (
        <div className={styles.container}>
            <input
                type="checkbox"
                id={id}
                name={id}
                checked={checked}
                required={!optional}
                disabled={disabled}
                className={`form-check-input ${styles.checkbox}`}
                onChange={handleChange}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    )
}
