import { withAuthenticationRequired } from '@auth0/auth0-react';
import { pureConfig } from './config';
import { Outlet } from 'react-router-dom';
import { Header } from './features/ui';
import { Footer } from './components/footer/footer';
import { ToastContainer } from 'react-toastify';

pureConfig().run()

function App() {
  return (
    <>
      <Header />
      <main id="main" className="main">
        <Outlet />
      </main>
      <Footer />
      <ToastContainer />
    </>
  )
}

export default withAuthenticationRequired(App)
