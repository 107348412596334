import styles from './list.module.css'
import { Link } from "../../../../components/link/link"
import { Table } from "../table/table"
import { Action, Column, Data } from "../types"

type Props = {
    columns: Column[]
    rows: Data[]
    rowId: string
    createLabel?: string
    readOnly?: boolean
    minItems?: number
    actions?: (rowIdValue: string) => Action[]
    onCreate?: () => void
    onEdit?: (rowIdValue: string) => void
    onRemove?: (rowIdValue: string) => void
    renderDetail?: (row: Data) => JSX.Element
}

export const List = (props: Props) => {
    const { columns, rows, rowId, createLabel, actions, readOnly, minItems, onCreate, onEdit, onRemove, renderDetail } = props

    return (<>
        <Table
            columns={columns}
            rows={rows}
            rowId={rowId}
            createLabel={createLabel || 'Crear Nuevo'}
            actions={actions}
            readOnly={readOnly}
            minItems={minItems}
            onCreate={onCreate}
            onEdit={onEdit}
            onRemove={onRemove}
            renderDetail={renderDetail}
        />
        {rows.length === 0 && (
            <div className={styles.empty}>
                <i className={`bi bi-bricks`} />
                <span>Esta tabla está vacía.</span>
                {!readOnly && onCreate && (
                    <Link
                        text='Crear el primer item'
                        kind="primary"
                        onClick={onCreate}
                        className={styles.emptyLink}
                    />
                )}
            </div>
        )}
    </>)
}
