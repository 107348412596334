import { Option } from "../../../features/ui";
import { normalize } from "../../../utils/typeUtils";

export const getOptions = (sourceOptions: Option[], text = '', freeValue = false) => {
    const includesText = (option: Option) =>
        normalize(option.label).includes(normalize(text))
    
    let options = sourceOptions.filter(includesText)
    
    if (!freeValue) {
        const unmatches = sourceOptions.filter(option => !includesText(option))
        options = options.concat(unmatches)
    }

    return options
}
