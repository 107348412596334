import { define } from '../../../../utils/typeUtils'
import { useSystemState } from '../..'
import { Data, DetailFieldProps, FieldType, FormField, ImageField, ProfilePanel, Spinner } from '../../../../features/ui'
import { useEffect, useState } from 'react'
import { useFileActions } from '../../../../features/files'
import { TableName } from '../../../../tables'
import { Employee } from '../../../administration'
import { areaLabel } from '../../../administration/utils/administrationUtils'
import { Badge } from '../../../../components/badge/badge'
import { useFind, useFindByParams } from '../../../../state/reducers/hooks'
import { Branch } from '../../../manager'

export const UserPanel = () => {
  const { user } = useSystemState()
  const definedUser = define(user)

  const [asyncDataLoaded, setAsyncDataLoaded] = useState(!definedUser.pictureUrl)
  const [pictureFile, setPictureFile] = useState<File>()
  
  const find = useFind()
  const findByParams = useFindByParams()
  const employee = define(findByParams(TableName.EMPLOYEES, { email: define(definedUser.email) })) as Employee

  // const { updateUser } = useActions()
  const { fetchFile } = useFileActions()

  useEffect(() => {
    if (definedUser.pictureUrl) {
      fetchFile(definedUser.pictureUrl, 'user-picture', true).then(file => {
        setPictureFile(file)
        setAsyncDataLoaded(true)
      })
    } else {
      setAsyncDataLoaded(true)
    }
  }, [])

  const userData = {
    ...definedUser,
    imageUrl: definedUser.pictureUrl
  }

  const infoFields: DetailFieldProps[] = [
    {
      name: 'name',
      type: FieldType.TEXT,
      label: 'Nombre'
    },
    {
      name: 'email',
      type: FieldType.TEXT,
      label: 'Email'
    },
    {
      name: 'branchId',
      type: FieldType.TABLE,
      table: TableName.BRANCHES,
      label: 'Sucursal',
      render: () => {
        const branch = define(find(TableName.BRANCHES, employee.branchId)) as Branch
        return <Badge label={branch.name} />
      }
    },
    {
      name: 'area',
      type: FieldType.TEXT,
      label: 'Área',
      render: () => <Badge label={areaLabel(employee.area)} />
    }
  ]

  const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

  const fields: FormField[] = [
    {
      name: 'picture',
      type: FieldType.IMAGE,
      label: 'Foto',
      render: () => renderAsync(<ImageField
        id="picture"
        value={pictureFile}
        onChange={setPictureFile}
      />)
    },
    {
      name: 'name',
      type: FieldType.TEXT,
      label: 'Nombre'
    },
    {
      name: 'email',
      type: FieldType.TEXT,
      label: 'Email'
    }
  ]

  const onSubmit = async (data: Data) => {
    // return updateUser(definedUser, data, pictureFile)
  }

  return <ProfilePanel
    infoFields={infoFields}
    fields={fields}
    data={userData}
    subtitle={definedUser.email}
    infoTabLabel='Información de Perfil'
    readOnly={true}
    onSubmit={onSubmit}
  />
}
