import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux"
import { State } from "../../../state"
import { Request, useSystemStateHooks } from "../../../modules/system"
import { UIState } from "../state/reducer"
import { MenuItem, UIPermission, actionCreators } from ".."
import { RoutePath } from "../../routes"

export const useUIState = (): UIState => useSelector((state: State) => state.uiReducer)

export const useUIStateActions = () => bindActionCreators(actionCreators, useDispatch())

export const useUIHooks = () => {
    const { userHasPermission } = useSystemStateHooks()

    const getMenu = (companyName = 'Compañía', request?: Request): any => {
        const canSaveRequests = userHasPermission(UIPermission.SAVE_REQUESTS, request)
        const canSaveEmployees = userHasPermission(UIPermission.SAVE_EMPLOYEES, request)
        const canSaveExpenses = userHasPermission(UIPermission.SAVE_EXPENSES, request)
        const canSaveCashFunds = userHasPermission(UIPermission.SAVE_CASH_FUNDS, request)
        const canReadTransactions = userHasPermission(UIPermission.READ_TRANSACTIONS, request)
        const canSaveExpenseTypes = userHasPermission(UIPermission.SAVE_EXPENSE_TYPES, request)
        const canSaveSuppliers = userHasPermission(UIPermission.SAVE_SUPPLIERS, request)
        const canSavePaymentTypes = userHasPermission(UIPermission.SAVE_PAYMENT_TYPES, request)
        const canSaveProductPrices = userHasPermission(UIPermission.SAVE_PRODUCT_PRICES, request)

        const companyItems: MenuItem[] = [
            {
                id: 'company',
                label: 'Compañía',
                path: RoutePath.COMPANY
            },
            {
                id: 'branches',
                label: 'Sucursales',
                path: RoutePath.BRANCHES
            }            
        ]
        if (canSaveRequests) {
            companyItems.push({
                id: 'requests',
                label: 'Solicitudes de Acceso',
                path: RoutePath.REQUESTS
            })
        }

        const administrationItems: MenuItem[] = []
        if (canSaveEmployees || canSaveExpenses) {
            const employeesItem: MenuItem = {
                id: 'employees',
                label: 'Empleados',
                icon: 'file-person',
                children: []
            }
            if (canSaveEmployees) {
                employeesItem.children?.push({
                    id: 'employees',
                    label: 'Lista de Empleados',
                    path: RoutePath.EMPLOYEES
                })
            }
            if (canSaveExpenses) {
                employeesItem.children?.push({
                    id: 'salaries',
                    label: 'Sueldos Liquidados',
                    path: RoutePath.SALARIES
                })
            }
            administrationItems.push(employeesItem)
        }
        if (canSaveCashFunds || canReadTransactions) {
            const cashFundsItem: MenuItem = {
                id: 'cash-funds-menu',
                label: 'Cajas',
                icon: 'cash-stack',
                children: []
            }
            if (canSaveCashFunds) {
                cashFundsItem.children?.push({
                    id: 'cash-funds',
                    label: 'Lista de Cajas',
                    path: RoutePath.CASH_FUNDS
                })
            }
            if (canReadTransactions) {
                cashFundsItem.children?.push({
                    id: 'transactions',
                    label: 'Movimientos',
                    path: RoutePath.TRANSACTIONS
                })
            }
            administrationItems.push(cashFundsItem)
        }
        if (canSaveExpenses || canSaveExpenseTypes) {
            const expensesItem: MenuItem = {
                id: 'expenses',
                label: 'Gastos',
                icon: 'wallet2',
                children: []
            }
            if (canSaveExpenses) {
                expensesItem.children?.push({
                    id: 'expenses',
                    label: 'Lista de Gastos',
                    path: RoutePath.EXPENSES
                })
            }
            if (canSaveExpenseTypes) {
                expensesItem.children?.push({
                    id: 'expense-types',
                    label: 'Tipos de Gasto',
                    path: RoutePath.EXPENSE_TYPES
                })
            }
            if (canSaveExpenses) {
                expensesItem.children?.push({
                    id: 'salaries',
                    label: 'Sueldos Liquidados',
                    path: RoutePath.SALARIES
                })
            }
            if (canSaveSuppliers) {
                expensesItem.children?.push({
                    id: 'expense-suppliers',
                    label: 'Proveedores',
                    path: RoutePath.EXPENSE_SUPPLIERS
                })
            }
            administrationItems.push(expensesItem)
        }
        if (canSavePaymentTypes) {
            administrationItems.push({
                id: 'payment-types',
                label: 'Medios de Pago',
                path: RoutePath.PAYMENT_TYPES
            })
        }
        administrationItems.push({
            id: 'taxes-menu',
            label: 'Impuestos',
            icon: 'coin',
            children: [
                {
                    id: 'taxes',
                    label: 'Configuración',
                    path: RoutePath.TAXES
                }
            ]
        })

        const productsItems: MenuItem[] = [
            {
                id: 'products',
                label: 'Lista de Productos',
                path: RoutePath.PRODUCTS
            },
            {
                id: 'category-list',
                label: 'Categorías',
                path: RoutePath.CATEGORIES
            },
            {
                id: 'sale-yield',
                label: 'Rentabilidad',
                path: RoutePath.SALE_YIELD
            }
        ]
        if (canSaveProductPrices) {
            productsItems.push({
                id: 'import-prices',
                label: 'Actualizar Precios',
                path: `${RoutePath.PRODUCTS}/${RoutePath.PRICES}`
            })
        }

        return [
            {
                id: 'stock',
                label: 'Stock',
                icon: 'boxes',
                children: [
                    {
                        id: 'stock-list',
                        label: 'Existencias',
                        path: RoutePath.STOCK
                    },
                    {
                        id: 'product-list',
                        label: 'Lista de Productos',
                        path: RoutePath.PRODUCTS
                    }
                ]
            },
            {
                id: 'company',
                label: companyName,
                icon: 'building',
                children: companyItems
            },
            {
                id: 'administration',
                label: 'Administración',
                icon: 'person-badge',
                children: administrationItems
            },
            {
                id: 'purchases-menu',
                label: 'Compras',
                icon: 'cart3',
                children: [
                    {
                        id: 'purchases',
                        label: 'Órdenes de Compra',
                        path: RoutePath.PURCHASES
                    },
                    {
                        id: 'products',
                        label: 'Productos',
                        icon: 'box-seam',
                        children: productsItems
                    },
                    {
                        id: 'suppliers',
                        label: 'Proveedores',
                        path: RoutePath.SUPPLIERS
                    }
                ]
            },
            {
                id: 'sales-menu',
                label: 'Ventas',
                icon: 'tags',
                children: [
                    {
                        id: 'budgets',
                        label: 'Presupuestos',
                        path: RoutePath.BUDGETS
                    },
                    {
                        id: 'sales',
                        label: 'Órdenes de Venta',
                        path: RoutePath.SALES
                    },
                    {
                        id: 'hoards-menu',
                        label: 'Acopios',
                        icon: 'layers',
                        children: [
                            {
                                id: 'hoards',
                                label: 'Lista de Acopios',
                                path: RoutePath.HOARDS
                            },
                            {
                                id: 'hoard-settings',
                                label: 'Configuración',
                                path: `${RoutePath.HOARDS}/${RoutePath.SETTINGS}`
                            }
                        ]
                    },
                    {
                        id: 'customers-menu',
                        label: 'Clientes',
                        icon: 'people-fill',
                        children: [
                            {
                                id: 'customers',
                                label: 'Lista de Clientes',
                                path: RoutePath.CUSTOMERS
                            },
                            {
                                id: 'customer-type-list',
                                label: 'Tipos de Cliente',
                                path: RoutePath.CUSTOMER_TYPES
                            }
                        ]
                    }
                ]
            },
            {
                id: 'warehouse',
                label: 'Depósito',
                icon: 'stack',
                children: [
                    {
                        id: 'purchase-deliveries',
                        label: 'Recepción de Compras',
                        path: RoutePath.PURCHASES
                    },
                    {
                        id: 'sale-deliveries',
                        label: 'Entrega de Ventas',
                        path: RoutePath.SALES
                    },
                    {
                        id: 'hoard-deliveries',
                        label: 'Entrega de Acopios',
                        path: RoutePath.HOARDS
                    }
                ]
            }
        ]
    }

    return {
        getMenu
    }
}
