import { useChildrenActions } from "../../../../actions/childrenActions"
import { BaseCallback, ItemCallback, ListCallback, SubmitChildrenFn } from "../../../../actions/types"
import { Data } from "../../../../features/ui"
import { DataType } from "../../../../tables"
import { Currency } from "../../../../tables/types/types"
import { newId } from "../../../../tables/utils"
import { callSequentiallyForDataTypes } from "../../../../utils/fnUtils"
import { define } from "../../../../utils/typeUtils"
import { OrderDelivery, OrderStatus, useOperationsActions, useOperationsHooks } from "../../../operations"
import { useStockActions } from "../../../stock"
import { useSystemStateHooks } from "../../../system"
import { HoardOrder, HoardItem } from "../../state/types"
import { useCommonActions } from "./commonActions"

export const useProductHoardActions = () => {
    const { getPendingQuantity } = useOperationsHooks()
    const stateCompanyId = useSystemStateHooks().companyId
    const commonActions = useCommonActions()
    const operationsActions = useOperationsActions()
    const stockActions = useStockActions()
    const { submitChildren } = useChildrenActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId

        const saveProductHoard = async (
            hoard: HoardOrder,
            hoardItemsData: Data[],
            callback?: ItemCallback
        ) => {
            const { saveHoard } = commonActions(define(companyId))
            const onFinish = () => callback && callback(hoard)

            const hoardItems = hoardItemsData.map(hoardItemData => ({
                ...hoardItemData,
                companyId: define(companyId),
                id: newId(),
                hoardId: hoard.id,
                currency: Currency.ARS
            })) as HoardItem[]

            const savedHoard = await saveHoard(hoard) as HoardOrder

            const saveItems = (hoardItems: HoardItem[], onSaveItems?: ListCallback) =>
                saveProductHoardItems(hoardItems, savedHoard.branchId, onSaveItems)
            submitChildren([], hoardItems, saveItems as SubmitChildrenFn, undefined, onFinish)
        }

        const saveProductHoardItems = (hoardItems: HoardItem[], branchId: string, callback?: ListCallback) => {
            const { saveHoardItems } = commonActions(define(companyId))
            const { reserveStock } = stockActions()

            const onUpdateStock = async () => {
                const hoardItemsDT = await saveHoardItems(hoardItems)
                callback && callback(hoardItemsDT)
            }

            const updateStock = (hoardItemDT: DataType, onUpdateItemStock: BaseCallback) => {
                const { productId, lotId, quantity } = hoardItemDT as HoardItem
                reserveStock(productId, branchId, quantity, lotId, onUpdateItemStock)
            }

            callSequentiallyForDataTypes(hoardItems, updateStock, onUpdateStock)
        }

        const saveProductHoardDelivery = async (
            hoard: HoardOrder,
            hoardItems: HoardItem[],
            delivery: OrderDelivery
        ) => {
            const { saveHoard } = commonActions(define(companyId))
            const { fetchDeliveries, saveDelivery } = operationsActions()
            const { decreaseReservedStock } = stockActions()
            
            const deliveries = await fetchDeliveries(hoard.id) as OrderDelivery[]
            const savedDelivery = await saveDelivery(delivery) as OrderDelivery 

            let pendingItemsCount = 0
            savedDelivery.deliveredItems.forEach(deliveredItem => {
                const deliveredHoardItem = hoardItems.find(hoardItem => hoardItem.id === deliveredItem.itemId)
                const pendingQuantity = getPendingQuantity(define(deliveredHoardItem) as HoardItem, deliveries)
                deliveredItem.delivered < pendingQuantity && pendingItemsCount++
            })
            const updatedHoard: HoardOrder = {
                ...hoard,
                branchId: savedDelivery.branchId,
                status: pendingItemsCount === 0 ? OrderStatus.DELIVERED : OrderStatus.PARTIALLY_DELIVERED
            }

            const savedHoard = await saveHoard(updatedHoard) as HoardOrder

            savedDelivery.deliveredItems.forEach(deliveredItem => {
                const hoardItem = define(hoardItems.find(hoardItem => hoardItem.id === deliveredItem.itemId))
                decreaseReservedStock(hoardItem.productId, savedDelivery.branchId, deliveredItem.delivered, hoardItem.lotId)
            })

            return savedHoard
        }

        return {
            saveProductHoard,
            saveProductHoardDelivery
        }
    }
}
