import { useEffect, useState } from "react"
import { PanelLoader } from "../../../ui"
import { useLoad } from "./load"
import { Outlet } from "react-router-dom"
import { DataType } from "../../../../tables"

export const IdElement = () => {
    const [dataType, setDataType] = useState<DataType>()

    const load = useLoad()

    useEffect(() => {
        load().then(dataTypeResult => dataTypeResult && setDataType(dataTypeResult))
    }, [])

    return (
        <PanelLoader ready={!!dataType}>
            <Outlet />
        </PanelLoader>
    )
}
