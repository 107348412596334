import { useSelector } from "react-redux"
import { State } from "../../../state"
import { bindActionCreators } from "redux"
import { useDispatch } from "react-redux"
import * as actionCreators from '../state/actionCreators'
import { PurchasesState } from "../state/reducer"
import { PurchaseOrder, Supplier } from "../state/types"
import { define, normalize } from "../../../utils/typeUtils"
import { useFind } from "../../../state/reducers/hooks"
import { DataType, TableName } from "../../../tables"
import { useFiltersHooks } from "../../../features/ui"

export const usePurchasesState = (): PurchasesState => useSelector((state: State) => state.purchasesReducer)

export const usePurchasesStateActions = () => bindActionCreators(actionCreators, useDispatch())

export const usePurchasesHooks = () => {
    const { purchases } = usePurchasesState()
    const { search } = useFiltersHooks()
    const find = useFind()

    const getBranchPurchases = (branchId: string) => purchases.filter(purchase => purchase.deliveryBranchId === branchId)

    const getSupplierMatchFn = (supplier: Supplier, words: string[]) => {
        const { code, name, taxId } = supplier
        
        const values = [
            code.toString(),
            normalize(name),
            taxId.toString()
        ]

        const matchCount = words.reduce((count, word) => 
            values.some(value => value.includes(word)) ? count + 1 : count
        , 0)

        return matchCount
    }

    const searchSuppliers = (suppliers: Supplier[], text = '', showAll = false): Supplier[] => {
        const matchFn = (supplierDT: DataType, words: string[]) =>
            getSupplierMatchFn(supplierDT as Supplier, words)
        const sortFn = (supplierDT_A: DataType, supplierDT_B: DataType) => 
            (supplierDT_A as Supplier).name.localeCompare((supplierDT_B as Supplier).name)
        
        return search(suppliers, matchFn, sortFn, text, showAll) as Supplier[]
    }

    const getPurchaseMatchFn = (purchase: PurchaseOrder, words: string[]) => {
        const { code, supplierId } = purchase
        const supplier = define(find(TableName.SUPPLIERS, supplierId)) as Supplier
            
        const values = [
            code.toString(),
            normalize(supplier.name)
        ]

        const matchCount = words.reduce((count, word) => 
            values.some(value => value.includes(word)) ? count + 1 : count
        , 0)

        return matchCount
    }

    const searchPurchases = (purchaseList: PurchaseOrder[], text = '', showAll = false): PurchaseOrder[] => {
        const matchFn = (purchaseDT: DataType, words: string[]) =>
            getPurchaseMatchFn(purchaseDT as PurchaseOrder, words)
        const sortFn = (purchaseDT_A: DataType, purchaseDT_B: DataType) => 
            (purchaseDT_B as PurchaseOrder).deliveryDate.localeCompare((purchaseDT_A as PurchaseOrder).deliveryDate)
        
        return search(purchaseList, matchFn, sortFn, text, showAll) as PurchaseOrder[]
    }

    return {
        getBranchPurchases,
        searchSuppliers,
        searchPurchases
    }
}
