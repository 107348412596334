import styles from './budgetDetail.module.css'
import { DataType, TableName } from '../../../../../tables'
import { Action, Spinner, ModalId, Data, UIPermission, useUIActions, DetailPanel, DetailFieldProps, Tab, FieldType, SpinnerDiv, useGetCrumbs } from '../../../../../features/ui'
import { Budget, BudgetItem } from '../../../state/types'
import { useSalesActions } from '../../../actions/salesActions'
import { useEffect, useState } from 'react'
import { Operation, OperationItemsTable, usePricesHooks, PriceDetail } from '../../../../operations'
import { useSystemStateHooks } from '../../../../system'
import { BudgetPDF } from '../budget-pdf/budgetPDF'
import { BudgetPDFModal } from '../budget-pdf-modal/budgetPDFModal'
import { BudgetPriceList } from '../budget-price-list/budgetPriceList'
import { useSalesHooks } from '../../../hooks/salesHooks'
import { RoutePath, useRoutesHooks } from '../../../../../features/routes'
import { useNavigate } from 'react-router-dom'
import { useDescriptorHooks } from '../../../../../features/data-types'

export const BudgetDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const budget = dataType as Budget | undefined

  const [budgetItems, setBudgetItems] = useState<BudgetItem[]>([])
  const [subtotalPrice, setSubtotalPrice] = useState<number>(budget?.totalPrice || 0)
  const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
  
  const { userHasPermission } = useSystemStateHooks()
  const pricesHooks = usePricesHooks()
  const descriptorHooks = useDescriptorHooks()
  const { getBudgetPricesList } = useSalesHooks()
  const navigate = useNavigate()

  const salesActions = useSalesActions()
  const { toggleModal } = useUIActions()

  const readOnly = !userHasPermission(UIPermission.SAVE_BUDGETS)

  useEffect(() => {
    const onFetchBudgetItems = (budgetItemsDT: DataType[]) => {
      const budgetItems = budgetItemsDT as BudgetItem[]  
      setBudgetItems(budgetItems)
      const fullPrice = pricesHooks.sale.fullPriceFromItems(budgetItems)
      setSubtotalPrice(fullPrice)
      setAsyncDataLoaded(true)
    }
    
    budget && salesActions().fetchBudgetItemsByOrder(budget.id, onFetchBudgetItems)
  }, [])

  const crumbs = useGetCrumbs('Presupuestos', RoutePath.BUDGETS, budget ? descriptorHooks.budget.code(budget) : undefined)

  if (!budget) return <SpinnerDiv />
  
  const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

  const fields: DetailFieldProps[] = [
    { name: 'date', type: FieldType.DATE, label: 'Fecha' },
    { name: 'sellerId', type: FieldType.TABLE, table: TableName.EMPLOYEES, label: 'Vendedor' },
    { name: 'customerId', type: FieldType.TABLE, table: TableName.CUSTOMERS, label: 'Cliente' },
    {
      name: 'budgetItems',
      type: FieldType.TABLE,
      table: TableName.BUDGET_ITEMS,
      render: () => renderAsync(
        <OperationItemsTable
            rows={budgetItems as Data[]}
            operation={Operation.SALE}
            readOnly
        />
      )
    },
    {
      name: 'totalPrice',
      type: FieldType.PRICE,
      label: 'Precio',
      render: () => (
        <PriceDetail
          subtotal={subtotalPrice}
          customerAdjustment={budget.totalPrice - subtotalPrice}
          total={budget.totalPrice}
        />
      )
    }
  ]

  const renderPricesContent = (
    <BudgetPriceList priceItems={getBudgetPricesList(budget.totalPrice)} />
  )

  const renderPDFContent = (
    <div className={styles.pdfContent}>
      <BudgetPDF
        id="budget-pdf-preview"
        budget={budget}
        previewMode
      />
    </div>
  )

  const tabs: Tab[] = [
    { id: 'payment-type-prices', title: 'Medios de Pago', content: renderPricesContent },
    { id: 'pdf', title: 'PDF', content: renderPDFContent }
  ]

  const onDownloadPDF = () => toggleModal(ModalId.BUDGET_PDF)

  const onRefresh = () => navigate(RoutePath.UPDATE)

  const onCreateSale = () => navigate(RoutePath.CREATE_SALE)

  const onRemove = () => toggleModal(ModalId.REMOVE)

  const actions: Action[] = [
    { label: 'Descargar', kind: 'tertiary', icon: 'download', onClick: onDownloadPDF }
  ]
  if (!readOnly) {
    actions.push(
      { label: 'Actualizar', icon: 'arrow-repeat', onClick: onRefresh },
      { label: 'Crear Venta', icon: 'tags-fill', onClick: onCreateSale },
      { label: 'Eliminar', kind: 'secondary', icon: 'trash-fill', onClick: onRemove }
    )
  }

  return (<>
    <DetailPanel
      tableName={TableName.BUDGETS}
      crumbs={crumbs}
      fields={fields}
      data={budget}
      icon='calculator'
      tabs={tabs}
      imgSpace={3}
      readOnly={readOnly}
      actions={actions}
    />
    <BudgetPDFModal budget={budget} />
  </>)
}
