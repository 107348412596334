import { useNavigate } from "react-router-dom"
import { showToast } from "../../../../features/ui"
import { Lot, Product } from "../../../products"
import { useWarehouseActions } from "../../../warehouse"
import { Stock } from "../../state/types"

export const useActions = () => {
    const navigate = useNavigate()
    const warehouseActions = useWarehouseActions()

    const findLotStock = (
        productStockList: Stock[],
        product?: Product,
        lot?: Lot
    ) => {
        return product && productStockList.find(productStock =>
            productStock.productId === product.id && productStock.lotId === lot?.id
        )
    }

    const updateStock = (
        productId: string,
        quantity: number,
        branchId: string,
        lotId: string,
        addMode = true
    ) => {
        const { increaseStock, decreaseStock } = warehouseActions()
        const updateStockFn = addMode ? increaseStock : decreaseStock
        const onUpdateStock = () => showToast('El stock fue actualizado.')
        updateStockFn(productId, branchId, quantity, lotId, onUpdateStock)
        navigate(-1)
    }
    
    return {
        findLotStock,
        updateStock
    }
}
