import AWS from 'aws-sdk'
import { awsRegion, awsS3Bucket, awsS3PublicBucket } from '../constants'
import { ErrorType } from '../../actions/types'

export class S3Api {
    private readonly client: AWS.S3

    constructor(
        readonly accessKeyId: string,
        readonly secretAccessKey: string,
    ) {
        const config = {
            region: awsRegion,
            credentials: {
                accessKeyId,
                secretAccessKey
            }
        }
        this.client = new AWS.S3(config)
    }

    private s3ErrorFromMsg(errorMsg: string) {
        return new Error(`S3 Error: ${errorMsg}`)
    }

    private s3Error(error: Error) {
        return this.s3ErrorFromMsg((error as ErrorType).message)
    }

    async getFile(filePath: string, fileName: string, publicBucket = false): Promise<File> {
        try {
            const params = {
                Bucket: publicBucket ? awsS3PublicBucket : awsS3Bucket,
                Key: filePath
            }
            return this.client.getObject(params).promise().then(data => {
                if (!data.Body) {
                    throw this.s3ErrorFromMsg('No response body received')
                }
                return new File([data.Body as ArrayBufferView], fileName)
            })

        } catch (error) {
            throw this.s3Error(error as Error)
        }
    }
    
    async saveFile(filePath: string, file: File, publicBucket = false): Promise<string> {
        try {
            const params = {
                Bucket: publicBucket ? awsS3PublicBucket : awsS3Bucket,
                Key: filePath,
                Body: file,
                ContentType: file.type,
            }
            return this.client.upload(params).promise().then(data => data.Location)
        } catch (error) {
            throw this.s3Error(error as Error)
        }
    }

    async removeFile(filePath: string, publicBucket = false): Promise<void> {
        try {
            const params = {
                Bucket: publicBucket ? awsS3PublicBucket : awsS3Bucket,
                Key: filePath
            }
            this.client.deleteObject(params).promise().then(_ => {})
        } catch (error) {
            throw this.s3Error(error as Error)
        }
    }
}
