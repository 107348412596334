import { useNavigate, useParams } from "react-router-dom"
import { Request, RequestStatus, UserRole, useSystemActions, useSystemStateActions } from "../../../../modules/system"
import { define, firstItem } from "../../../../utils/typeUtils"
import { Emitter, Event, useInitEmitter } from "../../../event-emitter"
import { useServicesHooks } from "../../../../services"
import { getInitialRoute, roleName } from "../../../roles"
import { TenantKey, UniqueKey } from "../../../../tables/constants"
import { useManagerActions, useManagerStateActions } from "../../../../modules/manager"
import { useProductsActions } from "../../../../modules/products"
import { usePurchasesActions } from "../../../../modules/purchases"
import { useSalesActions } from "../../../../modules/sales"
import { useStockActions } from "../../../../modules/stock"
import { Employee, useAdministrationActions, useAdministrationStateActions } from "../../../../modules/administration"
import { useRoutesHooks } from "../../hooks/routesHooks"
import { RoutePath } from "../../types"

export const useLoad = () => {
    const { companyCode } = useParams<{ companyCode: string }>()
    const { loadUser, parseUrl } = useRoutesHooks()
    const { pathParts } = parseUrl()
    
    const { initUserApis } = useServicesHooks()
    const navigate = useNavigate()

    const { setCurrentEmployee } = useAdministrationStateActions()
    const { setCurrentBranch } = useManagerStateActions()
    const { setUserRequest } = useSystemStateActions()

    const administrationActions = useAdministrationActions()
    const managerActions = useManagerActions()
    const productsActions = useProductsActions()
    const purchasesActions = usePurchasesActions()
    const salesActions = useSalesActions()
    const stockActions = useStockActions()
    const systemActions = useSystemActions()

    useInitEmitter()

    return async () => {
        const { fetchAllCompanies, fetchCompanyByCode } = systemActions()

        const code = define(companyCode)
        const companies = await fetchAllCompanies()
        const company = companies.find(company => company.code === code)

        if (company) {
            const user = await loadUser()

            if (user)  {
                await fetchCompanyByCode(code, true)
                const { fetchRequest, fetchAllRequests } = systemActions(company.id)
                const request = await fetchRequest(company.id, user.id) as Request | null
                request && setUserRequest(request)

                if (request && request.status === RequestStatus.APPROVED) {
                    const companyId = request.companyId
                    const userEmail = define(user.email)
                    await initUserApis(roleName(code, request.role), userEmail, TenantKey, UniqueKey)

                    const branches = await managerActions(companyId).fetchAllBranches()
                    setCurrentBranch(define(firstItem(branches)))
                    
                    const { fetchAllSettings, fetchAllCashFunds, fetchAllPaymentTypes, fetchAllEmployees, fetchAllExpenseTypes, fetchEmployeeByEmail } = administrationActions(companyId)
                    const { fetchAllCategories, fetchAllProducts, fetchAllProductPrices, fetchAllLots, fetchAllMeasures } = productsActions(companyId)
                    const { fetchAllSuppliers } = purchasesActions(companyId)
                    const { fetchAllBudgets, fetchAllCustomerTypes, fetchAllCustomers } = salesActions(companyId)
                    const { fetchAllStock, fetchAllUnitTypes } = stockActions(companyId)

                    const employee = await fetchEmployeeByEmail(userEmail) as Employee | null
                    if (employee) {
                        setCurrentEmployee(employee as Employee)
                    } else {
                        Emitter.emit(Event.EMPLOYEE_MISSING, request)
                    }
                    await fetchAllSettings()
                    await fetchAllBudgets()
                    await fetchAllCashFunds()
                    await fetchAllCategories()
                    await fetchAllCustomerTypes()
                    await fetchAllCustomers()
                    await fetchAllEmployees()
                    await fetchAllExpenseTypes()
                    await fetchAllPaymentTypes()
                    await fetchAllProducts()
                    await fetchAllLots()
                    await fetchAllSuppliers()
                    await fetchAllStock()
                    await fetchAllUnitTypes()
                    await fetchAllProductPrices()
                    await fetchAllMeasures()
                    request.role === UserRole.MANAGER && await fetchAllRequests()

                    if (pathParts.length === 1) {
                        const initialRoute = getInitialRoute(request.role)
                        navigate(initialRoute)
                    }
                } else {
                    navigate(RoutePath.ACCESS)
                }
            }
        } else {
            navigate(RoutePath.ERROR)
        }   
    }
}
