import styles from './importPricesPanel.module.css'
import { PanelWrapper } from "../../../../components/wrappers/panel-wrapper/panelWrapper"
import { UploadButton } from "../../../../components/button/upload-button/uploadButton_OLD"
import { Button } from '../../../../components/button/button'
import { useState } from 'react'
import { useActions } from './actions'
import { FileTile, FileType, useGetCrumbs } from '../../../../features/ui'
import { RoutePath } from '../../../../features/routes'

export const ImportPricesPanel = () => {
    const [file, setFile] = useState<File>()
    const { updatePrices } = useActions()

    const crumbs = useGetCrumbs('Productos', RoutePath.PRODUCTS, undefined, undefined, 'Actualizar Precios')

    const onSelectFile = (newFile?: File) => setFile(newFile)

    const onUpdatePrices = () => file && updatePrices(file)
    
    return (
        <PanelWrapper title="Actualizar Lista de Precios" crumbs={crumbs}>
            <div>
                <div className={styles.btnContainer}>
                    <FileTile fileType={FileType.CSV} fileName={file?.name} className={styles.tile} />
                    {file && (
                        <Button
                            label='Actualizar precios'
                            onClick={onUpdatePrices}
                            className={styles.submitBtn}
                        />
                    )}
                    <UploadButton
                        id='import-csv'
                        label='Importar Archivo .csv'
                        kind='tertiary'
                        fileTypes='.csv'
                        onSelectFile={onSelectFile}
                    />
                </div>
                <div className={styles.info}>
                    <i className="ri-information-line" />
                    <label>Importar un archivo con formato .csv con la siguiente estructura:</label>
                </div>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Código Proveedor</th>
                            <th scope="col">Proveedor</th>
                            <th scope="col">Precio</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>001</td>
                            <td>Proveedor A</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>002</td>
                            <td>Proveedor B</td>
                            <td>2000</td>
                        </tr>
                        <tr>
                            <td>003</td>
                            <td>Proveedor C</td>
                            <td>3000.50</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </PanelWrapper>
    )
}
