import { useDefaultModuleActions } from "../../../actions/defaultModuleActions"
import { OptionalItemCallback } from "../../../actions/types"
import { Emitter, Event } from "../../../features/event-emitter"
import { DynamoUserApi } from "../../../services"
import { TextParams } from "../../../state/reducers/types"
import { DataType, TableName } from "../../../tables"
import { Currency } from "../../../tables/types/types"
import { newId } from "../../../tables/utils"
import { define } from "../../../utils/typeUtils"
import { useSystemStateHooks } from "../../system"
import { Stock, UnitType } from "../state/types"

export const useStockActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId
    const defaultModuleActions = useDefaultModuleActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const tenantModuleActions = defaultModuleActions(DynamoUserApi, define(companyId))
        const moduleActions = defaultModuleActions(DynamoUserApi)

        const fetchAllStock = async (limit?: number, startKey?: TextParams) => {
            return tenantModuleActions.fetchAll(TableName.STOCK, limit, startKey)
        }

        const fetchProductStock = (productId: string) => {
            return tenantModuleActions.fetchMultipleByParams_async(TableName.STOCK, { productId })
        }

        const fetchProductBranchStock = (productId: string, branchId: string) => {
            return tenantModuleActions.fetchMultipleByParams_async(TableName.STOCK, { productId, branchId })
        }

        const fetchStock = async (
            productId: string,
            branchId: string,
            lotId?: string,
            callback?: OptionalItemCallback
        ) => {
            if (lotId) {
                const params = { productId, branchId, lotId }
                tenantModuleActions.fetchByParams(TableName.STOCK, params, callback)
            } else {
                const stockList = await fetchProductBranchStock(productId, branchId) as Stock[]
                const noLotStock = stockList.find(stock => !stock.lotId)
                callback && callback(noLotStock)
            }
        }

        const saveStock = (stock: Stock) => {
            return tenantModuleActions.save_async(TableName.STOCK, stock)
        }

        const modifyStock = (
            productId: string,
            branchId: string,
            lotId: string,
            availableUnitsDiff = 0,
            reservedUnitsDiff = 0,
            callback?: OptionalItemCallback
        ) => {
            const onSaveStock = (stockDT: DataType) => {
                Emitter.emit(Event.UPDATE_STOCK, stockDT as Stock)
                callback && callback(stockDT)
            }

            const onFetchStock = (stateStockDT?: DataType) => {
                const stateStock = stateStockDT as Stock | undefined
                let stock: Stock
                if (stateStock) {
                    stock = {
                        ...stateStock,
                        availableUnits: stateStock.availableUnits + availableUnitsDiff,
                        reservedUnits: stateStock.reservedUnits + reservedUnitsDiff
                    }
                } else {
                    stock = {
                        companyId: define(companyId),
                        id: newId(),
                        productId,
                        lotId,
                        availableUnits: availableUnitsDiff,
                        reservedUnits: reservedUnitsDiff,
                        currency: Currency.ARS,
                        branchId
                    }
                }
                
                saveStock(stock).then(onSaveStock)
            }

            fetchStock(productId, branchId, lotId, onFetchStock)
        }

        const increaseStock = (
            productId: string,
            branchId: string,
            quantity: number,
            lotId: string,
            callback?: OptionalItemCallback
        ) => {
            quantity > 0 && modifyStock(productId, branchId, lotId, quantity, 0, callback)
        }

        const decreaseStock = (
            productId: string, 
            branchId: string,
            quantity: number,
            lotId: string,
            callback?: OptionalItemCallback
        ) => {
            quantity > 0 && modifyStock(productId, branchId, lotId, -quantity, 0, callback)
        }

        const reserveStock = (
            productId: string, 
            branchId: string,
            quantity: number,
            lotId: string,
            callback?: OptionalItemCallback
        ) => {
            quantity > 0 && modifyStock(productId, branchId, lotId, -quantity, quantity, callback)
        }
        
        const enableStock = (
            productId: string,
            branchId: string,
            quantity: number,
            lotId: string,
            callback?: OptionalItemCallback
        ) => {
            quantity > 0 && modifyStock(productId, branchId, lotId, quantity, -quantity, callback)
        }

        const decreaseReservedStock = (
            productId: string,
            branchId: string,
            quantity: number,
            lotId: string,
            callback?: OptionalItemCallback
        ) => {
            quantity > 0 && modifyStock(productId, branchId, lotId, 0, -quantity, callback)
        }

        const removeStock = (id: string) => {
            return tenantModuleActions.remove_async(TableName.STOCK, id)
        }

        const fetchAllUnitTypes = async () => {
            return moduleActions.fetchAll(TableName.UNIT_TYPES).then(response => response.dataTypes as UnitType[])
        }

        return {
            fetchAllStock,
            fetchProductStock,
            fetchProductBranchStock,
            saveStock,
            increaseStock,
            decreaseStock,
            reserveStock,
            enableStock,
            decreaseReservedStock,
            removeStock,
            fetchAllUnitTypes
        }
    }
}
